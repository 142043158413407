<div class="content steps "
     [ngClass]="'layout-align-'+layoutAlign+ ' layout-'+layout"
     fxFlex
     [fxLayout]="layout"
     [fxLayoutAlign]="layoutAlign"
     fxLayoutGap="30px">
    <div *ngFor="let step of options.steps; index as i; let last = last"
         [ngClass]="{'active':step.isActive,'current': step.isCurrent, 'last': last }"
         (click)="clickOnElem(step)"
         fxLayoutAlign="space-evenly center"
         class="step" >

        <span class="number" >{{getLabelToDisplay(step)}}</span>

        <mat-icon svgIcon="arrow_right" class="arrow" [ngClass]="arrowPositionClass" *ngIf="step.isArrowActive"></mat-icon>
    </div>

    <div *ngIf="options.recap.isAllowed"
         [ngClass]="{'active':options.recap.isActive }"
         (click)="clickOnElem(null)"
         fxLayoutAlign="space-evenly center"
         class="step">

        <mat-icon svgIcon="flag" class="number flag"></mat-icon>

        <mat-icon svgIcon="arrow_right" class="arrow" [ngClass]="arrowPositionClass" *ngIf="options.recap.isArrowActive"></mat-icon>
    </div>
</div>
