<div class="dialog-content-wrapper">

    <div mat-dialog-title>
        <h2>
            {{'generic.chapters_filter_title' | translate}}
        </h2>
        <button mat-mini-fab
                class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="p-24 m-0" >
        <form  novalidate >
            <div  class="mb-24"
                  fxLayout="row"
                  fxLayoutAlign="start start">
                <mat-form-field class="w-100-p">
                    <mat-select  [(value)]="selectedMethod"
                            placeholder="{{ 'generic.method' | translate }}"
                            (selectionChange)="selectMethod($event, true)">
                        <mat-option value="all">{{ 'generic.all' | translate }}</mat-option>
                        <mat-option [value]="option.id" *ngFor="let option of methods">
                            {{ option?.get('name') }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="mb-24 fontBold">{{ 'generic.select_chapter_help' | translate}}</div>
            <div class="mb-24 fontBold">{{chaptersSelected.length}}
                <span *ngIf="chaptersSelected.length < 2">{{ 'generic.selected_chapter' | translate}}</span>
                <span *ngIf="chaptersSelected.length > 1">{{ 'generic.selected_chapters' | translate}}</span>
                <button  mat-icon-button (click)="resetChapters(true)" *ngIf="chaptersSelected.length > 0">
                     <mat-icon svgIcon="cross"></mat-icon>
                </button>
            </div>

            <div class="mb-24 h-400">

                <div *ngIf="dataSource?.data?.length">
                    <mat-checkbox (change)="updateCheck($event)" [(ngModel)]="selectAll" [ngModelOptions]="{standalone: true}">
                        {{ 'generic.select_all_chapters' | translate }}
                    </mat-checkbox>
                </div>

                <table mat-table #table [dataSource]="dataSource"
                           [@animateStagger]="{value:'50'}">
                    <ng-container matColumnDef="label">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let entity">
                            <p [ngClass]="{'custom' : isChildren(entity)}" class="text-truncate">{{entity.label}}</p>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
                             class="entity"
                             (click)="selectedChapter(entity)"
                             matRipple
                             [ngClass]="{'mat-light-blue-50-bg': checkSelected(entity) > -1}"
                             [@animate]="{value:'*',params:{y:'100%'}}">
                    </tr>
                </table>
            </div>

        </form>
    </div>

    <div class="example-loading-shade"
         *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div mat-dialog-actions>
        <button mat-raised-button
                (click)="dialogRef.close(chaptersSelected)"
                [disabled]="isLoading"
                class="save-button mat-accent"
                aria-label="SAVE">
            {{ 'generic.validate' | translate }}
        </button>

    </div>
</div>
