import {take, takeUntil, tap} from 'rxjs/operators';
import {ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CommunicationCenterService} from '@modules/communication-center';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LRSTracking} from '../../../../../settings';
import {TranslateService} from '@ngx-translate/core';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {Observable, of} from 'rxjs/index';

const settingsStructure = new ModelSchema({
    iframeParams: Structures.array(['uid']),
    dynamicContent: Structures.boolean(false),
    helpButton: Structures.object({})
});

@Component({
    selector: 'app-external',
    templateUrl: './external.component.html'
})
export class ExternalComponent extends BaseActivityComponent<any, any> {

    @ViewChild('iframeViewport', {static: true}) iframeViewport: ElementRef;
    @ViewChild('iframeView') iframeView: ElementRef;
    public referenceActivityGranule: any;
    public iframeUrl: SafeResourceUrl;
    public currentUser: DataEntity;
    public settings: { [key: string]: any };
    private activity = {
        id: null,
        isLoadBeforeLaunch: null
    };
    private isFullscreen = false;
    private LRSTracking = LRSTracking;
    private url: URL;
    private previousIdAssignmentNextEvent: number | string = 0;

    private isInitializing: boolean;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        private changeDetector: ChangeDetectorRef,
        private octopusConnect: OctopusConnectService,
        protected communicationCenter: CommunicationCenterService,
        private sanitizer: DomSanitizer,
        private router: Router,
        private dialog: MatDialog,
        private translate: TranslateService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);

        this.settings = settingsStructure.filterModel(modulesSettings.activities);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.currentUser = data;
                }
            });

        this.activatedRoute.queryParams.subscribe(params => {
            this.activity.id = params.id;
            this.activity.isLoadBeforeLaunch = params.isLoadBeforeLaunch;
        });

        this.octopusConnect.listen('externalAction').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((action: DataEntity) => {
            if (action.get('action') === 'next') {
                this.launchNextActivity();
            }
        });

        this.communicationCenter.getRoom('feedback').getSubject('nextActivity').subscribe(next => {
            if (next) {
                this.launchNextActivity();
            }
        });

        this.communicationCenter.getRoom('feedback').getSubject('needHelp').subscribe(next => {
            if (next) {
                this.openHelp();
            }
        });

        this.octopusConnect.listen('feedbackMessage').pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((message: DataEntity) => {
            if (message.get('type') === 'disclaimer') {
                this.openDetail(message);
            }
        });
    }

    public openDetail(message: DataEntity): void {
        if (message.get('detail')) {
            const config = {
                titleDialog: '',
                bodyDialog: message.get('detail'),
                labelTrueDialog: 'OK',
                labelOtherDialog: ''
            };

            if (message.get('type') === 'disclaimer') {
                this.translate.get('generic.start').subscribe((translation: string) => {
                    config.labelTrueDialog = translation;
                });

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                    panelClass: 'feedback-earning-dialog',
                    data: config
                });

                dialogRef.afterClosed().subscribe(_result => {
                    this.activitiesService.currentAssignment.set('hideFeedbacks', false);
                    this.launchNextActivity();
                });
            }
        }
    }

    public openHelp(): void {
        if (this.settings.helpButton.data && this.url) {
            this.settings.helpButton.data.payload.sensors.idTask = this.url.searchParams.get('idTask');
            this.settings.helpButton.data.payload.sensors.idLearner = this.url.searchParams.get('uid');
            this.settings.helpButton.data.payload.uid = this.url.searchParams.get('uid');
            this.settings.helpButton.data.assId = this.activitiesService.currentAssignment.id;
            this.octopusConnect.createEntity('feedbacks', this.settings.helpButton.data);
        }
    }

    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange(_event): void {
        this.isFullscreen = !this.isFullscreen;
        setTimeout(() => {
            this.iframeSizeByRatio();
        }, 1000);
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.iframeSizeByRatio();
    }

    public onIframeLoad(): void {
        this.iframeSizeByRatio();
    }

    /**
     * load next recommendation (which is stored in userSave)
     */
    public launchNextActivity(): void {
        this.loadUserSave();
        this.communicationCenter.getRoom('feedback').next('reset', true);
    }

    /**
     * Control the display of next button for this activity type
     */
    public displayUserActionButton(): boolean {
        return false;
    }

    protected setContentData(activityAttributes): void {
        this.activityType = activityAttributes.metadatas.typology.label;
        this.referenceActivityGranule = activityAttributes.reference;
        this.loadUserSave();
    }

    /**
     * Load userSave which store the iframe url and add params depending on instance settings or redirect to the end of the curent lesson
     */
    protected loadUserSave(): void {
        this.activitiesService.getUserSave(this.activityId.id, this.assignmentId).pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                // we not send another next if it was already done for the same id assignment
                if (this.activitiesService.currentAssignment
                    && (this.previousIdAssignmentNextEvent === 0 || +this.previousIdAssignmentNextEvent !== +this.activitiesService.currentAssignment.id)) {
                    this.previousIdAssignmentNextEvent = this.activitiesService.currentAssignment.id;
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: this.activitiesService.currentAssignment.id, event: 'next'});
                }

                this.userSave = userSave;

                let tempUrl = this.referenceActivityGranule.instruction;
                if (this.settings.dynamicContent && this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.content[0]) {
                    tempUrl = this.userSave.get('userActivity').entitySave.content[0];
                }

                if (tempUrl === 'end') {
                    if (this.activitiesService.currentAssignment &&
                        this.activitiesService.currentAssignment.get('type_term') &&
                        this.activitiesService.currentAssignment.get('type_term').label === 'init') {
                        this.activitiesService.metacognition(true);
                    } else {
                        this.router.navigate(['../../recap'], {relativeTo: this.activatedRoute});
                    }
                }

                if (tempUrl) {
                    this.url = new URL(tempUrl);
                    if (this.settings.iframeParams.includes('uid')) {
                        this.url.searchParams.set('uid', this.currentUser.id.toString());
                    }
                    if (this.settings.iframeParams.includes('prenom')) {
                        this.url.searchParams.set('prenom', this.currentUser.get('label'));
                    }
                    if (this.settings.iframeParams.includes('codeclasse')) {
                        this.url.searchParams.set('codeclasse', this.currentUser.get('groups')[0]);
                    }
                    if (this.settings.iframeParams.includes('token')) {
                        this.url.searchParams.set('token', JSON.parse(localStorage.getItem('http_accessToken')));
                    }
                    if (this.settings.iframeParams.includes('assignationId') && this.activitiesService.currentAssignment) {
                        this.url.searchParams.set('assignation_id', this.activitiesService.currentAssignment.id.toString());
                    }
                    if (this.settings.iframeParams.includes('idLesson') && this.activitiesService.currentAssignment) {
                        this.url.searchParams.set('idLesson', this.activitiesService.currentAssignment.id.toString());
                    }
                    if (this.settings.iframeParams.includes('idTask')) {
                        this.url.searchParams.set('idTask', Date.now().toString());
                    }

                    const traceInfos = {
                        uid: this.url.searchParams.get('uid'),
                        idTask: this.url.searchParams.get('idTask')
                    };

                    if (this.LRSTracking === true) {
                        this.communicationCenter
                            .getRoom('traces')
                            .next('infos', traceInfos);
                    }

                    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url.href); // TODO chargée deux fois

                    this.wording = 'this.referenceActivityGranule.wording';
                    this.wordingAudio = 'this.referenceActivityGranule.wordingAudio';
                    this.isInitializing = false;
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: this.activitiesService.currentAssignment.id, event: 'loaded'});
                }
            });
    }

    /**
     * Resets the current component
     */
    protected reset(): Observable<boolean> {
        this.isInitializing = true;
        delete this.referenceActivityGranule;
        delete this.iframeUrl;
        delete this.wording;
        this.changeDetector.detectChanges();
        return super.reset();
    }

    /**
     * resize the image according to its frame/viewport
     */
    private iframeSizeByRatio(): void {
        if (this.iframeView && !this.isFullscreen) {
            const iframe = this.iframeView.nativeElement;
            iframe.height = '';
            iframe.width = '';
            iframe.height = +this.iframeViewport.nativeElement.offsetHeight;
            iframe.width = +this.iframeViewport.nativeElement.offsetWidth;
        }
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }
}
