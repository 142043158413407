<mat-card class="activities-card inner-scroll mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [wording]="wording" [wordingAudio]="wordingAudio" [instructionAudio]="instructionAudio"
                           (isReading)="speakStateChanged($event, uuid)"
                           questionTypeName="{{ 'activities.questionTypeName.memory' | translate }}">
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [ngClass]="{ 'vertical': isVertical}">
        <ng-container *ngIf="!!data">
            <div class="answers-zone">
                <app-memory-card-info (playState)="cardPlayState($event,'left')"
                                      [card]="leftCard"></app-memory-card-info>
                <app-memory-card-info (playState)="cardPlayState($event, 'right')"
                                      [card]="rightCard"></app-memory-card-info>
            </div>
            <app-memory-find-zone
                [ngClass]="rulesForDisabledAllItems()?'disable-items':''"
                (idItemClicked)="changeState($event)"
                [data]="data"
                [selectedItem]="selectedItems()"
                [stateOfLastSelectedItems]="stateOfLastSelectedItems">
            </app-memory-find-zone>
        </ng-container>
    </mat-card-content>
</mat-card>




