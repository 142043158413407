<mat-card>
    <mat-card-header>
        <div *ngIf="displayField('titleIcon')" class="pastille pastille-icon">
            <mat-icon svgIcon="{{ headerTitleIcon }}"></mat-icon>
        </div>

        <mat-card-title *ngIf="displayField('title')">
            <h3>{{ headerTitle }}</h3>
        </mat-card-title>

        <button *ngIf="displayField('menu') && (isLessonOrSequence)" [matMenuTriggerFor]="menu"
                ariaLabelTranslate="{{'generic.menu' | translate}}"
                class="mat-icon-button"
                mat-icon-button matTooltip="{{'generic.menu' | translate}}">
            <mat-icon svgIcon="submenu"></mat-icon>
            <mat-menu #menu="matMenu">
                <button *ngIf="displayMenuLink('edit') && isEditableAndErasable"
                        (click)="dataCard.openEditor(dataCard.resource.id, 'edit', 'lesson')"
                        mat-menu-item>
                    <mat-icon svgIcon="edit"></mat-icon>
                    {{ 'generic.edit' | translate }}
                </button>
                <button *ngIf="displayMenuLink('editAndDeleteAssignments')"
                        (click)="dataCard.editAndDeleteAssignments(dataCard.resource)"
                        mat-menu-item>
                    <mat-icon svgIcon="editAndDeleteAssignments"></mat-icon>
                    {{ 'generic.editAndDeleteAssignments' | translate }}
                </button>
                <button *ngIf="displayMenuLink('assign') && dataCard.isAssignable"
                        (click)="dataCard.openAssign(dataCard.resource)"
                        mat-menu-item>
                    <mat-icon svgIcon="assignment_ind"></mat-icon>
                    {{ 'generic.assign' | translate }}
                </button>
                <button *ngIf="displayMenuLink('duplicateAndAssign') && dataCard.isDuplicable"
                        (click)="dataCard.duplicateAndAssign(dataCard.resource.id)"
                        mat-menu-item>
                    <mat-icon svgIcon="duplicateAndAssign"></mat-icon>
                    {{ 'generic.duplicate_and_assign' | translate }}
                </button>
                <button *ngIf="displayMenuLink('duplicateAndEdit') && dataCard.isDuplicable"
                        (click)="dataCard.duplicateAndEdit(dataCard.resource.id)"
                        mat-menu-item>
                    <mat-icon svgIcon="duplicateAndEdit"></mat-icon>
                    {{ 'generic.duplicate_and_edit' | translate }}
                </button>
                <button *ngIf="displayMenuLink('duplicate') && dataCard.isDuplicable"
                        (click)="dataCard.openDuplicate(dataCard.resource.id)"
                        mat-menu-item>
                    <mat-icon svgIcon="duplicate"></mat-icon>
                    {{ 'generic.clone' | translate }}
                </button>
                <button *ngIf="displayMenuLink('delete') && isEditableAndErasable"
                        (click)="dataCard.openDialog(dataCard.resource)"
                        mat-menu-item>
                    <mat-icon svgIcon="delete"></mat-icon>
                    {{ 'generic.delete' | translate }}
                </button>
                <button *ngIf="displayMenuLink('deleteWithAssignments')"
                        (click)="dataCard.deleteWithAssignments(dataCard.resource)"
                        mat-menu-item>
                    <mat-icon svgIcon="delete"></mat-icon>
                    {{ 'generic.deleteWithAssignments' | translate }}
                </button>
            </mat-menu>
        </button>
    </mat-card-header>

    <img *ngIf="displayField('image') && (typeOfCard.isTheme || typeOfCard.isLesson || typeOfCard.isSequence)"
         mat-card-image src="{{ contentDetails.image?.uri }}" alt="Photo">

    <img *ngIf="displayField('dynamicLevelTypeImage')
    && (typeOfCard.isTheme || typeOfCard.isLesson || typeOfCard.isSequence)"
         mat-card-image [src]="getDynamicLevelTypeImage(dataCard.resource)" alt="level-type-image">

    <img alt="" *ngIf="!typeOfCard.isLesson && !typeOfCard.isSequence && !typeOfCard.isVideo && !typeOfCard.isTheme"
         [ngClass]="getLabelToUse()" src="{{thumb}}" class="card-cover"/>

    <video *ngIf="!typeOfCard.isLesson && !typeOfCard.isSequence && typeOfCard.isVideo" class="card-cover">
        <source src="{{thumb}}" type="{{dataCard.originalResource?.ressourceEntity.get('reference').filemime}}">
        Your browser does not support HTML5 video.
    </video>

    <mat-card-content>
        <dl *ngIf="displayField('author')
                || displayField('chapters')
                || displayField('concepts')
                || displayField('description')
                || displayField('difficulty')
                || displayField('educationalLevel')
                || displayField('period')
                || displayField('skills')
                || displayField('source-author')
                || displayField('theme')"
            fxFlex
            fxLayout="column"
            role="list" class="dl-list">

            <!-- TODO: for each field we need to change all  term interface by "generic.field_..." -->
            <ng-container *ngIf="displayField('description')">
                <div [fxFlexOrder]="getOrder('description')">
                    <dt class="short-description">{{'activities.lesson.short_description' | translate}}</dt>
                    <dd>{{dataCard.resource.get('metadatas')?.description}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('difficulty')&& !this.settings.difficultyDisplaySteps">
                <div [fxFlexOrder]="getOrder('difficulty')">
                    <dt class="difficulty">{{'generic.field_difficulty' | translate}}</dt>
                    <dd>{{dataCard.resource.get('metadatas')?.difficulty?.label}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('difficulty')&& this.settings.difficultyDisplaySteps > 0">
                <div [fxFlexOrder]="getOrder('difficulty')" class="difficulty-dot">
                    <dt class="difficulty">{{'generic.field_difficulty' | translate}}</dt>
                    <dd>
                        <span *ngFor="let difficultyDisplayStep of difficultyDisplaySteps"
                              [ngClass]="(difficultyDisplayStep <= +dataCard.resource.get('metadatas')?.difficulty?.label)?'difficultyStep active':'difficultyStep'">
                        </span>
                    </dd>
                    <dt class="difficulty adaptative">{{'generic.field_difficulty_adaptative' | translate}}</dt>
                    <dd>
                        <span *ngIf="(dataCard.resource.get('metadatas')?.difficulty?.label)">{{'generic.no' | translate}}</span>
                        <span *ngIf="(!dataCard.resource.get('metadatas')?.difficulty?.label)">{{'generic.yes' | translate}}</span>
                    </dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('assignation_type') && getType(dataCard.resource)">
                <div [fxFlexOrder]="getOrder('assignation_type')">
                    <dt class="assignation_type">{{'generic.type' | translate}}</dt>
                    <dd>{{ localizedType(dataCard.resource.get('metadatas')?.assignation_type?.label) | translate }}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="(displayField('target-age') || displayField('educationalLevel')) && getEducationalLevel(dataCard.resource)">
                <div [fxFlexOrder]="displayField('target-age') ? getOrder('target-age') : getOrder('educationalLevel')">
                    <dt class="level">{{ displayField('target-age') ? ('activities.target_age' | translate) : ('generic.level' | translate) }}</dt>
                    <dd>{{dataCard.resource.get('metadatas')?.educationalLevel?.label}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('source-author')">
                <div [fxFlexOrder]="getOrder('source-author')">
                    <dt class="source-author">{{'generic.source_author' | translate}}</dt>
                    <dd>{{dataCard.resource.get('metadatas')['source-author']}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('concepts')">
                <div [fxFlexOrder]="getOrder('concepts')">
                    <dt class="concepts">{{'generic.concepts' | translate}}</dt>
                    <dd>{{concepts}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('chapters')">
                <div [fxFlexOrder]="getOrder('chapters')">
                    <dt>{{'generic.field_chapters' | translate}}</dt>
                    <dd>{{chapters}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('theme')">
                <div [fxFlexOrder]="getOrder('theme')">
                    <dt>{{'generic.theme' | translate}}</dt>
                    <dd>{{dataCard?.resource?.get('theme')?.label}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('skills')">
                <div [fxFlexOrder]="getOrder('skills')">
                    <dt>{{'activities.period' | translate}}</dt>
                    <dd>{{skills}}</dd>
                </div>
            </ng-container>

            <ng-container *ngIf="displayField('share-lesson')">
                <ng-container *ngIf="isCommunityPage">
                    <div [fxFlexOrder]="getOrder('author')">
                        <dt>{{'generic.author' | translate}}</dt>
                        <dd>{{dataCard?.resource?.get('owner-name')}}</dd>
                    </div>
                </ng-container>

                <div *ngIf="isShareableCommunity">
                    <hr/>
                    <dt>{{(!!sharedCommunity ? 'activities.shared_to_community' : 'activities.not_shared_to_community') | translate}}</dt>
                    <dd>
                        <mat-slide-toggle
                            [checked]="sharedCommunity"
                            (change)="toggleShare($event, 'shared')"
                        >
                        </mat-slide-toggle>
                    </dd>
                </div>
                <div *ngIf="isShareableModel">
                    <hr/>
                    <dt>{{(!!sharedAsModel ? 'activities.shared_to_model' : 'activities.not_shared_to_model') | translate}}</dt>
                    <dd>
                        <mat-slide-toggle
                            [checked]="sharedAsModel"
                            (change)="toggleShare($event, 'model')"
                        >
                        </mat-slide-toggle>
                    </dd>
                </div>
            </ng-container>
        </dl>
        <dl *ngIf="displayField('lessonStats') && (isLessonOrSequence)"
            role="list" class="dl-list">
            <div class="lesson-activity-infos">
                <!--lessons & activities ------------------------------------------------------------------- -->
                <div class="lesson-label">
                    <dt *ngIf="lessonCount() >= 1">{{ lessonCount() }} </dt>
                    <dd *ngIf="lessonCount() > 1">{{ 'generic.lessons' | translate }}</dd>
                    <dd *ngIf="lessonCount() === 1"
                        class="lesson-label">{{ 'generic.lesson' | translate }}</dd>
                </div>
                <div class="activity-label" *ngIf="activityCount() >= 0">
                    <dt>{{ activityCount() }}</dt>
                    <dd *ngIf="activityCount() > 1"
                        class="activity-label">{{ 'generic.activities' | translate }}</dd>
                    <dd *ngIf="activityCount() <= 1">{{ 'generic.activity' | translate }}</dd>
                </div>
                <div class="other-label" *ngIf="otherCount() >= 0">
                    <dt>{{ otherCount() }}</dt>
                    <dd *ngIf="otherCount() > 1"
                        class="other-label">{{ 'generic.resources' | translate }}</dd>
                    <dd *ngIf="otherCount() <= 1">{{ 'generic.resource' | translate }}</dd>
                </div>
            </div>
            <!--licenseContent ------------------------------------------------------------------- -->
            <div class="lesson-license-content" *ngIf="metadatas?.licenseContent[0]?.label">
                <dt class="lesson-name-label">{{ 'activities.lesson.name' | translate }}</dt>
                <dd>{{ metadatas?.licenseContent[0]?.label }}</dd>
            </div>
            <!--GetDate ------------------------------------------------------------------- -->
            <div class="get-date-label">
                <dt>{{ 'activities.metadata.title' | translate }}</dt>
                <dd>{{ dataCard.resource.GetDate }}</dd>
            </div>
        </dl>
        <dl role="list" class="dl-list keywords-list">
            <!--keywords ------------------------------------------------------------------- -->
            <div *ngIf="displayField('keywords') && (isLessonOrSequence)" class="keywords">
                <dt *ngFor="let keyword of metadatas?.indexation">
                    {{ keyword.label }}
                </dt>
            </div>
            <!--metadatas ------------------------------------------------------------------- -->
            <mat-chip-list *ngIf="displayField('metadatas') && (isLessonOrSequence)" class="metadatas">
                <mat-chip (click)="dataCard.openMetaDialog($event, metadatas)"
                          class="meta-info">
                    <mat-icon svgIcon="info"></mat-icon>
                    <span>{{'activities.metadata' | translate}}</span>
                </mat-chip>
            </mat-chip-list>
        </dl>
    </mat-card-content>

    <mat-card-actions>
        <!--assign------------------------------------------------------------------- -->
        <span *ngIf="displayField('assigned') && dataCard.displayAssignedCount && (isLessonOrSequence)"
              class="info-assignated" fxLayout="row" fxLayoutAlign="start center"
              (click)="openAssignments()">
            <mat-icon [ngClass]="{'active': assignedCount >= 1 }"
                      svgIcon="assignment_ind">
            </mat-icon>
            <label *ngIf="assignedCount !== 0">

                {{ 'generic.assignated_to' | translate }}
                {{assignedCount}}&nbsp;

                <span *ngIf="assignedCount > 1">
                    {{ 'generic.times' | translate }}
                </span>
                <span *ngIf="assignedCount === 1">
                    {{ 'generic.time' | translate }}
                </span>
            </label>
        </span>
        <!--duplication------------------------------------------------------------------- -->
        <span *ngIf="displayField('duplication') && dataCard.displayDuplicationCount && (isLessonOrSequence)"
              class="info-assignated" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon [ngClass]="{'active': duplicationCount >= 1 }"
                      svgIcon="duplicate">
            </mat-icon>
            <ng-container *ngIf="duplicationCount !== 0">

                {{ 'generic.duplicated' | translate }}
                {{duplicationCount}}&nbsp;

                <span *ngIf="duplicationCount > 1">
                    {{ 'generic.times' | translate }}
                </span>
                <span *ngIf="duplicationCount === 1">
                    {{ 'generic.time' | translate }}
                </span>
            </ng-container>
        </span>
        <!--theme-------------------------- TODO afficher si il y a un theme ----------------------------------------- -->
        <button
            *ngIf="settingsCardsDisplay.showButtonThemeLesson && dataCard.displayTheme(dataCard.resource) && (isLessonOrSequence)"
            mat-mini-fab (click)="dataCard.playAlt(dataCard.resource)" class="button-theme" role="button">
            <mat-icon svgIcon="book_open" aria-label="go to theme"></mat-icon>
        </button>
        <!--course------------------------------------------------------------------- -->
        <button title="{{ 'play.lesson' | translate }}"
                *ngIf="settingsCardsDisplay.showButtonThemeLesson && dataCard.displayLesson(dataCard.resource) && (isLessonOrSequence)"
                mat-mini-fab (click)="dataCard.playAlt(dataCard.resource)" class="button-course" role="button">
            <mat-icon svgIcon="layers" aria-label="start course"></mat-icon>
        </button>

        <!--------------------Play lesson link in theme card-------------->
        <button title="{{ 'play.lesson' | translate }}" *ngIf="isLessonAllowedAndExist" mat-mini-fab
                (click)="playLesson()" class="button-download-theme"
                role="button">
            <mat-icon svgIcon="collections_bookmark" aria-label="start course"></mat-icon>
        </button>

        <!---------------------download student pdf if is theme ------------------------->
        <button title="{{ 'download.student.pdf' | translate }}" *ngIf="isPdfAllowedAndExist && !typeOfCard.isTheme" mat-mini-fab
                (click)="downloadFile(fileType.themePedagoDocPdf)"
                class="button-download-theme" role="button">
            <mat-icon svgIcon="file_download" aria-label="download student pdf"></mat-icon>
        </button>

        <!--------------------- TODO download pdf if is lesson ------------------------->
        <button title="{{ 'lesson.download.pdf' | translate }}"
                *ngIf="isPdfLessonAllowedAndExist" mat-mini-fab (click)="downloadFile(fileType.lessonPdf)"
                class="button-download-theme" role="button">
            <mat-icon svgIcon="file_download" aria-label="download lesson"></mat-icon>
        </button>

        <!-------------- download pdf theme in lesson----------------->
        <button title="{{ 'lesson.download.theme' | translate }}"
                *ngIf="isPdfThemeAllowedAndExist()"
                mat-mini-fab (click)="downloadFile(fileType.themePdf)"
                class="button-download-theme" role="button">
            <mat-icon svgIcon="book_open" aria-label="download theme"></mat-icon>
        </button>

        <!--bookmarks------------------------------------------------------------------- -->
        <button *ngIf="showFavoriteButton"
                (click)="bookmark()"
                [ngClass]="{'active': isFavorite }"
                class="button-favorite flag" mat-raised-button role="button" fxLayout="row"
                fxLayoutAlign="start center">
            <mat-icon svgIcon="heart"
                      aria-label="favorite">
            </mat-icon>
            <span>{{ 'generic.favoris' | translate }}</span>
        </button>
        <!--Play--------------------------------------------------------------------->
        <button title="{{ 'play.lesson' | translate }}"
                *ngIf="!displayField('playMetadata') && isLessonOrSequence && !settingsCardsDisplay.isTextButton" mat-mini-fab (click)="play(dataCard)"
                [matMenuTriggerFor]="menuBottom"
                id="button-play-1" class="button-play" role="button">
            <mat-icon svgIcon="play" aria-label="Play lesson"></mat-icon>
        </button>
        <button title="{{ 'play.lesson' | translate }}"
                *ngIf="displayField('playMetadata')"
                mat-mini-fab (click)="dataCard.openMetaDialog($event, metadatas)"
                id="button-play-1.2" class="button-play" role="button">
            <mat-icon svgIcon="play" aria-label="Play lesson"></mat-icon>
        </button>

        <ng-container  *ngIf="isLessonOrSequence && settingsCardsDisplay.isTextButton">
            <!-- TODO build a generic setting for button mode -->
            <button title="{{ 'play.lesson' | translate }}"
                    *ngIf="displayMenuWithPreviewOption() === true" mat-raised-button
                    (click)="play(dataCard)" [matMenuTriggerFor]="menuBottom"
                    id="button-play-2" class="button-play" role="button">
                <span>{{ 'generic.lesson.play' | translate }}</span>
            </button>

            <button title="{{ 'play.lesson' | translate }}"
                    *ngIf="displayMenuWithPreviewOption() === false" mat-raised-button
                    (click)="play(dataCard)"
                    id="button-play-2" class="button-play" role="button">
                <span>{{ 'generic.lesson.play' | translate }}</span>
            </button>
        </ng-container>

        <button *ngIf="!typeOfCard.isTheme && !typeOfCard.isLesson && !typeOfCard.isSequence" mat-mini-fab
                (click)="dataCard.play(dataCard.originalResource)" id="button-play-3" class="button-play" role="button">
            <mat-icon svgIcon="play" aria-label="Play lesson"></mat-icon>
        </button>
        <!----------------------isTheme button style play make a download of pedago doc ------------------------------>
        <button *ngIf="isAllowedDownloadLesson && !settingsCardsDisplay.isTextButton" mat-mini-fab (click)="download()"
                id="button-play-4" class="button-play" role="button">
            <mat-icon svgIcon="play" aria-label="Play lesson"></mat-icon>
        </button>

        <button title="{{ 'download.pedago.doc' | translate }}"
                *ngIf="isAllowedDownloadPedagoDoc && settingsCardsDisplay.isTextButton && dataCard?.resource?.get('reference')?.length"
                mat-raised-button
                (click)="download()"
                id="button-play-5" class="button-play" role="button">
            <span>{{ 'generic.theme.play' | translate }}</span>
        </button>

        <mat-menu #menuBottom="matMenu">
            <button mat-menu-item (click)="dataCard.play(dataCard.resource, true)">
                {{ 'generic.run_preview' | translate }}
            </button>
            <button mat-menu-item (click)="playerNumberModal(dataCard)">
                {{ 'generic.run_lesson' | translate }}
            </button>
        </mat-menu>
    </mat-card-actions>
</mat-card>


