<div mat-dialog-title>
    <h2 *ngIf="titleDialog?.length > 0">
        {{titleDialog}}
    </h2>
    <button mat-mini-fab
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <div *ngIf="enableTTS" class="tts">
        <div appReadable (speaking)="speakStateChanged($event)" [ngClass]="{'tts-reading': isTTSSpeaking }" [innerHTML]="bodyDialog"></div>
        <mat-icon *ngIf="!isTTSSpeaking" svgIcon="sound_off"></mat-icon>
        <mat-icon *ngIf="isTTSSpeaking" svgIcon="sound_on"></mat-icon>
    </div>
    <div *ngIf="!enableTTS" [innerHTML]="bodyDialog"></div>
    <input matInput #inputToFocus [placeholder]="labelConfirmDialogPlaceHolder" *ngIf="labelConfirmDialog?.length > 0" [(ngModel)]="inputValue"  type="text" >
</div>
<div mat-dialog-actions>
    <button *ngIf="labelConfirmDialog?.length > 0" [disabled]="inputValue ==='' || inputValue === undefined" (click)="dialogRef.close(inputValue)" mat-raised-button class="mat-accent mr-16" >{{labelConfirmDialog}}</button>
    <button *ngIf="labelTrueDialog?.length > 0" (click)="dialogRef.close(true)"  mat-raised-button class="mat-accent mr-16" >{{labelTrueDialog}}</button>
    <button *ngIf="labelFalseDialog?.length > 0" [ngClass]="{'mat-accent':!(labelTrueDialog?.length > 0)}" mat-raised-button class="mr-16" (click)="dialogRef.close(false)">{{labelFalseDialog}}</button>
    <button *ngIf="labelOtherDialog?.length > 0" [ngClass]="{'mat-accent':!(labelOtherDialog?.length > 0)}" mat-raised-button class="mr-16" (click)="dialogRef.close('otherButtonDialogAction')">{{labelOtherDialog}}</button>
    <app-error-reporting-trigger-button *ngIf="showReportbutton" [type]="'resource'" [activityId]="resourceId" [objectTitle]="titleDialog"></app-error-reporting-trigger-button>
</div>
