import {of as observableOf, Subscription, Subject, iif, Observable} from 'rxjs';

import {takeUntil, filter, mergeMap, map, take, tap} from 'rxjs/operators';
import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {Router} from '@angular/router';

import {FlagService} from '../../../../../../shared/flag.service';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {FormControl} from '@angular/forms';
import {DataCollection, DataEntity, OctopusConnectService, OrderCriteria, OrderDirection} from 'octopus-connect';
import {LessonMetadataDialogComponent} from '../lesson-metadata-dialog/lesson-metadata-dialog.component';
import {brand} from '../../../../../../settings';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {DeleteLessonWarningComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/delete-lesson-warning/delete-lesson-warning.component';
import {EditLessonWarningComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/edit-lesson-warning/edit-lesson-warning.component';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';
import {ModalPageComponent} from 'fuse-core/components/basic-page/modal-page/modal-page.component';

@Component({
    selector: 'fuse-app-lessons-tab',
    templateUrl: './lessons-tab.component.html'
})

export class LessonsTabComponent implements OnInit, OnDestroy, AfterViewInit {
    corpusId: string;
    resourcesSubscription: Subscription;
    resources;
    selectAll = false;
    isChecked = false;
    files: any;
    dataSource = new MatTableDataSource();
    displayedColumns;
    displayedFiltersIcons;
    selected;
    numberOfActivities: number;
    items: any[];
    types: string[];
    private paginator: MatPaginator;
    private sort: MatSort;
    private optionsInterface: CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject();
    public levelsDataEntity: DataEntity[] = [];
    public fieldsToDisplay: string[] = [];
    public videoData: any;
    public showLoader: boolean = false; // show a loader during soma action duplicate lesson delete lesson etc.

    @Input('type') type: string;
    @Input() creatorsRolesFilter: number[];

    countEntities = 0;
    pageIndex = 0;
    pageRange = 12;
    pageRangeOptions = [12];
    public customFieldName: { field: string, value: string }[] = [{field: 'title', value: ''}];

    allTypes = [];
    public titleFilter: FormControl;
    public licenseFilter: FormControl;
    public searchSkillsFilter: FormControl;
    public searchThemeFilter: FormControl;
    public educationnalLevelFilter: FormControl;

    public brand: string = brand;

    public dataCards: any[] = [];
    private dataCardsCallbacks: { [key: string]: any };
    private dataCardSettings: { [key: string]: any };

    selectedResources;
    checkboxes: {};

    dialogRef: MatDialogRef<any>;

    @ViewChild('titleInput') titleInput: MatInput;
    public skillsList: any;
    public themeList: any;


    @ViewChildren(MatCheckbox) checkBoxes: QueryList<MatCheckbox>;
    public methods: any[] = [];

    @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

    /**
     * show info if allowed in setting and if type is not model (model = byRole) because user never create lesson model by front
     */
    public get displayCreateLessonHelper(): boolean {
        return this.lessonsService.settings.displayCreateLessonHelper && this.type !== 'byRole';
    }

    constructor(
        public activitiesService: ActivitiesService,
        public lessonsService: LessonsService,
        public lessonEditionService: LessonEditionService,
        public flagService: FlagService,
        private router: Router,
        public dialog: MatDialog,
        private octopusConnect: OctopusConnectService,
        public datacardService: DatacardService,
        private authService: AuthenticationService,
        private translate: TranslateService,
    ) {
        if (this.displayField('educationnalLevel')) {
            this.setEducationnalLeveList();
        }

        if (this.displayField('skills')) {
            this.getSkills();
        }

        if (this.displayField('theme')) {
            this.getThemes();
        }

        this.lessonsService.onFilesChanged.subscribe(files => {
            this.files = files;
            this.checkboxes = {};
        });

        this.lessonsService.onSelectedResourcesChanged.subscribe(resources => {
            for (const id in this.checkboxes) {
                if (!this.checkboxes.hasOwnProperty(id)) {
                    continue;
                }
                this.checkboxes[id] = resources.includes(id);
            }
            this.selectedResources = this.resources;
        });

        this.lessonsService.onFileSelected.subscribe(selected => {
            this.selected = selected;
        });

        this.optionsInterface = {
            filter: {}, // filter results by current user id
            page: 1,
            range: 12
        };

    }

    ngAfterViewInit(): void {
        this.setDataSourceAttributes();
    }

    ngOnInit(): void {
        this.showLoader = true;
        this.lessonsService.loadVideoHelpUrl()
            .pipe(
                take(1),
                tap((videoData) => this.videoData = videoData && videoData.length ? videoData[0] : null)
            ).subscribe();

        // init title filter with good value
        this.customFieldName[0].value = this.activitiesService.settings.filterTitleToTranslate;
        this.displayedFiltersIcons = this.activitiesService.settings.displayedFiltersIcons;

        this.resourcesSubscription = this.refreshList();

        this.titleFilter = new FormControl('');
        this.licenseFilter = new FormControl('');
        this.searchSkillsFilter = new FormControl('');
        this.searchThemeFilter = new FormControl('');
        this.educationnalLevelFilter = new FormControl('');

        if (this.lessonsService.checkAccess(['manager'])) {
            this.activitiesService.getMethods().pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe((methods) => {
                methods.entities.forEach(method => {
                    this.methods.push({
                        id: method.id,
                        label: method.get('name')
                    });
                });
            });
        } else {
            this.activitiesService.licensingMethods.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((methods) => {
                    this.methods = methods;
                });
        }

        this.dataCardsCallbacks = {
            isEditableAndErasable: (resource) => this.datacardService.isEditableAndErasable(resource, this.type),
            openAssign: (resource) => this.openAssign(resource),
            openDialog: (resource) => this.openDeleteDialog(resource),
            duplicateAndAssign: (id) => this.duplicateAndAssign(id, 'lesson'),
            duplicateAndEdit: (id) => this.duplicateAndEdit(id, 'lesson'),
            openDuplicate: (id, action, type) => this.launchEditor(id, 'copy', 'lesson'),
            openEditor: (id, action, type) => this.launchEditor(id, 'edit', 'lesson'),
            openMetaDialog: (event, metadata) => this.openMetaDialog(event, metadata),
            play: (resource, preview?: boolean) => this.lessonsService.navigateToLesson(resource, null, preview),
            downloadDoc: (path) => this.datacardService.downloadDoc(path),
            share: (resource, val) => this.lessonsService.editGranuleLesson(resource, val),
            isShareableCommunity: this.lessonsService.isShareableCommunity,
            isShareableModel: this.lessonsService.isShareableModel,
            isContextDefinedItAsModel: this.type === 'byRole',
            deleteWithAssignments: (resource: DataEntity) => this.deleteWithAssignments(resource),
            editAndDeleteAssignments: (resource: DataEntity) => this.editAndDeleteAssignments(resource)
        };

        this.dataCardSettings = {
            assignable: this.lessonsService.settings.assignableLesson
        };
        this.setDisplayField();
    }

    /**
     * launch editor from service
     * @param id :  id
     * @param action : copy edit etc.
     * @param type : lesson form etc.
     */
    private launchEditor(id: string, action: string, type: string): Observable<DataEntity> {
        this.showLoader = true;

        return this.lessonsService.launchEditor(id, action, type, true, this.router.url)
            .pipe(
                tap(() => this.showLoader = false)
            );
    }

    /**
     * manage delete lesson without assignment lamorim for example
     * @param ressource : dataentity
     */
    private openDeleteDialog(resource: DataEntity): void {
        this.showLoader = true;
        this.lessonsService.openDeleteDialog(resource)
            .subscribe(res => {
                },
                error => {
                    throw error;
                },
                () => this.showLoader = false
            );

    }


    /**
     * set array with fields to show in regard to settings
     */
    public setDisplayField(): void {
        this.fieldsToDisplay = this.settings.searchFields;
    }

    /**
     * set the educationnal level list !!!! PROBABLY NOT USED NOT REMOVED IN CASE OF UNDIRECT CALL
     */
    private setEducationnalLeveList(): void {
        this.activitiesService.getEducationnalLevel().subscribe((dataEducationnalLevel: DataEntity[]) => {
            this.levelsDataEntity = dataEducationnalLevel;
        }, error => {
            console.log(error);
        });
    }

    /**
     * show or not filter Field
     */
    public displayField(filterField: string): boolean {
        return this.activitiesService.settings['searchFields'].filter(field => field === filterField).length > 0;
    }

    launchSearch(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12}): void {
        this.resourcesSubscription.unsubscribe();
        this.resourcesSubscription = this.refreshList(optionsInterface);
    }

    refreshList(optionsInterface: CollectionOptionsInterface = {filter: {}, page: 1, range: 12, orderOptions: []}): Subscription {
        this.optionsInterface = _.cloneDeep(optionsInterface);
        // some spécific filter not coming from search filter top component

        this.optionsInterface.filter['multi_step'] = this.settings.loadLessonWithSublesson['multi_step'];
        this.optionsInterface.filter['typology'] = this.settings.loadLessonWithSublesson['typology'];


        if (this.settings && !!this.settings.shareableModel && !!this.creatorsRolesFilter) {
            this.optionsInterface.filter['model'] = this.settings.shareableModel;
        }
        // for erasme back order by default by title metadata force on changer for users lessons
        if (this.type === 'currentUser') {
            this.optionsInterface.orderOptions = [{field: 'changed', direction: OrderDirection.DESC}];
        }
        this.setFiltersByUrl();

        return this.resourcesSubscription = this.lessonsService.loadPaginatedLessons(this.type, this.creatorsRolesFilter, '', this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(resources => {
                    // sort by created date desc
                    if (!resources) {
                        return;
                    }
                    resources.forEach((resource) => {
                        resource['LocaleDateCreated'] = this.lessonsService.localeDate(resource.attributes.metadatas.created);
                        resource['LocaleDateChanged'] = this.lessonsService.localeDate(resource.attributes.metadatas.changed);
                        resource['GetDate'] = this.lessonsService.getDate(resource);
                    });

                    const entities = resources;

                    this.dataCards = this.datacardService.processResources(entities, this.dataCardsCallbacks, this.type, this.dataCardSettings);

                    // TODO directly get sort from webservice
                    this.resources = resources.sort((a, b) => 0 - (a['attributes'].metadatas.changed > b['attributes'].metadatas.changed ? 1 : -1));
                    this.displayedColumns = ['checkbox', 'type', 'title', 'utilisation', 'favori', 'evaluation', 'actions'];
                    this.numberOfActivities = resources.length;
                    this.setPaginator();
                    this.showLoader = false; // needed because of subscribing with takeUntil complete will be fired only ondestroy
                }, error => {
                    throw error;
                },
                () => {
                    this.showLoader = false;
                }
            );
    }

    /**
     * apply filter in regard of setting filtertoApplyOnLessonsByUrl and of current route
     * filter use taxonomy terme on back and field chapters
     */
    private setFiltersByUrl(): void {
        this.settings.filtertoApplyOnLessonsByUrl.forEach((urlToTest: { url: string, id: number }) => {
            if (this.router.url.includes(urlToTest.url)) {
                this.optionsInterface.filter['chapters'] = urlToTest.id;
            }
        });
    }

    public openMetaDialog(e, metadatas): void {
        e.stopPropagation();
        this.dialogRef = this.dialog.open(LessonMetadataDialogComponent, {
            data: {
                metadatas,
                settings: {lessonMetadataDialogFields: this.lessonsService.getLessonMetadataDialogFieldsForCurrentRole()}
            }
        });
    }

    setPaginator(): void {
        switch (this.type) {
            case 'currentUser':
                if (this.lessonsService.userLessonsPaginated.paginator) {
                    this.countEntities = this.lessonsService.userLessonsPaginated.paginator.count;
                    this.pageIndex = this.lessonsService.userLessonsPaginated.paginator.page - 1;
                    this.pageRange = this.lessonsService.userLessonsPaginated.paginator.range;
                }
                break;
            case 'byRole':
                if (this.lessonsService.lessonsPaginated.paginator) {
                    this.countEntities = this.lessonsService.lessonsPaginated.paginator.count;
                    this.pageIndex = this.lessonsService.lessonsPaginated.paginator.page - 1;
                    this.pageRange = this.lessonsService.lessonsPaginated.paginator.range;
                }
                break;
        }
    }


    /**
     * scroll to top when changing page
     */
    public scrollToTop(): void {
        this.scrollContainer.nativeElement.scrollTop = 0;
    }

    onPaginateChange(event, type): void {
        this.showLoader = true;
        this.scrollToTop();
        switch (type) {
            case 'currentUser':
                this.lessonsService.userLessonsPaginated.paginator.page = event.pageIndex + 1;
                break;
            case 'byRole':
                this.lessonsService.lessonsPaginated.paginator.page = event.pageIndex + 1;
                break;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    setDataSourceAttributes(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    applyFilters(event, type): void {
        if (event.value === 'all' || event.value === '') {
            delete this.optionsInterface.filter[type];
        } else {
            this.optionsInterface.filter[type] = event.value;
        }
    }

    openAssign(lesson): void {
        if (this.activitiesService.assignmentView) {
            this.dialogRef = this.dialog.open(this.activitiesService.assignmentView, {
                data: {
                    nodeId: lesson.id,
                    node: lesson
                }
            });
        }

        this.dialogRef.afterClosed().subscribe((data) => {
            if (data && lesson.get('locked') !== '1') {
                const entity = new DataEntity('granule-lesson', lesson.attributes, this.octopusConnect, lesson.id);
                entity.set('locked', true);
                this.showLoader = true;
                entity.save()
                    .subscribe(success => {
                            if (success) {
                                this.lessonsService.userLessonsPaginated.paginator.reload();
                                this.lessonsService.selectedIndexChange(0);
                            }
                            this.showLoader = false; // needed because never complete specificity of entity.save() subcription
                        }, error => {
                            throw error;
                        },
                        () => this.showLoader = false
                    );
            }
        });
    }

    updateCheck(): void {
        this.isChecked = !this.isChecked;
    }

    updateCheckBookmarks(event): any {
        this.optionsInterface.filter['bookmarks'] = event.checked;
        this.launchSearch(this.optionsInterface);
    }

    private get settings(): any {
        return this.activitiesService.settings;
    }

    public get showSpinner(): boolean {
        return this.lessonsService.showSpinner;
    }

    /**
     * focus on title filter after select a skill
     */
    public blurElementRef(): void {
        this.titleInput.focus();
    }

    /**
     * get the list of skills for the list in filter field
     */
    private getSkills(): void {
        this.lessonsService.getSkills().pipe(takeUntil(this.unsubscribeInTakeUntil),
            take(1), )
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.skillsList = data.entities;
                }
            });
    }

    /**
     * get all themes
     */
    private getThemes(): void {
        this.lessonsService.getThemes().pipe(takeUntil(this.unsubscribeInTakeUntil),
            take(1), )
            .subscribe((data: DataCollection) => {
                if (data.entities) {
                    this.themeList = data.entities;
                }
            });
    }

    /**
     * check if we show add button
     * @returns {boolean}
     */
    public get showAddLessonButton(): boolean {
        if ((this.type !== 'byRole') &&
            this.router.url.includes('community')) {
            return !this.settings.hideAddButtonLessonForCommunity;
        }
        return (this.type !== 'byRole' && !this.settings.hideAddButtonLessonList) ||
            this.type === 'byRole'
            && this.lessonsService.getAllowedRolesForModelsCreation().includes(this.authService.accessLevel)
            && !this.settings.hideAddButtonLessonForModel;
    }

    /**
     * check if we show add Card button
     * @returns {boolean}
     */
    public get showAddLessonButtonCard(): boolean {
        return this.type !== 'byRole' ? this.settings.showAddLessonButtonCard : false;
    }

    /**
     * check if we show community helper
     * @returns {boolean}
     */
    public get displayCommunityLessonHelper(): boolean {
        return this.router.url.includes('community');
    }

    /**
     * play help video
     */
    public playHelpVideo(): void {
        const dialogConfig = new MatDialogConfig();

        this.translate.get('generic.help.video').subscribe((translation: string) => {
            dialogConfig.data = {
                titleDialog: translation
            };
            dialogConfig.panelClass = ['help-lessons-tab-dialog', 'wide-dialog'];
        });

        dialogConfig.data.bodyDialog = '<video controls>' +
            '<source src="' + this.videoUrlToLaunch() + '" type="video/mp4">' +
            'Your browser does not support HTML5 video.' +
            '</video>';

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                window.open('uri', '_blank');
            }
        });
    }

    /**
     * return the video help url in regard of lang and place
     */
    private videoUrlToLaunch(): string {
        let lessonType: string;
        if (this.type === 'currentUser') {
            lessonType = 'userLessons';
        }
        if (this.type === 'byRole') {
            lessonType = 'modelsLessons';
        }
        if (this.router.url.includes('community')) {
            lessonType = 'sharedLessons';
        }
        return this.videoData && this.videoData.get('helpLessonVideo') &&
            this.videoData.get('helpLessonVideo')[lessonType] &&
            this.videoData.get('helpLessonVideo')[lessonType][this.translate.currentLang];
    }

    private duplicateAndAssign(lessonGranuleId: number | string, type: string): void {
        this.showLoader = true;
        this.lessonsService.getLessonObs(lessonGranuleId.toString(), true).pipe(
            take(1),
            mergeMap(() => this.lessonsService.lessonDuplication(lessonGranuleId, type)),
            mergeMap((duplicatedResult) => this.lessonsService.getLessonObs(duplicatedResult.get('duplicateGranuleLesson').nid, true).pipe(
                take(1)
            )),
            map((duplicatedLesson) => this.openAssign(duplicatedLesson))
        ).subscribe(res => {
            }, error => {
                throw error;
            },
            () => this.showLoader = false
        );
    }

    private duplicateAndEdit(lessonGranuleId: number | string, type: string): void {
        this.showLoader = true;
        this.lessonsService.launchCopyLessonEditor('copy', lessonGranuleId.toString(), type).pipe(
            take(1),
            mergeMap((duplicatedLesson) => this.lessonEditionService.openEditor(duplicatedLesson.id, null, this.router.url))
        ).subscribe(() => this.showLoader = false);
    }

    /**
     * Remove an lesson and his assignment
     * If there are any assignment, a modal will ask the user if he is sure.
     * @param resource
     * @private
     */
    private deleteWithAssignments(resource: DataEntity): void {
        this.lessonsService.getAssignmentsCountByLessonId(resource.id).pipe(
            take(1),
            mergeMap(count => {
                if (count === 0) {
                    this.showLoader = true;
                    return this.lessonsService.openDeleteDialog(resource, false);
                } else {
                    return this.dialog.open(DeleteLessonWarningComponent, {data: {count}}).afterClosed().pipe(
                        filter((isConfirmed: boolean) => isConfirmed),
                        tap(() => this.showLoader = true),
                        mergeMap(() => new DataEntity('granule', resource.attributes, this.octopusConnect, resource.id).remove()),
                        filter(isDeleted => isDeleted),
                        tap(() => this.refreshList()),
                    );
                }
            })
        ).subscribe(res => {
            }, error => {
                throw error;
            },
            () => this.showLoader = false);
    }

    /**
     * Edit a lesson and remove assignments
     * If there are any assignment, a modal will ask the user if he is sure.
     * @param resource
     * @private
     */
    private editAndDeleteAssignments(resource: DataEntity): void {
        this.lessonsService.getAssignmentsCountByLessonId(resource.id).pipe(
            take(1),
            mergeMap(count => {
                if (count === 0) {
                    return this.launchEditor(resource.id.toString(), 'edit', 'lesson');
                } else {
                    // l'icon + la modification normale sur les models + la nouvelle sur les lessons
                    return this.dialog.open(EditLessonWarningComponent, {data: {count}}).afterClosed().pipe(
                        take(1),
                        filter((isConfirmed: boolean) => isConfirmed),
                        tap(() => {
                            // It is intentionally in tap and not mergeMap so as not to have to wait for the end of the deletion
                            this.lessonsService.deleteLessonAssignments(resource.id).subscribe();
                        }),
                        mergeMap(() => this.launchEditor(resource.id.toString(), 'edit', 'lesson')),
                    );
                }
            })
        ).subscribe();
    }

    private getTypeOfPage(): 'byRole' | 'currentUser' | 'community' {
        if (this.router.url.includes('community')) {
            return 'community';
        } else if (this.type === 'byRole') {
            return 'byRole';
        } else {
            return 'currentUser';
        }
    }

    public shouldDisplayHelpModalPage(): boolean {
        return _.get(this.settings, 'displayHelpModalPage', []).includes(this.getTypeOfPage());
    }

    public displayHelpModalPage(): void {
        this.dialog.open(ModalPageComponent, {
            panelClass: ['help-lessons-tab-modalePage-dialog', 'wide-dialog'],
            data: {alias: this.type === 'byRole' ? 'help_models' : 'help_my_lessons'}
        });
    }
}
