<mat-card class="activities-card inner-scroll mat-elevation-z0 wrapper">
    <mat-card-header></mat-card-header>
    <mat-card-content class="wrapper">
        <div class="center-middle">

            <img *ngIf="isImage" [src]="media.uri" alt="">

            <video *ngIf="isVideo" controls>
                <source [src]="media.uri" [type]="media.filemime">
                {{ 'generic.video_support' | translate }}
            </video>

            <audio *ngIf="isAudio" controls>
                <source [src]="media.uri" [type]="media.filemime">
                {{ 'generic.audio_support' | translate }}
            </audio>

            <p *ngIf="isDocument">
                <a href="{{media.uri}}" rel="nofollow noreferrer noopener" target="_blank" title="">
                    {{media.uri}}
                </a>
            </p>

            <div *ngIf="isUrl && videoUrl" class="videoUrl">
                <iframe
                    [src]="urlMedia"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen
                    frameborder="0"
                    height="360"
                    mozallowfullscreen
                    webkitallowfullscreen
                    width="640">
                </iframe>
            </div>

            <p *ngIf="isUrl && !videoUrl">
                <a href="{{urlMedia}}" rel="nofollow noreferrer noopener" target="_blank" title="">
                    {{urlMedia}}
                </a>
            </p>

        </div>
    </mat-card-content>
    <mat-card-footer></mat-card-footer>
</mat-card>
