<div *ngIf="graphGenerated" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="pb-80 w-90-p" style="margin: 0 auto; position: relative">
  <canvas
          #chart
          baseChart
          [chartType]="chartType"
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
          [legend]="legend"
          [colors]="chartColors"
          (chartClick)="onChartClick($event)">
  </canvas>

  <info-graph [style.width.px]="settingsWrapper?.wrapperWidth" [style.height.px]="settingsWrapper?.wrapperHeight" *ngIf="showTooltip" [infoSettings]="infoSettings"></info-graph>
</div>
