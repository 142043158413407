export const accountManagement = {
    bottomActionButton: ['learner', 'trainer', 'manager', 'administrator'],
    canEditAvatar: [],
    canSelfDelete: ['learner', 'trainer', 'manager', 'administrator'],
    allowedMaxSize: 1,
    displayDialogMinorAdult: false,
    displayFilters: false,
    fields: {
        learner: ['label|required', 'topEditButton', 'studentCode'],
        default: ['label|required', 'email|required', 'password|required', 'you_are', 'topEditButton', 'studentCode'] // label = pseudo
    },
    joinClassByCode: true, // an user can join class with a code
    defaultValues: {
        youAre: 'teen'
    },
    youAreValues: ['teen', 'teacher'], // 6 ou autre
    selfSignup: true,
    passwordPolicy: {
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
    },
    validateEmailStrategyActivated: true,
    learnerCanEditMail: false,
    showFormTextRedirection: true,
    mailConfirmActionButton: true,
};
