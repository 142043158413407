import {DataEntity} from 'octopus-connect';
import {EditableActivity} from '@modules/activities/core/lessons/editor/models/editable-activity.class';

export class EditableLesson {

    private temporaryHiddenActivities: EditableActivity[] = [];

    constructor(private _lessonGranule: DataEntity, private _activities: EditableActivity[]) {
    }

    public get title(): string {
        return this._lessonGranule.get('metadatas').title;
    }

    public get activities(): EditableActivity[] {
        return this._activities;
    }

    public deleteActivity(toDeleteActivity: EditableActivity): void {
        this.temporaryHiddenActivities.push(toDeleteActivity);
        this._activities = this.activities.filter(displayableActivity => displayableActivity !== toDeleteActivity);
    }

    public addActivity(toAddActivity: EditableActivity): void {
        this._activities = [...this._activities, toAddActivity];
    }

    public get granuleLessonEntity(): DataEntity {
        return this._lessonGranule;
    }


    public jumpBelow(activity: EditableActivity): void {
        this._activities = this.switchPositions(this._activities, this._activities.indexOf(activity), this._activities.indexOf(activity) + 1);
    }

    private switchPositions<T> (arr: T[], oldIndex: number, newIndex: number): T[] {
        const tempArr = arr.slice();
        const tempValue = tempArr[oldIndex];
        tempArr[oldIndex] = tempArr[newIndex];
        tempArr[newIndex] = tempValue;
        return tempArr;
    }
}
