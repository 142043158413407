import {Component, OnInit, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-lesson-metadata-dialog',
    templateUrl: './lesson-metadata-dialog.component.html'
})
export class LessonMetadataDialogComponent implements OnInit {
    public metadata;
    public methodsNames: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: {
            metadatas: any;
            settings: {lessonMetadataDialogFields: string[]};
        },
        public dialogRef: MatDialogRef<LessonMetadataDialogComponent>) {
    }

    ngOnInit(): void {
        this.metadata = this.data.metadatas;
        this.methodsNames = !!this.metadata.licenseContent && this.metadata.licenseContent.map(x => x.label).join(',');
    }

    display(field: string): boolean {
        return this.data.settings.lessonMetadataDialogFields.includes(field);
    }
}
