<div mat-dialog-title>
    <h2>{{ title | translate }}</h2>
    <button mat-mini-fab class="close" (click)="close()" aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>
<mat-dialog-content>
    <app-dynamic [room]="room" [subject]="subject" [params]="params"></app-dynamic>
</mat-dialog-content>

