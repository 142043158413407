<div class="stat-row">
    <label>{{'activities.stars'|translate}}</label>
    <span>{{statistic?.stars}}</span>
</div>
<div class="stat-row">
    <label>{{'activities.diamonds'|translate}}</label>
    <span>{{statistic?.diamonds}}</span>
</div>
<div class="stat-row">
    <label>{{'activities.exercices_completed'|translate}}</label>
    <span>{{statistic?.exercicesCompleted}}</span>
</div>
<div class="stat-row">
    <label>{{'activities.lessons_completed'|translate}}</label>
    <span>{{statistic?.lessonsCompleted}}</span>
</div>