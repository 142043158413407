<mat-card class="activities-card inner-scroll mat-elevation-z0 qcuContainer"
          [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <!--exo instruction pass in the wording field-->
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes [wording]="wording" [wordingAudio]="wordingAudio"></app-consignes>
            <h2 *ngIf="showSubInstruction">{{'activities.sub_instruction_qmcu' | translate}}</h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <!--image to use for zone selection is pass by the back in instruction field
        instruction s not the real instruction here wording is use for show the instruction-->
        <div class="available-answers columns-1">
            <div [ngClass]="containerClass">
                <div *ngIf="instruction" [innerHtml]="instruction | stringToHtml"></div>
                <app-zone-generator (clickEvent)="zoneClickEvent($event)"
                                    [sizeOfZone]="4"
                                    [answers]="answersSelected"></app-zone-generator>
            </div>
        </div>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.try_correct' | translate }}</p>
    </div>

    <mat-card-footer>
        <!--not use-->
    </mat-card-footer>
</mat-card>
