<form name="searchForm" fxFlex fxLayout="column">
    <div fxFlex="1 1 100" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="24px">
        <mat-form-field appMoveElement
                        [movableElementIsActive]="orderFields"
                        [movableElementFields]="fields" [movableElementField]="'type'"
                        *ngIf="displayField('type')">
            <!--TODO replace 'projects-management.lesson_space' by a value coming form input because it could have two value-->
            <mat-select name="typeFilter"
                        [formControl]="controls['typeFilter'].formControl"
                        placeholder="{{ getFieldTerms('type')!=='' ? (getFieldTerms('type') | translate): ('generic.type_global_corpus' | translate) }}">
                <mat-option value="all"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="allTypes" title="audio"></mat-icon>{{ 'generic.all' | translate }}</mat-option>
                <mat-option *ngFor="let type of customLists['types']" [value]="type.id">
                    <span *ngIf="type.id === 'audio'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="audio" title="audio"></mat-icon>{{ 'generic.audio' | translate }}</span>
                    <span *ngIf="type.id === 'document'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="document" title="audio"></mat-icon>{{ 'generic.document' | translate }}</span>
                    <span *ngIf="type.id === 'image'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="image" title="audio"></mat-icon>{{ 'generic.image' | translate }}</span>
                    <span *ngIf="type.id === 'media'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="media" title="audio"></mat-icon>{{ 'generic.media' | translate }}</span>
                    <span *ngIf="type.id === 'text'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="text" title="audio"></mat-icon>{{ 'generic.text' | translate }}</span>
                    <span *ngIf="type.id === 'url'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="url" title="audio"></mat-icon>{{ 'generic.url' | translate }}</span>
                    <span *ngIf="type.id === 'video'"><mat-icon *ngIf="displayedFiltersIcons" svgIcon="video" title="audio"></mat-icon>{{ 'generic.video' | translate }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--TODO replace generic.title by a value coming form input because it could have multiple value-->
        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-title"
                        [movableElementFields]="fields" [movableElementField]="'title'"
                        *ngIf="displayField('title')">
            <input #titleInput="matInput" matInput [formControl]="controls['titleFilter'].formControl"
                   placeholder="{{ getFieldTerms('title')!=='' ? (getFieldTerms('title') | translate): defaultFieldTitle}}">
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-metadatas_title"
                        [movableElementFields]="fields" [movableElementField]="'metadatas_title'"
                        *ngIf="displayField('metadatas_title')">
            <input #titleInput="matInput" matInput [formControl]="controls['metadatas_title'].formControl"
                   placeholder="{{ getFieldTerms('metadatas_title')!=='' ? (getFieldTerms('metadatas_title') | translate): defaultFieldTitle}}">
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-skills"
                        [movableElementFields]="fields" [movableElementField]="'skills'"
                        *ngIf="displayField('skills')">
            <mat-select
                name="searchSkillsFilter"
                [formControl]="controls['searchSkillsFilter'].formControl"
                placeholder="{{ 'generic.skills' | translate }}">
                <mat-option>{{ 'generic.all' | translate }}</mat-option>
                <mat-option *ngFor="let skills of dataLists['skills']" [value]="skills.id">
                    <span>{{skills.get('label')}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-difficulty"
                        [movableElementFields]="fields" [movableElementField]="'difficulty'"
                        *ngIf="displayField('difficulty')">
            <mat-select name="difficultyFilter" placeholder="{{'generic.difficulty' | translate }}"
                        [formControl]="controls['difficultyFilter'].formControl">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let difficulty of dataLists['difficulty']" [value]="difficulty.id">
                    {{difficulty.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-theme"
                        [movableElementFields]="fields" [movableElementField]="'theme'"
                        *ngIf="displayField('theme')">
            <mat-select
                name="searchThemeFilter"
                [formControl]="controls['searchThemeFilter'].formControl"
                placeholder="{{ 'generic.theme' | translate }}">
                <mat-option value="all">{{ 'generic.all' | translate }}</mat-option>
                <mat-option *ngFor="let theme of dataLists['themes']" [value]="theme.id">
                    <span>{{theme.get('label')}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-educationnalLevel"
                        [movableElementFields]="fields" [movableElementField]="'educationnalLevel'"
                        *ngIf="displayField('educationnalLevel')">
            <mat-select name="educationnalLevelFilter" placeholder="{{'generic.level' | translate }}"
                        [formControl]="controls['educationnalLevelFilter'].formControl">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let level of dataLists['educational_level']" [value]="level.id">
                    {{level.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-method"
                        [movableElementFields]="fields" [movableElementField]="'licenseFilter'"
                        *ngIf="displayField('method')">
            <mat-select name="licenseFilter" [formControl]="controls['licenseFilter'].formControl"
                        placeholder="{{ 'licensing.method' | translate }}">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option [value]="method.id" *ngFor="let method of customLists['methods']">
                    {{ method.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-chapters"
                        [movableElementFields]="fields" [movableElementField]="'chapters'"
                        *ngIf="displayField('chapters')">
            <mat-select name="chaptersFilter" placeholder="{{'generic.chapters' | translate }}"
                        [formControl]="controls['chaptersFilter'].formControl">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let chapters of dataLists['chapters']" [value]="chapters.id">
                    {{chapters.get('label')}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-assignation_type"
                        [movableElementFields]="fields" [movableElementField]="'assignation_type'"
                        *ngIf="displayField('assignation_type')">
            <mat-select name="assignation_typeFilter" placeholder="{{'generic.type' | translate }}"
                        [formControl]="controls['assignation_typeFilter'].formControl">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option *ngFor="let assignation_type of dataLists['assignation_type']" [value]="assignation_type.id">
                    {{ localizedType(assignation_type.get('label')) | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-schoolyears"
                        [movableElementFields]="fields" [movableElementField]="'schoolyearsFilter'"
                        *ngIf="displayField('schoolyears')">
            <mat-select name="schoolyearsFilter" [formControl]="controls['schoolyearsFilter'].formControl"
                        placeholder="{{ 'groups-management.chose_school.year' | translate }}">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option
                        *ngFor="let shoolyear of dataLists['schoolyears']"
                        [value]="shoolyear.id">{{ displaySchoolYear(shoolyear.get('name')) }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-lessons"
                        [movableElementFields]="fields" [movableElementField]="'lessonsFilter'"
                        *ngIf="displayField('lessons')">
            <mat-select name="lessonsFilter" [formControl]="controls['lessonsFilter'].formControl"
                        placeholder="{{ 'generic.lessons' | translate }}">
                <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                <mat-option [value]="lesson.id" *ngFor="let lesson of customLists['lessons']">
                    {{ lesson.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <!--author is also use for theme todo field name placeholder -->
        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-author"
                        [movableElementFields]="fields" [movableElementField]="'author'"
                        *ngIf="displayField('author')">
            <input matInput [formControl]="controls['authorFilter'].formControl"
                   placeholder="{{ getFieldTerms('author')!=='' ? (getFieldTerms('author') | translate): ('generic.author_global_corpus' | translate) }}">
        </mat-form-field>

        <!--group is also use for niveau todo field name placeholder-->
        <mat-form-field appMoveElement [movableElementIsActive]="orderFields" class="filters-field-group"
                        [movableElementFields]="fields" [movableElementField]="'group'"
                        *ngIf="displayField('group')">
            <input matInput [formControl]="controls['groupFilter'].formControl"
                   placeholder="{{ getFieldTerms('group')!=='' ? (getFieldTerms('group') | translate): ('generic.group_global_corpus' | translate) }}">
        </mat-form-field>


        <button appMoveElement [movableElementIsActive]="orderFields" class="filters-field-launchSearch"
                [movableElementFields]="fields" [movableElementField]="'launchSearch'"
                mat-raised-button color="primary" (click)="launchSearch()">{{ 'generic.search' | translate }}</button>

        <span appMoveElement [movableElementIsActive]="orderFields" class="filters-field-countEntities"
              [movableElementFields]="fields" [movableElementField]="'countEntities'"
              *ngIf="displayField('countEntities')"
              fxlayout="row" fxlayoutalign="start center">
                  {{countEntities }} {{(countEntities < 2 ? 'generic.result' : 'generic.results') | translate}}
                </span>

        <mat-checkbox appMoveElement [movableElementIsActive]="orderFields" class="filters-field-bookmarks"
                      [movableElementFields]="fields" [movableElementField]="'bookmarks'"
                      [formControl]="controls['searchBookmarkFilter'].formControl"
                      (change)="launchSearch()" *ngIf="displayField('bookmarks')"
                      name="filterBookmarks">
            {{ 'generic.filter_by_bookmarks' | translate }}</mat-checkbox>
    </div>
</form>
