<div class="mat-button-wrapper" (click)="launch(step.id, index, assignment)"  [ngClass]="{'firstLaunch': step.firstLaunch}" appAddClassInRegardOfTextHeight>

    <span [ngClass]="step.firstLaunch?'title first_launch':'title'">{{step.title ? step.title : step.id}}</span>

    <span class="reward-container">
        <!--number of stars-->
        <mat-icon *ngFor="let icon of this.stars" svgIcon="star_1"></mat-icon>
        <!--number of empty icon-->
        <mat-icon *ngFor="let icon of this.empty" svgIcon="star_0"></mat-icon>
        <!--case of diamond win-->
        <mat-icon class="diamond" *ngIf="diamond===1" svgIcon="diamond"></mat-icon>
    </span>

</div>

