<div mat-dialog-title>
    <h2>{{ "add_text_modal.title" | translate }}</h2>
    <button mat-mini-fab class="close" (click)="selfDialogRef.close()" aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content >

<!--    <textarea matInput rows="5" [(ngModel)]="text" [placeholder]="'add_text_modal.placeholder' | translate" name="text"></textarea>-->
    <ckeditor [(ngModel)]="text" [config]="editorConfig" [editor]="editor"></ckeditor>

    <div class="center text-center">
        <button mat-raised-button class="save-button mat-accent"
                (click)="onSave() ">{{'generic.save' | translate}}
        </button>
        <button mat-raised-button class="save-button mat-accent"
                (click)="onCancel() ">{{'generic.cancel' | translate}}
        </button>
    </div>

</mat-dialog-content>