import {AfterViewInit, Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

/**
 * directive for changing content inside text in html
 * we can pass html with class to target specific element inside a text or
 * replace the text.
 * default value is replace all five successives _____ by <span class="glue-the-text">_____</span>
 * to apply a specific css this _____ element of a global text
 */
@Directive({
    selector: '[appReplaceTargetContent]'
})
export class ReplaceTargetContentDirective implements AfterViewInit, OnChanges {
    @Input() target = /_____/g; // regular expression for all iteration of five _ or use string if you target on;y the first occurence
    @Input() htmlReplaceContent = '<span class="glue-the-text">_____</span>'; // html or text to use at the same place

    constructor(private elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.replaceContent();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.replaceContent();
    }

    replaceContent(): void {
        this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.replace(this.target, this.htmlReplaceContent);
    }
}
