<mat-card class="activities-card inner-scroll mat-elevation-z0 ">
    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="data?.instruction" [instructionAudio]="data?.instructionAudio"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content #imgViewport fxLayout="column" fxLayoutAlign="flex-start center">

        <app-consignes [wording]="data?.wording" [wordingAudio]="data?.wordingAudio"></app-consignes>

        <div class="target-area" >
            <!-- to force reload when resize -->
<!--            <div *ngIf="hack"></div>-->
            <!---------dot icone use appPositionAndColor directive to change position and color status----->
            <ng-container *ngIf="!isDotDocOpen && isShowDot">
                <div class="clickable-target" *ngFor="let point of data.activity_content.points"
                     appPositionAndColorDot [point]="point">
                    <mat-icon svgIcon="magnify"
                              (click)="openDocument(point)">
                    </mat-icon>
                </div>
            </ng-container>
            <!-----------interactive picture------------------->
            <img #interactiveImage id="interactiveImage" (load)="imageSizeByRatio()" [ngStyle]="{'width.px': imgWidth, 'height.px': imgHeight}"
                 *ngIf="data?.activity_content.image.uri && !isDotDocOpen"
                 [src]="data?.activity_content.image.uri">

            <!----media to open when click on interactiv picture------>

            <div class="target-details" [ngClass]="currentMediaType(media)" *ngFor="let media of currentDot?.media">

                <div *ngIf="isDotDocOpen" class="target-details__header">
                    <!-----title and description on the same place for moment -->
                    <div class="title" [innerHtml]="currentDot?.title+ '  ' + currentDot?.description"></div>
                    <!--clear icone to close media--->
                    <button class="Close closeItem" mat-mini-fab (click)="closeDocument()">
                        <mat-icon svgIcon="cross"></mat-icon>
                    </button>
                </div>
                <div class="target-details__content">
                    <!--all other picture if exist-->
                    <img #targetDetailsImage *ngIf="media.uri.includes('.png') || media.uri.includes('.jpg')"
                         [src]="media.uri" (load)="imageRatioType()">
                    <!--associate audio if exist-->
                    <audio controls *ngIf="media.uri.includes('.mp3')">
                        <source [src]="media.uri" [type]="'audio/mp3'">
                        {{ 'generic.audio_support' | translate }}
                    </audio>
                    <!---Video if exist--->
                    <video *ngIf="media.uri.includes('.mp4')" controls>
                        <source [src]="media.uri" type="video/mp4">
                    </video>
                </div>
                <div *ngIf="media.uri.includes('.pdf')" class="target-details__actions">
                    <!--pdf if exist-->
                    <button mat-mini-fab (click)="openPdf(media.uri)"
                            class="button-download-theme" role="button">
                        <mat-icon svgIcon="file_download" aria-label="start course"></mat-icon>
                    </button>
                </div>

            </div>
        </div>
    </mat-card-content>
    <mat-card-footer>
        <!----button show hide dot----->
        <button mat-raised-button *ngIf="!isDotDocOpen"
                class="showDot" color="accent"
                (click)="showhideDot()"
                role="button">
            <span *ngIf="isShowDot"> {{'activities.hide_dot' | translate}} </span>
            <span *ngIf="!isShowDot"> {{'activities.show_dot' | translate}} </span>
        </button>
    </mat-card-footer>
</mat-card>
