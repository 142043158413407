import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map, take, tap} from 'rxjs/operators';
import {CollectionOptionsInterface, DataCollection, DataEntity} from 'octopus-connect';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-assignments-list',
    templateUrl: './assignments-list.component.html'
})
export class AssignmentsListComponent implements OnInit {

    @Input() chaptersChildrenIds: Array<string | number> = [];
    @Output() assigmentsListEvent = new EventEmitter<DataEntity[]>();
    @Output() autoAssigmentsListEvent = new EventEmitter<DataEntity[]>();
    @Output() closedAssigmentsListEvent = new EventEmitter<DataEntity[]>();

    public showSpinner = true;
    public assignments: Array<DataEntity> = [];
    public conceptAssignments: Array<DataEntity> = [];
    public conceptAutoAssignments: Array<DataEntity> = [];
    public conceptClosedAssignments: Array<DataEntity> = [];
    private optionsInterface: CollectionOptionsInterface = {
        filter: {},
        page: 1,
    };
    public loadPaginatedAssignments$: (filters?: CollectionOptionsInterface) => Observable<DataCollection>;


    constructor(
        private activitiesService: ActivitiesService,
        private communicationCenter: CommunicationCenterService,
        private lessonsService: LessonsService) {
        this.communicationCenter.getRoom('assignment').getSubject('loadPaginatedAssignmentsCallback')
            .pipe(
                tap((callback: (filters?: CollectionOptionsInterface) => Observable<DataCollection>) => {
                    this.loadPaginatedAssignments$ = callback;
                })
            ).subscribe();
    }

    ngOnInit(): void {
        this.optionsInterface.filter['assignated_user'] = this.activitiesService.userData.id;
        this.getAssignments().pipe(take(1)).subscribe(data => {
            this.assignments = data;
            if (this.chaptersChildrenIds.length > 0){
                // check if assignment has a chapter of current and is not closed and is not an auto assignment
                this.conceptAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersChildrenIds.includes(c.id))
                        && e['attributes']['state_term']['label'] !== 'closed' && e['attributes']['type_term']['label'] !== 'auto' : '');
                // get auto assignments
                this.conceptAutoAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersChildrenIds.includes(c.id))
                        && e['attributes']['type_term']['label'] === 'auto' : '');
                // get closed assignments
                this.conceptClosedAssignments = this.assignments.filter(e =>
                    e['attributes']['assignated_node'] ? e['attributes']['assignated_node']['chaptersTag'].some(c => this.chaptersChildrenIds.includes(c.id))
                        && e['attributes']['state_term']['label'] === 'closed' : '');

            } else {
                this.conceptAssignments = this.assignments;
                this.conceptAutoAssignments = this.assignments;
                this.conceptClosedAssignments = this.assignments;
            }
            // send assignments to parent chapters list component
            this.assigmentsListEvent.emit(this.conceptAssignments);
            this.autoAssigmentsListEvent.emit(this.conceptAutoAssignments);
            this.closedAssigmentsListEvent.emit(this.conceptClosedAssignments);

            this.showSpinner = false;
        });
    }

    public getAssignments(): Observable<DataEntity[]> {
        return this.loadPaginatedAssignments$(this.optionsInterface.filter).pipe(
            take(1),
            map(collection => collection.entities),
        );
    }

    public launchModal(resourceId: string, assignment: DataEntity): void {
        this.lessonsService.loadLessonById(resourceId).pipe(take(1)).subscribe(data => {
            const resource = data;
            this.lessonsService.openLessonModal(resource, assignment);
        });
    }

    public pourcent(assignation): number {
        return assignation.get('progress') || 0;
    }

}
