import {Component} from '@angular/core';
import {BaseActivityComponent} from '../base-activity.component';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html'
})
export class AudioComponent extends BaseActivityComponent<any, any> {
    public questionObject: any;
    public options: any;
    public audioUrl: string;
    public imageUrl: string;

    protected setContentData(activityAttributes): void {
        this.questionObject = activityAttributes.reference;
        this.instruction = this.questionObject.instruction;
        this.wording = this.questionObject.wording;
        this.instructionAudio = this.questionObject.instructionAudio;
        this.wordingAudio = this.questionObject.wordingAudio;
        this.imageUrl = this.questionObject.activity_content[0].image;
        this.audioUrl = this.questionObject.activity_content[0].media;
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected  getGrade(): {oldGrade: number, newGrade: number} {
        throw new Error('Method not implemented.');
    }
}
