<div class="page-layout simple fullwidth" *ngIf="isReady === true">

    <mat-tab-group [(selectedIndex)]="selectedTab">

        <mat-tab label="{{ 'gamification.gallery' | translate}}">
            <app-gallery class="inner-scroll" (openCreature)="openCreature($event)"></app-gallery>
        </mat-tab>

        <mat-tab label="{{ 'gamification.avatars' | translate}}">
            <app-my-animals [selected]="selectedCreature" *ngIf="selectedTab === 1"></app-my-animals>
        </mat-tab>

    </mat-tab-group>

    <div class="credits">
        <div class="icon">
            <mat-icon svgIcon="credits" class="white-icon"></mat-icon>
        </div>{{userPoints}} {{ 'generic.credits' | translate}}
    </div>

    <app-buy-popup></app-buy-popup>
</div>

<div class="spinner" *ngIf="isReady === false">
    <mat-spinner></mat-spinner>
</div>