<div class="container">
    <ng-container *ngFor="let answer of answersByZone;let isLast=last; let isFirst=first;">
        <!--fisrt answer-->
        <div *ngIf="isFirst"
             class="zone"
             [ngClass]="answer.isAnswerCorrect?'zone trueAnswer isFirst':'zone falseAnswer isFirst'"
             appApplyWidthDelay [delay]="200" [width]="widthToApply(answer)"></div>
        <!--all answer except first and last-->
        <div *ngIf="!isFirst && !(lastTrueAnswersDone() && isLast) " class="zone"
             [ngClass]="answer.isAnswerCorrect?'trueAnswer':'falseAnswer'"
             appApplyWidthDelay [delay]="200" [width]="widthToApply(answer)"></div>
        <!--last asnwer-->
        <div *ngIf="answer.isAnswerCorrect && lastTrueAnswersDone() && isLast" class="zone isLast trueAnswer"
             appApplyWidthDelay [delay]="200" [width]="widthToApply(answer)"></div>
    </ng-container>
    <!--cursor-->
    <div [ngStyle]="{'margin-left':lastTrueAnswersDone()?'-3px':''}" class="progression-cursor"></div>
    <!--limit max remove only at the end when all answer are done and good-->
    <div [ngStyle]="{width:lastTrueAnswersDone()?'0%':'2%'}" class="limitMax"></div>
</div>