import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {DataCollection, OctopusConnectService} from 'octopus-connect';

@Injectable({
    providedIn: 'root'
})

export class ConceptsService {

  constructor(private octopusConnect: OctopusConnectService) { }

    public getConcepts(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('concepts');
    }


}
