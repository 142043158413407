<div mat-dialog-title>
    <h2>
        {{ 'generic.edit_step' | translate }}
    </h2>
    <button (click)="dialogRef.close()"
            aria-label="Close dialog"
            class="close">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>

    <!-- TODO add term interface for this sentence : -->
    <div class="mat-body editor-step-helper">{{data.instructionKey | translate}}</div>

    <form [formGroup]="form">
        <div mat-dialog-content>
            <div class="content p-24" fxFlex="1 0 auto" fxLayout="column" fxLayoutAlign="strech">
                <mat-form-field>
                    <input formControlName="title" matInput placeholder="{{ 'generic.title' | translate }}"/>
                </mat-form-field>

                <mat-form-field>
                    <textarea formControlName="instruction"
                              matInput
                              placeholder="{{ 'generic.wording' | translate }}"
                              rows="7"
                              type="text">
                    </textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="pt-24" mat-dialog-actions>
            <button (click)="validate()" [disabled]="form?.invalid" class="submit-button" color="accent"
                    mat-raised-button
                    type="submit"
                    value="{{ 'generic.validate' | translate }}">{{ 'generic.validate' | translate }}</button>

            <button (click)="dialogRef.close()" class="submit-button" color="accent" mat-raised-button
                    type="submit"
                    value="{{ 'generic.cancel' | translate }}">{{ 'generic.cancel' | translate }}</button>
        </div>
    </form>

</mat-dialog-content>





