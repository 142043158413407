<div id="mail-confirm" fxLayout="column">

    <div id="mail-confirm-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <mat-spinner *ngIf="validateEmailStrategyActivated"></mat-spinner>
        <ng-container *ngIf="!validateEmailStrategyActivated">
            <div id="mail-confirm-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

                <div class="logo">
                    <mat-icon class="s-96">email</mat-icon>
                </div>

                <div class="title">{{'account-management.email_validate' | translate}}</div>

                <div class="message">
                    <a class="link" [routerLink]="usersProvider.defaultRoute">{{ termForBackRoute | translate}}</a>
                </div>

            </div>
        </ng-container>

    </div>

</div>



