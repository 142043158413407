import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {take, takeUntil} from 'rxjs/operators';
import {CollectionOptionsInterface, DataEntity, OrderDirection} from 'octopus-connect';
import {Subject, Subscription} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
    selector: 'app-lesson-card',
    templateUrl: './lesson-card.component.html'
})
export class LessonCardComponent implements OnInit, OnDestroy {

    @Input() chapterId: string[] = [];
    @Input() assigmentsList: DataEntity[] = [];
    @Input() autoAssigmentsList: DataEntity[] = [];
    @Input() closedAssigmentsList: DataEntity[] = [];
    @Input() gradeId: number;
    @Input() params: { lesson: string, chapter: string };

    public assigmentsListId: number[] = [];
    public autoAssigmentsListId: number[] = [];
    public closedAssigmentsListId: number[] = [];
    public showSpinner = true;
    private optionsInterface: CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject();
    public resources;

    constructor(
        public lessonsService: LessonsService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['gradeId']) {
                this.gradeId = params['gradeId'];
            }

            this.optionsInterface = {
                filter: {
                    chapters: this.chapterId.join(',')
                },
                page: 1,
                range: 50
            };

            this.assigmentsListId = this.assigmentsList.map(n => n['attributes']['assignated_node'].id);
            this.autoAssigmentsListId = this.autoAssigmentsList.map(n => n['attributes']['assignated_node'].id);
            this.closedAssigmentsListId = this.closedAssigmentsList.map(n => n['attributes']['assignated_node'].id);
            this.getLessonsById(this.optionsInterface);
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    private getLessonsById(optionsInterface: CollectionOptionsInterface): Subscription {

        if (this.gradeId) {
            this.optionsInterface.filter.educationalLevel = this.gradeId;
        }

        return this.lessonsService.loadPaginatedLessons('all', [6], '', optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((resources: DataEntity[]) => {
                    // sort by created date desc
                    // TODO directly get sort from webservice
                    this.resources = resources.sort((a, b) => 0 - (a['attributes'].metadatas.changed > b['attributes'].metadatas.changed ? 1 : -1));
                    this.resources = this.resources.filter(e => e.get('metadatas').chapters.length > 1
                        && e.get('metadatas').chapters.some(chapt => chapt.id === this.chapterId[0])
                        && e.get('metadatas').chapters.some(chapt => chapt.id === this.chapterId[1]));
                    this.showSpinner = false;

                    // open modal if lesson query param
                    for (const resource of this.resources) {
                        if (this.params && this.params.lesson && this.params.lesson === resource.id) {
                            this.launchModal(resource,
                                this.assigmentsListId.includes(resource.id),
                                this.autoAssigmentsListId.includes(resource.id),
                                this.closedAssigmentsListId.includes(resource.id));
                        }
                    }
                }, error => {
                    throw error;
                },
                () => {
                }
            );
    }

    public launchModal(resource: DataEntity, isAssignment: boolean, isAutoAssignment: boolean, closedAssignment: boolean): void {
        let dialogRef;
        if (isAutoAssignment || isAssignment || closedAssignment) {
            const assignmentList = isAssignment ? this.assigmentsList : [...this.autoAssigmentsList, ...this.closedAssigmentsList];
            const assignment = assignmentList.find((as: DataEntity) => as.get('assignated_node').id === resource.id);
            dialogRef = this.lessonsService.openLessonModal(resource, assignment);
        } else {
            dialogRef = this.lessonsService.openLessonModal(resource);
        }
        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                this.router.navigate(['.'], {
                    relativeTo: this.route,
                    queryParams: {
                        'lesson': null,
                        'chapter': null,
                    },
                    queryParamsHandling: 'merge'
                });
            }
        });

    }


    public pourcent(resourceId): number {
        const assignment = [...this.autoAssigmentsList,
            ...this.assigmentsList,
            ...this.closedAssigmentsList].find((as: DataEntity) => as.get('assignated_node').id === resourceId);
        return assignment ? assignment.get('progress') : 0;
    }

    /**
     * use to return assignement comment if exist in regard of the id of assignment
     * @param resourceId current resourceId
     */
    public getAssignmentComment(resourceId: number): string {
        const assignment = this.assigmentsList.find((as: DataEntity) => as.get('assignated_node').id === resourceId);
        return assignment ? assignment.get('comment') : '';
    }

    /**
     * use to return assignement comment if exist in regard of the id of assignment
     * @param resourceId current resourceId
     */
    public getAssignmentAssignatorName(resourceId: number): string {
        const assignment = this.assigmentsList.find((as: DataEntity) => as.get('assignated_node').id === resourceId);
        return assignment && assignment.get('assignator') ? assignment.get('assignator').name : '';
    }
}
