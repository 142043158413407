<div id="profile" class="page-layout simple tabbed">
    <!-- CONTENT -->
    <div class="content">

        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.path" routerLinkActive
                #rla="routerLinkActive" [active]="rla.isActive">
                {{link.keyTranslate | translate}}
            </a>
        </nav>

        <router-outlet></router-outlet>


    </div>
    <!-- / CONTENT -->

</div>