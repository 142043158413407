<div class="main-container">
  <div class="popup-container" *ngIf="showPopup">
    <div class="popup">
      <div class="close" (click)="closePopup()">
        <mat-icon svgIcon="cross"></mat-icon>
      </div>
      <div class="popup-inner">
        <div class="tabs">
          <div class="tab accessories" [ngClass]="activeTab === 'accessories' ? 'active' : null"
            (click)="switchTab('accessories')">{{ 'gamification.avatar.accessories' | translate }}</div>
          <div class="tab universes" [ngClass]="activeTab === 'universes' ? 'active' : null"
            (click)="switchTab('universes')">{{ 'gamification.avatar.universe' | translate }}</div>
        </div>
        <div class="items-container">
          <div class="items accessories inner-scroll" fusePerfectScrollbar *ngIf="activeTab === 'accessories'">
            <app-accessory-thumbnail class="item" *ngFor="let accessory of accessories" [badge]="accessory.attributes"
              (click)="onAccessoryClick(accessory)"></app-accessory-thumbnail>
          </div>
          <div class="items universes inner-scroll" fusePerfectScrollbar *ngIf="activeTab === 'universes'">
            <app-accessory-thumbnail class="item" *ngFor="let universe of universes" [badge]="universe.attributes"
              (click)="onUniverseClick(universe)"></app-accessory-thumbnail>
          </div>
        </div>
        <div class="buttons">
          <div class="button">
            <div class="inner-button save" [ngClass]="isSaveButtonActive ? 'enabled' : null" (click)="save()">
              {{ 'generic.register' | translate }}
            </div>
          </div>
          <div class="button">
            <div class="inner-button reset" [ngClass]="isResetButtonActive ? 'enabled' : null" (click)="reset()">
              {{ 'generic.reset' | translate }}</div>
          </div>
        </div>
        <div class="loading" *ngIf="isLoading === true">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
  <div class="controls customize-container" *ngIf="!showPopup" title="{{ 'gamification.avatar.edit' | translate }}">
    <div class="control customize" (click)="openPopup()">
      <mat-icon svgIcon="accessorize"></mat-icon>
    </div>
  </div>
</div>