<div fxLayout="column" class="tools page-layout simple fullwidth" fxFlex *ngIf="renderIsReady">
    <div class="header" fxlayout="row" fxlayoutalign="start center">
        <h2>{{ 'generic.multi_title' | translate }}</h2>
    </div>

    <div class="content">
        <form [formGroup]="multiActivityForm" >

            <mat-form-field>
                <mat-label>{{ 'multi_editor.title' | translate }}</mat-label>
                <input matInput id="title" type="text" formControlName="title"
                       [placeholder]="'multi_editor.title' | translate">
            </mat-form-field>
            <div
                [(dragulaModel)]="activities"
                [dragula]="formIsSaving ? null : 'draggableMedias'" >
                <div *ngFor="let item of activities" class="activity-item" >
                    <div class="handle" fxFlex="0 0 40px" fxLayoutAlign="center center">
                        <button type="button">
                            <mat-icon svgIcon="drag"></mat-icon>
                        </button>
                    </div>
                    <div class="ItemTitle" fxFlex fxLayoutAlign="left center">
                        {{item?.attributes.metadatas.title}}
                    </div>
                    <div class="actionBtns" fxFlex="0 0 150px" fxLayoutAlign="center center">
                        <button type="button" (click)="editActivity(item)" *ngIf="isEditableActivity(item)"
                                aria-label="edit" matTooltip="{{ 'generic.edit' | translate }}">
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>

            <div class="footer h-64" fxLayout="row" fxLayoutAlign="center center">
                <button type="submit" mat-raised-button [disabled]="formIsSaving || (getIsUserShouldSave() === false)" class="save-button mat-accent"
                        (click)="onMultiActivityFormSubmit()">
                    <span>{{ 'generic.save' | translate }}</span>
                    <mat-icon svgIcon="save"></mat-icon>
                </button>
                <button type="button" mat-raised-button (click)="goToParentLesson()">
                    <span>{{ 'generic.exit' | translate }}</span>
                    <mat-icon svgIcon="exit"></mat-icon>
                </button>

                <div class="addBtnBottomRight">
                    <button type="reset" aria-label="More" class="add-file-button" mat-fab matTooltip="More"
                            (click)="createChildActivityAndRedirect(); $event.preventDefault()">
                        <mat-icon svgIcon="add"></mat-icon>
                    </button>
                </div>
            </div>

        </form>
    </div>
</div>