import {Component} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-delete-activity-dialog',
    templateUrl: './confirm-delete-activity-dialog.component.html',
})
export class ConfirmDeleteActivityDialogComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmDeleteActivityDialogComponent>) {
    }

}
