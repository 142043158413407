<mat-card class="example-card">
    <mat-card-header>
        <img width="62" height="62" [src]="conceptImage"/>
        <mat-card-title>{{ cardTitle }}</mat-card-title>
        <mat-card-subtitle>{{ lessonsCompleted }}/{{ lessonsCount }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <div *ngIf="hasAssignedLessons" class="warning-assignment-message">{{ 'concept_card.content_text' | translate }}</div>
    </mat-card-content>
    <mat-card-actions>
        <button  mat-raised-button role="button" (click)="navigateTo( conceptId )">
            <mat-icon aria-hidden="true" svgIcon="conceptCardIcon" ></mat-icon>
            <span>{{ 'concept_card.go_to_button' | translate }}</span>
        </button>
    </mat-card-actions>
</mat-card>