import {Component, Input} from '@angular/core';
import {MainMenuElementLinkInterface} from '../../models/main-menu-element-link.interface';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-main-menu-element-link',
    templateUrl: './main-menu-element-link.component.html'
})
export class MainMenuElementLinkComponent {

    constructor(private communicationCenter: CommunicationCenterService) {
    }

    @Input() public element: MainMenuElementLinkInterface;
    @Input() public externalUrl: boolean;
    @Input() public openInNewTab: boolean;
    // cypress e2e test does not support . separator so for automatic flag we replace all .  by _
    public regex = new RegExp('\\.', 'g');

    public onLinkClick(_$event: MouseEvent): void {
        this.communicationCenter.getRoom('mainMenu').next('onMenuLinkClick', null);
    }
}
