<div id="editor-activities" fxLayout="column" class="page-layout" fxFlex>

    <!-- HEADER -->
    <div mat-dialog-title>
        <h2>
            {{'generic.select_activities' | translate}}
        </h2>
        <button (click)="dialogRef.close()"
                aria-label="Close dialog"
                class="close">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>
    <!-- / HEADER -->
    <div class="page-layout simple right-sidebar inner-scroll">
        <!-- CENTER -->
        <div class="center" fxFlex>
            <!-- CONTENT -->
            <div #scollContainer class="content mat-white-bg">

                <mat-toolbar class="filters">
                    <mat-toolbar-row *ngIf="filters && filters.length">
                        <app-search-filters (launchSearch)="refreshList($event)"
                                            [fields]="filters"
                                            [customLists]="{methods: chaptersList}"
                                            [initialsValues]="initialValues"
                                            [options]="{launchSearchOnChange: true}"
                        ></app-search-filters>
                    </mat-toolbar-row>
                </mat-toolbar>
                <mat-dialog-content>

                    <div class="mat-body editor-activities-list-helper">{{termInterfaceTitleActivity | translate}}</div>
                    <table mat-table #table [dataSource]="dataSource" *ngIf="activitiesIsReady else loading">
                        <!-- Type Column -->
                        <ng-container matColumnDef="type">
                            <th *matHeaderCellDef
                                fxHide.xs
                                mat-header-cell
                                mat-sort-header>
                                {{ 'generic.type' | translate }}
                            </th>
                            <td *matCellDef="let row"
                                fxHide.xs
                                mat-cell>
                <span>
                    <mat-icon svgIcon="{{ getContentTypeIcon(row)?.name }}"
                              title="{{ getContentTypeIcon(row)?.translate | translate }}">
                    </mat-icon>
                </span>
                            </td>
                        </ng-container>

                        <!-- Title Column -->
                        <ng-container matColumnDef="title">
                            <th *matHeaderCellDef mat-header-cell></th>
                            <td *matCellDef="let entity" mat-cell>
                                <p>{{dataEntitySetFromLesson() ? entity?.get('metadatas')?.title : entity.get('title')}}</p>
                            </td>
                        </ng-container>

                        <!-- educationalLevel Column -->
                        <ng-container matColumnDef="educationalLevel">
                            <th *matHeaderCellDef mat-header-cell></th>
                            <td *matCellDef="let entity" mat-cell>
                                <p>{{entity.get('metadatas')?.educationalLevel?.label}}</p>
                            </td>
                        </ng-container>

                        <!-- chapters Column -->
                        <ng-container matColumnDef="chapters">
                            <th *matHeaderCellDef mat-header-cell></th>
                            <td *matCellDef="let entity" mat-cell>
                                <p>{{chapters(entity)}}</p>
                            </td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th *matHeaderCellDef mat-header-cell>
                                {{ 'generic.actions' | translate }}
                            </th>
                            <td *matCellDef="let row" mat-cell>
                                <button (click)="loadActivityForPreview(row)" [disabled]="disableBtnPreview" class="mat-elevation-z1"
                                        mat-mini-fab>
                                    <mat-icon svgIcon="editor-play"></mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                        <tr (click)="selectActivity(entity)" *matRowDef="let entity; columns: displayedColumns;"
                            [ngClass]="{'selected': checkSelected(entity)}"
                            mat-row
                            matRipple>
                        </tr>
                    </table>
                </mat-dialog-content>
                <mat-paginator
                    [length]="countEntities"
                    [pageIndex]="pageIndex"
                    [pageSizeOptions]="pageRangeOptions"
                    (page)="data?.onPaginateChange($event)">
                </mat-paginator>

                <mat-dialog-actions>
                    <button (click)="dialogRef.close(activitySelected)"
                            [disabled]="!activitySelected"
                            aria-label="SAVE"
                            class="save-button mat-accent"
                            mat-raised-button>
                        {{ 'generic.validate' | translate }}
                    </button>
                </mat-dialog-actions>
                <ng-template #loading>
                    <mat-spinner></mat-spinner>
                </ng-template>


            </div>
            <!-- / CONTENT -->
        </div>

        <!-- SIDENAV -->
        <fuse-sidebar *ngIf="!data.settings.hiddenActivityPreview" fxFlex class="sidebar details-sidebar pb-60" position="right" lockedOpen="gt-xs">
            <div fxFlex="100">
                <!-- SIDENAV CONTENT -->
                <div class="content">
<!--                    <div class="header">-->
<!--                        <div class="title">{{selected?.title}}</div>-->
<!--                    </div>-->
                    <div class="file-details"
                         [@animate]="{value:'*',params:{delay:'200ms'}}">
                        <!-- preview-->
                        <div class="preview file-icon" fxLayout="row" fxLayoutAlign="center center">
                            <img *ngIf="activitySelected" [src]="thumbnail"/>
                        </div>
                        <!-- / preview-->
                        <dl>
                            <ng-container *ngIf="displayField('chapters')">
                                <div>
                                    <dt>{{'generic.field_chapters' | translate}}:</dt>
                                    <dd>{{chapters()}}</dd>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="displayField('description')">
                                <div>
                                    <dt class="short-description">{{'activities.lesson.short_description' | translate}}:</dt>
                                    <dd>{{activitySelected?.get('metadatas')?.description}}</dd>
                                </div>
                            </ng-container>
                        </dl>

                    </div>
                </div>
                <!-- / SIDENAV CONTENT -->
            </div>
        </fuse-sidebar>
        <!-- / SIDENAV -->
    </div>
</div>