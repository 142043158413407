<div class="visible page-layout simple fullwidth">
    <!-- HEADER -->
    <div class="header p-16" fxLayout="row" fxLayoutAlign="start center">
        <h2>{{ 'generic.tools' | translate }} > {{ 'activities.forms_model_title' | translate }}</h2>
        <!-- <h2>{{ 'notification.title' | translate }}</h2> -->
    </div>
    <!-- / HEADER -->
    <div layout="row" layout-wrap class="p-40">
        <form [formGroup]="entityForm"  name="entityForm" novalidate >

            <!-- Activity Wording Field -->
            <div class="mat-elevation-z2 p-40" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input matInput name="wording" formControlName="wording" placeholder="{{ 'activities.form.activity_wording' | translate }}" required>
                    <span matPrefix>
                        <mat-icon svgIcon="edit"></mat-icon>
                    </span>
                    <span matSuffix>
                        <mat-icon svgIcon="free_answer"></mat-icon>
                        {{ 'generic.crt' | translate }}
                    </span>
                </mat-form-field>
            </div>

        </form>
    </div>

    <div fxLayoutAlign="center center">
        <button mat-raised-button class="mat-primary" (click)="saveActivity()">{{ 'generic.save' | translate }}<mat-icon>save</mat-icon></button>
        <button mat-raised-button class="mat-primary" (click)="exit()">{{ 'generic.exit' | translate }}<mat-icon>exit_to_app</mat-icon></button>
    </div>
    <!-- button end -->
</div>