<div id="tooltip" [style.top.px]="settingsTooltip?.tooltipY" [style.left.px]="settingsTooltip?.tooltipX">
    <div class="tooltips" [ngClass]="settingsTooltip.type">
        <mat-card>
            <mat-card-header>
                <div class="pastille">
                    <mat-icon [ngClass]="settingsTooltip.type" svgIcon="{{settingsTooltip.type}}"
                              title="{{ 'assignment.type.' + settingsTooltip.type | translate }}">
                    </mat-icon>
                </div>
                <mat-card-title>
                    {{settingsTooltip?.title}}
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="space-between center"><span>{{'graph-assignation.coefficient' | translate}}  {{settingsTooltip?.coeff}}</span></div>
                <div *ngIf="settingsTooltip?.learnerSelected" fxLayout="row" fxLayoutAlign="space-between center">
                    <div style="display: flex; justify-content: space-between;">
                        <span style="color: #81B52B;">{{'graph-assignation.grade' | translate}} {{settingsTooltip?.grade}} </span>
                        <span style="cursor: pointer; color: #81B52B; text-decoration: underline;" (click)="lauchAssignment()">{{'generic.consult' | translate}}</span>
                    </div>
                    <div  style="display: flex; justify-content: space-between;">
                        <span style="color: #434445;">{{'graph-assignation.others_amount' | translate:{ amount_learner:settingsTooltip?.learnerAmount } }}  {{settingsTooltip?.average}}</span>
                        <span *ngIf="isAtLeastTrainer" style="cursor: pointer; text-decoration: underline;" (click)="navigateToAssignmentList()">{{'generic.consult' | translate}}</span>
                    </div>
                </div>
                <div *ngIf="!settingsTooltip?.learnerSelected" fxLayout="row" fxLayoutAlign="space-between center" style="display: flex; justify-content: space-between;">
                    <span style="color: #434445;">{{'graph-assignation.others_amount' | translate:{ amount_learner:settingsTooltip?.learnerAmount } }}  {{settingsTooltip?.grade}} </span>
                    <span style="cursor: pointer; text-decoration: underline;" (click)="navigateToAssignmentList()">{{'generic.consult' | translate}}</span>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>




