<mat-spinner *ngIf="showSpinner"></mat-spinner>
<div [ngClass]="{'wrapper': !displayDefaultLink}" *ngIf="(!showSpinner && displayDefaultLink) || (!showSpinner && !displayDefaultLink && hasSave)">
    <div class="play_button">
        <div class="button-tab">
            <span>{{ buttonTabText }}</span>
        </div>
        <button mat-button (click)="navigate()">
            <mat-icon aria-hidden="true" svgIcon="conceptCardIcon"></mat-icon>
            <span>{{ buttonText}}</span>
        </button>
    </div>
</div>
