export const gamification = {
    showRewards: true,
    badgeTypes: [
        'Körper', // Corps
        'Accessoire', // Accessoire
        'Hut', // Chapeaux
        'Tanzen', // Danses ,
        'Arm', // Bras
        'Auge', // Oeil
        'Ohr', // Oreille
        'Mund', // Bouche,
        'Bein', // Jambe
    ], // Liste des badges types (taxonomies à charger depuis le drupal)
    // list of image icon to use in same order than the badge type
    badgeImageIcon: [
        'bodies', // Corps
        'clothes', // Accessoire
        'hats', // Chapeaux
        'dances', // Danses ,
        'arms', // Bras
        'eyes', // Oeil
        'ears', // Oreille
        'mouths', // Bouche,
        'legs', // Jambe
    ],
    badgeImageIconBigger: [ // this icone need a different class
        'clothes', // Accessoire
        'hats', // Chapeaux
        'dances', // Danses ,
        'ears', // Oreille
    ],
    badgeTypeAnimations: [ // Badges Types n'étant pas des skins mais des animations
        'Tanzen'
    ],
    defaultSkinName: 'S_DEFAULT',
    defaultAnimationName: 'IDLE', // Animation par défaut à charger
    skeletonAtlas: './assets/ubolino_app/animations/AVATAR_01/AVATAR.atlas',
    skeletonJson: './assets/ubolino_app/animations/AVATAR_01/AVATAR.json'
};
