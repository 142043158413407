
import {takeUntil} from 'rxjs/operators';
import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataEntity} from 'octopus-connect';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BasicPageService} from 'fuse-core/components/basic-page/basic-page.service';
import {Subject, Subscription, ReplaySubject} from 'rxjs';

@Component({
    selector: 'app-basic-page',
    templateUrl: './basic-page.component.html',
    styleUrls: ['./basic-page.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BasicPageComponent implements OnInit, OnDestroy {

    @Input() private alias: string;
    @Input('page') private inputPage: DataEntity;
    @Input('title') public displayTitle = true;
    private langSubscription: Subscription;
    private unsubscribeInTakeUntil = new Subject();
    private loadedPage: DataEntity;

    constructor(
        private route: ActivatedRoute,
        private translate: TranslateService,
        private domSanitizer: DomSanitizer,
        private pageService: BasicPageService
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((value: Object) => {
            if (!this.inputPage) {
                if (value['alias']) {
                    this.alias = value['alias'];
                }

                if (this.alias && this.secureAlias()) {
                    this.getPage();

                    if (!this.langSubscription) {
                        this.langSubscription = this.translate.onLangChange.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
                            this.getPage();
                        });
                    }
                }
            }
        });
    }

    getPage(): void {
        this.pageService.loadPage(this.alias).subscribe((page) => {
            this.loadedPage = page;
            this.loadedPage.attributes['body'] = this.domSanitizer.bypassSecurityTrustHtml(this.loadedPage.get('body'));
            this.loadedPage.get('pages').forEach((subpage) => {
                subpage.body = this.domSanitizer.bypassSecurityTrustHtml(subpage.body);
            });
        });
    }

    public get page(): DataEntity {
        return this.inputPage ? this.inputPage : this.loadedPage;
    }

    public get brand(): string {
        return this.pageService.brand;
    }

    public get isCustomBanner(): boolean {
        return this.pageService.isCustomBanner && this.alias && this.alias.includes('home-' + this.brand);
    }
    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    public secureAlias(): boolean {
        if (this.pageService.settings.secureAlias && this.pageService.settings.secureAlias.includes(this.alias)) {
             this.pageService.isUserLogged().subscribe((connected) => connected);
        }

        return true;
    }
}
