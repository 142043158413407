<h2 mat-dialog-title>{{'update_mail_dialog.title' | translate}}</h2>
<mat-dialog-content class="flex-it">
    <div *ngIf="isLoading" class="loading-wrapper">
        <mat-spinner></mat-spinner>
    </div>
    <p>{{'update_mail_dialog.body' | translate}}</p>
    <mat-form-field>
        <mat-label>{{ 'update_mail_dialog.mail' | translate }}</mat-label>
        <input [formControl]="mailFormControl" [placeholder]="'update_mail_dialog.mail' | translate" matInput
               type="text">
        <mat-error *ngIf="!!error">{{error | translate}}</mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="onSaveClick($event)" [disabled]="canSave() === false" color="primary"
            mat-button>{{'update_mail_dialog.save'}}</button>
    <button (click)="onCancelClick($event)" [disabled]="isLoading"
            mat-button>{{'update_mail_dialog.cancel' | translate}}</button>
</mat-dialog-actions>
