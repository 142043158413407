<div class="tools visible page-layout simple fullwidth">
    <!-- HEADER -->
    <div class="header p-16" fxLayout="row" fxLayoutAlign="start center">
        <h2>{{ 'generic.tools' | translate }} > {{ 'activities.forms_model_title' | translate }}</h2>
        <!-- <h2>{{ 'notification.title' | translate }}</h2> -->
    </div>
    <!-- / HEADER -->

    <div class="p-40">
        <h3 class="title">{{ 'activities.form.dialog_new' | translate }}</h3>
        <span class="sub-title">{{ 'activities.form.wording_details' | translate }}</span>
        <div class="container list-item mt-32 mb-32" dragula="itemsBAG" [(dragulaModel)]="orderedContent" id="itemsBAG" [ngClass]="{'empty': selectedLessonContent?.length <= 0 }">
            <div class="itemDragula activity-item" *ngFor="let item of selectedLessonContent">
                <div fxFlex="0 0 40px" fxLayoutAlign="center center" class="handle">
                    <button>
                        <mat-icon>drag_indicator</mat-icon>
                    </button>
                </div>
                <div fxFlex="0 0 50px" fxLayoutAlign="center center" class="itemType">
                    <mat-icon svgIcon="{{ getContentTypeIcon(item)['name'] }}"
                              title="{{ getContentTypeIcon(item)['translate'] | translate }}">
                    </mat-icon>
                </div>
                <div fxFlex fxLayoutAlign="left center" class="ItemTitle">
                    {{item?.attributes.reference.instruction}}
                </div>
                <div fxFlex="0 0 150px" fxLayoutAlign="center center" class="actionBtns">
                    <button aria-label="edit" matTooltip="{{ 'generic.edit' | translate }}"
                            (click)="editActivity(item)">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button aria-label="delete" matTooltip="{{ 'generic.delete' | translate }}"
                            (click)="deleteActivity(item)">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <!-- button start -->
        <div fxLayoutAlign="center center">
            <button mat-raised-button class="mat-primary" (click)="saveForm()">
                <span>{{ 'generic.save' | translate }}</span>
                <mat-icon svgIcon="save"></mat-icon>
            </button>
            <button mat-raised-button class="mat-primary" (click)="exit()">
                <span>{{ 'generic.exit' | translate }}</span>
                <mat-icon svgIcon="exit"></mat-icon>
            </button>
        </div>
        <!-- button end -->
    </div>


    <div class="addBtnBottomRight">
        <button mat-fab class="add-file-button" aria-label="More" matTooltip="More"
                [matMenuTriggerFor]="menu">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="form-activities-menu" backdropClass="horizontalAddSubMenu">
            <div mat-menu-item>
                <button mat-fab (click)="createActivity('crt')">
                    <mat-icon svgIcon="free_answer"></mat-icon>
                </button>
                <span (click)="createActivity('crt')">{{ 'generic.crt' | translate }}</span>
            </div>
            <div mat-menu-item>
                <button mat-fab (click)="createActivity('qcm')">
                    <mat-icon svgIcon="qcm"></mat-icon>
                </button>
                <span (click)="createActivity('crt')">{{ 'generic.qcm' | translate }}</span>
            </div>
            <div mat-menu-item>
                <button mat-fab (click)="createActivity('qcmu')">
                    <mat-icon svgIcon="qcu"></mat-icon>
                </button>
                <span (click)="createActivity('crt')">{{ 'generic.qcu' | translate }}</span>
            </div>
        </mat-menu>
    </div>
</div>

