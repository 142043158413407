import {AfterViewInit, Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {RewardService} from '@modules/activities/core/services/reward.service';

@Component({
    selector: 'app-reward-unlocked',
    templateUrl: './reward-unlocked.component.html'
})
export class RewardUnlockedComponent implements OnInit, AfterViewInit {
    rewardTitle = '';
    public ready = false;
    public imageToWinUrl = '';
    public itemToWinLabel = '';

    constructor(private rewardService: RewardService) {
        this.rewardService.loadBadgeByLevel(13)
            .pipe(take(1))
            .subscribe(data => {
                // get data for current user
                try {
                    this.imageToWinUrl = data.entities[0].get('image');
                    this.itemToWinLabel = data.entities[0].get('label');
                } catch (ex) {
                    console.error('25:not getting image' + ex);
                }
            });

        this.rewardTitle = 'you pass next level';
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {

        setTimeout(() => {
            this.ready = true;
        }, 2000);
    }

    next(): void {
        console.log('next');
    }

}
