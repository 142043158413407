<mat-dialog-content>
    <mat-icon [svgIcon]="data.svg"></mat-icon>
    <p>{{data.body | translate}}</p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="accent"
            (click)="dialogRef.close()">
        {{ data.button | translate}}
    </button>
</mat-dialog-actions>
