<mat-card class="activities-card inner-scroll mat-elevation-z0 wrapper" >
    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="false"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column">
        <app-consignes [wording]="wording" [wordingAudio]="wordingAudio"></app-consignes>

        <!--middle center-->
        <div fxFlex fxLayout="column" fxLayoutAlign="space-around center">
            <img *ngIf="imageUrl" [src]="imageUrl" >
            <audio controls *ngIf="audioUrl">
                <source [src]="audioUrl" type="audio/mp3">
            </audio>
        </div>

    </mat-card-content>
</mat-card>