import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthenticationService} from './authentication.service';

import {FuseSharedModule} from '@fuse/shared.module';

import {FuseForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {FuseResetPasswordComponent} from './reset-password/reset-password.component';
import {FuseLoginComponent} from './login/login.component';
import {FusePasswordResetEmailComponent} from '@modules/authentication/core/password-reset-email/password-reset-email.component';
import {SharedModule} from '../../../shared/shared.module';
import {IsUserAnonymous} from '../../../guards/is-user-anonymous.class';
import { UpdateMailDialogComponent } from './update-mail-dialog/update-mail-dialog.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { LogoutComponent } from './logout/logout.component';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RegistrationCodeDirective} from '@modules/authentication/core/directives/registration-code';
import {QuickConnectPageComponent} from '@modules/authentication/core/quick-connect-page/quick-connect-page.component';
import {ManageConnectPageComponent} from '@modules/authentication/core/manage-connect-page/manage-connect-page.component';

const routes = [
    {
        path: 'login',
        component: FuseLoginComponent,
        canActivate: [IsUserAnonymous]
    },
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [IsUserLogged],
    },
    {
        path: 'forgot-password',
        component: FuseForgotPasswordComponent
    },
    {
        path: 'user/reset/:token',
        component: FuseResetPasswordComponent
    },
    {
        path: 'password-reset-email',
        component: FusePasswordResetEmailComponent
    },
    {
        path: 'connect',
        component: ManageConnectPageComponent
    }
];

@NgModule({
    imports: [
        FuseSharedModule,
        SharedModule,
        RouterModule.forChild(routes),
        MatProgressSpinnerModule
    ],
    declarations: [
        FuseLoginComponent,
        FuseForgotPasswordComponent,
        FuseResetPasswordComponent,
        FusePasswordResetEmailComponent,
        UpdateMailDialogComponent,
        LogoutComponent,
        RegistrationCodeDirective,
        QuickConnectPageComponent,
        ManageConnectPageComponent
    ],
    providers: [],
})
export class AuthenticationModule {

    static forRoot(): ModuleWithProviders<AuthenticationModule> {

        return {
            ngModule: AuthenticationModule,
            providers: [
                AuthenticationService
            ]
        };
    }
}
