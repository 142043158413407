<div class="container" [ngClass]="{
  'locked': (badge.unLocked === false && userPoints < badge.price),
  'unlockable': (badge.unLocked === false && userPoints >= badge.price),
  'selected': badge.selected === true,
  'available': badge.unLocked === true
}" [title]="(badge.unLocked === false && userPoints < badge.price) ? '?' : badge.label">
  <img [src]="userPoints >= badge.price === true ? badge['image-t'] : badge['image-b']">
  <div class="locked" *ngIf="badge.unLocked === false">
    <div class="btn unlock" *ngIf="userPoints >= badge.price">{{badge.price}}<mat-icon svgIcon="credits"></mat-icon></div>
    <div class="btn locked-icon" *ngIf="userPoints < badge.price">
      <mat-icon svgIcon="lock"></mat-icon>
    </div>
  </div>
</div>
