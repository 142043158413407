<div class="activities-card-status-btn">
    <mat-radio-group mat-stroked-button [(ngModel)]="chooseActionForActivity" (change)="radioChange($event)">
        <!--Button: Validate  -->
        <mat-radio-button [disableRipple]="true" class="validated" value="validated" [disabled]="!canEdit">
            <button mat-stroked-button color="primary">
                {{ 'activities.form.validated' | translate }}
            </button>
        </mat-radio-button>

        <!-- Button: To be Validated -->
        <mat-radio-button [disableRipple]="true" class="tobevalidated" value="gobackover" [disabled]="!canEdit">
            <button mat-stroked-button color="primary">
                {{ 'activities.form.correct' | translate }}
            </button>
        </mat-radio-button>

        <!-- Button: No Comments  -->
        <mat-radio-button [disableRipple]="true" class="nocomments" value="seen" [disabled]="!canEdit">
            <button mat-stroked-button color="primary">
                {{ 'activities.form.seen' | translate }}
            </button>
        </mat-radio-button>
    </mat-radio-group>
</div>