<a mat-list-item
   *ngIf="!externalUrl"
   [routerLink]="element.path"
   routerLinkActive="active"
   (click)="onLinkClick($event)"
   [ngClass]="element.options?.customClasses?.join(' ')"
   [attr.data-e2e]="element.label.replace(regex,'_')"
   #rla="routerLinkActive"
>
    {{element.label | translate}}
</a>

<a mat-list-item
   *ngIf="externalUrl"
   [href]="element.path"
   [target]="openInNewTab ? '_blank' : '_self'"
   routerLinkActive="active"
   (click)="onLinkClick($event)"
   [ngClass]="element.options?.customClasses?.join(' ')"
   [attr.data-e2e]="element.label.replace(regex,'_')"
   #rla="routerLinkActive"
>
    {{element.label | translate}}
</a>


