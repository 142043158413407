<div class="page-layout simple inner-scroll" id="lessons">
    <app-stepper-wrapper class="inner-scroll" [position]="stepperPosition" [stepperOptions]="stepperOptions">

        <div class="center" [ngClass]="{'js-latex-is-open' : isLatexKeyboardDisplayed}" fxFlex fxLayout="column"
        ><!-- center car il y a le stepper en sidebar-->
            <ng-container *ngIf="!isLoading">
                <div *ngIf="showHeaderExo || hasLessonSteps" class="header fuse-app-lessons-header">
                    <div class="header-top" *ngIf="showHeaderExo && totalOfExercises()>0">
                        <button role="button" class="help" *ngIf="displayHelpButton"
                                [ngStyle]="{visibility:getFeedBack()==''?'hidden':'visible'}"
                                (click)="help()" mat-raised-button>
                            <mat-icon svgIcon="help"></mat-icon>
                            <span class="sr-only">{{'accessibility.header.exo.help' | translate}}</span>
                        </button>
                        <span *ngIf="showLessonTitle" class="title">
                            <mat-icon *ngIf="lessonTitleIcon" svgIcon="titleIconLesson"></mat-icon>
                            {{lessonTitle}}</span>
                        <span *ngIf="showActivityTitle"
                              class="title"> {{lessonsService.settings.stepTitlePrefixPosition ? currentStep + '-' : ''}} {{activityTitle}}</span>
                        <span *ngIf="showActivityQuestionIndex"
                              class="title"> {{'generic.question' | translate}} {{this.activitiesService.currentActivityIndex }}
                            /{{totalOfExercises()}}</span>
                        <div fxLayoutAlign="start center" class="toolbar">
                                    <span class="ml-12 mr-24 learner-info" *ngIf="learnerInfo">
                                        {{ 'generic.learner' | translate }} : {{learnerInfo.name}}
                                        - {{ 'generic.label_group' | translate }} : {{learnerInfo.classes}}
                                    </span>
                            <button (click)="$event.stopPropagation();" *ngIf="allowedToolsPlugins.length > 0"
                                    [matMenuTriggerFor]="toolsMenu"
                                    mat-mini-fab>
                                <mat-icon svgIcon="tools-circle"></mat-icon>
                            </button>
                            <mat-menu #toolsMenu="matMenu">
                                <button *ngFor="let tool of allowedToolsPlugins" (click)="executeTool(tool)"
                                        mat-menu-item>
                                    <span>{{ tool.setting.label | translate }}</span>
                                </button>
                            </mat-menu>
                            <button *ngIf="displayFullscreenButton" mat-mini-fab (click)="fullscreenMode()"
                                    color="white">
                                <mat-icon *ngIf="isFullscreen" svgIcon="fullscreen_exit"></mat-icon>
                                <mat-icon *ngIf="!isFullscreen" svgIcon="fullscreen"></mat-icon>
                            </button>
                            <button [ngClass]="displayCloseButtonPng?'close_header':''" mat-raised-button
                                    (click)="goBack()"
                                    color="white">
                                <mat-icon svgIcon="cross"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="header-bottom" *ngIf="hasLessonSteps">
                        <h2>{{ stepTitle | translate }}</h2>
                    </div>
                </div>
                <!--hide content until all data are ready to avoid unsynchronize screen opening-->
                <content [ngStyle]="{'display' : totalOfExercises()>0?'':'none'}">
                    <app-progress-multi-zone *ngIf="showProgressBarExo && showHeaderExo && totalOfExercises()>0"
                                             [answers]="answersCopy"
                                             [numberOfQuestion]="numberOfQuestion"></app-progress-multi-zone>
                    <app-feedback *ngIf="displayFeedbacks"></app-feedback>
                    <div class="outlet-player-navigation">
                        <div class="previous-wrapper">
                            <button *ngIf="isArrowNavigationEnabled('previous')" (click)="previousActivity()" mat-fab
                                    class="previous" aria-label="preview">
                                <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
                            </button>
                        </div>
                        <router-outlet></router-outlet>
                        <div class="next-wrapper">
                            <button *ngIf="isArrowNavigationEnabled('next')" (click)="nextActivity()" mat-fab
                                    class="next" aria-label="next">
                                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
                            </button>
                        </div>
                    </div>
                    <app-progress-bar-generic *ngIf="activitiesService.settings.showProgressBar"
                                              [pourcent]="progression" [numberOfSteps]="0"></app-progress-bar-generic>
                </content>


                <!-- This is to manage the user actions. This is not fixed. We need to findout a good way to
    manage show and hide of these buttons. -->
                <div class="footer fuse-app-lessons-content-footer" fxFlex="none">
                    <!--                   <app-user-action-button
                                           [activityId]='getCurrentActivityOrMediaId()'
                                           [activityTitle]="activityTitle"
                                           [isMedia]="isMedia()"
                                           [showButtons]="isUserActionButtonsAllowed"
                                       ></app-user-action-button>-->
                </div>
            </ng-container>
            <div class="spinner-host" fxLayoutAlign="center center" *ngIf="isLoading || !(totalOfExercises()>0)">
                <mat-spinner></mat-spinner>
            </div>

        </div>

    </app-stepper-wrapper>
</div>

