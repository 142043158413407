<mat-card class="activities-card inner-scroll mat-elevation-z0" *ngIf="!isSaving && !isInitializing">
    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="false"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-around center">
        <app-consignes [wording]="wording" [wordingAudio]="wordingAudio"></app-consignes>

        <!--middle center-->
        <div fxFlex fxLayout="column" fxLayoutAlign="space-around center">
            <video *ngIf="videoUrl" controls>
                <source [src]="videoUrl" type="video/mp4">
            </video>

            <app-video-with-markers *ngIf="videoConfig" [videoConfig]="videoConfig"></app-video-with-markers>
        </div>

    </mat-card-content>
</mat-card>

<div fxFill fxLayoutAlign="center center" *ngIf="isSaving || isInitializing">
    <mat-spinner></mat-spinner>
</div>