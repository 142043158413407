import {Component, Inject, OnInit} from '@angular/core';
import {ILauncherButtonParam, ILessonModalData} from '@modules/activities/core/lessons/lessons-list/launcher/models/ilauncher';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-launcher',
    templateUrl: './launcher.component.html',
})

/**
 * component from showing inside a modal the question set list
 * and launch one of them.
 */
export class LauncherComponent implements OnInit {
    /**
     * todo remove when data will come from back
     */
    public resource: DataEntity;
    public isAssignment: boolean;
    public assignment: DataEntity;
    public index: number;
    public progressBarValue = {value: 0, max: 5};

    /**
     * todo remove when data will come from back
     */
    public steps: ILauncherButtonParam[] /*=
        [{id: '1', title: 'test', reward: 3, firstLaunch: false},
            {id: '2', title: 'question set 5 niveau 5 eme math et francais', reward: 0, firstLaunch: true},
            {id: '3', title: 'question set2', reward: 0, firstLaunch: false},
            {id: '4', title: 'test', reward: 1, firstLaunch: false},
            {id: '1', title: 'question set3 difficile', reward: 2, firstLaunch: false},
            {id: '1', title: 'question set 4 niveau 4eme b', reward: 3, firstLaunch: false},
            {id: '1', title: 'question set 5 niveau 5 eme math et francais', reward: 4, firstLaunch: false},
            {id: '1', title: 'test', reward: 0, firstLaunch: true},
            {id: '1', title: 'test', reward: 0, firstLaunch: false},
            {id: '1', title: 'test', reward: 1, firstLaunch: false},
            {id: '1', title: 'test', reward: 2, firstLaunch: false},
            {id: '1', title: 'test', reward: 3, firstLaunch: false},
            {id: '1', title: 'test', reward: 4, firstLaunch: false},
            {id: '1', title: 'test', reward: 0, firstLaunch: true},
            {id: '1', title: 'test', reward: 0, firstLaunch: false},
            {id: '1', title: 'test', reward: 1, firstLaunch: false},
            {id: '1', title: 'test', reward: 2, firstLaunch: false},
            {id: '1', title: 'test', reward: 3, firstLaunch: false},
            {id: '1', title: 'test', reward: 4, firstLaunch: false},
            {id: '1', title: 'test', reward: 0, firstLaunch: true},
            {id: '1', title: 'test', reward: 0, firstLaunch: false},
            {id: '1', title: 'test', reward: 1, firstLaunch: false},
            {id: '1', title: 'test', reward: 2, firstLaunch: false},
            {id: '1', title: 'test', reward: 3, firstLaunch: false},
            {id: '1', title: 'test', reward: 4, firstLaunch: false},
            {id: '1', title: 'test', reward: 0, firstLaunch: true},
            {id: '1', title: 'test', reward: 0, firstLaunch: false},
            {id: '1', title: 'test', reward: 1, firstLaunch: false},
            {id: '1', title: 'test', reward: 2, firstLaunch: false},
            {id: '1', title: 'test', reward: 4, firstLaunch: false},
            {id: '1', title: 'fin', reward: 4, firstLaunch: false},
        ]*/;

    constructor(
        public dialogRef: MatDialogRef<LauncherComponent>,
        @Inject(MAT_DIALOG_DATA) data: ILessonModalData,
        private communicationCenter: CommunicationCenterService) {
        this.resource = data.resource;
        this.isAssignment = data.isAssignment && data.assignment.get('state_term').label !== 'closed';
        this.assignment = data.assignment;
        this.steps = data.resource.attributes.reference;
        this.progressBarValue.max = this.steps.length;
        this.setRewards();
    }

    ngOnInit(): void {
    }

    /**
     * return the id of the question set to launch
     * and close the modal
     * @param id : string
     */
    public launch(id: string): void {
        this.dialogRef.close(id);
    }

    /**
     * close modal without any choice
     */
    public close(): void {
        setTimeout(() => {
            this.dialogRef.close();
        }, 500);
    }

    public pourcent(): number {
        return this.progressBarValue.value * 100 / this.progressBarValue.max;
    }

    /**
     *  set rewards based on score on assignation
     */
    private setRewards(): void {
        if (this.assignment) {
            const scoreList = JSON.parse(this.assignment.get('config')) || {};
            this.steps.forEach(step => {
                if (scoreList[step.id] < 50) {
                    step.reward = 0;
                } else if (scoreList[step.id] >= 50 && scoreList[step.id] < 70) {
                    step.reward = 1;
                } else if (scoreList[step.id] < 90) {
                    step.reward = 2;
                } else if (scoreList[step.id] <= 99) {
                    step.reward = 3;
                } else if (scoreList[step.id] === 100) {
                    step.reward = 4;
                }
                if (scoreList[step.id] >= 70) {
                    this.progressBarValue.value++;
                }
            });
        }
    }
}


