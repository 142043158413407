<div class="tools page-layout simple inner-scroll">
    <!-- HEADER -->
    <div class="header p-16" fxLayout="row" fxLayoutAlign="start center">
        <h2>{{ selectedLesson.attributes.metadatas.title }}</h2>
        <span class="sub-title">{{ lessonsService.getDate(selectedLesson) }}</span>
    </div>
    <!-- / HEADER -->
    <span class="sub-title p-16">{{ 'activities.lesson.wording_details' | translate }}</span>

    <div class="content p-16">

        <div [(dragulaModel)]="orderedContent" class="container list-item" dragula="itemsBAG" id="itemsBAG">
            <div *ngFor="let item of selectedLessonContent" class="itemDragula activity-item">
                <div class="handle" fxFlex="0 0 40px" fxLayoutAlign="center center">
                    <button>
                        <mat-icon svgIcon="drag"></mat-icon>
                    </button>
                </div>
                <div class="itemType" fxFlex="0 0 50px" fxLayoutAlign="center center">
                    <mat-icon svgIcon="{{ getContentTypeIcon(item)['name'] }}"
                              title="{{ getContentTypeIcon(item)['translate'] | translate }}"></mat-icon>
                </div>
                <div class="ItemTitle" fxFlex fxLayoutAlign="left center">
                    {{item?.attributes.metadatas.title}}
                </div>
                <div class="actionBtns" fxFlex="0 0 150px" fxLayoutAlign="center center">
                    <button (click)="edit(item)" *ngIf="canEdit(item)"
                            aria-label="edit" matTooltip="{{ 'generic.edit' | translate }}">
                        <mat-icon svgIcon="edit"></mat-icon>
                    </button>
                    <button (click)="navigateToMainActivity(item)" aria-label="consult"
                            matTooltip="{{ 'generic.launch' | translate }}">
                        <mat-icon svgIcon="play"></mat-icon>
                    </button>
                    <button (click)="deleteActivity(item)" aria-label="delete"
                            matTooltip="{{ 'generic.delete' | translate }}">
                        <mat-icon svgIcon="delete"></mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="footer h-64" fxLayout="row" fxLayoutAlign="center center">

        <!-- button start -->

        <button (click)="saveLesson()" [disabled]="!btnSave" class="save-button mat-accent" mat-raised-button>
            <span>{{ 'generic.save' | translate }}</span>
            <mat-icon svgIcon="save"></mat-icon>
        </button>
        <button (click)="exit()" [disabled]="!btnLeave" mat-raised-button>
            <span>{{ 'generic.exit' | translate }}</span>
            <mat-icon svgIcon="exit"></mat-icon>
        </button>

        <!-- button end -->


        <div class="addBtnBottomRight">
            <button [matMenuTriggerFor]="menu" aria-label="More" class="add-file-button" mat-fab matTooltip="More">
                <mat-icon svgIcon="add"></mat-icon>
            </button>
            <mat-menu #menu="matMenu" backdropClass="horizontalAddSubMenu" class="lesson-activities-dialog">
                <div (click)="chooseActivity()" *ngIf="displayAddButton('activity')" mat-menu-item>
                    <mat-icon aria-label="activities" svgIcon="activity"></mat-icon>
                    <span (click)="chooseActivity()">{{ 'generic.activities' | translate }}</span>
                </div>
                <div (click)="chooseResource()" *ngIf="displayAddButton('resource')" mat-menu-item>
                    <mat-icon aria-label="corpus" svgIcon="corpus"></mat-icon>
                    <span>{{ 'generic.resources' | translate }}</span>
                </div>
                <div (click)="openMediaCreationModal()" *ngIf="displayAddButton('divider')" mat-menu-item>
                    <mat-icon aria-label="transition" svgIcon="transition"></mat-icon>
                    <span>{{ 'generic.dividers' | translate }}</span>
                </div>
                <div (click)="addMultimediaActivity()" *ngIf="displayAddButton('multimedia')" mat-menu-item>
                    <mat-icon aria-label="transition" svgIcon="transition"></mat-icon>
                    <span>{{ 'generic.multimedia' | translate }}</span>
                </div>
                <div (click)="addToolToLesson()" *ngIf="displayAddButton('tools')" mat-menu-item>
                    <mat-icon aria-label="transition" svgIcon="tools"></mat-icon>
                    <span>{{ 'generic.tools' | translate }}</span>
                </div>
            </mat-menu>
        </div>

    </div>


</div>
