import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';
import {takeUntil} from 'rxjs/operators';
import {AutoUnsubscribeTakeUntilClass} from '../../../../../shared/models/auto-unsubscribe-take-until.class';
import {Subject} from 'rxjs/index';
import {FullscreenService} from 'fuse-core/services/fullscreen.service';
import {DOCUMENT} from '@angular/common';
import {ActivitiesService} from '@modules/activities/core/activities.service';

@Component({
    selector: 'app-consignes',
    templateUrl: './consignes.component.html'
})
export class ConsignesComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, OnChanges, AfterViewInit {

    isTTSSpeaking = false;
    isFullscreen = false;
    public imgTag: string;
    public instructionUpdated: string;
    public imgTagUpdated: string;
    private contentIsHidden = false;
    @ViewChild('readMe', {read: ElementRef}) readMe: ElementRef<HTMLElement>;

    speakStateChanged(data: { id: string, value: boolean }): void {
        this.isReading.emit(data);
        this.isTTSSpeaking = data.value;
        this.ref.detectChanges();
    }

    @Input('questionTypeName') questionTypeName?: string;
    @Input('instruction') instruction?: string;
    @Input('instructionAudio') instructionAudio?: string;
    @Input('read') read = new Subject(); // to force read by input event use uuidv4() to pass different value at each time
    @Input('wording') wording?: any;
    @Input('additionalHiddenText') additionalHiddenText?: string;
    @Input('autoRead') autoRead = true; // launch automaticly or not the read of instruction or wording
    @Input('wordingAudio') wordingAudio?: string;
    @Output('isReading') isReading: EventEmitter<{ id: string, value: boolean }> = new EventEmitter<{ id: string, value: boolean }>();

    constructor(
        protected communicationCenter: CommunicationCenterService,
        public ref: ChangeDetectorRef,
        public fullscreenService: FullscreenService,
        @Inject(DOCUMENT) private document: any,
        public activitiesService: ActivitiesService
    ) {
        super();
        // listen if a modal is open and is hiding the content
        this.autoReadAfterModalCLose();
    }

    /**
     * check if a modal is open if one is the content is hidden and the text will be not read
     * so we must launch it after the modal will be closed and content will be visible
     * @private
     */
    private autoReadAfterModalCLose(): void {
        this.communicationCenter.getRoom('multi').getSubject('hide-content')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((res: boolean) => {
                if (this.readMe && this.autoRead && this.contentIsHidden === true && res === false) {
                    // it was hide and now not anymore we read it
                    this.readContent();
                }
                this.contentIsHidden = res;
            });
    }

    ngOnInit(): void {
        this.updateInstruction();
    }

    updateInstruction(): void{
        if (this.instruction && this.instruction.includes('<img')) {
            this.imgTag = this.instruction.match(/<img([\w\W]+?)>/g)[0];
            this.instructionUpdated = this.instruction.replace(this.imgTag, '');
            this.imgTag = this.imgTag.replace('<img', '<img id="consigneImgTag"');
        } else if (this.instruction) {
            this.instructionUpdated = this.instruction;
            this.imgTag = '';
        }
    }

    ngAfterViewInit(): void {
        // read the wording if exist and autoread is not pass at false by input and if there is no global modal feedback opened
        if (this.readMe && this.autoRead && !this.contentIsHidden) {
            this.readMe.nativeElement.click();
        }
        // launch read manually emitting true from parent
        this.read.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(read => {
            if (read === true && !this.contentIsHidden) {
                this.readContent();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes.instruction && changes.instruction.currentValue !== changes.instruction.previousValue) ||
            (changes.wording && changes.wording.currentValue !== changes.wording.previousValue)
        ) {
            this.updateInstruction();
        }

        if ((changes.instruction && changes.instruction.currentValue !== changes.instruction.previousValue
                || changes.wording && changes.wording.currentValue !== changes.wording.previousValue)
            && this.readMe && this.autoRead && !this.contentIsHidden) {
            this.readContent();
        }
    }

    /**
     * read content
     * @private
     */
    private readContent(): void {
        const el: HTMLElement = this.readMe.nativeElement;
        setTimeout(() => {
            el.click();
        }, 500);
    }
}
