<div class="step-type-content">
    <div *ngIf="visible" class="step-type-wrapper">
        <div *ngFor="let type of types"
             class="step-type"
             [ngClass]="{'selected': selectedType === type}"
             (click)="selectType(type)">
            <mat-icon svgIcon="{{ getContentTypeIcon(type)?.name }}"
                      title="{{ getContentTypeIcon(type)?.translate | translate }}">
            </mat-icon>
            <label>{{getContentTypeIcon(type)?.translate | translate}}</label>
        </div>
    </div>

    <button (click)="showStepPanel()" mat-fab class="add-file-button" fxFlexAlign="end">
        <mat-icon svgIcon="add"></mat-icon>
    </button>
</div>

