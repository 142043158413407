import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LessonEditorComponent} from './components/lesson-editor/lesson-editor.component';
import {Route, RouterModule} from '@angular/router';
import {CanLaunchEditorGuard} from '@modules/activities/core/lessons/editor/guards/can-launch-editor.guard';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {GenericActivityComponent} from './components/editable-activities/generic-activity/generic-activity.component';
import {EditableActivitiesListComponent} from './components/editable-activities-list/editable-activities-list.component';
import {OneLineActivityComponent} from './components/editable-activities/one-line-activity/one-line-activity.component';
import {ConfirmDeleteActivityDialogComponent} from './components/confirm-delete-activity-dialog/confirm-delete-activity-dialog.component';
import {EditorAddStepComponent} from '@modules/activities/core/lessons/editor/components/editor-add-step/editor-add-step.component';
import { MultiLineActivityComponent } from './components/editable-activities/multi-line-activity/multi-line-activity.component';
import { MultimediaPageEditorComponent } from './components/multimedia-page-editor/multimedia-page-editor.component';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import { MultiLineChildComponent } from './components/editable-activities/multi-line-child/multi-line-child.component';

const routes: Route[] = [
    {
        path: '',
        canActivate: [CanLaunchEditorGuard],
        component: LessonEditorComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatProgressSpinnerModule,
        ActivitiesSharedModule,
        CKEditorModule,
    ],
    declarations: [
        ConfirmDeleteActivityDialogComponent,
        EditableActivitiesListComponent,
        EditorAddStepComponent,
        GenericActivityComponent,
        LessonEditorComponent,
        MultiLineActivityComponent,
        MultimediaPageEditorComponent,
        OneLineActivityComponent,
        MultiLineChildComponent,
    ],
    exports: [RouterModule],
    providers: []
})
export class EditorModule {
}
