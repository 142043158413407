<div fxLayout="column" [ngStyle]="{'visibility':hideContent?'hidden':'visible'}"
     class="page-layout simple fullwidth inner-scroll" fxFlex>

    <div class="content inner-scroll" fxFlex fxLayout="{{!displayUserActionButton ? 'column' : 'row'}}">
        <div *ngIf="!displayUserActionButton('hideSideNavigation')" fxFlex="none" fxLayout="column"
             fxLayoutAlign="center none" class="multi-side-nav-previous pr-8 mt-24 mb-24 ml-40">
            <button mat-fab class="previous" (click)="launchPreviousActivity()"
                    [disabled]="canlaunchPrevious() === false"
                    [style.visibility]="lessonsService.settings.hideUserActionButtonsWhenDisabled && !canlaunchPrevious()?'hidden':'visible'"
                    aria-label="previous">
                <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
            </button>
        </div>

        <content class="multi-content">
            <router-outlet></router-outlet>
        </content>

        <div *ngIf="!displayUserActionButton('hideSideNavigation')" fxFlex="none" fxLayout="column"
             fxLayoutAlign="center none" class="multi-side-nav-next  pl-8 mt-24 mb-24 mr-40">
            <button mat-fab class="next" (click)="launchNextActivity()"
                    [disabled]="canlaunchNext() === false"
                    [style.visibility]="lessonsService.settings.hideUserActionButtonsWhenDisabled && !canlaunchNext()?'hidden':'visible'"
                    aria-label="next">
                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
            </button>
        </div>
    </div>
</div>
