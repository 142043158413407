<mat-card class="activities-card inner-scroll mat-elevation-z0" *ngIf="!isInitializing">
    <mat-card-header *ngIf="instruction?.length > 0">
        <mat-card-title>
            <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="true"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content fxLayout="column" fxLayoutAlign="space-around stretch">
        <app-consignes *ngIf="wording?.length > 0 && !typeOfContent" [wording]="wording" [wordingAudio]="wordingAudio"></app-consignes>

        <!--middle center-->
        <div fxLayout="column" fxLayoutAlign="start none" class="medias" [class]="typeOfContent">

            <ng-container *ngFor="let resource of resources">
                <div class="media-wrapper image" *ngIf="resource.get('format').label === 'image'">
                    <div>
                        <img
                            src="{{resource.get('reference').uri}}"
                            alt="{{resource.get('metadatas').title}}">
                    </div>
                </div>

                <div class="media-wrapper video" *ngIf="resource.get('format').label === 'video'">
                    <video controls>
                        <source src="{{resource.get('reference').uri}}" type="{{resource.get('reference').filemime}}">
                        Your browser does not support HTML5 video.
                    </video>
                </div>

                <div class="media-wrapper video-with-markers"
                     *ngIf="resource.get('format').label === 'videoUrl' && !!videoEmbedUrl(resource)">
                    <app-video-with-markers
                        [videoConfig]="videoEmbedUrl(resource)"></app-video-with-markers>
                </div>

                <div class="media-wrapper audio"
                     *ngIf="resource.get('format').label === 'audio'">
                    <audio *ngIf="resource.get('format').label === 'audio'" controls>
                        <source [src]="resource.get('reference').uri" [type]="resource.get('reference').filemime">
                        Your browser does not support the audio tag.
                    </audio>
                </div>


                <div class="media-wrapper text"
                     *ngIf="resource.get('format').label === 'text'">
                    <div [innerHTML]="resource.get('reference').text"></div>
                </div>
            </ng-container>

            <ng-container *ngIf="typeOfContent === 'read' && wording && wording !== ''">
                <div class="media-wrapper text read" [innerHTML]="wording"></div>
            </ng-container>

            <ng-container *ngIf="typeOfContent === 'listen' && wording && wording !== ''">
                <div class="btn-tts-wrapper">
                    <div #readMe
                         appReadable
                         class="tts btn-tts" [uuid]="uuid"
                         [forceText]="wording"
                         [ngClass]="{'tts-reading': isTTSReadingText}"
                         (speaking)="speakStateChanged($event)">
                        <mat-icon [class.read]="isTTSReadingText" [svgIcon]="'sound_off'" (click)="clickToTts()"></mat-icon>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-container *ngFor="let button of buttons">
            <app-button-element
                [ngClass]="button.display?'visible':'invisible'"
                [button]="button"
                (clickButton)="onAction($event)">
            </app-button-element>
        </ng-container>
    </mat-card-content>
</mat-card>

<div fxFill fxLayoutAlign="center center" *ngIf="isInitializing">
    <mat-spinner></mat-spinner>
</div>
