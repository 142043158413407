import {ActivatedRoute} from '@angular/router';
import {GamificationService} from './../gamification.service';
import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {Subscription} from 'rxjs';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {PreviewItemPopupComponent} from '../preview-item-popup/preview-item-popup.component';
import {firstBy} from 'thenby';
import {Subject} from 'rxjs/index';
import {filter, mergeMap, takeUntil, tap} from 'rxjs/operators';

@Component({
    selector: 'app-badge-type-gallery',
    templateUrl: './badge-type-gallery.component.html'
})
export class BadgeTypeGalleryComponent implements OnInit, OnDestroy {

    public badges: DataEntity[] = [];
    private dialogRef: MatDialogRef<PreviewItemPopupComponent>;

    constructor(private gamificationService: GamificationService,
                private activatedRoute: ActivatedRoute,
                public dialog: MatDialog) {
    }

    private unsubscribeTakeUntil: Subject<any> = new Subject<any>();

    private resetBadgesList(): void {
        this.badges = this.gamificationService.skins.filter((badge) => {
            return badge.attributes['type']['id'] === this.activatedRoute.snapshot.params['typeId'];
        }, this).sort(
            firstBy((a: DataEntity, b: DataEntity) => {
                return b.get('selected') - a.get('selected');
            })
                .thenBy((a: DataEntity, b: DataEntity) => {
                    return b.get('unLocked') - a.get('unLocked');
                }).thenBy((a: DataEntity, b: DataEntity) => {
                return a.get('level') - b.get('level');
            }, 1)
        );
    }

    ngOnInit(): void {
        // force scrolling to
        this.scrollToTop();
        // Load assets based on the route parameters
        this.resetBadgesList();
        // Handle page reload
        this.gamificationService.skinsObservable.pipe(
            takeUntil(this.unsubscribeTakeUntil)
        ).subscribe(() => {
            this.resetBadgesList();
        });
    }

    public scrollToTop(): void {
        document.getElementsByTagName('mat-sidenav-content')[0].scrollTo(0, 0);
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();

    }


    public invoqueBuyPopInOrEquip(badge: DataEntity): void {
        if ((!badge.attributes['selected'] && badge.attributes['buyable']) || badge.attributes['selected']) {
            const dialogConfig = new MatDialogConfig();

            dialogConfig.panelClass = 'preview_badge_modal';
            dialogConfig.backdropClass = 'backdrop-blur';
            dialogConfig.data = badge;
            this.dialogRef = this.dialog.open(PreviewItemPopupComponent, dialogConfig);
            this.dialogRef.afterClosed().pipe(takeUntil(this.unsubscribeTakeUntil))
                .pipe(
                    filter((data: {isSelected: boolean, badge: DataEntity}) => data.isSelected !== data.badge.get('selected')),
                    mergeMap((data: {isSelected: boolean, badge: DataEntity}) => this.gamificationService.doActionDependingOnType(data)),
                    tap(() => this.resetBadgesList())
                )
                .subscribe();
        }
    }
}
