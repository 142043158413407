<div fxLayout="column" class="page-layout simple fullwidth inner-scroll" fxFlex>

    <!-- HEADER -->
    <div mat-dialog-title>
        <h2>
            {{'activities.recommendation' | translate}}
        </h2>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div #scollContainer class="content mat-white-bg">

        <mat-toolbar class="filters">
           <mat-toolbar-row *ngIf="!loading">

               <mat-form-field>
                    <mat-select name="typeFilter" placeholder="{{'activities.recommendation_type_filter' | translate }}"
                                [formControl]="typeControl">
                        <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                        <mat-option *ngFor="let type of types" [value]="type.id">
                            {{localizedType(type.label) | translate | translate}}
                        </mat-option>
                    </mat-select>
               </mat-form-field>
               <mat-form-field>
                    <mat-select name="lessonsFilter" placeholder="{{'activities.recommendation_lessons_filter' | translate }}"
                                [formControl]="lessonsControl"
                                multiple>
                        <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                        <mat-option *ngFor="let lesson of lessons" [value]="lesson.id">
                            {{lesson.get('metadatas').title}}
                        </mat-option>
                    </mat-select>
               </mat-form-field>
               <mat-form-field>
                    <mat-select name="learnersFilter" placeholder="{{'activities.recommendation_learners_filter' | translate }}"
                                [formControl]="learnersControl"
                                multiple>
                        <mat-option>{{ 'generic.allf' | translate }}</mat-option>
                        <mat-option *ngFor="let learner of learners" [value]="learner.id">
                            {{learner?.username}}
                        </mat-option>
                    </mat-select>
               </mat-form-field>

                    <button mat-raised-button
                            class="count-lesson-selected"
                            role="button"
                            (click)="assignLesson()">
                        <span class="count-title">{{ 'activities.recommendation_assign_multiple_lesson' | translate}}</span>
                        <span class="count">{{countEntitiesSelected}}</span>
                    </button>


            </mat-toolbar-row>
        </mat-toolbar>

        <ng-container *ngIf="loadingPage">
            <div class="spinner-wrapper">
                <mat-spinner></mat-spinner>
            </div>
        </ng-container>

        <mat-dialog-content>

            <table mat-table #table [dataSource]="dataSource" *ngIf="!loading else spinner">

                <!-- checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox [formControl]="allEntitiesSelectedControl"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox
                            [formControl]="checkboxes[row.id]"
                            id="{{ row.id }}"
                        ></mat-checkbox>
                    </td>
                </ng-container>

                <!-- Pseudo Column -->
                <ng-container matColumnDef="pseudo">
                    <th *matHeaderCellDef mat-header-cell>{{'activities.recommendation_learner_pseudo' | translate}} <mat-icon [ngClass]="{'active': sortSelected === 'learner'}" svgIcon="sort" (click)="sortBy('learner')"></mat-icon></th>
                    <td *matCellDef="let entity" mat-cell>
                        <p>{{entity?.get('learner')}}</p>
                    </td>
                </ng-container>

                <!-- lesson Column -->
                <ng-container matColumnDef="lesson">
                    <th *matHeaderCellDef mat-header-cell>{{'activities.recommendation_lesson_title' | translate}} <mat-icon [ngClass]="{'active': sortSelected === 'lesson'}" svgIcon="sort" (click)="sortBy('lesson')"></mat-icon></th>
                    <td *matCellDef="let entity" mat-cell>
                        <p>{{entity?.get('lesson')}}</p>
                    </td>
                </ng-container>


                <!-- level Column -->
                <ng-container matColumnDef="level">
                    <th *matHeaderCellDef mat-header-cell>{{'activities.recommendation_level' | translate}}</th>
                    <td *matCellDef="let entity" mat-cell>
                        <p>{{entity?.get('skill')}}</p>
                    </td>
                </ng-container>

                <!-- type Column -->
                <ng-container matColumnDef="type">
                    <th *matHeaderCellDef mat-header-cell>{{'activities.recommendation_type' | translate}}</th>
                    <td *matCellDef="let entity" mat-cell>
                        <p>{{entity?.get('journeyDifficulty')}}</p>
                    </td>
                </ng-container>

                <!-- confidence Column -->
                <ng-container matColumnDef="confidence">
                    <th *matHeaderCellDef mat-header-cell>{{'activities.recommendation_confidence' | translate}}<mat-icon [ngClass]="{'active': sortSelected === 'confidence'}" svgIcon="sort" (click)="sortBy('confidence')"></mat-icon></th>
                    <td *matCellDef="let entity" mat-cell>
                        <p>{{entity?.get('confidence')}}</p>
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let entity let i = index">
                        <button
                            mat-icon-button
                            [matMenuTriggerFor]="moreMenu"
                            aria-label="More"
                            (click)="$event.stopPropagation();">
                            <mat-icon svgIcon="submenu"></mat-icon>
                        </button>

                        <mat-menu #moreMenu="matMenu">
                            <button
                                mat-menu-item
                                aria-label="edit"
                                (click)="assignLesson(entity)">
                                <mat-icon svgIcon="assign"></mat-icon>
                                <span>{{'generic.assign' | translate}}</span>
                            </button>
                            <button
                                mat-menu-item
                                aria-label="edit"
                                (click)="editLesson(entity)">
                                <mat-icon svgIcon="edit"></mat-icon>
                                <span>{{'generic.modify' | translate}}</span>
                            </button>

                            <button
                                mat-menu-item
                                aria-label="remove"
                                (click)="deleteLesson(entity)">
                                <mat-icon svgIcon="delete"></mat-icon>
                                <span>{{'generic.ignore' | translate}}</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>


                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let entity; columns: displayedColumns;"
                    mat-row
                    matRipple>
                </tr>
            </table>

            <button mat-raised-button class="see-more" *ngIf="shouldDisplaySeeMoreLink()" [routerLink]="redirectToRecommendationsUrl">{{'generic.see_more' | translate}}</button>
        </mat-dialog-content>

        <ng-template #spinner>
            <div class="spinner-wrapper">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>


    </div>
</div>