import {ChangeDetectorRef, Directive, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../activities.service';
import {LessonsService} from '../lessons/lessons.service';
import {AutoUnsubscribeTakeUntilClass} from '../../../../shared/models/auto-unsubscribe-take-until.class';
import {ButtonComponentConfigInterface} from '@modules/activities/core/models/button-component.interface';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {TypedDataEntityInterface} from '../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {ActivityAttributesInterface} from '@modules/activities/core/models/activity-attributes.interface';
import {userSaveEndPointEnum} from '@modules/activities/core/models/user-save-end-point.enum';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';

@Directive()
/**
 * Il s'agit d'une couche d'abstraction autour de toutes les activités.
 * Elle est censé gérer le chargement/déchargement de l'activité et autres choses communes a toute activité
 *
 * On rajoute ce décorateur "Directive" parce que le moteur IVY a besoin de comprendre que c'est un component.
 * Et la base d'un component est une directive (pas de template & sélecteur)
 *
 * Votre IDE/linter risque de souligner le nom de la classe en rouge, parce que le nom de la classe ne fini pas par "directive".
 * C'est volontaire.
 * Toutefois, on ne peut pas ajouter une règle pour ignorer le linter exceptionnellement car la règle doit etre placé précisément au dessus de la déclaration de la classe
 * Donc on perdrais l'association de cette documentation et la classe.
 */
export abstract class BaseActivityComponent<T, U> extends AutoUnsubscribeTakeUntilClass implements OnInit {
    // Parfois un object, parfois un id, attention c'est à refacto
    @Input() public activityId: TypedDataEntityInterface<ActivityAttributesInterface<T, U>>;
    @Input() preview?: boolean;
    @Input() questionTypeName?: string;
    // utilisé en cas de succession du même type d'exo pour initialisé l'activité (l'instance n'est pas détruite)
    @Input() refresh?: ReplaySubject<DataEntity>;
    // id de l'assignation en cours
    @Input('contextId') public assignmentId?: string;

    @Input() readable?: boolean;
    @ViewChild('readMe', {read: ElementRef}) readMe: ElementRef<HTMLElement>;

    // on differencie la sauvegarde par default d'une nouvelle sauvegarde
    public answerSaved: boolean;
    // on answer click => show correction, no need to wait until lesson's due date's finished
    public autoCorrection: boolean;
    // les boutons recuperés depuis les settings
    public buttons: ButtonComponentConfigInterface[] = [];
    // afficher la solution
    public displaySolution: boolean;
    // instruction de l'exercice
    public instruction: string;
    // consigne enregistré de l'exercice (alternative au TTS même comportement)
    public instructionAudio: string;
    // status pour savoir si la sauvegarde est terminé
    public isSaving: boolean;
    public isTTSSpeaking: { id: string, value: boolean }; // to know if TTS is played
    public wordingAlreadyReadWithTts = false; // to know if we launch TTS on wording when instruction TTS finished
    // permet de definir la mise en page de l'exercice, récuperer depuis le champs config de la reference de l'activité
    public isTwoColumns = false;
    // permet de definir la mise en page de l'exercice, récuperer depuis le champs config de la reference de l'activité
    public isVertical = false;
    // permet de savoir que l'utilisateur a testé sa reponse
    public testAnswer: boolean;
    // consigne de l'exercice (normalement seulement utilisé dans les qcm/qcu/quiz)
    public wording: string;
    // consigne enregistré de l'exercice (alternative au TTS même comportement)
    public wordingAudio: string;
    public answerResult: Subject<AnswerResultInterface> = new Subject<AnswerResultInterface>();
    // type de l'activité
    protected activityType: string;
    // status de(s) réponse(s) cf answerStatusEnum
    public answerStatus = answerStatusEnum.missing;
    // réponses selectionnées par l'utilisateur ou par default.
    public answersSelected: ItemAnswerInterface[] = [];
    // réponses disponible à sélectionner.
    protected availableAnswers: ItemAnswerInterface[] = [];
    // le pourcentage de réussite de l'activité (utilisé sur iboost)
    protected activityPercentile = 0;
    // l'index de l'activité dans un parcours ou sous-parcours (sert à la sauvegarde local sans assignation)
    protected activityStepIndex: number;
    // Utilisé pour afficher des messages lorsque l'on teste une réponse pour aider l'utilisateur (iboost)
    public displayFeedback = false;
    // la sauvegarde de l'utilisateur pour cette activité. une sauvegarde paar defaut est crée si aucune sauvegarde existante
    protected userSave: DataEntity;
    // endpoint à ciblé pour créer ou éditer une sauvegarde
    protected userSaveEndPoint: userSaveEndPointEnum;
    // at the end of an exercise we can have a feedback if exist before passing to next exercise
    private feedbackEndExo: string = null;
    // content is currently hidden by the feedback modal or not
    public contentIsHidden = false;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef) {
        super();
        this.manageProgressBarEventToSend();
        this.listenCurrentStateOfModalFeedback();
    }

    /**
     * manage all event to send to lesson component by communication center
     * for change progress bar state :
     * reset
     * number of question
     * state of asnwer made : answer is true or false
     * @private
     */
    private manageProgressBarEventToSend(): void {
        this.answerResult.subscribe((res: AnswerResultInterface) => {
            this.communicationCenter.getRoom('progress-bar-exo').getSubject('answerResult').next(res);
            this.ifFeedbackEndExoOpenModal(res);
        });
    }

    ngOnInit(): void {

        this.setActivityId();
        this.autoCorrection = this.activitiesService.settings.autoCorrection || false;
        this.displayFeedback = this.activitiesService.settings.displayFeedback || false;
        this.activatedRoute.params.subscribe(() => {
            this.initialize();
        });
        if (this.refresh) {
            this.refresh.subscribe((activity: TypedDataEntityInterface<ActivityAttributesInterface<T, U>>) => {
                if (activity && activity.get('metadatas').typology && activity.get('metadatas').typology.label === this.activityType) {
                    this.activityId = activity;
                    this.initialize(activity);
                }
            });
        }

        if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
            this.answerResult.subscribe((res) => {
                const completionData = {
                    id: `activity/${this.activityId['id']}`,
                    result: {
                        success: res.isAnswerCorrect
                    }
                };
                this.communicationCenter.getRoom('lrs')
                    .getSubject('activity_attempt')
                    .next(completionData);
            });
        }
    }

    protected activityInitialized(activityAttributes): void {
        if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
            const initData = {
                id: `activity/${this.activityId['id']}`
            };

            this.communicationCenter.getRoom('lrs')
                .getSubject('activity_initialize')
                .next(initData);
        }
    }

    /**
     * initialize activity.
     * 'activity' is used when we start the activity from the activities list in a sub lesson
     * for refresh the component exp: start activity then second activity is the same type 'qcu => qcu'
     * @protected
     */
    protected initialize(activity: TypedDataEntityInterface<ActivityAttributesInterface<T, U>> = null): void {
        this.lessonsService.initDefaultGrade();
        this.reset(true);
        this.activityStepIndex = this.activitiesService.presentArrayElementIndex;
        if (!activity && this.activityId && this.activityId['type'] && this.activityId['type'] === 'granule') {
            // todo need to refacto to have only one format of data : DataEntity
            const activityEntity = <TypedDataEntityInterface<ActivityAttributesInterface<T, U>>>this.activityId;
            this.initializePlayerWithActivity(activityEntity.attributes);
        } else {
            if (this.lessonsService.currentLesson && this.lessonsService.currentLesson.get('reference')[this.activityStepIndex].type === 'lesson') {
                const activityEntity =
                    <TypedDataEntityInterface<ActivityAttributesInterface<T, U>>>this.lessonsService.subLessonContentEdited.find((act) => +act.id === +this.activityId.id);
                this.initializePlayerWithActivity(activityEntity.attributes);
            } else {
                this.activitiesService.launchActivity(this.activityId)
                    .pipe(take(1))
                    .subscribe(attributes => {
                        this.initializePlayerWithActivity(attributes);
                    });
            }

        }
        // initialise les boutons de l'activité, la configuration des boutons est récuperé des settings.
        this.initializeButtons();
    }

    /**
     * initialise le player avec les data de l'activité pour démarrer l'activité
     * @param attributes
     * @private
     */
    private initializePlayerWithActivity(attributes: ActivityAttributesInterface<T, U>): void {
        this.feedbackEndExo = attributes.reference.finalFeedback ? attributes.reference.finalFeedback : null;
        this.setActivityTypeAndUserSaveEndpoint(attributes);
        this.setContentData(attributes);
        this.activityInitialized(attributes);
    }

    /**
     * met a jour l'endpoint pour la sauvegarde et le type de l'activité
     * @param activityAttributes les attributs de l'activité
     * @protected
     */
    protected setActivityTypeAndUserSaveEndpoint(activityAttributes): void {
        this.activityType = activityAttributes && activityAttributes.metadatas && activityAttributes.metadatas.typology.label || null;
        this.userSaveEndPoint = userSaveEndPointEnum[this.activityType];
    }

    /**
     * effectue l'action du bouton cliqué (sauvegarder/tester/suivant/précedant/modifier/etc..
     * @param action exemple d'action : save / test / reset etc..
     * @param preActionMatrix tableau 'd'action' à faire avant de lancer la principale 'action' (prop 1)
     * @protected
     */
    protected doAction(action: string, preActionMatrix: string[] = null): void {
        if (this[action]) {
            if (preActionMatrix) {
                const obs = preActionMatrix.map((preAction: string) => this[preAction]());
                combineLatest(obs).pipe(mergeMap(() => this[action]())).subscribe();
            } else {
                this[action]().pipe(take(1)).subscribe();
            }
        } else {
            throw new Error(`no method ${action} implemented`);
        }
    }

    /**
     * test la réponse en y apportant une correction
     * @protected
     */
    protected abstract checkAnswer(): void;

    /**
     * revoir sa réponse (contient une logique differente selon l'activité)
     * @protected
     */
    protected abstract reviewAnswer(): void;

    /**
     * voir la solution de l'activité (contient une logique differente selon l'activité)
     * @protected
     */
    protected abstract seeAnswerSolution(): void;

    /**
     * une fois la sauvegarde récuperée, elle est traitée pour que les réponses sauvegardés soit inserer dans l'activité
     * @protected
     */
    protected abstract setAnswer(): void;

    /**
     * sauvegarde les réponse avant de sauvegarder la 'user-save'
     * @protected
     */
    protected abstract saveAnswer(): Observable<any>;

    /**
     * une fois les infos de l'activité crée, initialise l'activité avec les infos
     * @protected
     */
    protected abstract setContentData(attributes: ActivityAttributesInterface<T, U>): void;

    /**
     * recupere la note de l'utilisateur, l'ancienne provenant de l'user-save et la nouvelle en fonction des réponses.
     * @protected
     */
    protected abstract getGrade(): { oldGrade: number, newGrade: number };

    /**
     *  initialise les boutons de l'activité, la configuration des boutons est récuperé des settings.
     * @protected
     */
    protected initializeButtons(): void {
        if (this.activitiesService.settings.buttons && this.settingsForButton) {
            this.buttons = this.settingsForButton.slice()
                .map((button: ButtonComponentConfigInterface) => {
                    return this.setDefaultOptionForButton(button);
                });
        }
    }

    /**
     * recupere l'id de l'activité à lancer depuis les queryParams de la route
     * @private
     */
    private setActivityId(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            if (!this.activityId) {
                this.activityId = <TypedDataEntityInterface<ActivityAttributesInterface<T, U>>>{};
            }

            if (params) {
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        this.activityId[key] = params[key];
                    }
                }
            }
        });
    }

    /**
     * initialise les boutons d'action et les mets à jour selon l'action ou l'etat de l'activité
     * @param button
     * @protected
     */
    protected setDefaultOptionForButton(button: ButtonComponentConfigInterface): ButtonComponentConfigInterface {
        const customButton: ButtonComponentConfigInterface = {type: null};
        customButton.svgIcon = button.svgIcon || null;
        customButton.classCss = button.classCss || ['button-' + button.type];
        customButton.classCssIcon = button.classCssIcon || ['button-icon-' + button.type];
        customButton.disable = button.disable !== true; // for information: if button.display === undefined, need to return false !
        customButton.display = button.display !== false; // for information: if button.display === undefined, need to return true !
        customButton.title = button.title || 'button.' + button.type;
        customButton.preActionMatrix = button.preActionMatrix || null;
        customButton.type = button.type || null;
        customButton.options = button.options || null;

        this.optionForButton(customButton);
        if (customButton.options && customButton.options[this.activityType]) {
            for (const field in customButton.options[this.activityType]) {
                switch (customButton.options[this.activityType][field].case) {
                    case 'poll':
                        if (this.lessonsService.currentActivityInSubLesson) {
                            const index = this.lessonsService.subLessonContentEdited
                                .findIndex((act: DataEntity) => +act.id === +this.lessonsService.currentActivityInSubLesson.id);
                            const activity = this.lessonsService.subLessonContentEdited[+index + 1];
                            if (activity && activity.get('metadatas').typology.label === 'summary') {
                                customButton[field] = customButton.options[this.activityType][field].value;
                            }
                        }
                        break;
                    case 'displayWithDelay':
                        customButton[field] = false;
                        setTimeout(() => {
                            customButton[field] = true;
                        }, customButton.options[this.activityType][field].value);

                        break;
                    case 'override':
                        customButton[field] = customButton.options[this.activityType][field].value;
                        break;
                }
            }
        }
        return customButton;
    }

    /**
     * définis differentes options des boutons permettant de defenir quand rendre le bouton activé/desactivé, afficher ou non
     * @param button
     * @protected
     */
    protected optionForButton(button: ButtonComponentConfigInterface): void {
        switch (button.type) {
            case 'solution':
                button.disable = this.displaySolution;
                break;

            case 'save':
                button.display = !(this.lessonsService.isLessonCorrected() || this.lessonsService.isLessonValidated()) && !this.lessonsService.isAtLeastTrainer();
                button.disable = this.isSaving;
                break;

            case 'modify':
                button.display = !(this.lessonsService.isLessonCorrected() || this.lessonsService.isLessonValidated()) && !this.lessonsService.isAtLeastTrainer();
                button.disable = this.isSaving;
                break;

            case 'test':
                button.disable = this.answerStatus === answerStatusEnum.missing || this.displaySolution || this.testAnswer;
                break;

            case 'review':
                button.disable = !this.displaySolution && !this.testAnswer;
                break;

            case 'reset':
                button.disable = false; // TODO: check if reset need to be disabled. no case existing.
                break;

            case 'previous':
                button.disable = this.activitiesService.presentArrayElementIndex === 0;
                break;

            case 'next':
                button.disable = false; // TODO: check if next need to be disabled. no case existing.
                break;
        }
    }

    /**
     * au clique sur un bouton d'action, on effectue la tache et on met à jour les boutons
     * @param button
     */
    public onAction(button: ButtonComponentConfigInterface): void {
        this.doAction(button.type, button.preActionMatrix);
        this.lessonsService.lessonButtonClicked.next(true);
        this.initializeButtons();
    }

    /**
     * lorsque l'on effectue un changement dans l'activité (répondre, enlever, etc..) cela nous permet de savoir a quel moment afficher certains boutons
     * @param e
     */
    public onOptionChange(e: boolean): void {
        if (e) {
            this.answerStatus = answerStatusEnum.answered;
        } else {
            this.answerStatus = answerStatusEnum.missing;
        }
        this.initializeButtons();
    }

    /**
     * récupere les settings des boutons à afficher dans l'activité
     * @protected
     */
    protected get settingsForButton(): ButtonComponentConfigInterface[] {
        if (this.lessonsService.isLessonTest()) {
            return this.activitiesService.settings.buttons && this.activitiesService.settings.buttons['preview'] || [];
        } else {
            return this.activitiesService.settings.buttons
                && this.activitiesService.settings.buttons['player'][this.lessonsService.currentAssignment.get('type_term').label] || [];
        }
    }

    /**
     * recupere la sauvegarde ou en crée une par defaut, définis les réponses présentes dans la sauvegarde
     * @private
     */
    protected loadUserSave(): void {
        this.activitiesService.getUserSave(this.activityId.id.toString(), this.assignmentId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (userSave) {
                    this.userSave = userSave;
                    if (this.activitiesService.settings.setAnswerWithUserSave) {
                        this.setAnswer();
                    }
                    this.activityPercentile = this.getGrade() && Math.round(this.getGrade().oldGrade * 100) || 0;
                } else if (this.lessonsService.isMyAssignment()) {
                    this.save().subscribe();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.checkAnswer();
                }
                this.isSaving = false;
            });
    }

    /**
     * calcule le pourcentage depuis la note récuperer ci dessus.
     * @private
     */
    protected get calculateUserSavePercent(): number {
        return Math.round(this.getGrade().newGrade * 100);
    }

    /**
     * dans le cas ou l'user navigue dans une sous lesson.
     * @param direction
     * @protected
     */
    protected navigateInSubLesson(direction: string): void {
        switch (direction) {
            case 'previous':
                this.communicationCenter
                    .getRoom('activities')
                    .next('previous', true);
                break;
            case 'next':
                this.communicationCenter
                    .getRoom('activities')
                    .next('next', true);
                break;
            default:
                throw new Error('no direction provided');
        }
    }

    /**
     * verifie si l'on est dans un sous parcours et si il y a une activité suivante
     * @protected
     */
    protected isActivityInSubLesson(): boolean {
        return this.lessonsService.currentActivityInSubLesson && +this.lessonsService.currentActivityInSubLesson.id === +this.activityId.id;
    }

    /**
     * modifie la réponse
     * @protected
     */
    protected modify(): Observable<boolean> {
        this.reset(false, 'modify');
        return of(true);
    }

    /**
     * reviens à l'activité précédente
     * @protected
     */
    protected previous(): Observable<boolean> {
        if (this.isActivityInSubLesson) {
            this.navigateInSubLesson('previous');
        } else {
            this.activitiesService.loadPreviousActivity();
        }
        return of(true);
    }

    /**
     * passe à l'activité suivante
     * @protected
     */
    protected next(): Observable<boolean> {
        if (this.lessonsService.isAssignmentWithMetacognition()) {
            this.activitiesService.metacognition();
        } else {
            if (this.isActivityInSubLesson) {
                this.navigateInSubLesson('next');
            } else {
                if (this.activitiesService.activitiesArray.length <= this.activitiesService.presentArrayElementIndex + 1) {
                    this.activitiesService.navigateToRecapOrReward();
                } else {
                    this.activitiesService.loadNextActivity();
                }
            }
        }

        if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
            // For ubolino we can only pass to next question when the answer is correct. Send the activity completed statement here
            const completionData = {
                id: `activity/${this.activityId['id']}`,
                result: {
                    success: true,
                    completion: true
                }
            };
            this.communicationCenter.getRoom('lrs')
                .getSubject('activity_complete')
                .next(completionData);
        }

        return of(true);
    }

    /**
     * sauvegarde des réponses de l'activité, on envoie l'id de l'assignation (si existante) le status de l'activité, la sauvegarde (si existante)
     * et l'index de l'activité dans le parcours ou le sous parcours.
     * @private
     * @param status FormPlayerComponent use prop 'status', need to check if it is really needed...
     */
    protected save(status?: answerStatusEnum): Observable<DataEntity> {
        if (!!status) {
            this.answerStatus = status;
        }
        this.isSaving = true;
        if (this.userSave) {
            this.userSave.set('grade', +this.calculateUserSavePercent);
        }
        this.lessonsService.setProgress(this.userSave, this.answerStatus);
        const grade = this.getGrade();
        this.lessonsService.setAssignGrade(grade['newGrade'], grade['oldGrade']);
        return this.saveAnswer().pipe(
            take(1),
            mergeMap((answers: any) => {
                return this.activitiesService
                    .saveUserSave(this.activityId.id.toString(),
                        this.assignmentId,
                        answers || this.answersSelected.map((answer) => answer.id),
                        this.answerStatus,
                        this.userSaveEndPoint,
                        this.userSave,
                        this.activityStepIndex)
                    .pipe(
                        take(1),
                        tap((userSave: DataEntity) => this.userSave = userSave),
                        tap(() => this.isSaving = false),
                        tap(() => this.initializeButtons())
                    );
            })
        );
    }

    protected test(): Observable<DataEntity> {
        this.displaySolution = false;
        this.testAnswer = true;
        this.checkAnswer();
        return this.save();
    }

    /**
     * revoir sa réponse apres avoir affiché la solution ou l'avoir testé
     * @protected
     */
    protected review(): Observable<boolean> {
        this.reviewAnswer();
        this.displayFeedback = false;
        this.displaySolution = false;
        this.testAnswer = false;
        return of(true);
    }

    /**
     * permet d'afficher la solution de l'exercice
     */
    protected solution(): Observable<boolean> {
        this.seeAnswerSolution();
        this.displayFeedback = false;
        this.displaySolution = true;
        this.testAnswer = false;
        return of(true);
    }

    /**
     * reinitialise l'activité
     * @param resetAllSubscribe
     * @param type
     * @protected
     */
    protected reset(resetAllSubscribe = false, type: string = null): Observable<boolean> {
        this.answerSaved = false;
        this.answerStatus = answerStatusEnum.missing;
        this.displayFeedback = false;
        this.isSaving = false;
        this.displaySolution = false;
        this.testAnswer = false;
        if (resetAllSubscribe) {
            if (this.unsubscribeInTakeUntil) {
                this.unsubscribeInTakeUntil.next();
                this.unsubscribeInTakeUntil.complete();
            }
            this.unsubscribeInTakeUntil = new Subject();
        }
        this.activitiesService.displayActions.next(true);
        this.activitiesService.doesUserResponsed.next(false);
        return of(true);
    }

    public get showPercentile(): string {
        return this.activityPercentile ? ' ' + this.activityPercentile + '%' : ' 0%' || null;
    }

    /**
     * at end of an exo a finalFeedback could exist if it s open a modal
     * @param currentAnswer
     * @private
     */
    private ifFeedbackEndExoOpenModal(currentAnswer: AnswerResultInterface): void {
        if (currentAnswer.isLast && currentAnswer.isLast && this.feedbackEndExo) {
            this.openFeedBack(this.feedbackEndExo, 'activities.title_modal_answer_end');
            // hide exo content when modal is opened
            this.communicationCenter.getRoom('multi').getSubject('hide-content').next(true);
        }
    }

    /**
     * open a modal with the feedback of the bad answer if exist
     * @param idAnswer : optionnal id of the current answer made
     * @param feedback : optionnal we can also pass comment directly from some exo case who not respect same shema
     */
    public ifFeedbackOpenModal(idAnswer?: string, feedback?: string): void {
        // feedback is include in answerSelected
        if (!feedback) {
            const answer = idAnswer ?
                this.answersSelected.find(a => a.id === idAnswer && (a.state === ItemAnswerStateEnum.incorrect || (a.state === ItemAnswerStateEnum.pristine && !a.correct_answer))) :
                this.answersSelected.find(a => a.select && (a.state === ItemAnswerStateEnum.incorrect || (a.state === ItemAnswerStateEnum.pristine && !a.correct_answer)));
            if (answer && answer?.feedback) {
                this.openFeedBack(answer.feedback, 'activities.title_modal_bad_answer_help');
            }
        }
        // exo not use answerselected we pass feedback by string field
        if (feedback) {
            this.openFeedBack(feedback, 'activities.title_modal_bad_answer_help');
        }
    }

    /**
     * send the feedback to open it into a modal
     * @param feedback feedback to show
     * @param title title to change if needed
     * @private
     */
    private openFeedBack(feedback: string, title?: string): void {
        this.communicationCenter
            .getRoom('header-exo')
            .getSubject('show-custom-feedback')
            .next({feedback: feedback, title: title});
    }

    /**
     * affiche ou non le pourcentage de bonne reponse dans l'activité
     */
    public get isPercentileDisplayAllowed(): boolean {
        const asTrainer = this.lessonsService.isAtLeastTrainer() && (this.lessonsService.isLessonEvaluation() || this.lessonsService.isLessonTraining());
        const asLearner = this.lessonsService.isAtLeastTrainer() === false && (this.lessonsService.isLessonEvaluation() && this.testAnswer);
        return asTrainer || asLearner;
    }

    /**
     * change state of TTS
     * @param data : state of TTS and id of the event
     * @param uuid: current id to compare to a child component using read with to a parent one with another id of read not use if no child
     */
    public speakStateChanged(data: { id: string, value: boolean }, uuid = ''): void {
        // a feedback modal is open we must not read the text who is hidden by the modal
        if (this.contentIsHidden) {
            return;
        }

        this.isTTSSpeaking = data;
        this.ref.detectChanges();
        if (!this.wordingAlreadyReadWithTts && this.isTTSSpeaking && !this.isTTSSpeaking.value && (uuid !== this.isTTSSpeaking.id || uuid === '')) {
            this.clickToTts();
        }
        if (this.isTTSSpeaking && !this.isTTSSpeaking.value && uuid !== this.isTTSSpeaking.id) {
            this.wordingAlreadyReadWithTts = true;
        }
    }

    public clickToTts(): void {
        try {
            this.readMe.nativeElement.click();
        } catch (ex) {
            console.error('error clicking TTS 820' + ex);
        }

    }

    /**
     * check if a modal feedback is open if one is the content is hidden and the text will be not read
     * so we must launch read it after the modal will be closed and content will be visible
     * we store state to block reading
     * @private
     */
    private listenCurrentStateOfModalFeedback(): void {
        this.communicationCenter.getRoom('multi').getSubject('hide-content')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((res: boolean) => {
                this.contentIsHidden = res;
            });
    }
}
