import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import {FuseSidebarModule} from 'fuse-core/components';
import {GraphModule} from 'fuse-core/components/graph/graph.module';
import { FlashcardComponent } from './components/flashcard/flashcard.component';
import {SharedMaterialModule} from '../app/shared/shared-material.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FuseSidebarModule,
        GraphModule,
        SharedMaterialModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FuseSidebarModule,
        GraphModule,
        FlashcardComponent
    ],
    declarations: [FlashcardComponent]
})
export class FuseSharedModule
{
}
