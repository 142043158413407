<mat-card class="activities-card inner-scroll mat-elevation-z0 qcuContainer"
          [ngClass]="{'two-columns-layout': isTwoColumns, 'listen-content':type === 'listen' }"
          [attr.id]="this.type===''?'':'type-is-word-or-lettersSelecting'">

    <mat-card-header>
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes (isReading)="ttsReadingEvent($event)" [wording]="wording" [wordingAudio]="wordingAudio"
                           [additionalHiddenText]="showSubInstruction?('activities.sub_instruction_qmcu' | translate):''"></app-consignes>
            <h2 [ngClass]="isTTSReading?.value ? 'read-in-progress':'read-not-in-progress'"
                *ngIf="showSubInstruction">{{'activities.sub_instruction_qmcu' | translate}}</h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [read]="readSecondConsigne" [autoRead]="false" *ngIf="type !== 'listen'"
                       questionTypeName="{{ 'activities.questionTypeName.app-qcu' | translate }}">
        </app-consignes>


        <ng-container *ngIf="type === 'listen'">
            <div class="btn-tts-wrapper">
                <div #readMe
                     appReadable
                     class="tts btn-tts" [uuid]="uuid"
                     [forceText]="instruction"
                     [ngClass]="{'tts-reading': isTTSReadingText}"
                     (speaking)="speakStateChanged($event)">
                    <mat-icon [class.read]="isTTSReadingText" [svgIcon]="'sound_off'" (click)="clickToTts()"></mat-icon>
                </div>
            </div>
        </ng-container>


        <div [attr.id]="this.type===''?'container-for-answers-available':'container-for-answers-available-text-version'"

             [ngClass]="!!answersSelected[0].image?
             'answers-are-images ' + getColumnClass() + ' available-answers grid-square-' + answersSelected.length :columnClass"
             *ngIf="displaySaveBtn" >
            <ng-container *ngFor="let option of answersSelected">
                <ng-container *ngIf="!!answersSelected[0].image">
                    <div [ngClass]="option.state"  (click)="clickAnswer(option)">
                        <img [src]="option.image"/>
                    </div>
                </ng-container>
                <ng-container *ngIf="!answersSelected[0].image">
                    <button
                        class="btn-option"
                        [class]="option.state"
                        (click)="clickAnswer(option)"
                        [disabled]="isDisabledOptions">
                        <span [innerHTML]="option.answer | stringToHtml"></span>
                    </button>
                </ng-container>

            </ng-container>
        </div>
        <mat-radio-group *ngIf="!displaySaveBtn"
                         [ngClass]="{showFinalAnswers: this.testAnswer || this.displaySolution || this.answerSaved, answersChecked: testAnswer}"
                         [(ngModel)]="valueChecked">
            <mat-radio-button *ngFor="let option of answersSelected | intToBoolean: 'correct_answer':'select'"
                              (change)="clickAnswer(option)"
                              [disabled]="isDisabledOptions"
                              [ngClass]="optionState(option)"
                              [disableRipple]="true" [value]="option.answer"
                              appAddClassInRegardOfTextHeight>
                <span [innerHTML]="option.answer | stringToHtml"></span>
            </mat-radio-button>
        </mat-radio-group>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.try_correct' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>

</mat-card>
