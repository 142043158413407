import { Injectable } from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {Observable} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';

export interface UserLevelInterface {
    id: string|number;
    label: string;
    weight: string;
}

@Injectable({
  providedIn: 'root'
})
export class EducationalLevelService {

    private userData: DataEntity;

  constructor(
      private octopusConnect: OctopusConnectService,
      private communicationCenter: CommunicationCenterService,
  ) {

      this.communicationCenter
          .getRoom('authentication')
          .getSubject('userData')
          .subscribe((data: DataEntity) => {
              this.userData = data;
          });

  }

    public getEducationalLevel(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('educational_level');
    }

    /**
     *  Return user progression for each concepts of the selected educational level
     */
    public getEducationalLevelUserProgression(selectedLevelId: string|number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('user-progress', {educationalLevel: selectedLevelId});
    }

    /**
     * Return current authenticated user's level, undefined if user is not logged in.
     * {@link setUserEducationalLevels}
     */
    public getUserLevels(): UserLevelInterface[] {
      if (this.userData) {
          return this.userData.get('level');
      }
    }

    /**
     * Save educational levels Ids in user data
     * @param educationalLevelIds
     * {@link getUserLevels}
     * could be return an error if user are not connected
     */
    public setUserEducationalLevels(educationalLevelIds: (number|string)[]): Observable<DataEntity> {
        if (this.userData) {
            this.userData.set('level', educationalLevelIds);
            return this.userData.save().pipe(
                // level's field will be an array of id(string) because it is set before the .save() and we need the full value
                tap((userdata) => this.userData = userdata)
            );
        }
        throw new Error('Cannot set user\'s levels if you are not connected');
    }

}
