import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AriaLabelTranslateDirective } from "../aria-label-translate.directive";
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule
  ],
  declarations: [
    AriaLabelTranslateDirective
  ],
  exports: [
    AriaLabelTranslateDirective,
    TranslateModule
  ]

})
export class SharedTranslateModule { }
