<div mat-dialog-title>
    <h2>{{'generic.add_to_lesson' | translate}}</h2>
    <button mat-mini-fab
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form  novalidate>
        <p>{{ 'generic.select_lesson_help' | translate}}</p>
        <p>{{ 'generic.select_lesson_help_2' | translate}}</p>
        <div>
            <mat-form-field>
                <input [formControl]="titleFilter" matInput placeholder="{{ 'activities.title_lessons' | translate }}">
            </mat-form-field>
        </div>

        <table mat-table [dataSource]="resources"
                   [@animateStagger]="{value:'50'}">
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let entity">
                    <div *ngIf="entity.attributes.locked!=='1'">
                        <p class="text-truncate">{{entity.attributes.metadatas.title}}</p>
                    </div>
                    <div *ngIf="entity.attributes.locked==='1'" title="{{ 'activities.lesson_not_editable' | translate }}">
                        <p class="text-truncate"><mat-icon svgIcon="lock"></mat-icon> {{entity.attributes.metadatas.title}}</p>
                    </div>
                </td>
            </ng-container>
            <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
                     class="entity locked{{entity.attributes.locked}}"
                     (click)="selectedLesson(entity)"
                     matRipple
                     [ngClass]="{'mat-light-blue-50-bg': checkSelected(entity) > -1}"
                     [@animate]="{value:'*',params:{y:'100%'}}">
            </tr>
        </table>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            [disabled]="!lessonsSelectedButton"
            (click)="dialogRef.close({lessons:lessonsSelected, activities:activities})"
            class="save-button mat-accent"
            aria-label="SAVE">
        {{ 'generic.validate' | translate }}
    </button>
</mat-dialog-actions>
