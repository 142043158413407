<mat-card class="activities-card inner-scroll mat-elevation-z0 qcmContainer"
          [ngClass]="{'two-columns-layout': isTwoColumns}"
          [attr.id]="type === 'isWordSelecting' || type === 'isLetterSelecting'?'type-is-word-or-lettersSelecting':''">

    <mat-card-header>
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes (isReading)="ttsReadingEvent($event)" [wording]="wording" [wordingAudio]="wordingAudio"
                           [additionalHiddenText]="showSubInstruction?('activities.sub_instruction_qmc' | translate):''"></app-consignes>
            <h2 [ngClass]="isTTSReading?.value ? 'read-in-progress' : 'read-not-in-progress'"
                *ngIf="showSubInstruction">{{'activities.sub_instruction_qmc' | translate}}</h2>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-consignes [read]="readSecondConsigne" [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="false"
                       questionTypeName="{{ 'activities.questionTypeName.app-qcm' | translate }}">
        </app-consignes>
        <div class="mat-checkbox-group"
             [ngClass]="parentClass()">
            <div *ngFor="let option of answersSelected | intToBoolean: 'correct_answer':'select' "
                 class="checkbox-wrapper" (click)="toggleCheck($event, option, true)" [ngClass]="optionState(option)">
                <mat-checkbox
                    [(ngModel)]="option.select " (click)="toggleCheck($event, option, false)"
                    (change)="optionChange()"
                    [disabled]="isDisabledOptions()"
                    [ngClass]="optionState(option)"
                    [disableRipple]="true" [value]="option.answer">
                    <span [innerHTML]="option.answer | stringToHtml"></span>
                </mat-checkbox>
            </div>
        </div>
        <button
            class="btn-option"
            *ngIf="displaySaveBtn"
            [class]="buttonState"
            mat-raised-button
            [disabled]="isDisabledOptions(false)"
            (click)="clickAnswer()">
            <span>{{ 'generic.register' | translate }}</span>
        </button>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ feedback | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>
