import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';

@Injectable({
  providedIn: 'root'
})
export class CanLaunchOldEditorGuard implements CanActivate {
    constructor(private lessonEditionSvc: LessonEditionService) {
    }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.lessonEditionSvc.canNewLaunchEditor === false;
  }
}
