import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {of} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable} from 'rxjs/index';
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'app-multimedia',
    templateUrl: './multimedia.component.html'
})
export class MultimediaComponent extends BaseActivityComponent<any, any> {
    @Input() public activityInput?: DataEntity;
    public isInitializing: boolean;
    public resources: any[] = [];
    public title: string;
    public typeOfContent: string;
    public uuid: string;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        private changeDetector: ChangeDetectorRef,
        private octopusConnect: OctopusConnectService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef,
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
        this.activatedRoute.queryParams.subscribe(params => {
            if (params && !this.activityInput) {
                this.activityId = <any>{
                    id: params.id,
                    isLoadBeforeLaunch: params.isLoadBeforeLaunch
                };
            }
        });
    }

    protected setContentData(activityAttributes): void {
        this.uuid = uuidv4();
        // todo: need to check in case of activity "GHOST" (cf new editor) if we get the metadatas
        if (activityAttributes.metadatas) {
            this.activityType = activityAttributes.metadatas.typology.label;
        }
        if (this.activityInput && this.activityInput.get('ghost')) {
            // TODO get title, instruction or wording from editable activity
            this.instruction = activityAttributes.instruction;
            this.wording = activityAttributes.instruction;
            this.instructionAudio = activityAttributes.instructionAudio;
            this.wordingAudio = activityAttributes.wordingAudio;

            this.resources = activityAttributes.page.map((val: { type: string, media: any }) => {
                if (val.type === 'text' && typeof val.media === 'string') {
                    const attributes = {
                        format: {
                            label: val.type
                        },
                        reference: {
                            text: val.media
                        }
                    };
                    return new DataEntity('granule', attributes, this.octopusConnect);
                } else {
                    return val.media;
                }
            });
        } else {
            this.title = activityAttributes.metadatas.title;
            this.instruction = activityAttributes.reference.instruction;
            this.wording = activityAttributes.reference.wording;
            this.instructionAudio = activityAttributes.reference.instructionAudio;
            this.wordingAudio = activityAttributes.reference.wordingAudio;

            if (!activityAttributes.reference.activity_content || activityAttributes.reference.config && activityAttributes.reference.config.type) {
                this.typeOfContent = activityAttributes.reference.config.type;
            } else {
                this.resources = this.getGranule(activityAttributes.reference.activity_content[0]);
            }
        }
        this.isInitializing = false;
    }

    /**
     * Extract the granule's DataEntity from the given activityContent data
     * @param activityContent - field from activity
     * @returns granule from the content as a DataEntity
     */
    private getGranule(activityContent: any): DataEntity[] {
        if (activityContent && activityContent.granule && activityContent.granule.length) {
            const resources = activityContent.granule.filter((media) => !!media).map((granule) => {
                if (granule && granule.attributes) {
                    return granule;
                }
                return new DataEntity('granule', granule, this.octopusConnect, granule.id);
            });

            return resources;
        }

        return [];
    }

    /**
     * Resets the current component
     */
    protected reset(): Observable<boolean> {
        this.isInitializing = true;
        delete this.instruction;
        delete this.wording;
        this.changeDetector.detectChanges();
        return super.reset();
    }

    public videoEmbedUrl(video): any {
        const videoConfig = {
            src: video.get('reference').url,
        };
        if (this.activitiesService.settings.urlVideoException.some((urlException) => video.get('reference').url.includes(urlException))) {
            videoConfig['type'] = 'video/mp4';
        }
        return {
            config: videoConfig,
        };
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        throw new Error('Method not implemented.');
    }


    /**
     * change state of TTS
     * TODO better to move in base-activity, need to add ref (ChangeDetectorRef) in contructor
     * @param data
     */
    public speakStateChanged(data: { id: string, value: boolean }): void {
        this.isTTSSpeaking = data;
        this.ref.detectChanges();
        if (!this.wordingAlreadyReadWithTts && this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.clickToTts();
        }
        if (this.isTTSSpeaking && !this.isTTSSpeaking.value && this.uuid !== this.isTTSSpeaking.id) {
            this.wordingAlreadyReadWithTts = true;
        }
    }

    public get isTTSReadingText(): boolean {
        return this.isTTSSpeaking && this.isTTSSpeaking.id === this.uuid && this.isTTSSpeaking.value;
    }

}
