import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FlashCardInterface} from '@modules/activities/core/models/flash-card.interface';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';

/**
 * it's an array of item who can be visible or not
 * the logic is on the parent the component only show or not item
 * and send event to parent
 */
@Component({
    selector: 'app-memory-find-zone',
    templateUrl: './memory-find-zone.component.html',
})
export class MemoryFindZoneComponent implements OnInit, OnChanges {
    @Input('data') data: FlashCardInterface[] = []; // data to show
    @Input('selectedItem') selectedItem: number[] = []; // list of element currently selected to change in data
    @Input('stateOfLastSelectedItems') stateOfLastSelectedItems: { uid: number, state: ItemAnswerStateEnum }[]; // state to change in data
    @Output('idItemClicked') idItemClicked: EventEmitter<number> = new EventEmitter(); // send to parent id of item selected

    constructor() {
    }


    ngOnInit(): void {
    }

    /**
     * if data change we update the items
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        this.updateSelectedItem();
    }

    /**
     * update data : change the select state and the current state : it's used because array change are not directly detected by input entry
     * @private
     */
    private updateSelectedItem(): void {
        this.updateState();
        this.updateSelectState();
    }

    /**
     * update the state of item pristine currently-correct incorrect etc.
     * @private
     */
    private updateSelectState(): void {
        if (this.selectedItem.length === 0) {
            this.data.map(item => item.select = false);
        } else {
            this.selectedItem.forEach(item => {
                this.data.find(card => card.uid === item).select = true;
            });
        }
    }

    /**
     * update the select state is item currently selected or not
     * @private
     */
    private updateState(): void {
        if (this.stateOfLastSelectedItems && this.stateOfLastSelectedItems.length > 0) {
            this.stateOfLastSelectedItems.forEach(itemState => {
                this.data.find(card => card.uid === itemState.uid).state = itemState.state;
            });
        }
    }

    /**
     * emit the id of element clicked
     * @param uid id unique
     */
    public changeState(uid: number): void {
        this.idItemClicked.emit(uid);
    }
}
