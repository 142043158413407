<div>
  <!-- TODO add class css for scroll and features -->

  <h1 class="recap-header">{{ "activities.summary_header" | translate }}</h1>

  <ng-container *ngFor="let activity of activities; let i = index">
    <div class="activity-counter" [ngClass]="answerState(activity)">
      {{ "activities.summary_activity_counter" | translate }} {{ i + 1 }} /{{
        activities.length
      }}
    </div>
    <mat-card
      [ngClass]="activity.attributes.metadatas?.typology.label | lowercase"
      class="activities-card inner-scroll mat-elevation-z0"
    >
      <mat-card-header>
        <mat-card-title>
          <app-consignes
            [autoRead]="false"
            *ngIf="!isActivityTypeUseWording(activity)"
            [elementToWatch]="activity.get('reference')?.instruction"
            [instruction]="activity.get('reference')?.instruction"
            appAddClassIfContainsPicture
          ></app-consignes>
          <app-consignes
            [autoRead]="false"
            *ngIf="isActivityTypeUseWording(activity)"
            [elementToWatch]="activity.get('reference')?.wording"
            [wording]="activity.get('reference')?.wording"
            [wordingAudio]="activity.get('reference')?.wordingAudio"
            appAddClassIfContainsPicture
          ></app-consignes>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <app-consignes
          [autoRead]="false"
          *ngIf="
            isActivityTypeUseWording(activity) &&
            activity.get('metadatas')?.typology.label !== 'ORDMAT' &&
            activity.get('metadatas')?.typology.label !== 'TXTMAT' &&
            !!activity.get('reference')?.instruction
          "
          [elementToWatch]="activity.get('reference')?.instruction"
          [instruction]="activity.get('reference')?.instruction"
          appAddClassIfContainsPicture
        ></app-consignes>

        <ng-container
          *ngIf="activity.get('metadatas')?.typology.label === 'MULTI'"
        >
          <div
            class="multi-summary-container"
            [innerHTML]="activity.get('reference')?.wording"
          ></div>
        </ng-container>
      </mat-card-content>
      <mat-card-footer>
        <div class="answer-state">
          <div
            *ngIf="activity.get('metadatas')?.typology.label.startsWith('QCM')"
          >
            {{
              getItemAnswerInterfaceAsALine(
                getOnlyCorrectAnswer(answers(activity))
              )
            }}
          </div>
          <ng-container
            *ngIf="activity.get('metadatas')?.typology.label === 'VF'"
          >
            <div
              *ngFor="
                let answerSelected of getOnlyCorrectAnswer(answers(activity));
                index as i
              "
            >
              {{ answerSelected.answer }}
            </div>
          </ng-container>
          <ng-container
            *ngIf="activity.get('metadatas')?.typology.label === 'RB'"
          >
            <!--                        <div>{{replaceFieldToFind(activity)}}</div>-->
            <div *ngFor="let answerSelected of answers(activity); index as i">
              {{ getParsedAnswer(answerSelected.answer)[i] }}
            </div>
          </ng-container>
          <ng-container
            *ngIf="activity.get('metadatas')?.typology.label === 'CRT'"
          >
            <div *ngFor="let answerSelected of answers(activity)">
              {{ answerSelected.answer }}
            </div>
          </ng-container>

          <div
            *ngIf="
              activity.get('metadatas')?.typology.label.startsWith('POINTWORD')
            "
          >
            {{
              getItemAnswerInterfaceAsALine(
                getOnlyCorrectAnswer(answers(activity))
              )
            }}
          </div>
          <div
            *ngIf="
              activity
                .get('metadatas')
                ?.typology.label.startsWith('POINTMULTIWORD')
            "
          >
            {{
              getItemAnswerInterfaceAsALine(
                getOnlyCorrectAnswer(answers(activity))
              )
            }}
          </div>
          <div
            *ngIf="
              activity.get('metadatas')?.typology.label.startsWith('POINTIMG')
            "
          >
            <div class="answer-wrapper">
              <div
                [innerHtml]="
                  activity.get('reference')?.instruction | stringToHtml
                "
              ></div>
              <app-zone-generator
                [sizeOfZone]="4"
                [answers]="answers(activity)"
                [showAnswers]="true"
              >
              </app-zone-generator>
            </div>
          </div>

          <ng-container
            *ngIf="activity.get('metadatas')?.typology.label === 'ORDMAT'"
          >
            <div class="app-order-matching">
              <div id="container-for-answers-selected">
                <div
                  *ngFor="let answerSelected of answers(activity); index as i"
                  class="was-correct"
                >
                  <img
                    *ngIf="
                      activity.get('reference').config.direction !== 'vertical'
                    "
                    [src]="answerSelected.image"
                  />
                  <label
                    *ngIf="
                      activity.get('reference').config.direction === 'vertical'
                    "
                    >{{ answerSelected.answer }}</label
                  >
                  <span class="answer-position current">{{ i + 1 }}</span>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="isActivityTypeUseWording(activity)">
            <ng-container
              *ngIf="activity.get('metadatas')?.typology.label === 'TXTMAT'"
            >
              <div *ngFor="let answerSelected of answers(activity)">
                <div
                  [innerHtml]="answerSelected.source | stringToHtml"
                  appAddClassIfContainsPicture
                  class="guessing-item"
                ></div>
                <div
                  [innerHtml]="answerSelected.target | stringToHtml"
                  class="answer-item"
                ></div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container
            *ngIf="activity.get('metadatas')?.typology.label === 'DRL'"
          >
            <div class="image-wrapper-drl" style="position: relative">
              <img
                *ngFor="
                  let imagePath of getDrawLineActivityImagePaths(activity)
                "
                [src]="imagePath"
                style="position: absolute"
              />
            </div>
          </ng-container>
        </div>
      </mat-card-footer>
    </mat-card>
  </ng-container>

  <div class="footer-summary">
    <button (click)="nextActivity()" *ngIf="isNextActivity" mat-raised-button>
      <mat-icon svgIcon="next"></mat-icon>
      <span>{{ "generic.next" | translate }}</span>
    </button>
    <button (click)="retry()" mat-raised-button>
      <mat-icon svgIcon="retry"></mat-icon>
      <span>{{ "generic.retry" | translate }}</span>
    </button>
    <button (click)="back()" mat-raised-button>
      <mat-icon svgIcon="back"></mat-icon>
      <span>{{ "generic.back" | translate }}</span>
    </button>
  </div>
</div>
