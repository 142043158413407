import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {QcuComponent} from '@modules/activities/core/player-components/qcu/qcu.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities';
import {CommunicationCenterService} from '@modules/communication-center';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {takeUntil, timeout} from 'rxjs/operators';

@Component({
    selector: 'app-what-is-missing',
    templateUrl: './what-is-missing.component.html',
})
/**
 * this component extend qcu be carefull to not implement ngoninit or other element that can overide
 * the default behaviour of parent class without reason
 */
export class WhatIsMissingComponent extends QcuComponent implements AfterViewInit {
    public step = 0;
    public showButton = false;

    constructor(protected activatedRoute: ActivatedRoute,
                protected activitiesService: ActivitiesService,
                protected lessonsService: LessonsService,
                protected communicationCenter: CommunicationCenterService,
                protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    ngAfterViewInit(): void {
        this.resetStepWhenAnswerIsGood();
    }

    private resetStepWhenAnswerIsGood(): void {
        // each time one activity is done the step come back to zero for the next one acitivity if exist
        this.activitiesService.isUserAnswerStatus.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(data => {
                if (data.status === 1) {
                    this.step = 0;
                    this.showButton = false;
                }
            });
    }

    public nextStep(): void {
        this.step = this.step + 1;
        if (this.step === 1) {
            setTimeout(() => {
                this.nextStep();
            }, 3000);
        }

        if (this.step === 2) {
            setTimeout(() => {
                this.showButton = true;
            }, 1000);

        }
    }

    public dynamicImgContainerClass(option: ItemAnswerInterface): string {
        const isHide = option.correct_answer ? ' to-hide' : ' not-to-hide';
        return option.state + ' step-' + this.step + isHide;
    }
}
