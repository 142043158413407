<div mat-dialog-title>
    <h2>{{'multimedia_page_editor.title' | translate}}</h2>
    <button (click)="selfDialogRef.close(false)"
            aria-label="Close dialog"
            class="close"
            mat-mini-fab>
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    {{'multimedia_page_editor.instruction' | translate}}

    <h2>{{'multimedia_page_editor.choose_media' | translate}}</h2>

    <div *ngIf="showCorpusSelection">
        <app-select-corpus-upload [uploadAvailable]="false" (corpusSelect)="addMedia($event)"></app-select-corpus-upload>
    </div>
    <div *ngIf="!showCorpusSelection">
        <button *ngFor="let media of availableMedias" mat-raised-button class="media-selector"
                [ngClass]="media + (isSelectedMedia(media) ? ' active': '')" (click)="selectTypeOfMedia(media)">
            <mat-icon [svgIcon]="media"></mat-icon>
            {{'generic.' + media | translate}}
        </button>
    </div>


    <h2>{{'multimedia_page_editor.add_text' | translate}}</h2>
    <ckeditor [(ngModel)]="text" [config]="editorConfig" [editor]="editor"></ckeditor>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="save()">{{'generic.add' | translate}}</button>
</mat-dialog-actions>
