import {ChangeDetectorRef, Component} from '@angular/core';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {shuffle} from '../../../../../shared/utils';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {v4 as uuidv4} from 'uuid';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';


// format de l'activity_content
interface TextMatchingActivityContentInterface {
    answers_app: ItemAnswerInterface[];
}


// format du champ config de l'activité
interface TextMatchingActivityConfigInterface {
    columns?: number;
    direction: string;
    // define the mode of the inputs
    mode?: 'picture' | 'text' | undefined;
    // InstructionMode is a bad name, the mode defined the guessing item in the activity content mode, not the instruction, undefined = should be a text
    instructionMode?: 'picture' | undefined;
}

// format du champ référence de l'activité
type TextMatchingActivityReferenceInterface = ActivityReferenceInterface<TextMatchingActivityContentInterface, TextMatchingActivityConfigInterface>;

@Component({
    selector: 'app-text-matching',
    templateUrl: './text-matching.component.html'
})
export class TextMatchingComponent extends BaseActivityComponent<TextMatchingActivityContentInterface, TextMatchingActivityConfigInterface> {
    // Lot de réponses possible, généré depuis imagesToGuess
    public availableAnswers: ItemAnswerInterface[] = [];
    // Lot d'images utilisé comme instruction
    public imagesToGuess: ItemAnswerInterface[] = [];
    public currentImageToGuess: ItemAnswerInterface = null;
    public disableAllAnswers = false;
    public uuid: string = uuidv4();
    private referenceActivityGranule: TextMatchingActivityReferenceInterface;
    private answers: unknown[] = [];

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    public onAnswerClick(item: ItemAnswerInterface): void {
        const answerResult: AnswerResultInterface = {
            id: +this.activityId.id,
            isAnswerCorrect: undefined,
            isLast: undefined
        };
        this.disableAllAnswers = true;
        if (item.id === this.currentImageToGuess.id) {
            item.state = ItemAnswerStateEnum.currentlyCorrect;
            answerResult.isAnswerCorrect = true;
        } else {
            answerResult.isAnswerCorrect = false;
            item.state = ItemAnswerStateEnum.incorrect;
            if (item.feedback) {
                super.ifFeedbackOpenModal(null, item.feedback);
            }
        }
        answerResult.isLast = this.imagesToGuess.indexOf(this.currentImageToGuess) === this.imagesToGuess.length - 1 && item.state === ItemAnswerStateEnum.currentlyCorrect;
        this.answerResult.next(answerResult);
        this.nextItem();
    }

    public getColumnClass(): string {
        const numberOfColumns = this.referenceActivityGranule.config.columns;
        return 'columns-' + (_.isNumber(numberOfColumns) ? numberOfColumns : 1);
    }

    public getAvailableAnswersClasses(): string {
        const inputMode = 'input-as-' + this.getTypeOfInputs();
        return [this.getColumnClass(), inputMode].join(' ');
    }

    public getGuessingItemClasses(): string {
        return 'instruction-as-' + this.getTypeOfInstruction();
    }

    protected setAnswer(): any {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            const answers = this.userSave.get('userActivity').entitySave.answers;

            if (answers) {
                this.restoreAnswers(answers);
                if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.testAnswer = true;
                }
                this.checkAnswer();
                this.activitiesService.userAnswer.next(answers);
            }
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.testAnswer = true;
            this.checkAnswer();
        }
    }

    protected getGrade(): any {
        let oldGrade = 0;
        const correctCnt = this.availableAnswers.length;
        const selectedCorrect = this.availableAnswers.filter(item => item.state === ItemAnswerStateEnum.currentlyCorrect || item.state === ItemAnswerStateEnum.wasCorrect).length;

        if (this.userSave) {
            const oldAnswers = <ItemAnswerInterface[]>this.userSave.get('userActivity').entitySave.answers;
            const oldSelectedCorrect = oldAnswers.filter(item => item.state === ItemAnswerStateEnum.currentlyCorrect || item.state === ItemAnswerStateEnum.wasCorrect).length;

            if (oldSelectedCorrect > 0) {
                oldGrade = oldSelectedCorrect / correctCnt;
            }
        }

        const grade = selectedCorrect / correctCnt;
        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    protected checkAnswer(): void {
        this.answerStatus = answerStatusEnum.wrong;

        const isCorrect = (i: ItemAnswerInterface) => i.state === ItemAnswerStateEnum.currentlyCorrect || i.state === ItemAnswerStateEnum.wasCorrect;
        const isPristine = (i: ItemAnswerInterface) => i.state === ItemAnswerStateEnum.pristine;

        if (this.availableAnswers.every(isCorrect)) {
            this.answerStatus = answerStatusEnum.correct;
        } else if (this.availableAnswers.some(isPristine)) {
            this.answerStatus = answerStatusEnum.missing;
        }
        this.activitiesService.doesUserResponsed.next(true);
        /* state message showing */
        if (this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining()) {
            this.testAnswer = true;
            this.displayFeedback = this.answerStatus === 3
                && this.lessonsService.isLessonTraining()
                && !this.lessonsService.isAtLeastTrainer()
                && this.activitiesService.settings.displayFeedback;
        }

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.testAnswer) {
            this.activitiesService.checkAnswers.next({lessonCorrected: true});
        }
    }

    protected seeAnswerSolution(): void {
        this.testAnswer = true;
        this.availableAnswers = _.clone(this.imagesToGuess);
    }

    protected reset(resetAllSubscribe = false, type: string = null): Observable<boolean> {
        this.availableAnswers.forEach((item) => item.state = ItemAnswerStateEnum.pristine);
        return super.reset(resetAllSubscribe, type);
    }

    protected reviewAnswer(): void {
        this.displayFeedback =
            this.answerStatus === answerStatusEnum.wrong
            && this.lessonsService.isLessonTraining()
            && !this.lessonsService.isAtLeastTrainer()
            && this.activitiesService.settings.displayFeedback;

        this.reset(false, 'reviewAnswer');
        this.testAnswer = true;
        // restore answer only if add one and if almost one response is not null
        if (this.userSave && this.userSave.get('userActivity') && this.userSave.get('userActivity').entitySave.answers.filter(res => res !== null).length > 0) {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.restoreAnswers(answers);
            this.checkAnswer();
        } else {
            // we don't have choose solution so we go back like if user click on reset button
            this.makeItShuffled();
            this.reset(false, 'reset');
        }
    }

    protected setContentData(data): void {
        this.wordingAlreadyReadWithTts = false;
        this.isTTSSpeaking = null;
        this.referenceActivityGranule = data.reference;
        if (this.referenceActivityGranule.config) {
            this.isVertical = (this.referenceActivityGranule.config.direction === 'vertical');
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.imagesToGuess = this.referenceActivityGranule.activity_content.answers_app;
        this.makeItShuffled();
        this.currentImageToGuess = this.imagesToGuess[0];
        this.loadUserSave();
    }

    // API Save Answer functionality
    protected saveAnswer(): Observable<number[]> {
        return of(this.availableAnswers.map((answer: ItemAnswerInterface) => +answer.id));
    }

    private makeItShuffled(): void {
        this.availableAnswers = _.cloneDeep(this.imagesToGuess);
        this.availableAnswers = shuffle(this.availableAnswers, true);
        this.imagesToGuess = shuffle(this.availableAnswers, true);
    }

    private restoreAnswers(answers: ItemAnswerInterface[]): void {
        this.imagesToGuess = _.cloneDeep(answers);
        this.makeItShuffled();
    }

    private nextItem(): void {
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            this.disableAllAnswers = false;
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            const currentPos = this.imagesToGuess.indexOf(this.currentImageToGuess);
            if (currentPos < this.imagesToGuess.length - 1 && this.currentImageToGuess.state === ItemAnswerStateEnum.wasCorrect) {
                this.currentImageToGuess = this.imagesToGuess[currentPos + 1];
                // read the secondConsigne when switch to next question
                this.ref.detectChanges();
                this.clickToTts();
            } else {
                if (this.currentImageToGuess.state === ItemAnswerStateEnum.wasCorrect) {
                    this.doAction('next', ['save']);
                }
            }
        }, 500);

    }

    private getTypeOfInputs(): string {
        const mode = this.referenceActivityGranule.config.mode;

        if (!!mode) {
            return mode;
        }

        return 'undefined';
    }

    private getTypeOfInstruction(): string {
        const mode = this.referenceActivityGranule.config.instructionMode;

        if (!!mode) {
            return mode;
        }

        return 'undefined';
    }

    public get isTTSReadingText(): boolean {
        return this.isTTSSpeaking && this.isTTSSpeaking.id === this.uuid && this.isTTSSpeaking.value;
    }
}
