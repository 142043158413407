<mat-card class="activities-card inner-scroll mat-elevation-z0"
          [ngClass]="{'two-columns-layout': isTwoColumns, 'order-sentence':isOrderSentence}">
    <mat-card-header>
        <mat-card-title>
            <app-consignes [wording]="wording" [wordingAudio]="wordingAudio"
                           questionTypeName="{{ 'activities.questionTypeName.app-consignes' | translate }}">
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="false"
                       questionTypeName="{{ 'activities.questionTypeName.app-consignes' | translate }}">
        </app-consignes>
        <div id="container-for-answers-selected"
             [ngClass]="!!availableAnswers[0].image?'answers-will-be-images grid-inline-' + answersSelected.length :'answers-will-be-text grid-inline-' + answersSelected.length">
            <div *ngFor="let answerSelected of answersSelected; index as i"
                 [ngClass]="returnClassAnswerState(i, answerSelected.state)">
                <img *ngIf="!isTwoColumns && !!answerSelected.image" [src]="answerSelected.image"/>
                <label *ngIf="isTwoColumns && !!answerSelected.answer">{{answerSelected.answer}}</label>
                <span [class.current]="currentAnswerToSelect === i" class="answer-position">{{i + 1}}</span>
            </div>
        </div>

        <div id="container-for-answers-available"
             [ngClass]="!!availableAnswers[0].image?
             'answers-are-images ' + getColumnClass() + 'available-answers grid-square-' + answersSelected.length :
             'answers-are-text ' + getColumnClass() + 'available-answers grid-square-' + answersSelected.length">
            <ng-container *ngIf="!isTwoColumns">
                <div [ngClass]="item.state" *ngFor="let item of availableAnswers" (click)="clickAnswer(item)">
                    <img [src]="item.image"/>
                </div>
            </ng-container>
            <ng-container *ngIf="isTwoColumns">
                <button
                    mat-raised-button
                    *ngFor="let item of availableAnswers"
                    (click)="clickAnswer(item)"
                    [ngClass]="item.state" class="button-image"
                    [disabled]="item.state === 'was-correct' && !disableAllAnswers"><span>{{ item.answer }}</span>
                </button>
            </ng-container>
        </div>
    </mat-card-content>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>
        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>

