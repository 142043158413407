import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsUserLogged} from '../../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonsComponent} from '@modules/activities/core/lessons/lessons.component';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {LessonsListComponent} from './lessons-list/lessons-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {FormsListComponent} from '@modules/activities/core/lessons/forms-list/forms-list.component';
import {LessonsTabComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/lessons-tab.component';
import {FlagService} from '../../../../shared/flag.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {PlayersModule} from '@modules/activities/core/player-components/players.module';
import {FormPlayerComponent} from './lessons-list/forms-tab/form-player/form-player.component';
import {ResolverForFormPlayer} from '@modules/activities/core/lessons/resolvers/formPlayer.resolver';
import {EditFormDialogComponent} from './forms-list/edit-form-dialog/edit-form-dialog.component';
import {FormActivitiesComponent} from './forms-list/form-activities/form-activities.component';
import {DragulaModule} from 'ng2-dragula';
import { MatStepperModule } from '@angular/material/stepper';
import {ActivitiesDirectiveModule} from '@modules/activities/core/directive/activities-directive.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {EditLessonDialogComponent} from './lessons-list/edit-lesson-dialog/edit-lesson-dialog.component';
import {LessonActivitiesComponent} from './lessons-list/lesson-activities/lesson-activities.component';
import {SequencesTabComponent} from '@modules/activities/core/lessons/lessons-list/sequences-tab/sequences-tab.component';
import {ActivitiesListComponent} from '@modules/activities/core/activities-list/activities-list.component';
import {ActivitiesHomeComponent} from '@modules/activities/core/activities-home/activities-home.component';
import {LessonActivitiesResolver} from '@modules/activities/core/lessons/resolvers/lesson-activities.resolver';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {CorpusDisplayEmbedComponent} from '@modules/activities/core/shared-components/corpus-display-embed/corpus-display-embed.component';
import {LessonsRecapComponent} from '@modules/activities/core/lessons/lessons-recap/lessons-recap.component';
import {FuseLessonsModule} from 'fuse-core/components/lessons-selection/fuse-lessons.module';
import {LessonMetadataDialogComponent} from './lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {LessonsFavoritesComponent} from './widget/lessons-favorites/lessons-favorites.component';
import {CommonSharedModule} from '@modules/activities/core/shared-components/common-shared.module';
import {EditLessonComponent} from '@modules/activities/core/lessons/lessons-list/edit-lesson/edit-lesson.component';
import {OnlyLessonLessonsListComponent} from './lessons-list/only-lesson-lessons-list/only-lesson-lessons-list.component';
import {OnlyModelLessonsListComponent} from './lessons-list/only-model-lessons-list/only-model-lessons-list.component';
import {CanShowLessonListGuard} from '@modules/activities/core/lessons/lessons-list/can-show-lesson-list.guard';
import {SummaryComponent} from '@modules/activities/core/shared-components/summary/summary.component';
import {LessonsConsultedComponent} from './widget/lessons-consulted/lessons-consulted.component';
import {LessonsCreatedComponent} from './widget/lessons-created/lessons-created.component';
import {QcmEditorComponent} from '@modules/activities/core/editor-components/qcm-editor/qcm-editor.component';
import {QcuEditorComponent} from '@modules/activities/core/editor-components/qcu-editor/qcu-editor.component';
import {ShortAnswerEditorComponent} from '@modules/activities/core/editor-components/short-answer-editor/short-answer-editor.component';
import {VideoEditorComponent} from '@modules/activities/core/editor-components/video-editor/video-editor.component';
import {ShortAnswerMultiEditorComponent} from '@modules/activities/core/editor-components/short-answer-multi-editor/short-answer-multi-editor.component';
import {EditorsModule} from '@modules/activities/core/editor-components/editors.module';
import {NgxFileDropModule} from 'ngx-file-drop';
import {CommunityListComponent} from '@modules/activities/core/lessons/lessons-list/community-list/community-list.component';
import {ThemesModule} from '@modules/activities/core/themes/themes.module';
import {SearchFiltersModule} from 'fuse-core/components';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import { DeleteLessonWarningComponent } from './lessons-list/lessons-tab/delete-lesson-warning/delete-lesson-warning.component';
import { EditLessonWarningComponent } from './lessons-list/lessons-tab/edit-lesson-warning/edit-lesson-warning.component';
import {CanLaunchOldEditorGuard} from '@modules/activities/core/lessons/editor/guards/can-launch-old-editor.guard';
import {EditorModule} from '@modules/activities/core/lessons/editor/editor.module';
import {ProgressBarGenericModule} from '@fuse/components/progress-bar-generic/progress-bar-generic.module';
import {ChartsModule} from 'ng2-charts';
import { RecommendationComponent } from './lessons-list/recommendation/recommendation.component';
import { LauncherComponent } from './lessons-list/launcher/launcher/launcher.component';
import { LauncherButtonComponent } from './lessons-list/launcher/launcher-button/launcher-button.component';
import {ChaptersListComponent} from '@modules/activities/core/activities-home/chapters-list/chapters-list.component';
import {RewardPageComponent} from '@modules/activities/core/reward-page/reward-page.component';
import { SummarySubLessonComponent } from './summary-sub-lesson/summary-sub-lesson.component';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';

const routes: Routes = [
    {
        path: 'lessons',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'home',
                component: ActivitiesHomeComponent,
            },
            {
                path: 'grade/:gradeId/concept',
                children: [
                    {
                        path: ':conceptId',
                        component: ChaptersListComponent,
                    }
                ]
            },
            {
                path: 'list',
                canActivate: [CanShowLessonListGuard],
                children: [
                    {
                        path: '',
                        component: LessonsListComponent,
                    },
                    {
                        path: 'lessons',
                        component: OnlyLessonLessonsListComponent
                    },
                    {
                        path: 'models',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'algebra',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'geometry',
                        component: OnlyModelLessonsListComponent
                    },
                    {
                        path: 'community',
                        component: CommunityListComponent
                    },
                    {
                        path: 'recommendation',
                        component: RecommendationComponent
                    }]
            },
            {
                path: ':lessonId',
                children: [
                    {
                        path: 'player',
                        component: LessonsComponent,
                        children: [
                            {
                                path: 'recap',
                                component: LessonsRecapComponent
                            },
                            {
                                path: 'reward',
                                component: RewardPageComponent
                            },                            {
                                path: 'summary',
                                component: SummarySubLessonComponent
                            },
                            {
                                path: '',
                                loadChildren: () => PlayersModule
                            }
                        ]
                    },
                    {
                        path: 'edit',
                        canActivate: [CanLaunchOldEditorGuard],
                        children: [
                            {
                                path: '',
                                resolve: {
                                    lessonActivities: LessonActivitiesResolver
                                },
                                component: LessonActivitiesComponent
                            },
                            {
                                path: 'stepto',
                                children: [
                                    {
                                        path: '',
                                        component: EditLessonComponent,
                                        children: [
                                            {
                                                path: ':activityId',
                                                children: [
                                                    {
                                                        path: 'video',
                                                        component: VideoEditorComponent,
                                                    },
                                                    {
                                                        path: 'poll',
                                                        component: ShortAnswerMultiEditorComponent,
                                                    },
                                                    {
                                                        path: 'summary',
                                                        component: SummaryComponent,
                                                    },
                                                    {
                                                        path: 'resources',
                                                        component: CorpusDisplayEmbedComponent,
                                                    }
                                                ]
                                            },
                                        ]
                                    },
                                ]
                            },
                            {
                                path: 'activities',
                                component: ActivitiesListComponent
                            },
                            {
                                path: 'resources',
                                component: CorpusDisplayEmbedComponent,
                            },
                            {
                                path: '',
                                loadChildren: () => EditorsModule
                            },
                        ]
                    },
                    {
                        path: 'editor',
                        children: [
                            {
                                path: '',
                                loadChildren: () => EditorModule
                            }
                        ]
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'player'
                    }
                ]
            }
        ]
    },
    {
        path: 'forms',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: FormsListComponent
            },
            {
                path: ':formId',
                children: [
                    {
                        path: 'player',
                        resolve: {
                            formList: ResolverForFormPlayer
                        },
                        component: FormPlayerComponent,
                    },
                    {
                        path: 'edit',
                        children: [
                            {
                                path: '',
                                component: FormActivitiesComponent
                            },
                            {
                                path: 'activity',
                                children: [
                                    {
                                        path: 'qcm',
                                        component: QcmEditorComponent
                                    },
                                    {
                                        path: 'qcmu',
                                        component: QcuEditorComponent
                                    },
                                    {
                                        path: 'crt',
                                        component: ShortAnswerEditorComponent
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'player'
                    }
                ]
            }
        ]
    },
    {
        path: 'sequences',
        canActivate: [IsUserLogged],
        children: [
            {
                path: 'list',
                component: SequencesTabComponent
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        ActivitiesSharedModule,
        MatButtonModule,
        MatGridListModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PlayersModule,
        DragulaModule,
        MatStepperModule,
        ActivitiesDirectiveModule,
        InfiniteScrollModule,
        FuseLessonsModule,
        MatCheckboxModule,
        CommonSharedModule,
        EditorsModule,
        // NgxFileDropModule,
        ThemesModule,
        SearchFiltersModule,
        ProgressBarGenericModule,
        ChartsModule,
        NgxFileDropModule,
        ActivitiesPipesModule
    ],
    declarations: [
        LessonsComponent,
        LessonsListComponent,
        FormsListComponent,
        SequencesTabComponent,
        LessonsTabComponent,
        FormPlayerComponent,
        EditFormDialogComponent,
        FormActivitiesComponent,
        LessonsRecapComponent,
        EditLessonDialogComponent,
        LessonActivitiesComponent,
        LessonMetadataDialogComponent,
        LessonsFavoritesComponent,
        EditLessonComponent,
        OnlyLessonLessonsListComponent,
        OnlyModelLessonsListComponent,
        LessonsConsultedComponent,
        LessonsCreatedComponent,
        CommunityListComponent,
        DeleteLessonWarningComponent,
        EditLessonWarningComponent,
        RecommendationComponent,
        LauncherComponent,
        LauncherButtonComponent,
        SummarySubLessonComponent,
    ],
    exports: [
        RouterModule,
    ],
    providers: []
})
export class LessonsModule {
    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private activitiesService: ActivitiesService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsConsultedComponent', LessonsConsultedComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsCreatedComponent', LessonsCreatedComponent);

        this.communicationCenter
            .getRoom('lessons')
            .next('lessonsFavoritesComponent', LessonsFavoritesComponent);

        this.communicationCenter
            .getRoom('recommendation')
            .next('recommendationListComponent', RecommendationComponent);

        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'FEEDBACK_FORM',
                icon: 'activity',
                text: 'assignment.notification_feedback_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        projectName: data['projectName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    return ['followed', 'list'];
                }
            });


        this.communicationCenter
            .getRoom('notifications')
            .getSubject('registerNotification')
            .next({
                name: 'Assignment',
                type: 'LEARNER_ANSWER_FORM_SENDED',
                icon: 'activity',
                text: 'assignment.notification_learner_answer_assignment',
                textTransform: (text: string, data: Object) => {
                    return {
                        projectName: data['projectName'],
                        formName: data['formName'],
                    };
                },
                action: (data: Object) => {
                    return ['followed', 'list'];
                }
            });

    }

    static forRoot(): ModuleWithProviders<LessonsModule> {

        return {
            ngModule: LessonsModule,
            providers: [
                LessonsService,
                LessonActivitiesResolver,
                ResolverForFormPlayer,
                FlagService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
            // TODO menu
            // /**
            //  * displayIcon permet d'afficher ou non l'icon d'un lien dans le menu
            //  * @type {{id: string; icon: string; title: string; translate: string; type: string; url: string; displayIcon: any}}
            //  */
            // const onlyModels = {
            //     'id': 'onlyModels',
            //     'icon': 'transition',
            //     'title': 'onlyModels',
            //     'translate': 'generic.models',
            //     'type': 'item',
            //     'url': '/lessons/list/models',
            //     'displayIcon': this.activitiesService.settings.menu['models']
            // };
            //
            // const onlyLessons = {
            //     'id': 'onlyLessons',
            //     'title': 'onlyLessons',
            //     'translate': 'generic.my_lessons',
            //     'type': 'item',
            //     'url': `/lessons/list/lessons`
            // };
            // const onlyCommunity = {
            //     'id': 'onlyCommunity',
            //     'title': 'onlyCommunity',
            //     'translate': 'generic.community',
            //     'type': 'item',
            //     'url': '/lessons/list/community'
            // };
            //
            // const recommendation = {
            //     'id': 'recommendation',
            //     'title': 'recommendation',
            //     'translate': 'generic.recommendation',
            //     'type': 'item',
            //     'icon': 'recommendation',
            //     'url': '/lessons/list/recommendation'
            // };
            //
            // this.dynamicNavigation.registerModuleMenu('level0', [
            //     {
            //         'id': 'Lessons',
            //         'title': 'Lessons',
            //         'translate': 'activities.title_lessons',
            //         'type': 'item',
            //         'icon': 'lessons',
            //         'url': '/lessons/list'
            //     },
            //     {
            //         'id': 'lessonsDeployable',
            //         'title': 'lessonsDeployable',
            //         'translate': 'activities.title_lessons',
            //         'type': 'collapse',
            //         'icon': 'lessons',
            //         'children': [
            //             onlyModels,
            //             onlyLessons,
            //             onlyCommunity]
            //     },
            //     {
            //         'id': 'lessonsDeployableWithoutCommunity',
            //         'title': 'lessonsDeployableWithoutCommunity',
            //         'translate': 'activities.title_lessons',
            //         'type': 'collapse',
            //         'icon': 'lessons',
            //         'children': [
            //             onlyModels,
            //             onlyLessons
            //         ]
            //     },
            //     onlyModels,
            //     onlyLessons,
            //     onlyCommunity,
            //     {
            //         // TODO : rendre paramétrable l'url, éviter de créer de nouvelles routes pour chaque type de liste
            //         'id': 'lessonsDeployableAlgebraGeometry',
            //         'title': 'algebraAndGeometryDeployed',
            //         'translate': 'activities.title_lessons',
            //         'type': 'collapse',
            //         'icon': 'lessons',
            //         'children': [
            //             {
            //                 'id': 'algebraDeployed',
            //                 'title': 'algebraDeployed',
            //                 'translate': 'generic.models.algebra',
            //                 'type': 'item',
            //                 'url': '/lessons/list/algebra'
            //             },
            //             {
            //                 'id': 'geometryDeployed',
            //                 'title': 'geometryDeployed',
            //                 'translate': 'generic.models.geometry',
            //                 'type': 'item',
            //                 'url': '/lessons/list/geometry'
            //             }]
            //     },
            //     recommendation
            // ]);
        // }
    }
}
