import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of, Subject} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';

// format de l'activity_content
interface TrueFalseActivityContentInterface {
    answers: ItemAnswerInterface[];
}

// format du champ config de l'activité
interface TrueFalseActivityConfigInterface {
    direction: string;
}

// format du champ référence de l'activité
type TrueFalseActivityReferenceInterface = ActivityReferenceInterface<TrueFalseActivityContentInterface, TrueFalseActivityConfigInterface>;

@Component({
    selector: 'app-true-false',
    templateUrl: './true-false.component.html'
})

export class TrueFalseComponent extends BaseActivityComponent<any, any> {
    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    public displaySaveBtn = false; // know if we display checkbox or button, data fetched from the api
    public isTwoColumns = false;
    public referenceActivityGranule: TrueFalseActivityReferenceInterface;
    public title: string;
    public isTTSReading: { id: string, value: boolean };
    public readSecondConsigne = new Subject<boolean>();
    private firstConsigneAutoReadIsDone = false;


    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    protected setContentData(activityAttributes): void {
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
            this.displaySaveBtn = activityAttributes.reference.config.displaySaveBtn === true;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.setDefaultAnswersAvailable(this.referenceActivityGranule.activity_content.answers);
        this.loadUserSave();
    }

    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    private setDefaultAnswersAvailable(answers, resetSelectEd?: boolean): void {
        this.answersSelected = answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                select: false,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : null
            };
        });
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.setDefaultAnswersAvailable(this.answersSelected);
        return super.reset(resetAllSubscribe, type);
    }

    protected setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete' && this.answersSelected) {
            this.answersSelected.forEach((option: ItemAnswerInterface, index: number) => {
                const answerSaved = this.userSave.get('userActivity').entitySave.answers[index];
                option.select = answerSaved && !!+answerSaved.select && option.id === answerSaved.id;
            });
            this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
        }
        this.checkAnswer();
    }

    protected checkAnswer(): void {
        this.answerStatus = answerStatusEnum.missing;
        if (this.autoCorrection) {
            const answerResult: AnswerResultInterface = {
                id: +this.activityId.id,
                isAnswerCorrect: undefined,
                isLast: undefined
            };
            this.answersSelected.forEach((option: ItemAnswerInterface) => {
                if (option.select) {
                    if (option.correct_answer) {
                        option.state = ItemAnswerStateEnum.currentlyCorrect;
                        answerResult.isAnswerCorrect = true;
                        answerResult.isLast = true;
                    } else {
                        option.state = ItemAnswerStateEnum.incorrect;
                        answerResult.isAnswerCorrect = false;
                    }
                }
            });
            super.ifFeedbackOpenModal();
            this.answerResult.next(answerResult);
            // TODO faire une meilleure animation angular
            setTimeout(() => {
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
                this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
                if (this.allAnswerCorrect) {
                    this.doAction('next', ['save']);
                }
            }, 500);
        }

        if (this.answersSelected.some((option: ItemAnswerInterface) => option.select)) {
            this.answerStatus = this.answersSelected.find((option: ItemAnswerInterface) =>
                option.select && option.correct_answer) ? answerStatusEnum.correct : answerStatusEnum.wrong;
        }
        if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
            this.displayFeedback = (this.answerStatus === 3)
                && this.activitiesService.settings.displayFeedback;
        }
        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    private get allAnswerCorrect(): boolean {
        return this.answersSelected.some((option: ItemAnswerInterface) => option.select && option.correct_answer);
    }

    /**
     * get the grade calculated with answer correctly answered
     */
    protected getGrade(): { newGrade: number, oldGrade: number } {
        return {
            newGrade: this.answersSelected.filter((answer: ItemAnswerInterface) => answer.select && answer.correct_answer).length,
            oldGrade: this.userSave ?
                this.userSave.get('userActivity').entitySave.answers.filter((answer: ItemAnswerInterface) => answer.select && answer.correct_answer).length
                : 0
        };
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public get columnClass(): string {
        return this.answersSelected && this.answersSelected.length > 3 ? 'columns-2' : 'columns-1';
    }

    public clickAnswer(option: ItemAnswerInterface): void {
        this.onOptionChange(true);
        if (!option.select) {
            this.answersSelected.forEach((answer: ItemAnswerInterface) => {
                answer.select = false;
                answer.state = ItemAnswerStateEnum.pristine;
            });
            option.select = true;
        }
        this.checkAnswer();
    }

    public get valueChecked(): string {
        const answerSelected = this.answersSelected && this.answersSelected.find(option => option.select);
        return answerSelected && answerSelected.answer || null;
    }

    public set valueChecked(value: string) {
        this.answersSelected.forEach((item: ItemAnswerInterface) => {
            item.select = (value === item.answer);
        });
    }

    public get isDisabledOptions(): boolean {
        if (this.displaySaveBtn) {
            return this.answersSelected.some((answer: ItemAnswerInterface) => answer.state === ItemAnswerStateEnum.wasCorrect || answer.state === ItemAnswerStateEnum.currentlyCorrect);
        } else {
            return this.displaySolution || // solution displayed
                this.testAnswer || // answer tested
                (this.answerSaved && this.lessonsService.isLessonEvaluation()) || // answer saved and assignment is assessment or homework
                this.lessonsService.isAtLeastTrainer() || // check if user is trainer
                this.lessonsService.isLessonCorrected(); // assignment exist and due date finished
        }
    }

    /**
     * sert aux css pour afficher l'état d'une reponse
     * TODO: on doit faire en sorte que les class css utilise le "state" d'une réponse cf: ItemAnswerInterface
     * pour permettre de retourner simplement le "state" qui sera la également la class css
     * @param option
     */
    public optionState(option: any): string {
        if (this.displaySolution) {
            if (option.correct_answer) {
                return 'correctAnswer';
            }
        }

        if (this.testAnswer) {
            if (option.select) {
                if (option.correct_answer) {
                    return 'correctAnswer';
                } else {
                    return 'wrongAnswer';
                }
            } else {
                if (option.correct_answer) {
                    return 'answerMissed';
                }
            }
        }

        if (option.select) {
            if (this.lessonsService.isLessonEvaluation() && this.answerSaved) {
                return 'validated';
            } else {
                return 'selected';
            }
        }
        return '';
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * set if Text to speach is currently reading or not
     * @param evt
     */
    public ttsReadingEvent(evt: { id: string, value: boolean }): void {
        // content is hidden we do nothing
        if (this.contentIsHidden) {
            return;
        }

        this.isTTSReading = evt;
        if (evt.value === true) {
            this.firstConsigneAutoReadIsDone = true;
        }
        // the fisrt consigne is finishto be read and the content is visible modal is closed an
        if (evt.value === false && this.firstConsigneAutoReadIsDone === true && !this.contentIsHidden) {
            // we send a different value to permit ngOnChange to detect event
            this.readSecondConsigne.next(true);
            this.ref.detectChanges();
        } else {
            this.ref.detectChanges();
        }
    }
}

