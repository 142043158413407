import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {BehaviorSubject, Subscription} from 'rxjs';
import {CommunicationCenterService} from '@modules/communication-center';
import {take} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {brand} from '../../../../../settings';

@Component({
    selector: 'app-chapters-list',
    templateUrl: './chapters-list.component.html',
})
export class ChaptersListComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() conceptId = '';
    @Input() gradeId: number;
    private userData: DataEntity;
    public showSpinner = true;
    public showPlaceholder = true;
    private loadingAssignments = true;
    public assigmentsList: Array<DataEntity> = [];
    public autoAssigmentsList: Array<DataEntity> = [];
    public closedAssigmentsList: Array<DataEntity> = [];
    public chapters: Array<object> = [];
    public chaptersChildrenIds: Array<string | number> = [];
    public chaptersParents: Array<object> = [];
    public chaptersSubscription: Subscription = null;
    public chaptersChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    public brand = brand;
    public params: { lesson: string, chapter: string };

    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private route: ActivatedRoute
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                } else {
                    this.postLogout();
                }
            });
    }

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            if (params['conceptId']) {
                this.conceptId = params['conceptId'];
            }
            if (params['gradeId']) {
                this.gradeId = params['gradeId'];
            }

            // receive lesson and chapter to open modal
            this.route.queryParams.subscribe(queryParams  => {
                this.params = {
                    lesson: queryParams.lesson,
                    chapter: queryParams.chapter
                };
                this.getChapters();
            });
        });
    }

    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'position-top-lower-for-content');
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }

    private postLogout(): void {
        if (this.chaptersSubscription) {
            this.unsubscribeAll();
        }
    }

    private getChapters(): void {
        this.octopusConnect.loadCollection('chapters', {grade: this.gradeId, concepts: this.conceptId}).pipe(take(1)).subscribe(data => {
            this.chapters = [];
            this.chaptersParents = [];
            for (const entity of data.entities) {
                if (entity.get('name')) {
                    this.chapters.push({
                        id: entity.id,
                        label: entity.get('label'),
                        name: entity.get('name'),
                        parents: entity.get('parent'),
                        concepts: entity.get('concepts'),
                        grade: entity.get('grade'),
                        openedPanel: this.params && this.params.chapter && this.params.chapter === entity.id,
                    });
                }
            }
            this.chaptersParents = this.chapters.filter(e => (!e['parents'].length));
            this.showSpinner = false;
            // hide placeholder if there is at least one chapter
            if (this.chaptersParents.length > 0){
                this.showPlaceholder = false;
            }
            this.getChaptersChildren();
        });
    }

    private getChaptersChildren(): void {
        this.chaptersChildrenIds = [];
        this.chaptersParents.forEach(parent => {
            this.getChaptersByParents(parent['id']).forEach(child => {
                this.chaptersChildrenIds.push(child.id);
            });
        });
    }

    private getChaptersByParents(parentId: number): any {
        const children = this.chapters.filter(e => e['parents'].includes(parentId));
        return children;
    }

    private getAssignmentsList(assigmentsList: DataEntity[]): void {
        this.assigmentsList = assigmentsList;
        this.loadingAssignments = false;
    }

    private getAutoAssignmentsList(assigmentsList: DataEntity[]): void {
        this.autoAssigmentsList = assigmentsList;
        this.loadingAssignments = false;
    }

    private getClosedAssignmentsList(assigmentsList: DataEntity[]): void {
        this.closedAssigmentsList = assigmentsList;
        this.loadingAssignments = false;
    }

    unsubscribeAll(): void {
        this.chaptersSubscription.unsubscribe();
    }

}



