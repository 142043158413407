<mat-card class="activities-card inner-scroll mat-elevation-z0 trueFalseContainer" [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes [wording]="wording" [wordingAudio]="wordingAudio" (isReading)="ttsReadingEvent($event)"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content class="page-layout ord-page">
        <app-consignes
            [read]="readSecondConsigne"
            [instruction]="instruction"
            [instructionAudio]="instructionAudio"
            [autoRead]="false"
            questionTypeName="{{ 'activities.questionTypeName.app-true-false' | translate }}"></app-consignes>

            <div id="container-for-answers-available" class="available-answers" [ngClass]="columnClass" *ngIf="displaySaveBtn">
                <ng-container *ngFor="let option of answersSelected">
                    <button
                        class="btn-option"
                        [class]="option.state"
                        [class.currently-correct]="displaySolution && option.correct_answer"
                        (click)="clickAnswer(option)"
                        [disabled]="isDisabledOptions">
                        <mat-icon [svgIcon]="option.answer"></mat-icon>
                    </button>
                </ng-container>
            </div>

            <mat-radio-group *ngIf="!displaySaveBtn" [ngClass]="{showFinalAnswers: this.testAnswer || this.displaySolution || this.answerSaved, answersChecked: testAnswer}"  [(ngModel)]="valueChecked">
                <mat-radio-button *ngFor="let option of answersSelected | intToBoolean: 'correct_answer':'select'"
                                  (change)="clickAnswer(option)"
                                  [disabled]="isDisabledOptions"
                                  [ngClass]="optionState(option)"
                                  [disableRipple]="true" [value]="option.answer">
                    <span [innerHTML]="option.answer | stringToHtml"></span>
                </mat-radio-button>
            </mat-radio-group>

    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.try_correct' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>
        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>
