<app-generic-activity >
    <div class="inject-left">
        <button (click)="selfJumpBelow()" mat-fab [disabled]="canJumpBelow === false">
            <mat-icon svgIcon="drag_indicator"></mat-icon>
        </button>
    </div>
    <div class="inject-middle">
        <mat-icon [svgIcon]="getType(page.first)"></mat-icon>
        <span>{{'generic.' + getType(page.first) | translate }}</span>

        <ng-container *ngIf="page.second">
            <mat-icon [svgIcon]="getType(page.second)"></mat-icon>
            <span>{{'generic.' + getType(page.second) | translate }}</span>
        </ng-container>
    </div>
    <div class="inject-right">
        <button (click)="selfEdit()" mat-fab>
            <mat-icon svgIcon="edit"></mat-icon>
        </button>
        <button (click)="sefPlay()" mat-fab>
            <mat-icon svgIcon="play"></mat-icon>
        </button>
        <button (click)="selfDelete()" mat-fab>
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>
</app-generic-activity>
