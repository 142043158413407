<mat-card class="activities-card inner-scroll mat-elevation-z0" [ngClass]="{'two-columns-layout': isVertical}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes [wording]="wording" [wordingAudio]="wordingAudio"
                questionTypeName="{{ 'activities.questionTypeName.app-consignes' | translate }}">
            </app-consignes>
            <!-- just passed qumOptions and qcuOptions false  and passed answerStatus -->
            <app-options [mode]="'ordon'" [activityId]="activityId" [contextId]="assignmentId"
                [answerStatus]="answerStatus">
            </app-options>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content class="page-layout ord-page center-box map-page">
        <app-consignes [ngClass]="{ 'div-consignes': !isVertical }" [instruction]="instruction" [instructionAudio]="instructionAudio"
                       [autoRead]="false"
            questionTypeName="{{ 'activities.questionTypeName.app-consignes' | translate }}">
        </app-consignes>
        <div class="ans-part" id="ord">
            <div class="button-row" [ngClass]="{ 'button-row-flex': !isVertical }" [dragula]='"bag-1"'
                [dragulaModel]='orderList'>
                <!-- for green bg add success-left class -->
                <div *ngFor="let item of orderList; index as no;" class="btn-container disabled"
                    [ngClass]="{'cannotMove':canMove == 0, 'row-btn-container': !this.isVertical }">
                    <span class="counting-circle">{{item.orderno}}</span>
                    <button class="orderlist" [ngClass]="answerState(item)">
                        <span class="orderlist-id" style="display:none;">{{item.id}}</span>
                        <span class="options orderlist-value" [innerHTML]="item.answer | stringToHtml"></span>
                    </button>
                    <ng-container *ngIf="displaySolution || testAnswer">
                        <span class="mat-mini-fab success" *ngIf="correctAnswer[item.id] == 1">
                                <mat-icon>check</mat-icon>
                        </span>
                        <span class="mat-mini-fab error" *ngIf="correctAnswer[item.id] == 2">
                                <mat-icon>close</mat-icon>
                        </span>
                        <span class="mat-mini-fab warning" *ngIf="correctAnswer[item.id] == 3">
                                <mat-icon>info_outline</mat-icon>
                        </span>
                    </ng-container>
                    <span class="mat-mini-fab check" *ngIf="correctAnswer[item.id] && !(testAnswer || displaySolution) && !isPreview">
                            <mat-icon>save</mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.ord.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>