<mat-grid-list cols="2" [gutterSize]="'10px'">
    <mat-grid-tile *ngFor="let badge of badges">
        <div class="badge-container"
             [ngClass]="{'purple': !badge.attributes['selected']&&badge.attributes['unLocked'], 'gray': !badge.attributes['unLocked'] }">
            <!--badge-->
            <img [src]="badge.attributes['image-t']"
                 [ngStyle]="{opacity: badge.attributes['price'] > 0 ? 0.5 : 1}"/>
        </div>
        <!--button to show-->
        <button mat-raised-button class="equipped" *ngIf="badge.attributes['selected']">
            <mat-icon svgIcon="check"></mat-icon>
        </button>
        <!-- Translate it -->
        <button (click)="invoqueBuyPopInOrEquip(badge)" mat-raised-button class="not-equipped"
                *ngIf="!badge.attributes['selected'] && badge.attributes['unLocked']"><span>{{ 'gamification.badge.type.equip' | translate }}</span></button>
        <button (click)="invoqueBuyPopInOrEquip(badge)" mat-raised-button class="not-equipped"
                *ngIf="!badge.attributes['unLocked'] && badge.attributes['buyable']"><span>{{badge.attributes['price']}}</span>
            <mat-icon svgIcon="coin"></mat-icon>
        </button>
        <button mat-raised-button class="not-equipped not-equipped-locked"
                *ngIf="!badge.attributes['buyable']"><span>{{ 'generic.level' | translate }} {{badge.attributes['level']}}</span></button>
    </mat-grid-tile>
</mat-grid-list>