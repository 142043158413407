<div fxFlex fxLayout="column" fxLayoutAlign="start center">
    <div class="banner" [style.backgroundImage]="'url('+ imgBannerUrl +')'">
        <div *ngIf="title && titleTextInHomePageBanner" class="title">{{title}}</div>
        <img *ngIf="imgLogoUrl" [src]="imgLogoUrl" class="logo-inside-banner">
    </div>
    <div *ngIf="title && !titleTextInHomePageBanner" class="title">{{title}}</div>
    <div class="content" [innerHTML]="content"></div>


    <div fxLayout="row wrap" class="content-cards">
        <div fxFlex.gt-sm="50" fxFlex.lt-sm="100" *ngFor="let dataCard of dataCards" class="p-16">
            <mat-card fxLayout="column">
                <mat-card-header>
                    <mat-card-title>
                        <h3 class="mat-card-title-text">{{ dataCard?.title }}</h3>
                    </mat-card-title>
                </mat-card-header>
                <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="wrapper">
                    <div fxFlex="40%" fxLayout="column" fxLayoutAlign="center center" class="mat-card-img p-8">
                        <img [src]="dataCard?.image?.uri" alt="image card">
                        <label>{{dataCard?.image_title}}</label>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="space-between start" class="w-100-p wrapper-content">
                        <mat-card-content class="w-100-p">
                            <div fxFlex fxLayout="row" fxLayoutAlign="start start">
                                <dl role="list" class="dl-listitem-inline">
                                    <ng-container>
                                        <!-- <dt>{{'generic.goals' | translate}}</dt> -->
                                        <dd [innerHTML]="dataCard?.description?.value"></dd>
                                    </ng-container>
                                </dl>
                            </div>
                        </mat-card-content>
                        <mat-card-actions class="w-100-p" fxFlex fxLayout="row" fxLayoutAlign="end start">
                            <button *ngIf="dataCard.link_label && dataCard.link_label !== ''"
                                    [title]="dataCard.link_label"
                                    mat-raised-button
                                    (click)="play(dataCard)"
                                    class="button-play" role="button">
                                <span>{{ dataCard.link_label }}</span>
                            </button>
                        </mat-card-actions>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>

</div>


