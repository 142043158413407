<div class="page-layout simple fullwidth">
    <div class="header p-24" fxLayout="row" fxLayoutAlign="start center" *ngIf="displayHeader">
        <h2>{{ 'activities.title' | translate }}</h2>
    </div>
    <div class="content mat-white-bg" [className]="displayHeader ? 'p-16' : 'pb-16 pl-16 pr-16'">
        <content class="inner-scroll">
            <router-outlet></router-outlet>
        </content>

        <!-- <fuse-app-activities-list></fuse-app-activities-list>-->
    </div>


</div>

