import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {Router} from '@angular/router';

@Component({
    selector: 'app-concept-card',
    templateUrl: './concept-card.component.html',
    styleUrls: ['./concept-card.component.scss']
})
export class ConceptCardComponent implements OnInit {

    @Input('selectedLevelId') selectedLevelId: number;
    @Input('data') cardData: DataEntity;
    @Input('hasAssignedLessons') public hasAssignedLessons: boolean;
    @Output('idConcept') idConcept: EventEmitter<string> = new EventEmitter<string>();
    public headerTitleIcon: string;
    public cardTitle: string;
    public cardSubTitle: string;
    public contentText: string;
    public btnText: string;
    public conceptId: string;
    public conceptImage: string;
    public lessonsCount: number;
    public lessonsCompleted: number;


    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.headerTitleIcon = 'picto_math'; // TODO provisoir en attendant la base ubolino et la série de pictos
        this.cardTitle = this.cardData.get('label');
        this.conceptId = this.cardData.id.toString();
        this.conceptImage = this.cardData.get('image').uri;
    }

    @Input() set userProgress(value: any) {
        if (value['currentUser']) {
            this.lessonsCount = value['currentUser']['total'];
            this.lessonsCompleted = value['currentUser']['completed'];
        }
    }

    /**
     * navigate to good listing
     * @param conceptId
     */
    public navigateTo(conceptId: string): void {
        this.idConcept.emit(conceptId);
        this.router.navigate(['lessons', 'grade', this.selectedLevelId, 'concept', conceptId]);

    }
}
