import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {DataEntity} from 'octopus-connect';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {TypedDataEntityInterface} from '../../../../../shared/models/octopus-connect/typed-data-entity.interface';
import {ActivityAttributesInterface} from '@modules/activities/core/models/activity-attributes.interface';
import {DrawLineActivityContentInterface} from '@modules/activities/core/player-components/draw-line/models/draw-line-activity-content.interface';
import {DrawLineActivityConfigInterface} from '@modules/activities/core/player-components/draw-line/models/draw-line-activity-config.interface';
import {DrawLineService} from '@modules/activities/core/services/draw-line.service';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';

export const ACTIVITY_USE_WORDING = {
    appaire: false,
    divider: true,
    APP: true,
    Audio: true,
    CRT: true,
    EXT: true,
    GCM: true,
    IMG: true,
    IMGI: true,
    ORD: false,
    ORDMAT: true,
    TXTMAT: true,
    awareness: true,
    QCM: true,
    QCMU: true,
    RB: false,
    Tool: true,
    VF: true,
    summary: true,
    video: true,
    POINTWORD: true,
    POINTMULTIWORD: true,
    POINTIMG: true,
    DRL: false
};

type AnonymousActivityInterface = TypedDataEntityInterface<ActivityAttributesInterface>;
type DrawLineActivity = TypedDataEntityInterface<ActivityAttributesInterface<DrawLineActivityContentInterface, DrawLineActivityConfigInterface>>;

@Component({
    selector: 'app-summary-sub-lesson',
    templateUrl: './summary-sub-lesson.component.html',
    styleUrls: ['./summary-sub-lesson.component.scss']
})
export class SummarySubLessonComponent implements OnInit, OnDestroy {
    public activities: AnonymousActivityInterface[] = [];
    constructor(private activitiesService: ActivitiesService,
                private lessonsService: LessonsService,
                private communicationCenter: CommunicationCenterService,
                private drawLineService: DrawLineService) {
    }

    ngOnInit(): void {
        this.communicationCenter.getRoom('header-exo').next('show', false);
        this.communicationCenter.getRoom('progress-bar-exo').next('hide', true);
        this.communicationCenter
            .getRoom('footer')
            .next('visibility', false);
        this.initialize();
        // scroll on top to ever had summary title on top
        document.getElementsByTagName('app-summary-sub-lesson')[0].scrollTo(0, 0);
    }

    ngOnDestroy(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', false);
        this.communicationCenter.getRoom('activities').next('retryCurrentActivity', false);
        this.communicationCenter.getRoom('activities').next('nextActivity', false);
        this.communicationCenter.getRoom('activities').next('backToLesson', false);
        this.communicationCenter.getRoom('header-exo').next('show', true);
        this.communicationCenter.getRoom('progress-bar-exo').next('hide', false);
        this.communicationCenter.getRoom('footer').next('visibility', true);
        document.getElementsByTagName('body')[0].classList.remove('summary');
    }

    initialize(): void {
        document.getElementsByTagName('body')[0].classList.add('summary');
        this.activities = <AnonymousActivityInterface[]>this.lessonsService.subLessonContentEdited;
    }

    public answers(activity: DataEntity): ItemAnswerInterface[] {
        return activity.get('reference').activity_content.answers
            || activity.get('reference').activity_content.answers_app;

    }

    public isActivityTypeUseWording(activity: DataEntity): string {
        return ACTIVITY_USE_WORDING[activity.get('metadatas').typology.label];
    }

    public getOnlyCorrectAnswer(itemAnswerInterfaces: ItemAnswerInterface[]): ItemAnswerInterface[] {
        return itemAnswerInterfaces.filter((answer) => !!+answer.correct_answer);
    }

    public getParsedAnswer(answer: string): string[] {
        return answer && JSON.parse(answer);
    }

    public replaceFieldToFind(activity: DataEntity): string {
        return activity.get('reference').activity_content.answer_text.replace(/\[]/g, '_ _ _ _');
    }

    public answerState(activity: DataEntity): string {
        const userGetWrongAnswersAttempt = this.activitiesService.answersProgressBarMultiZone
            .some((act: AnswerResultInterface) => +act.id === +activity.id && !act.isAnswerCorrect);
        return userGetWrongAnswersAttempt ? 'incorrect' : 'was-correct';
    }

    retry(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter.getRoom('activities').next('retryCurrentActivity', true);
    }

    nextActivity(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter.getRoom('activities').next('nextActivity', true);
    }

    back(): void {
        this.communicationCenter.getRoom('progress-bar-exo').next('reset', true);
        this.communicationCenter.getRoom('activities').next('backToLesson', {forceUrl: true, initialiseSubject: true});
    }

    /**
     * savoir si on a une activité suivante pour afficher le bouton "next"
     */
    public get isNextActivity(): boolean {
        return !!this.lessonsService.currentLesson.get('reference')[this.activitiesService.presentArrayElementIndex + 1];
    }

    public getItemAnswerInterfaceAsALine(onlyCorrectAnswer: ItemAnswerInterface[]): string {
        return onlyCorrectAnswer.map(a => a.answer).join(', ');
    }

    public getDrawLineActivityImagePaths(activity: DrawLineActivity): string[] {
        const imageIdentifier = activity.get('reference').config.charId;

        const dirPath = this.drawLineService.getRootImageDirectoryPath();
        return this.drawLineService.getTemplate(imageIdentifier).steps.map(s => s.imagePath).map(i => dirPath + i);
    }
}
