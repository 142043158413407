<div id="contetList" class="p-24">
  <div class="pl-24 pr-24">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'generic.search' | translate }}">
    </mat-form-field>
    <mat-form-field>
      <mat-select placeholder="{{ 'generic.type' | translate }}">
        <mat-option [value]="option" *ngFor="let option of uniqueTypes">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="py-4 font-size-14" fxlayout="row" fxlayoutalign="start center" *ngIf="numberOfActivities < 2">{{ numberOfActivities }} {{
      'generic.result' | translate }}
    </div>
    <div class="py-4 font-size-14" fxlayout="row" fxlayoutalign="start center" *ngIf="numberOfActivities >= 2">{{ numberOfActivities }} {{
      'generic.results' | translate }}
    </div>
  </div>

  <div class="cards" fxLayoutGap="20px">
    <mat-card class="lesson-card fuse-card" *ngFor="let ressource of ressources">
      <mat-card-header>
        <div fxFlex>
          <mat-card-title fxLayout="row" fxLayoutGap="10px" class="{{ cleanOutputForClassName(ressource.embeddings.metadatas.attributes.lessonType[0].label) }}">
            <button class="type" *ngIf="ressource.embeddings.metadatas.attributes.lessonType[0].label === 'Training'" (click)="navigateToLesson(ressource)" color="#24B7C6">
              <mat-icon>insert_emoticon</mat-icon>
            </button>
            <button class="type" *ngIf="ressource.embeddings.metadatas.attributes.lessonType[0].label === 'Homework'" (click)="navigateToLesson(ressource)">
              <mat-icon>query_builder</mat-icon>
            </button>
            <div fxFlex>
              <h3>{{ ressource.embeddings.metadatas.attributes.title }}
                <small>
                            <span *ngIf="ressource.attributes.infos.lessons > 0">
                                                            {{ ressource.attributes.infos.lessons }}
                                                            <span class="pl-4" *ngIf="ressource.attributes.infos.lessons > 1 "> {{ 'generic.lessons' | translate }}</span>
                                                            <span class="pl-4" *ngIf="ressource.attributes.infos.lessons <= 1 "> {{ 'generic.lesson' | translate }}</span>
                                                            <span class="p-4"> / </span>
                                                        </span>
                  <span *ngIf="ressource.attributes.infos.activities > 0">
                                                            {{ ressource.attributes.infos.activities }}
                                                            <span class="pl-4" *ngIf="ressource.attributes.infos.activities > 1 "> {{ 'generic.activities' | translate }}</span>
                                                            <span class="pl-4" *ngIf="ressource.attributes.infos.activities <= 1 "> {{ 'generic.activity' | translate }}</span>
                                                            <span class="p-4"> / </span>
                                                        </span>
                  <span *ngIf="ressource.attributes.infos.others > 0">
                                                            {{ ressource.attributes.infos.others }}
                                                            <span class="pl-4" *ngIf="ressource.attributes.infos.others > 1 "> {{ 'generic.resources' | translate }}</span>
                                                            <span class="pl-4" *ngIf="ressource.attributes.infos.others <= 1 "> {{ 'generic.resource' | translate }}</span>
                                                        </span>
                </small>
              </h3>
              <p>{{ 'generic.type' | translate }}: {{ ressource.embeddings.metadatas.attributes.lessonType[0].label }}</p>
            </div>
          </mat-card-title>
        </div>
        <div fxFlex="0 0 40px">
          <button mat-icon-button class="mat-icon-button" aria-label="More" matTooltip="More" [matMenuTriggerFor]="menu"><mat-icon svgIcon="submenu"></mat-icon></button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item >
              <mat-icon svgIcon="edit"></mat-icon> {{ 'generic.annotate' | translate }}
            </button>
            <button mat-menu-item>
              <mat-icon svgIcon="edit"></mat-icon> {{ 'generic.edit' | translate }}
            </button>
            <button mat-menu-item>
              <mat-icon svgIcon="edit"></mat-icon> {{ 'generic.assign' | translate }}
            </button>
          </mat-menu>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div fxFlex>
          <ul class="keywords">
            <li *ngFor="let keyword of ressource.embeddings.metadatas.attributes.indexation">
              {{ keyword.label }}
            </li>
          </ul>
        </div>
        <div fxFlex="0 0 56px">
          <button (click)="navigateToLesson(ressource)" mat-mini-fab class="mat-elevation-z1">
            <mat-icon svgIcon="play" aria-label="Play lesson"></mat-icon>
          </button>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-icon-button class="flag">
          <mat-icon [ngClass]="{'active': ressource.attributes.bookmarks === true }" (click)="flagService.flagEntity(ressource, 'node', 'bookmarks')" aria-label="favorite">favorite</mat-icon>
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
  <mat-paginator #paginator
                 [pageSize]="10"
                 [pageSizeOptions]="[5, 10, 20]">
  </mat-paginator>

</div>
