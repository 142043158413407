import {Injectable} from '@angular/core';
import {Font, jsPDF, TextOptionsLight} from 'jspdf';
import * as _ from 'lodash';
import {PdfPattern} from './models/generated-pdf';
import {ImagePattern, TextPattern} from './models/generated-pdf/patterns/visuals';
import {PatternBatch, PatternInterface} from './models/generated-pdf/patterns';
import {FontInterface} from './models/generated-pdf/fonts/font.interface';

@Injectable({
    providedIn: 'root'
})
export class PdfCreatorService {

    /**
     * Automatically generated and download to the user a Pdf
     * @param pattern used to define data and content of the pdf
     * @todo pattern should contain metadata properties to add to the file (author, keyword, etc)
     */
    public createAndDownload(pattern: PdfPattern): void {

        // Default export is a4 paper, portrait, using millimeters for units
        const pdf = new jsPDF(pattern.orientation, pattern.unit, [pattern.size.height, pattern.size.width]);
        if (!!pattern.font) {
            this.setFont(pdf, pattern.font);
        }

        if (!!pattern.fontSize) {
            pdf.setFontSize(pattern.fontSize);
        }

        this.generateContent(pdf, pattern);

        pdf.save(pattern.filename);
    }

    /**
     * Set a font to the current pdf. The font will be added to the pdf before.
     * There a no way to retrieve custom added fonts so if you are setting a new font but with an same name than another it's at your own risk.
     * @param pdf
     * @param font
     */
    public setFont(pdf: jsPDF, font: FontInterface): jsPDF {
        this.addCustomFont(font, pdf);
        pdf.setFont(font.name);
        return pdf;
    }

    /**
     * Add font in the pdf to be setted after.
     * @param font
     * @param pdf
     * @private
     */
    private addCustomFont(font: FontInterface, pdf: jsPDF): jsPDF {
        pdf.addFileToVFS(`${font.name}.ttf`, font.base64);
        pdf.addFont(`${font.name}.ttf`, font.name, 'normal');
        return pdf;
    }

    /**
     * Apply the pattern on the pdf to display every visual elements
     * @param pdf
     * @param pattern
     * @private
     */
    private generateContent(pdf: jsPDF, pattern: PdfPattern): { bottom: number; right: number } {
        const pos = this.applyHeaderOnPdf(pdf, pattern, pattern.margin.left, pattern.margin.top);
        return this.generateElement(pdf, pattern, pattern.margin.left, pos.bottom, pattern.content);
    }

    /**
     * Generate generic element, could be a visual pattern or a batch of elements (it's will be recursive in this case).
     * @param pdf
     * @param pattern
     * @param left
     * @param top
     * @param element
     * @private
     */
    private generateElement(pdf: jsPDF, pattern: PdfPattern, left: number, top: number, element: PatternInterface): { bottom: number; right: number } {
        switch (element.type) {
            case 'TextPattern' :
                return this.applyTextPattern(pdf, pattern, left, top, <TextPattern>element);
            case 'ImagePattern' :
                return this.applyImagePattern(pdf, pattern, left, top, <ImagePattern>element);
            case 'PatternBatch' :
                return this.applyBatchOfPatterns(pdf, pattern, left, top, <PatternBatch>element);
        }
    }

    /**
     * Write the text bloc on the pdf by splitting the text element to multiple text element every time there are a break line
     * @param pdf
     * @param pattern
     * @param left
     * @param top
     * @param element
     * @private
     */
    private applyTextPattern(pdf: jsPDF, pattern: PdfPattern, left: number, top: number, element: TextPattern): { bottom: number; right: number } {

        if (!!element.font) {
            this.setFont(pdf, element.font);
        }

        if (!!element.fontSize) {
            pdf.setFontSize(element.fontSize);
        }

        const texts = element.text.split('\n');
        const pos = texts.reduce((position, t) => {
            const newInsText = _.merge(_.cloneDeep(element), {text: t, disposition: 'column'});
            return this.writeParagrapheOnPdf(pdf, pattern, left, position.bottom, newInsText);
        }, {right: left, bottom: top});

        this.setFont(pdf, pattern.font);
        pdf.setFontSize(pattern.fontSize);

        return pos;
    }

    /**
     * Write a text element to the pdf. Should be call by {@link applyTextPattern}.
     * if the text offset the page, a new page will be automatically added.
     * @param pdf
     * @param pattern
     * @param left
     * @param top
     * @param element
     * @private
     */
    private writeParagrapheOnPdf(pdf: jsPDF, pattern: PdfPattern, left: number, top: number, element: TextPattern): { bottom: number; right: number } {
        // On détermine la largeur du bloc disponible et l'on prend la largeur la plus petite entre la disponible et la souhaitée
        const calculatedLeft = left + element.padding.left;
        const availableWidth = pattern.size.width - calculatedLeft - pattern.margin.right - element.padding.right;
        const expectedWidth = !!element.width ? element.width : availableWidth;
        const estimatedWidth = Math.min(availableWidth, expectedWidth);
        const bottomLimit = pattern.size.height - pattern.margin.bottom;

        /**
         * Si l'origine du bloc (point en haut à gauche) dépasse déjà la limite de la page (cas exceptionnel mais possible), on commence par changer de page.
         */
        if (top + element.padding.top > bottomLimit) {
            const position = this.moveToANewPage(pdf, pattern);
            return this.writeParagrapheOnPdf(pdf, pattern, left, position.bottom, element);
        }

        /**
         * baseline top est utile parce que le texte est "relativement" posé aux coordonné x/y. Il faut imaginer une ligne comme un rectangle.
         * les coordonnée left et top de la page ne correspondent pas forcément aux coordonnée left & top de ce rectangle.
         * C'est ce a quoi set le "baseline" et comme on calcule la taille des champs et des images pour déduire les positions qui bloc suivants
         * Il faut qu'ils soient tous sur la meme base, le point d'origine 0,0 est en faut a gauche.
         */
        const opts: TextOptionsLight = _.merge({baseline: 'top'}, element.options, {maxWidth: estimatedWidth});

        const splitText: string[] = pdf.splitTextToSize(element.text, estimatedWidth);
        // @ts-ignore The getTextDimensions works with array. The test and the official documentation approves it but not the types
        const estimatedHeight = pdf.getTextDimensions(splitText).h + element.padding.top; // on peut ignorer le padding du bas :)
        const estimatedBottomEnd = top + estimatedHeight;

        /**
         * Si le texte dépasse la page, on le split, on met ce que l'on peut jusqu'aux limites, on ajouter une page puis on met le reste.
         * Mais pour garder le texte justifié (s'il l'est), on ne peut pas splitter par ligne par ligne et ajouter jusqu'à ce que l'on ne puisse plus.
         * Sinon chaque ligne sera mise, et considéré comme une ligne, le texte n'est pas justifié.
         * Donc on boucle mot par mot, pour savoir quand passer a la ligne.
         * C'est pour l'instant le plus propre, le problème est que la ligne qui sera splitté ne sera pas forcément proprement justifié
         */
        if (estimatedBottomEnd > bottomLimit) {
            let textCanFit = element.text;
            let textCannotFit = '';
            let lastIndex = textCanFit.lastIndexOf(' ');

            if (lastIndex < 0) { // S'il n'y a pas de caractère " " on ne peut pas splitter le texte dessus alors on split dans le tas
                lastIndex = textCanFit.length - 1;
                while (lastIndex > 0) {
                    textCanFit = textCanFit.substr(0, lastIndex);
                    textCannotFit = element.text.substr(lastIndex, element.text.length);
                    const splitTextCanFit: string[] = pdf.splitTextToSize(textCanFit, estimatedWidth);
                    // @ts-ignore The getTextDimensions works with array. The test and the official documentation approves it but not the types
                    const estimatedHeightCanFit = pdf.getTextDimensions(splitTextCanFit).h + element.padding.top; // on peut ignorer le padding du bas :)
                    if (top + estimatedHeightCanFit <= bottomLimit) {
                        break;
                    }

                    lastIndex--;
                }
            } else {
                while (lastIndex > 0) {
                    textCanFit = textCanFit.substr(0, lastIndex);
                    textCannotFit = element.text.substr(lastIndex + 1, element.text.length);

                    const splitTextCanFit: string[] = pdf.splitTextToSize(textCanFit, estimatedWidth);
                    // @ts-ignore The getTextDimensions works with array. The test and the official documentation approves it but not the types
                    const estimatedHeightCanFit = pdf.getTextDimensions(splitTextCanFit).h + element.padding.top; // on peut ignorer le padding du bas :)
                    if (top + estimatedHeightCanFit <= bottomLimit) {
                        break;
                    }

                    lastIndex = textCanFit.lastIndexOf(' ');
                }
            }


            // On pense a retirer le padding bottom qui sert a rien en fin de page
            const oldPageText = _.merge(_.cloneDeep(element), {text: textCanFit, padding: {left: 0, right: 0, bottom: 0, top: 0}, width: estimatedWidth});
            this.applyTextPattern(pdf, pattern, calculatedLeft, top + element.padding.top, oldPageText);
            const newPosition = this.moveToANewPage(pdf, pattern);
            // Par contre ici on remet le padding
            const newPageText = _.merge(_.cloneDeep(element), {text: textCannotFit, padding: {left: 0, right: 0, top: 0}, width: estimatedWidth});
            return this.applyTextPattern(pdf, pattern, calculatedLeft, newPosition.bottom, newPageText);

        }

        // Si le text est centré, jspdf veut que la position x soit le centre du text
        const x = opts.align === 'center' ? calculatedLeft + estimatedWidth / 2 : calculatedLeft;

        pdf.text(splitText, x, top + element.padding.top, opts);
        return {right: calculatedLeft + estimatedWidth, bottom: estimatedBottomEnd + element.padding.bottom};
    }

    /**
     * Recursively recall {@link generateElement} but with a custom management of disposition.
     * @param pdf
     * @param pattern
     * @param left
     * @param top
     * @param insertedElements
     * @private
     */
    private applyBatchOfPatterns(pdf: jsPDF, pattern: PdfPattern, left: number, top: number, insertedElements: PatternBatch): { bottom: number; right: number } {
        const isColumn = insertedElements.disposition === 'column';

        let maxBottom = 0;
        let maxRight = 0;

        const res = insertedElements.elements.reduce((position: { left: number, top: number }, element) => {
            const end = this.generateElement(pdf, pattern, position.left, position.top, element);
            maxBottom = maxBottom > end.bottom ? maxBottom : end.bottom;
            maxRight = maxBottom > end.right ? maxRight : end.right;
            return isColumn ? {left: position.left, top: end.bottom} : {left: end.right, top: position.top};
        }, {left: left, top: top});

        maxBottom = maxBottom > res.top ? maxBottom : res.top;
        maxRight = maxBottom > res.left ? maxRight : res.left;
        return {bottom: maxBottom, right: maxRight};
    }

    /**
     * Add a page, apply the header and set the new page at current
     * @param pdf
     * @param pattern
     * @private
     */
    private moveToANewPage(pdf: jsPDF, pattern: PdfPattern): { bottom: number; right: number } {
        pdf.addPage();
        return this.applyHeaderOnPdf(pdf, pattern, pattern.margin.left, pattern.margin.top);
    }

    /**
     * Apply the header on the pdf
     * @param pdf
     * @param pattern
     * @param left
     * @param top
     * @private
     */
    private applyHeaderOnPdf(pdf: jsPDF, pattern: PdfPattern, left: number, top: number): { bottom: number; right: number } {
        const pos = this.generateElement(pdf, pattern, left, top, pattern.header);
        return {bottom: pos.bottom, right: left};
    }

    /**
     * Add an image to the generated PDF
     * @param pdf
     * @param _doc
     * @param left
     * @param top
     * @param element
     * @private
     */
    private applyImagePattern(pdf: jsPDF, _doc: PdfPattern, left: number, top: number, element: ImagePattern): { bottom: number; right: number } {
        const imageData = pdf.getImageProperties(element.base64);
        const width = !!element.width ? element.width : imageData.width;
        const height = imageData.height * (width / imageData.width);

        pdf.addImage(element.base64, left + element.padding.left, top + element.padding.top, width, height);
        const blocHeight = height + element.padding.top + element.padding.bottom;
        const blockWidth = width + element.padding.right + element.padding.left;

        return {bottom: top + blocHeight, right: left + blockWidth};
    }
}
