<div id="about">

    <div class="about-content" fxLayout="column" fxFlex="100">

        <div class="profile-box info-box general" fxLayout="column">

            <header class="mat-accent-bg">
                <button *ngIf="canDeleteCurrentAccount()" (click)="deleteCurrentAccount()" class="delete" mat-raised-button>
                    <mat-icon svgIcon="delete"></mat-icon>
                </button>
                <div class="title">{{ 'account-management.profile.title' | translate }}</div>
                <button *ngIf="displayField('topEditButton')" (click)="setMode(true)" mat-raised-button>
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>

            </header>

            <div class="content">

                <div fxFlex fxLayout="column" fxLayoutAlign="start start">

                    <div class="info-line" *ngIf="displayField('id') && userInformation?.id">
                        <div class="title">{{ 'account-management.id' | translate }}</div>
                        <div class="info">{{userInformation.id}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('label')">
                        <div class="title">{{ 'account-management.username' | translate }}</div>
                        <div class="info">{{ifEmptyValue('label') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('studentCode')">
                        <div class="title">{{ 'account-management.studentCode' | translate }}</div>
                        <div class="info">{{studentCode}}</div>
                    </div>

                    <div class="info-line"
                         *ngIf="(displayField('email') && authenticationService.isAtLeastTrainer()) || learnerCanEditMail">
                        <div class="title">{{ 'account-management.my_email' | translate }}</div>
                        <div class="info">{{ifEmptyValue('email') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('password') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.password' | translate }}</div>
                        <div class="info">*********</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('institution')">
                        <div class="title">{{ 'account-management.institution' | translate }}</div>
                        <div class="info">{{ifEmptyValue('institution') | translate}}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('you_are') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.you-are' | translate }}</div>
                        <div class="info">{{ 'generic.you-are.' + youAreValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('find_us') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.find-us' | translate }}</div>
                        <div class="info">{{ 'account-management.find-us.' + findUsValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('level') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.level' | translate }}</div>
                        <div class="info">{{ levelValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('region') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.region' | translate }}</div>
                        <div class="info">{{ regionValue | translate }}</div>
                    </div>

                    <div class="info-line" *ngIf="displayField('newsletter') && !userInformation?.get('sso')">
                        <div class="title">{{ 'account-management.newsletter' | translate }}</div>
                        <div class="info">{{ getTranslate() | translate}}</div>
                    </div>
                </div>


                <div fxFlex="100px" fxLayoutAlign="end start" *ngIf="displayField('picture')">
                    <img *ngIf="userInformation?.get('picture')" class="avatar" [src]="userInformation?.get('picture')">
                    <div *ngIf="!userInformation?.get('picture')"
                         class="info">{{ 'generic.to-complete' | translate}}</div>
                </div>
            </div>
            <mat-card-actions fxLayoutAlign="center center" *ngIf="displayField('bottomActionButton')">
                <button (click)="setMode(true)" mat-raised-button>
                    <mat-icon svgIcon="edit"></mat-icon>
                    <span>{{ 'account-management.edit_my_profile' | translate }}</span>
                </button>
                <button *ngIf="canDeleteCurrentAccount()" (click)="deleteCurrentAccount()" mat-raised-button>
                    <mat-icon svgIcon="delete"></mat-icon>
                    <span>{{ 'account-management.delete_my_account' | translate }}</span>
                </button>
            </mat-card-actions>

        </div>

    </div>

</div>
