<ng-template #loading>
    <mat-spinner></mat-spinner>
</ng-template>

<div fxLayout="column" class="page-layout simple fullwidth" fxFlex>
    <ng-container *ngIf="lessonIsReady else loading">

        <!-- HEADER -->
        <div class="header lesson-title">
            <h2>{{editableLesson.title}}</h2>
        </div>

        <div class="content">
            <div class="mat-body lesson-editor-helper">
                {{ tips | translate }}
            </div>

            <ng-container *ngIf="isSaving === false else loading">
                <app-editable-activities-list [activities]="activities"
                                              (addChild)="addChildActivity($event)"
                                              (delete)="deleteActivity($event)"
                                              (edit)="editActivity($event)"
                                              (editChild)="editChildActivity($event)"
                                              (play)="playActivity($event)"
                                              (playChild)="playChildActivity($event)"
                                              [toggle]="lastToggleActivity"
                                              (jumpBelow)="jumpBelowActivity($event)"></app-editable-activities-list>
            </ng-container>
        </div>
    </ng-container>
    <div *ngIf="activityOptionsEditable('content')" class="flex-container-button">
        <button class="save-button add-step-button" mat-raised-button (click)="addActivityWithDefinedTypology()" [disabled]="isSaving">
            <mat-icon svgIcon="add"></mat-icon>
            <span>{{ 'generic.add_activity' | translate }}</span>
        </button>
    </div>
    <div class="flex-container-button">
        <button class="save-button" mat-raised-button (click)="save()" [disabled]="isSaving">
            <span>{{ 'generic.save' | translate }}</span>
        </button>
        <button mat-raised-button class="exit-button" (click)="exit()">
            <span>{{ 'generic.exit' | translate }}</span>
        </button>
    </div>

    <ng-container *ngIf="activityOptionsEditable('titleAndInstruction') && isSaving === false">
        <div class="addBtnBottomRight" fx-layout="row"
             fx-flex fx-layout-align="end">
            <editor-add-step (selectStepTypeToAddInLesson)="selectStepTypeToAddInLesson($event)"></editor-add-step>
        </div>
    </ng-container>
</div>
