import { LrsService } from './@modules/lrs/core/lrs.service';
import { Injectable } from '@angular/core';
import {AssignationService} from '@modules/assignation';
import {SvgIconList} from './themes/ubolino_app/svgIconList';
import {OnboardingService} from './shared/onboarding/onboarding.service';

@Injectable()
export class ServiceInstantiator {

    constructor(
        private _assignationService: AssignationService,
        private svgIconList: SvgIconList,
        private lrsService: LrsService,
        private onboardingService: OnboardingService
    ) {
    }

}
