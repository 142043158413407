import {Component, OnInit} from '@angular/core';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import {DictionaryService} from '@modules/activities/core/dictionary/dictionary.service';


@Component({
    selector: 'app-dictionary',
    templateUrl: './dictionary.component.html',
})
export class DictionaryComponent implements OnInit {
    public readInProgress = false;
    public flashcards: FlashCardInterface[] = [];
    public loader = false;
    private page = 1;

    constructor(private dictionaryService: DictionaryService) {

    }

    ngOnInit(): void {
        this.dictionaryService.loadFlashCard().subscribe(flashcards => {
            this.flashcards = flashcards;
        });
    }

    /**
     *  if is playing block other card read
     * @param $event 'is-playing' | 'is-selected'
     * @param flashcard
     */
    public onStateChange($event: 'is-playing' | 'is-selected', flashcard: FlashCardInterface): void {
        if ($event === 'is-playing') {
            this.readInProgress = true;
        } else {
            this.readInProgress = false;
        }
    }

    /**
     * when infinite scroll detect event load next data
     */
    public onScroll(): void {
        this.loader = true;
        this.page = this.page + 1;
        this.dictionaryService.loadFlashCard(this.page).subscribe(flashcards => {
            this.flashcards.push(...flashcards);
            this.loader = false;
            this.readInProgress = false;
        });
    }
}
