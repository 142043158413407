import {Component, NgZone, OnInit} from '@angular/core';
import {AnimatedCanvasComponent} from '@modules/gamification/core/animated-canvas/animated-canvas.component';
import {GamificationService} from '@modules/gamification/core/gamification.service';

/**
 * we cannot use app-annimated-canvas with same id in more than one place and if cannot be set by input because
 * input fire to late so we extend the app-annimated-canvas and just change html with another id
 * and value call in ts with same id.
 */
@Component({
    selector: 'app-animated-canvas-in-my-progress',
    templateUrl: './animated-canvas-in-reward-page.component.html'
})
export class AnimatedCanvasInRewardPageComponent extends AnimatedCanvasComponent implements OnInit {

    constructor(protected zone: NgZone, public gamificationService: GamificationService) {
        super(zone, gamificationService);
        this.idPlayerContainer = 'player-container-reward';
    }
}
