<!--we generate a matrix  of x row and x column first the column and for each column the row  corresponding -->
<!--matrix column-->
<div class="generated-column"
     *ngFor="let e of generateArray(); let c = index">
    <!--matrix row of x element for each column previously made-->
    <div class="generated-row-element"
         [ngStyle]="{'height': 100/sizeOfZone + '%'}"
         *ngFor="let e of generateArray(); let r = index"
         (click)="storeTrueFalseZoneSelected(r, c)">
        <div [ngClass]="returnClassInRegardOfZoneState(r, c) + ' ' + returnClassInRegardOfAnswerState(r, c)"></div>
    </div>
</div>
