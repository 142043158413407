export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'arms': 'arms',
    'allTypes': 'toustypes',
    'archive': 'archiver',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'arrow_left': 'arrow-left',
    'arrow_right': 'arrow_right',
    'assignment_ind': 'assignment_ind',
    'assignmentsCardIcon': 'arrow-right',
    'logout': 'logout',
    'back': 'back',
    'bodies': 'bodies',
    'brand': 'brand',
    'brand_square': 'brand',
    'btn_trash': 'trash_tiny',
    'btn_log_out': 'log_out_tiny',
    'chevron': 'chevron',
    'class_add': 'add',
    'close': 'close',
    'clothes': 'clothes',
    'conceptCardIcon': 'arrow-right',
    'contact': 'contact',
    'cross': 'cross',
    'editor-play': 'play_outline',
    'trash': 'trash',
    'cross_outline': 'cross_outline',
    'dances': 'dances',
    'delete': 'trash',
    'document': 'document',
    'drag_indicator': 'arrow_down_circle_outline',
    'edit': 'edit',
    'ears': 'ears',
    'eyes': 'eyes',
    'file_download': 'file_download',
    'duplicate': 'duplicate',
    'file': 'corpus',
    'fill-in-blanks': 'remplir_blancs',
    'hats': 'hats',
    'icon-arrow-back': 'arrow_back',
    'institution_add': 'add',
    'legs': 'legs',
    'lessons': 'transition',
    'lessonCardIcon': 'arrow-right',
    'link': 'url',
    'lock': 'lock',
    'mouths': 'mouths',
    'next': 'arrow-right',
    'pdf': 'ressources',
    'play_button': 'play_button',
    'picto_math': 'picto_math',
    'play': 'play_outline',
    'puce': 'puce',
    'reload': 'reload',
    'retry': 'retry',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'shield': 'confi',
    'sound_on': 'sound_on',
    'sound_off': 'sound_off',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'student_add': 'check_circle_outline',
    'trainer_add': 'add',
    'groups-form-add': 'check',
    'transition': 'transition',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'unlock': 'unlock',
    'visibility': 'visibility',
    'visibility_legalnotice': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_privacy': 'privacy',
    'workgroup_add': 'add',
    'xxx': 'dislike',
};
