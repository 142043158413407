<div class="visible page-layout simple fullwidth">
    <!-- HEADER -->
    <div class="header p-16" fxLayout="row" fxLayoutAlign="start center">
        <h2>{{ 'generic.tools' | translate }} > {{ 'activities.forms_model_title' | translate }}</h2>
        <!-- <h2>{{ 'notification.title' | translate }}</h2> -->
    </div>
    <!-- / HEADER -->
    <div layout="row" layout-wrap class="p-40">
        <form [formGroup]="entityForm"  name="entityForm" novalidate >

            <!-- Activity Wording Field -->
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input matInput name="wording" formControlName="wording" placeholder="{{ 'activities.form.activity_wording' | translate }}" required>
                    <span matPrefix>
                        <mat-icon svgIcon="edit"></mat-icon>
                    </span>
                    <span matSuffix>
                        <mat-icon svgIcon="qcu"></mat-icon>
                        {{ 'generic.qcu' | translate }}
                    </span>
                </mat-form-field>
            </div>

            <!-- Activity Wording Field -->
            <div dragula="itemsBAG" class="mat-elevation-z2 pt-40 pb-40">

                <div fxLayout="row" fxLayoutAlign="start start" *ngFor="let answer of entity.answers; let i = index">
                    <mat-form-field class="answer" fxFlex floatLabel="never">
                        <input matInput  name="answers{{i}}" formControlName="answers{{i}}" placeholder="option {{i+1}}">
                        <span matPrefix class="pl-16">
                            <mat-icon>drag_indicator</mat-icon>
                            <!--<mat-icon svgIcon="checkbox"></mat-icon>-->
                           <mat-radio-button disabled [disableRipple]="true"></mat-radio-button>
                        </span>
                        <span matSuffix class="pr-16">
                            <button aria-label="delete" matTooltip="{{ 'generic.delete' | translate }}" (click)="removeAnswer(i)">
                                <mat-icon svgIcon="delete"></mat-icon>
                            </button>
                        </span>
                    </mat-form-field>
                </div>

                <div fxLayout="row" class="pl-40 pr-32px" fxLayoutAlign="start start" (click)="addAnswer()">
                    <!--<mat-form-field fxFlex floatLabel="never" (click)="addAnswer()">-->
                    <mat-radio-button disabled [disableRipple]="true"><mat-label>{{ 'activities.form.add_activity' | translate }}</mat-label></mat-radio-button>
                    <!--</mat-form-field>-->
                </div>

            </div>


        </form>
    </div>

    <div fxLayoutAlign="center center">
        <button mat-raised-button class="mat-primary" (click)="saveActivity()">{{ 'generic.save' | translate }}<mat-icon>save</mat-icon></button>
        <button mat-raised-button class="mat-primary" (click)="exit()">{{ 'generic.exit' | translate }}<mat-icon>exit_to_app</mat-icon></button>
    </div>
    <!-- button end -->
</div>