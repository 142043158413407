<div class="page-layout simple tabbed inner-scroll" fxFlex>
    <div class="content inner-scroll" fxFlex fxLayout="column">
        <mat-card>
            <ng-template #loading>
                <mat-spinner></mat-spinner>
            </ng-template>

            <ng-container *ngIf="isLoading === false else loading">
                <mat-card-header>
                    <mat-card-title>{{'link_child.title' | translate}}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <p>{{'link_child.body' | translate}}</p>

                    <mat-form-field>
                        <mat-label>{{ 'link_child.child_identifier' | translate }}</mat-label>

                        <input [formControl]="childIdentifierControl" matInput
                               placeholder="{{ 'link_child.child_identifier' | translate }}"
                               required>
                    </mat-form-field>


                    <mat-error
                        *ngIf="isChildNotFound">{{'link_child.trainer_not_found' | translate}}</mat-error>

                    <p *ngIf="isSuccess"
                       class="success">{{childLabel + ' ' + 'link_child.success' | translate}}</p>

                </mat-card-content>

                <mat-card-actions>
                    <button (click)="onLinkClick($event)" [disabled]="canLink() === false" color="primary"
                            mat-button>{{'link_child.validate' | translate}}</button>
                </mat-card-actions>
            </ng-container>
        </mat-card>
    </div>
</div>
