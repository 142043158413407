import {of as observableOf, Observable, BehaviorSubject, Subscription, Subject, ReplaySubject, combineLatest, of, pipe} from 'rxjs';

import {takeUntil, map, mapTo, mergeMap, take, tap} from 'rxjs/operators';
import {Injectable, QueryList} from '@angular/core';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {CommunicationCenterService} from '../../communication-center';
import {TranslateService} from '@ngx-translate/core';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {
    IFormatIdentifier,
    LessonActivityRoutes,
    NavigateToLessonOptions
} from '@modules/activities/core/models/lessonsActivityRoutes';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../settings';
import {EditStepComponent} from '@modules/activities/core/shared-components/edit-step/edit-step.component';
import {
    EditorActivitiesListComponent,
    EditorActivitiesListDialogData
} from '@modules/activities/core/lessons/editor/components/editor-activities-list/editor-activities-list.component';
import {DialogComponent} from '@modules/activities/core/shared-components/dialog/dialog.component';
import {EntityDataSet} from 'octopus-connect';
import * as _ from 'lodash';
import {ActivitiesActivityInterface, GranuleInterface, MediaInterface, MetadataInterface} from '@modules/activities/core/lessons/lessons-interfaces.interface';
import {PaginatedCollection} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import {CollectionPaginator} from 'octopus-connect';
import {EditableLesson} from '@modules/activities/core/lessons/editor/models/editable-lesson.class';
import {FORM_CONTROL_MAPPING} from 'fuse-core/components/search-filters/search-filters.component';
import {MultimediaPage} from '@modules/activities/core/lessons/editor/models/multimedia-page.class';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';

declare var require: any;
const urlParser = require('js-video-url-parser');

const settingsStructure = new ModelSchema({
    activitiesNoClone: Structures.boolean(false),
    saveOnDestroy: Structures.array([]),
    addFromActivities: Structures.boolean(true),
    allowErrorReporting: Structures.boolean(false),
    allowedThumbnailExtensions: Structures.array(['image/jpg', 'image/jpeg', 'image/png']),
    cardFieldsForPreview: Structures.array(['description']),
    columns: Structures.object({
        default: ['checkbox', 'type', 'title', 'author', 'level', 'difficulty', 'changed', 'actions']
    }),
    displayArchiveOptionToolbar: Structures.boolean(false),
    displayedFiltersIcons: Structures.boolean(false),
    displayHelpModalPage: Structures.array([]), // 'byRole', 'currentUser', 'community'
    filters: Structures.object({
        default: ['title', 'keywords', 'type']
    }),
    allowedActivityTypes: Structures.array([]),
    filterTitleToTranslate: Structures.string(''),
    hideHeader: Structures.boolean(false),
    hiddenActivityPreview: Structures.boolean(true),
    filtertoApplyOnLessonsByUrl: Structures.array([]),
    gradeCalculation: Structures.object(),
    hideAddButtonLessonForModel: Structures.boolean(false),
    hideAddButtonLessonForCommunity: Structures.boolean(false),
    isArrowNavigationEnabled: Structures.object({default: false}),
    keepFiltersEditorActivitiesList: Structures.array([]),
    latexKeyboard: Structures.boolean(false),
    lessonDialogFields: Structures.object({
        default: ['title', 'educationnalLevel', 'method', 'tags', 'description']
    }),
    lessonDialogRequiredFields: Structures.object({
        default: ['title']
    }),
    lessonStep: Structures.object(),
    levels: Structures.object({primary: [], secondary: []}),
    loadLessonWithSublesson: Structures.object({
        typology: null,
        multi_step: 0
    }),
    maxSizeThumbnail: Structures.string('600000'),
    plugins: Structures.object({}),
    searchFields: Structures.array(['title', 'educationnalLevel', 'method', 'launchSearch', 'countEntities', 'bookmarks']),
    shareableModel: Structures.number(0),
    showProgressBar: Structures.boolean(false),
    showVideoWithMarkersLabel: Structures.boolean(true),
    showVideoMarkersNoteSubtitle: Structures.boolean(true),
    videoMarkerTextareaAutosize: Structures.boolean(false),
    hiddenFieldActivityPreview: Structures.array([]),
    showAddLessonButtonCard: Structures.boolean(false),
    hideAddButtonLessonList: Structures.boolean(false),
    hideReview: Structures.boolean(false),
    menu: Structures.object({
        models: false,
    }),
    navigationInStepsAllowed: Structures.boolean(true), // Can navigate in stepper
    navigateInPreviousStep: Structures.boolean(true), // can navigate in previous step
    canOverrideStepMetadatasInLesson: Structures.boolean(false),
    multiSelectionForActivitiesList: Structures.boolean(true),
    urlVideoException: Structures.array([]),
    activitiesDisplayedColumns: Structures.array(['title']),
    activitiesTypesUserCanUse: Structures.array([]),
    warnings: Structures.boolean(false),
    filtersEditorActivitiesList: Structures.array(),
    saveLessonContentOptions: Structures.object({
        activityTypesCanBeDuplicate: [], // type d'activitiés que l'on peut dupliquer (certaines activitiés n'ont pas besoin d'être dupliqué)
        saveContent: true
    }),
    typeActivitiesToSkip: Structures.array(null),
    recommendationDisplayedColumns: Structures.array([]),
    buttons: Structures.object(null),
    autoCorrection: Structures.boolean(false),
    displayFeedback: Structures.boolean(false),
    setAnswerWithUserSave: Structures.boolean(true),
    showSubInstruction: Structures.boolean(false),
    symbolsForLatexKeyboard: Structures.object(null),
    loadSubActivitiesOfAllSubLesson: Structures.boolean(true),
    imageFullscreenButton: Structures.boolean(false),
});

/**
 * Represent the minimum used data for create an complete granule activity : {@link createGenericActivity}
 */
export interface GenericActivityOptionsInterface {
    activity?: {
        activity_content?: number | string,
        config?: any,
        instruction?: string,
        [key: string]: any
    };
    metadatas?: {
        language?: string,
        title?: string,
        typology?: number,
        [key: string]: any
    };
}

@Injectable()
export class ActivitiesService {
    activities: any = [];
    private selectedActivities: DataEntity[] = [];
    savedAnswers: any = [];
    lessonsAnswers: { [key: string]: DataEntity } = {};
    downloadedActivity: any;
    lessons: DataEntity[];
    sequences: DataEntity[];
    savedAnswersSubscription: Subscription;
    activitiesSubscription: Subscription;
    activitiesObservable: Observable<DataEntity[]>;
    formsSubscription: Subscription;
    onFilesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});
    skillsChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onSelectedResourcesChanged: BehaviorSubject<any> = new BehaviorSubject({resources: [], event: null});
    i: number;
    dialogYes: string;
    dialogCancel: string;
    dialogTitle: string;
    dialogDeleteMessage: string;
    userData: DataEntity;
    sequencesSubscription: Subscription;
    showVideoWithMarkersLabel: boolean;
    showVideoMarkersNoteSubtitle: boolean;
    activitiesArray: any[] = [];
    activitiesInSublessonAlreadyLoaded: any[] = []; // all the activities in each sublesson already loaded
    /**
     * Seems to be the index of the current activity in the current context activity list
     */
    presentArrayElementIndex = 0;
    assignmentView: any = null;
    visitedMediaActivity: number[] = [];
    public activitiesSelection: string;
    /*
    * This variable stores the information of the following :
    *  1) userId
    *  2) expires
    *  3) state
    *  4) type
    *  */
    public currentAssignment: DataEntity;

    /**
     * An object of the type Subject which holds the Boolean values. These
     * values are responsible for the decide upon the state of the activity.
     *      -- STATE like : Revoir Ma Response or Tester la reponse, Reinitiliser, etc.
     */
    public checkAnswers: Subject<any> = new Subject();
    /**
     * Trigger a action in application like on Activities component
     * * The `actionLabel` is the unique identifier of the action (like `saveAnswer`)
     * * The `endSubject` is for subscribe/emit when the associated action are ended.
     */
    public userActionWaiting = new Subject<{ actionLabel: string, endSubject: Subject<any> }>();
    /**
     * Type of Subject with Boolean value. That helps to decide whether the user
     * do reply or not.
     * IMPORTANT : It is not the behaviorSubject. There is a difference in the two.
     */
    public doesUserResponsed: Subject<boolean> = new Subject();
    public activityChange: Subject<boolean> = new Subject();
    // for lesson play screen attributes
    public isLessonPlay = false;
    public playScreenStatus = 1; // 0=> activity 1 => introduction 2=> restart 3=> result;
    public endScreenSeen = false;
    public activityAnswerResult: any[] = [];
    public currentLesson: any;
    public activityFormats: DataEntity[] = [];
    private currentActivities: DataEntity[] = [];
    public saving = false;
    public isSaveReady = false;

    public userSelectedOption: any[] = [];
    public userAnswerTempSave: any[] = [];
    private answerAPICalled: any[] = [];

    public pushLessonFromAssignment = new Subject();
    public activityActionsHandler = new Subject();
    public userAnswer = new Subject<DataEntity>();
    public isUserAnswerStatus = new Subject<any>();
    private unsubscribeInTakeUntil = new Subject();
    public userAnswerOnChange = new Subject<any>();
    public displayActions = new ReplaySubject<boolean>(1);
    public currentAssignmentID: any;

    public activitiesPaginated: PaginatedCollection;
    private activitiesPaginatedObs: Observable<DataEntity[]>;

    private localShortAnswers: string[];

    public chapters: Array<object> = [];
    public chaptersSubscription: Subscription = null;
    public chaptersChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    public tags: Array<object> = [];
    public tagsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    public activityEntities: DataEntity[] = [];

    public settings: { [key: string]: any };
    public licensingMethods = new BehaviorSubject<object[]>([]);
    public licensingSettings: { [key: string]: any };

    public onLatexKeyboardDisplayChange = new Subject<boolean>();
    public isMultipleAssignatedNodes = false; // know if the assignment contain several lesson
    public metadatasUsedForOverride: {
        id: string | number;
        title: string;
        instruction: string
    }[] = []; // contain all title and wording the user changed when he add or edit an activity

    public allTypes: any[];
    public answersProgressBarMultiZone: AnswerResultInterface[] = [];
    public currentActivityIndex = 0;
    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.activities);
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('view')
            .subscribe((type: any) => {
                this.assignmentView = type;
            });

        this.communicationCenter
            .getRoom('activities')
            .next('shareableModelCallback', () => {
                return this.settings.shareableModel;
            });

        this.onFilesChanged.subscribe((data: any[]) => {
            if (Array.isArray(data)) {
                for (const activity of data) {
                    const activityEntity = new DataEntity('granule-activity', activity, this.octopusConnect);
                    const findIndex = this.activityEntities.findIndex((element) => +element.id === +activity.id);

                    if (findIndex !== -1) {
                        this.activityEntities[findIndex] = activityEntity;
                    } else {
                        this.activityEntities.push(activityEntity);
                    }
                }
            }
        });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((data) => {
                this.currentAssignment = data;
                if (data && data.id) {
                    this.currentAssignmentID = data.id;
                } else {
                    this.currentAssignmentID = null;
                }
                /*userId, expires, state*/
            });
        this.localShortAnswers = [];

        this.communicationCenter
            .getRoom('activities')
            .next('loadActivitiesFromIdCallback',
                (granuleActivityId: string | number) => {
                    return this.loadActivitiesFromId(granuleActivityId.toString());
                }
            );
    }

    postAuthentication(): void {
        this.loadActivityFormats()
            .pipe(
                take(1),
            ).subscribe((entities) => this.activityFormats = entities);

        this.communicationCenter.getRoom('licenses').getSubject('methods').subscribe(methods => {
            const methodsTmp = [];
            methods.forEach(method => {
                if (method.get('uid') === this.userData.id) {

                    const access = method.get('access');
                    if (access) {
                        methodsTmp.push({
                            id: access.id,
                            label: access.name
                        });
                    }
                }
            });

            const onlyDisctinctMethods = [];
            methodsTmp.forEach(loopMethod => {
                if (onlyDisctinctMethods.some(selectedMethod => selectedMethod['id'] === loopMethod['id']) === false) {
                    onlyDisctinctMethods.push(loopMethod);
                }
            });
            this.licensingMethods.next(onlyDisctinctMethods);
        });
        this.communicationCenter.getRoom('licenses').getSubject('settings').pipe(take(1)).subscribe(settings => {
            this.licensingSettings = settings;
        });

        this.getMethods().subscribe((list) => {
                this.communicationCenter
                    .getRoom('activities')
                    .next('chapters', list.entities);
            }
        );
    }

    /**
     * get the list of id and label from an endppoint
     * TODO think about a genric method helper like this method  for list if code is the same every where in application
     public getBasicList(endpoint: string): Observable<IBasicListDto[]> {
            return new BasicListDto(this.octopusConnect.loadCollection(endpoint)).getObjectDto();
        }*/

    /**
     * get the list of possible educationnal level
     */
    public getEducationnalLevel(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('educational_level')
            .pipe(mergeMap((collection: DataCollection) => of(collection.entities)));
    }

    /**
     * get the list of skills
     */
    public getSkills(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('skills')
            .pipe(mergeMap((collection: DataCollection) => of(collection.entities)));
    }

    /**
     * get the list of difficulty
     */
    public getDifficulties(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('difficulty')
            .pipe(mergeMap((collection: DataCollection) => of(collection.entities)));
    }

    /**
     * get the list of assignation_type
     */
    public getAssignationTypes(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('assignation_type')
            .pipe(mergeMap((collection: DataCollection) => of(collection.entities)));
    }

    /**
     * get the list of assignation_type
     */
    public getChaptersTypes(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('chapters')
            .pipe(mergeMap((collection: DataCollection) => of(collection.entities)));
    }

    /**
     * get the list of themes
     */
    public getThemes(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('themes')
            .pipe(mergeMap((collection: DataCollection) => of(collection.entities)));
    }

    public isPrimary(activity): boolean {
        if (activity.level && activity.level.length) {
            return this.settings.levels['primary'].includes(activity.level[0].alias);
        }
        return false;
    }

    saveAnswer(entity, type = null): any {
        if (!this.currentAssignmentID) {
            const labelAnswer = entity.answer;
            const observable = new ReplaySubject<DataEntity>();
            entity = new DataEntity(type, {
                answer: labelAnswer
            }, null, this.localShortAnswers.length);
            this.localShortAnswers[entity.id] = labelAnswer;
            observable.next(entity);
            return observable;
        } else if (entity.id) {
            entity.save();
        } else if (type && entity.answer !== undefined) {
            return this.octopusConnect.createEntity(type, entity);
        } else {
            const observable = new ReplaySubject(1);
            observable.next(null);
            return observable;
        }

        return entity;
    }

    createSavedAnswers(gid, context = null, answerId, type): Subscription {
        const activityId = gid;
        // i did correction API Call
        // return this.octopusConnect.createEntity(type, {answers: [answerId]}).pipe(take(1)).subscribe((type: DataEntity) => {
        // tslint:disable-next-line:no-shadowed-variable

        return this.octopusConnect.createEntity(type, {answers: answerId}).pipe(take(1)).subscribe((type: DataEntity) => {
            this.octopusConnect.createEntity('user-activity', {entitySave: type.id}).pipe(take(1)).subscribe((data: DataEntity) => {
                this.octopusConnect.createEntity('user-save', {
                    granule: activityId,
                    context: context,
                    userActivity: data.id,
                    granuleParent: this.currentLesson.id, // save the id of granule lesson parent
                    lesson: this.currentLesson.id
                });
            });
        });
    }

    loadSavedAnswers(gid, context): Observable<DataEntity[]> {
        if (this.savedAnswersSubscription) {
            this.savedAnswersSubscription.unsubscribe();
        }
        if (!context && this.currentAssignmentID) {
            context = this.currentAssignmentID;
        }
        const obj = {granule: gid};
        if (context) {
            obj['context'] = context;
        }

        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('user-save', obj).pipe(map(collection => {
            return collection.entities;
        }));
        this.savedAnswersSubscription = obs.subscribe(entities => {
            this.savedAnswers = entities;
        });
        return obs;
    }

    /**
     *
     * @param {string} granuleId
     * @param {string} contextId
     * @param {string} getAllUserSave => if activity summary, we need all usersave for polls
     * @returns {Observable<DataEntity> || any[]}
     */
    public loadUserSave(granuleId: string, contextId?: string, getAllUserSave = null, step = null): any {
        const filters = {granule: granuleId};
        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }
        if (contextId) {
            filters['context'] = contextId;
        }
        if (!filters['context']) {
            const observable = new ReplaySubject<DataEntity>();
            observable.next(this.lessonsAnswers[step || step === 0 ? filters['granule'] + '-' + step.toString() : filters['granule'] + '-' + this.presentArrayElementIndex.toString()]);
            // if no assign (context) set isSaveReady to true because, we dont load save from endpoint.
            this.isSaveReady = true;
            return observable;
        }
        return this.octopusConnect.loadCollection('user-save', filters).pipe(
            take(1),
            map((collection: DataCollection) => {
                this.isSaveReady = true;
                if (collection.entities.every((usersave) => usersave.get('step') && usersave.get('step') !== '')) {
                    // there is two save for the same question the diff will be by step value

                    if (getAllUserSave) {
                        return collection.entities;
                    }
                    return collection.entities.filter((entity) => +entity.get('step') === this.presentArrayElementIndex)[0];
                } else {
                    return collection.entities.sort((a, b) => a.id > b.id ? -1 : 1)[0];
                }
            }));
    }

    loadPaginatedActivities(filterOptions = {}, useLessonSearchEndpoint?: boolean): Observable<{ entities: DataEntity[], paginator: CollectionPaginator }> {
        const endpoint: string = useLessonSearchEndpoint ? 'lesson_granule_search' : 'basic_search';
        this.activitiesPaginated = this.octopusConnect.paginatedLoadCollection(endpoint, filterOptions);
        this.activitiesPaginatedObs = this.activitiesPaginated.collectionObservable.pipe(map(collection => collection.entities));

        return this.activitiesPaginatedObs.pipe(
            mergeMap((entities: DataEntity[]) => of({entities, paginator: this.activitiesPaginated.paginator}))
        );
    }

    public onPaginateChange(event): void {
        this.activitiesPaginated.paginator.page = event.pageIndex + 1;
    }

    loadActivityTypes(): Observable<DataCollection> {
        const obs = this.octopusConnect.loadCollection('variables/instance').pipe(take(1));

        obs.subscribe((collection) => {
            return this.allTypes = collection.entities[0].get('activityTypes');
        });
        return obs;
    }

    loadLessons(): Observable<Object[]> {
        if (this.activitiesSubscription) {
            this.activitiesSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-lesson').pipe(map(collection => collection.entities));
        this.activitiesSubscription = obs.subscribe(entities => this.activities = entities);
        return obs;
    }

    loadSequences(): Observable<Object[]> {
        if (this.sequencesSubscription) {
            this.sequencesSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-sequence').pipe(map(collection => collection.entities));
        this.sequencesSubscription = obs.subscribe(entities => this.activities = entities);
        return obs;
    }

    launchActivity(singleActivity: any): EntityDataSet {
        this.isSaveReady = false;
        /**
         * in case we start a sublesson from activities list,
         * this.downloadedActivity is the last activity loaded,
         * need to check if current activity launched is the same as this.downloadedActivity
         * if not find the current activities in this.activityEntities
         */
        if (singleActivity) {
            // i dont know why we keep and use this.downloadedActivity...
            if (this.downloadedActivity &&
                +singleActivity.id === +this.downloadedActivity.id &&
                singleActivity.isLoadBeforeLaunch) {
                return observableOf(this.downloadedActivity.attributes);
            }
            const findIndex = this.activityEntities
                .findIndex((element) => +element.id === +singleActivity.id);
            if (this.activityEntities[findIndex]) {
                return observableOf(this.activityEntities[findIndex].attributes);
            }
        }
        return observableOf(null);
    }

    openDialog(entity: any, checkBoxesList: QueryList<MatCheckbox> = null): void {

        // get translation
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogCancel = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => this.dialogTitle = translation);

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: this.dialogTitle,
        };

        const checkboxes = document.getElementsByName('corpusCheckboxe');
        const checkboxesChecked = [];
        // loop over them all
        for (this.i = 0; this.i < checkboxes.length; this.i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[this.i]['checked']) {
                checkboxesChecked.push(checkboxes[this.i].id.replace('-input', ''));
            }
        }

        // Return the array if it is non-empty, or null
        // return checkboxesChecked.length > 0 ? checkboxesChecked : null;

        if (entity !== 'multiple') { // for 1 entity
            this.translate.get('generic.confim_delete_single_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
            dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
            dialogConfig.data.labelTrueDialog = this.dialogYes;
            dialogConfig.data.labelFalseDialog = this.dialogCancel;

            const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    entity.remove();
                }
            });

        } else { // for 1 or multiple entities
            if (checkboxesChecked.length > 0) {
                this.translate.get('generic.confim_delete_multiple_files').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
                dialogConfig.data.labelTrueDialog = this.dialogYes;
                dialogConfig.data.labelFalseDialog = this.dialogCancel;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                        for (this.i = 0; this.i < checkboxesChecked.length; this.i++) {
                            // tslint:disable-next-line:no-shadowed-variable
                            this.octopusConnect.loadEntity('node', checkboxesChecked[this.i]).pipe(take(1)).subscribe(entity => entity.remove());
                        }
                    }
                });

            } else { // no checked checkbox
                this.translate.get('generic.confim_action_no_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            }
        }
    }

    pushValueIntoSubscriber(response: any): void {
        this.onFilesChanged.next(response);
    }

    // generic function to get the exact object from the nestedObject.
    getPropertyFromNestedObject(mainObject: Object, pathToAttribute: Array<string>): any {
        return pathToAttribute.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, mainObject);
    }

    getVideoLinkInfo(url: string): string {
        if (url) {
            const parsed: Object = urlParser.parse(url);

            if (parsed) {
                return urlParser.create({
                    videoInfo: parsed,
                    format: 'embed'
                });
            } else if (this.settings.urlVideoException.some((urlException) => url.includes(urlException))) {
                return url;
            }
        }
    }

    setActivitiesListWithIds(activitiesArray: Array<any>): any {
        this.activitiesArray = activitiesArray;
    }

    /**
     * TODO: refacto name to loadActivityById because we only fetch only one activity
     * @param id
     */
    loadActivitiesFromId(id: number | string): Observable<DataEntity> {
        const obs: Observable<DataEntity> = this.octopusConnect.loadEntity('granule', id.toString());

        obs.pipe(take(1)).subscribe(entity => {
            this.downloadedActivity = entity;
        }, error => {
            return error;
        }, () => {

        });

        return obs;
    }

    /**
     * Add or Update Activities currently used ({@link currentActivities}) and currently loaded ({@link activityEntities})
     * @param activities List of activity currently used
     */
    setCurrentActivities(activities: DataEntity[]): void {
        this.currentActivities = activities;

        for (const activity of this.currentActivities) {
            this.updateActivityEntities(activity);
        }
    }

    getCurrentActivity(index: number): DataEntity {
        if (this.activitiesArray[index] && this.currentActivities) {
            const currentActivity = this.currentActivities.find((activity) => +activity.id === +this.activitiesArray[index].id);
            if (currentActivity) {
                this.downloadedActivity = currentActivity;
                return currentActivity;
            }
        }

        return null;
    }

    public getActivityEntity(id: number): DataEntity {
        return this.activityEntities.find((element) => +element.id === id);
    }

    /**
     * (Re)load activities and add/overwrite current loaded activities in service
     * @param activitiesRef
     * @param forSubLesson if true, it will not add/overwrite current loaded activity
     */
    public setActivitiesAfterLoaded(activitiesRef, forSubLesson = false): Observable<DataEntity[]> {
        const activitiesObs: Observable<DataEntity>[] = [];

        let obs;
        for (const ref of activitiesRef) {
            obs = this.loadActivitiesFromId(ref.id);
            obs.pipe(take(1)).subscribe((entity) => {
                if (!forSubLesson) {
                    this.updateActivityEntities(entity);
                }
            }, (error) => {
                console.error(error);
            });

            activitiesObs.push(obs);
        }

        return activitiesObs.length > 0 ? combineLatest(activitiesObs) : observableOf([]);
    }

    /**
     * Add activity (or Update if already exist) in {@link activityEntities}
     * @param activity To add or update in list. A same activity than another already in {@link activityEntities} will be ignored.
     */
    private updateActivityEntities(activity: DataEntity): void {
        const findIndex = this.activityEntities.findIndex((element) => +element.id === +activity.id);
        if (findIndex === -1) {
            this.activityEntities.push(activity);
        } else if (this.activityEntities[findIndex] !== activity) {
            this.activityEntities[findIndex] = activity;
        }
    }

    constructNavParams(loadBeforeLaunch: boolean, activityId?: string): NavigationExtras {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        return {
            relativeTo: currentRoute,
            queryParams: {
                isLoadBeforeLaunch: loadBeforeLaunch,
                id: activityId
            }
        };
    }

    loadNextActivity(): boolean {
        if (this.presentArrayElementIndex < this.activitiesArray.length) {
            this.presentArrayElementIndex += 1;
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id).pipe(
                    take(1))
                    .subscribe(data => {
                        this.navigateHere(data);
                    });
            }

            return this.presentArrayElementIndex !== this.activitiesArray.length - 1;
        } else {
            this.presentArrayElementIndex = 0;
        }

        return false;
    }

    loadFirstActivity(altPath = false): boolean {
        this.presentArrayElementIndex = 0;

        if (this.presentArrayElementIndex < this.activitiesArray.length) {
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity, false, altPath ? '..' : null);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id).pipe(
                    take(1))
                    .subscribe(data => {
                        this.navigateHere(data, false, altPath ? '..' : null);
                    });
            }
            return this.presentArrayElementIndex !== this.activitiesArray.length - 1;
        }

        return true;
    }

    /**
     * navigate to the activity
     * @param index
     * @param {boolean} altPath
     * @returns {boolean}
     */
    loadActivityByStep(index, altPath = false): boolean {
        this.presentArrayElementIndex = index;

        if (this.presentArrayElementIndex < this.activitiesArray.length) {
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity, false, altPath ? '..' : null);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id).pipe(
                    take(1))
                    .subscribe(data => {
                        this.navigateHere(data, false, altPath ? '..' : null);
                    });
            }
            return this.presentArrayElementIndex !== this.activitiesArray.length - 1;
        }

        return true;
    }

    loadPreviousActivity(): boolean {
        if (this.presentArrayElementIndex !== 0) {
            this.presentArrayElementIndex -= 1;
            const currentActivity = this.getCurrentActivity(this.presentArrayElementIndex);

            if (currentActivity) {
                this.navigateHere(currentActivity);
            } else {
                this.loadActivitiesFromId(this.activitiesArray[this.presentArrayElementIndex].id).pipe(
                    take(1))
                    .subscribe(data => {
                        this.navigateHere(data);
                    });
            }
        } else if (this.presentArrayElementIndex === 0) {
            this.router.navigate(['../..']);
        }

        return true;
    }

    resetArrayindex(): boolean {
        this.presentArrayElementIndex = 0;
        return true;
    }

    loadForms(): Observable<Object[]> {
        if (this.formsSubscription) {
            this.formsSubscription.unsubscribe();
        }
        const obs: Observable<DataEntity[]> = this.octopusConnect.loadCollection('granule-form').pipe(map(collection => collection.entities));
        this.formsSubscription = obs.subscribe(entities => {
            this.activities = entities[0].attributes.reference;
        });
        return obs;
    }

    /**
     * Directly execute routing to an url for execute the good activity player from the params
     * @param {DataEntity} data Activity to play (can be a lesson to play as a multi-activity)
     * @param loadBeforeLaunch todo
     * @param relativePath Force the relative path used to calculate new path. If no argument is passed the default value is '../..' (or '../../../..' in case of multi).
     */
    navigateHere(data: any, loadBeforeLaunch?: boolean, relativePath?: string, forceSkipLocation?: boolean): void {
        const urlFragments = [];
        const type = data.attributes.format.label;

        if (type === 'activity') {
            const {label} = this.getExactRoute(type, data);
            console.log('activities.service::910::navigateHere', label);
            urlFragments.push(LessonActivityRoutes[label]);
        } else if (type === 'divider') {
            urlFragments.push('divider');
        } else if (type === 'lesson') {
            forceSkipLocation = true;
            urlFragments.push('multi');
        } else {
            urlFragments.push('media');
        }

        urlFragments.push(data.id);

        if (!relativePath) {
            relativePath = this.router.url.indexOf('multi') > -1 ? '../../../..' : '../..';
        }
        const navigationExtras = this.constructNavParams(!!loadBeforeLaunch, data.id);
        if (forceSkipLocation !== undefined) {
            navigationExtras.skipLocationChange = forceSkipLocation;
        }
        this.router.navigate([
                relativePath,
                ...urlFragments
            ],
            navigationExtras
        );
    }

    getExactRoute(type, data): IFormatIdentifier {
        if (type === 'activity') {
            return this.getPropertyFromNestedObject(data['attributes'], ['metadatas', 'typology']);
        } else {
            return {label: this.getPropertyFromNestedObject(data['attributes'], ['format', 'label'])};
        }
    }

    public getContentTypeIcon(data: DataEntity, setFormat?: string): any {

        let type: string = null;
        let format: string = null;
        let filemime: string = null;

        if (data.get('reference') && data.get('reference').filemime) {
            filemime = data.get('reference').filemime;
        }

        if (data.get('format')) {
            format = data.get('format').label;
            if (data.get('metadatas').typology) {
                type = data.get('metadatas').typology.label;
            }
        } else {
            format = setFormat;
            if (data.get('typologyParent') && data.get('typologyParent').label !== '') {
                type = data.get('typologyParent').label;
            } else {
                if (data.attributes.hasOwnProperty('typology')
                    && data.get('typology') !== null) {
                    type = data.get('typology').label;
                }
            }
        }

        let iconObj = {
            name: '',
            translate: ''
        };

        switch (format) {
            case 'divider':
                iconObj = {
                    name: 'collections_bookmark',
                    translate: 'generic.photo'
                };
                break;
            case 'media':
            case 'audio':
            case 'video':
            case 'document':
            case 'image':
                switch (filemime) {
                    case ('image/png'):
                    case ('image/jpeg'):
                    case ('image/gif'):
                        iconObj = {
                            name: 'photo',
                            translate: 'generic.photo'
                        };
                        break;
                    case ('audio/mp3'):
                    case ('audio/mpeg'):
                        iconObj = {
                            name: 'video',
                            translate: 'generic.video'
                        };
                        break;
                    case ('application/pdf'):
                        iconObj = {
                            name: 'document',
                            translate: 'generic.document'
                        };
                        break;
                    default:
                        iconObj = {
                            name: 'photo',
                            translate: 'generic.photo'
                        };
                        break;
                }
                break;
            case 'url':
                iconObj = {
                    name: 'link',
                    translate: 'corpus.import_url'
                };
                break;
            case 'videoUrl':
                iconObj = {
                    name: 'video',
                    translate: 'generic.video'
                };
                break;
            case 'activity':
                return this.getIconNameAndTitle(type);
                break;
        }

        return iconObj;
    }

    public getIconNameAndTitle(type: string): any {
        let iconObj: any;
        switch (type) {
            case 'APP':
                iconObj = {
                    name: 'view_day',
                    translate: 'activities.questionTypeName.app-appaire'
                };
                break;
            case 'CRT':
                iconObj = {
                    name: 'free_answer',
                    translate: 'activities.questionTypeName.app-short-answer'
                };
                break;
            case 'DRL':
                iconObj = {
                    name: 'free_answer',
                    translate: 'activities.questionTypeName.app-draw-line'
                };
                break;
            case 'IMGI':
                iconObj = {
                    name: 'imgi',
                    translate: 'activities.image_interactive'
                };
                break;
            case 'ORD':
                iconObj = {
                    name: 'view_list',
                    translate: 'activities.questionTypeName.app-ordon'
                };
                break;
            case 'TXTMAT':
                iconObj = {
                    name: 'view_list',
                    translate: 'activities.questionTypeName.app-matching'
                };
                break;
            case 'awareness':
                iconObj = {
                    name: 'view_list',
                    translate: 'activities.questionTypeName.app-awareness'
                };
                break;
            case 'QCM':
                iconObj = {
                    name: 'qcm',
                    translate: 'activities.questionTypeName.app-qcm'
                };
                break;
            case 'QCMU':
                iconObj = {
                    name: 'qcmu',
                    translate: 'activities.questionTypeName.app-qcu'
                };
                break;
            case 'RB':
                iconObj = {
                    name: 'view_stream',
                    translate: 'activities.questionTypeName.app-fill-in-blanks'
                };
                break;
            case 'VF':
                iconObj = {
                    name: 'view_week',
                    translate: 'activities.questionTypeName.app-true-false'
                };
                break;
            case 'EXT':
                iconObj = {
                    name: 'ext',
                    translate: 'activities.questionTypeName.ext'
                };
                break;
            case 'Tool':
                iconObj = {
                    name: 'tools',
                    translate: 'generic.tools'
                };
                break;
            case 'MULTI':
                iconObj = {
                    name: 'MULTI',
                    translate: 'activities.multimedia'
                };
                break;
            case 'MULTIAC':
                iconObj = {
                    name: 'qcmu', // TODO add the missing svg for multiac
                    translate: 'activities.quiz'
                };
                break;
        }

        return iconObj;
    }

    // i did this set Save answer values API
    setSaveAnswer(answers): void {
        this.userSelectedOption = answers.filter(item => item.select);
    }

    public clearLessonState(resetAssignment: boolean): void {
        if (resetAssignment) {
            this.communicationCenter
                .getRoom('assignment')
                .next('current', null);
        }

        this.currentLesson = null;
        this.isLessonPlay = false;
        this.playScreenStatus = 1;
        this.presentArrayElementIndex = 0;
        this.activitiesArray = [];
        this.visitedMediaActivity = [];

        this.activityAnswerResult = [];
        this.userAnswerTempSave = [];
        this.answerAPICalled = [];
        this.lessonsAnswers = {};
        this.localShortAnswers = [];
    }

    public setSelectionMode(backUrl: string = ''): void {
        this.activitiesSelection = backUrl;
    }

    public clearSelection(): void {
        this.selectedActivities = [];
        this.onSelectedResourcesChanged.next({resources: this.selectedActivities, event: null});
    }

    /**
     * user check on uncheck the checkbox in activities list
     * two mode : multiple selection or unique selection
     * @param {DataEntity} activity
     * @param {boolean} keep
     * @param event event of the mat-checkbox
     */
    public toggleActivitySelection(activity: DataEntity, keep?: boolean, event?): void {
        if (this.settings.multiSelectionForActivitiesList) {
            const index = this.selectedActivities.findIndex((element: DataEntity) => element.id === activity.id);
            if (index > -1) {
                if (!keep) {
                    this.selectedActivities.splice(index, 1);
                }
            } else {
                if (keep === undefined || keep) {
                    this.selectedActivities.push(activity);
                }
            }
        } else {
            // unique selection mode, only one activity selected
            this.selectedActivities = event && event.source.checked ? [activity] : [];
        }

        this.onSelectedResourcesChanged.next({resources: this.selectedActivities, event: event});
    }

    public get selectionCount(): number {
        return this.selectedActivities.length;
    }

    /* Handling Activity actions */
    activityActionHandler(testAnswer: boolean, seeSolution: boolean, resetAll: boolean, reviewAnswer: boolean): void {
        const State = {
            testAnswer: testAnswer,
            seeSolution: seeSolution,
            resetAll: resetAll,
            reviewAnswer: reviewAnswer,
        };
        this.activityActionsHandler.next(State);
    }

    /* method get presentArray activity ID */
    getLessonActivityID(): number {
        return this.activitiesArray[this.presentArrayElementIndex] ? this.activitiesArray[this.presentArrayElementIndex].id : null;
    }

    public getAnswerData(activityId: string, contextId: string): Observable<any> {
        const answerObservable = new Observable((observer) => {
            if (this.authenticationService.isLearner()) {
                if (!this.answerAPICalled.includes(this.presentArrayElementIndex) || !this.activitiesArray.length) {
                    this.loadSavedAnswers(activityId, contextId).pipe(
                        takeUntil(this.unsubscribeInTakeUntil))
                        .subscribe(savedAnswers => {
                            if (savedAnswers && savedAnswers[0]) {
                                const totalSave = savedAnswers.length;
                                const answers = (savedAnswers[totalSave - 1] as DataEntity);
                                if (answers.get('userActivity').entitySave) {
                                    const userAnswer = answers.get('userActivity').entitySave.answers;
                                    this.setAnswerTempSave(JSON.stringify(userAnswer));
                                    this.answerAPICalled.push(this.presentArrayElementIndex);
                                    observer.next(userAnswer);
                                    observer.complete();
                                }
                            }
                        });
                } else {
                    const tempAnswer = this.getActivityTempSaveAnswer();
                    if (tempAnswer) {
                        observer.next(tempAnswer);
                    }
                    observer.complete();
                }
            } else {
                const tempAnswer = this.getActivityTempSaveAnswer();
                if (tempAnswer) {
                    observer.next(tempAnswer);
                }
                observer.complete();
            }
        });
        return answerObservable;
    }

    public getUserSave(activityId: string | number, contextId?: string, getAllUserSave = null, step = null): Observable<DataEntity> {
        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }

        return this.loadUserSave(activityId.toString(), contextId, getAllUserSave, step);
    }

    setAnswerTempSave(answer: any): void {
        this.userAnswerTempSave[this.presentArrayElementIndex] = answer;
    }

    getActivityTempSaveAnswer(): any {
        let answer = null;
        if (this.userAnswerTempSave.includes(this.presentArrayElementIndex)) {
            answer = this.userAnswerTempSave[this.presentArrayElementIndex];
            answer = JSON.parse(answer);
        }
        return answer;
    }

    unsetAnswerTempSave(): void {
        this.userAnswerTempSave[this.presentArrayElementIndex] = 0;
        this.activityAnswerResult[this.presentArrayElementIndex] = null;
    }

    resetActivityAnswerData(): ReplaySubject<boolean> {
        const observable = new ReplaySubject<boolean>();

        this.activityAnswerResult = [];
        this.userAnswerTempSave = [];
        this.answerAPICalled = [];

        this.visitedMediaActivity = [];

        if (this.currentAssignmentID) {
            // Reset progress
            {
                this.communicationCenter
                    .getRoom('activities')
                    .getSubject('saveProgress')
                    .next(0);
            }

            const data = new DataEntity('user-save', {}, this.octopusConnect, 'context/' + this.currentAssignmentID);
            this.octopusConnect.deleteEntity(data).pipe(take(1)).subscribe(() => {
                observable.next(true);
            });
        } else {
            // no assignmentID = no user-save
            observable.next(true);
        }

        return observable;
    }

    public createUserSave(activityId: string, contextId: string, answers: string[] | string, state: string, type: string, step: number = null): Observable<DataEntity> {
        const observable = new Subject<DataEntity>();

        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }
        // for genericsave endpoint the name of field to save is content for the other it's answers
        let data: any;
        if (type === 'genericsave') {
            data = {'content': answers};
        } else {
            data = {answers: answers};
        }

        if (type === 'noEndpoint') {
            console.warn('A noEndpoint save should not be sent to the back');
        }

        this.octopusConnect.createEntity(type, data).pipe(take(1),
            mergeMap((save: DataEntity) => {
                return this.octopusConnect.createEntity('user-activity', {entitySave: save.id}).pipe(take(1),
                    mergeMap((userActivity: DataEntity) => {
                        return this.octopusConnect.createEntity('user-save', {
                            granule: activityId,
                            context: contextId,
                            state: state,
                            step: step ? step : this.presentArrayElementIndex,
                            userActivity: userActivity.id,
                            granuleParent: this.currentLesson.id, // save the id of granule lesson parent
                            lesson: this.currentLesson.id // save the id of current lesson
                        }).pipe(take(1));
                    }));
            }))
            .subscribe((userSave: DataEntity) => {
                observable.next(userSave);
            }, (error) => {
                observable.error(error);
            });

        return observable;
    }

    /**
     * Save answer of user
     * @param activityId : id of current activity
     * @param contextId : id of assignation or lessonId(not sure for lessonId...)
     * @param selectedAnswers : answers of user
     * @param status : status of activity validated close incomplete(not finish) correct etc..
     * @param type : type of save 'qcm-save' etc... it's equal to the endpoint name genericSAve etc..
     * @param save : if already have a user save
     * @param step : index og activity in current lesson
     *
     * @remarks refacto a faire pour passer un objet en argument et non un liste d'argument, et avoir un meilleur typage (les valeurs de status, de type, etc.)
     */
    public saveUserSave(
        activityId: string,
        contextId: string,
        selectedAnswers: Array<any> | string,
        status: number,
        type: string,
        save?: DataEntity,
        step: number = null
    ): Observable<DataEntity> {
        let observable: Observable<DataEntity>;
        let state: string;

        this.saving = true;

        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }

        switch (status) {
            case 1:
                state = 'validated';
                break;
            case 2:
                state = 'incomplete';
                break;
            case 3:
                state = 'correct';
                break;
            default:
                state = 'closed';
                break;
        }

        if (!contextId) {
            observable = new ReplaySubject<DataEntity>(1);
            const answers = <any>selectedAnswers;
            const filter = ['rb-save', 'app-save'];
            if (!filter.includes(type)) {
                for (let i = 0; i < answers.length; i++) {
                    if (!answers[i]) {
                        continue;
                    }
                    const _answer = this.localShortAnswers[answers[i]] ? this.localShortAnswers[answers[i]] : answers[i].answer || answers[i];
                    answers[i] = {id: answers[i].id || answers[i], answer: _answer};
                }
            }

            const entity = new DataEntity(type, {
                granule: activityId,
                context: null,
                state: state,
                step: step !== null ? step : this.presentArrayElementIndex,
                userActivity: {
                    entitySave: {
                        answers: answers,
                    }
                },
            });
            if (this.currentLesson) {
                entity.set('lesson', this.currentLesson.id);
            }
            this.lessonsAnswers[step || step === 0 ? activityId + '-' + step.toString() : activityId + '-' + this.presentArrayElementIndex.toString()] = entity;
            (<ReplaySubject<DataEntity>>observable).next(entity);
        } else {
            if (!save) {
                observable = this.createUserSave(activityId, contextId, selectedAnswers, state, type, step);
            } else {
                const entitySaveData = save.get('userActivity').entitySave;
                const entitySave = new DataEntity(type, entitySaveData, this.octopusConnect);
                observable = new ReplaySubject<DataEntity>(1);

                // for genericsave endpoint the name of field to save is content for the other it's answers
                if (type === 'genericsave') {
                    entitySave.set('content', selectedAnswers);
                } else {
                    entitySave.set('answers', selectedAnswers);
                }

                // entitySave.set('answers', selectedAnswers);
                entitySave.save().pipe(
                    take(1))
                    .subscribe((saveData) => {
                        save.set('state', state);
                        // force update change prop if usersave content edited
                        save.set('changed', Math.round(new Date().getTime() / 1000));
                        save.save(true).pipe(
                            take(1))
                            .subscribe((userSave) => {
                                (<ReplaySubject<DataEntity>>observable).next(userSave);
                            }, (error) => {
                                (<ReplaySubject<DataEntity>>observable).error(error);
                            });
                    });
            }
        }

        observable.subscribe((userSave: DataEntity) => {
            this.saving = false;
            this.userAnswerOnChange.next(userSave);
        }, (error) => {
            this.saving = false;
            this.userAnswerOnChange.error(error);
        });

        return observable;
    }

    public saveUserAnswer(id: string, content: string, answerStatus: any, contextId: string, endPoint: string, answerId: string): Observable<any> {
        if (!answerId) {
            answerId = content;
        }
        if (!contextId && this.currentAssignmentID) {
            contextId = this.currentAssignmentID;
        }

        if (!contextId) {
            return new Observable();
        }

        return new Observable((observer) => {
            if (this.authenticationService.isLearner()) {
                this.saveAnswer({answer: content, correct_answer: answerStatus}, 'answer')
                    .pipe(take(1))
                    .subscribe(dataEntity => {
                        const userAnswer = dataEntity;
                        this.setAnswerTempSave(userAnswer);
                        this.createSavedAnswers(id, contextId, answerId, endPoint);
                        observer.next(userAnswer);
                    });
            }
            this.userAnswerOnChange.next(this.getActivityTempSaveAnswer());
            observer.complete();
        });
    }

    /* change activity button action */
    public userActionButtonState(showAnswers: boolean, withoutAnyUserResponse: boolean, reinitializeOptions: boolean): void {
        const finalObj = {
            showAnswers: showAnswers,
            withoutAnyUserResponse: withoutAnyUserResponse,
            reinitializeOptions: reinitializeOptions
        };
        this.checkAnswers.next(finalObj);
    }

    /**   Chapter load   **/

    // TODO mutualiser avec getChapters() et exporter ça dans un service associé
    getMethods(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('chapters', {parent: 'null'});
    }

    // TODO mutualiser avec getMethods() et exporter ça dans un service associé
    getChapters(methodId): void {
        this.octopusConnect.loadCollection('chapters', {parent: methodId}).pipe(take(1)).subscribe(data => {
            this.chapters = [];
            for (const entity of data.entities) {
                this.chapters.push({
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                    parent: entity.get('parent')[0]
                });
            }
            this.chaptersChanged.next(this.chapters);
        });
    }

    /*******    Tags    *******/
    getTags(type): void {
        this.octopusConnect.loadCollection(type).pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(data => {
            this.tags = [];
            for (const entity of data.entities) {
                this.tags.push({
                    id: entity.id,
                    label: entity.get('label'),
                    name: entity.get('name'),
                });
            }
            this.tagsChanged.next(this.tags);
        });
    }

    private postLogout(): void {
        this.resetActivitiesInCache();
        if (this.chaptersSubscription) {
            this.unsubscribeAll();
        }
    }

    unsubscribeAll(): void {
        this.chaptersSubscription.unsubscribe();
    }

    /**
     * Save a Resource granule as part of activity's content.
     * @param activity should be a 'media' activity type but no controls are done before save
     * todo: for function call in multimedia editor, replace prop activity reference by activity_content entity
     * @param resource Granule resource should be provided by Corpus
     */
    public addResourceToActivity(activity: DataEntity, resource: DataEntity): Observable<DataEntity> {
        let resources;
        if (activity.type === 'media') {
            resources = activity.get('granule') ? activity.get('granule').slice() : [];
        } else {
            resources = activity.get('activity_content') ? activity.get('activity_content')[0]['granule'].slice() : [];
        }
        if (resource.get('format').label === 'text') {
            if (!resources.find((res) => +res.id === resource.id)) {
                resources.push(resource);
            }
        } else {
            resources = [resource, ...resources.filter((res) => +res.id !== +resource.id && res.format.label === 'text')];
        }
        console.log('activities.service', resources);
        return this.replaceCorpusResourcesOfActivity(activity, resources.map((res) => +res.id));
    }

    /**
     * Remove a Corpus Resource granule from the list of activity's content.
     * @param activity should be a 'media' activity type but no controls are done before save
     * @param resource Granule resource to remove
     */
    public removeCorpusResourceFromActivity(activity: DataEntity, resource: DataEntity): Observable<DataEntity> {
        return this.replaceCorpusResourcesOfActivity(activity, activity.get('activity_content')[0]['granule'].map(g => +g.id).filter(id => +id !== +resource.id));
    }

    /**
     * Save a Corpus Resource granule list as activity's content.
     *
     * @remarks
     * The resources will erased the already existed resources in the activity content
     *
     * @param activity should be a 'media' activity type but no controls are done before save.
     * @param resourceIds Id list of resources initially provided by Corpus
     */
    public replaceCorpusResourcesOfActivity(activity: DataEntity, resourceIds: number[]): Observable<DataEntity> {
        let entity$: Observable<DataEntity>;
        if (activity.type === 'media') {
            entity$ = this.loadGenericEntity('media', activity.id);
        } else {
            entity$ = this.loadGenericEntity('media', activity.get('activity_content')[0].id);
        }

        return entity$.pipe(
            take(1),
            mergeMap((mediaEntity: DataEntity) => {
                mediaEntity.set('granule', resourceIds);
                return mediaEntity.save(true);
            })
        );
    }

    /**
     * load the next lesson in multiassignement case
     */
    public loadNextLesson(): void {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }
        const lessons = this.currentAssignment.get('assignated_nodes');
        const nextIndex = this.getIndexOfCurrentLesson(lessons) + 1;
        if (nextIndex < lessons.length) {
            const route = this.lessonRoutePath(this.router.url, lessons[nextIndex].id);
            this.router.navigate(route, {relativeTo: currentRoute});
        }
    }

    /**
     * return the array to navigate to lesson
     * @param url : current url
     * @param id : id of lesson where to go
     */
    private lessonRoutePath(url: string, id: string): string[] {
        const urlEnd = url.split('lessons')[1];
        const countOccurence = urlEnd.split('/').length - 1; // -1 : string begin with '/' => first item is empty array
        let path = '';
        for (let i = 0; i < countOccurence; i++) {
            path = path + '../';
        }
        let route = [path];
        route.push(id);
        return route;
    }

    /**
     * return index of current lesson in regard of the array of lessons in assignements
     * @param lessons current lesson
     */
    public getIndexOfCurrentLesson(lessons): number {
        const idCurrentLesson = this.currentLesson.id;
        const index = lessons.findIndex(lesson => lesson.id === idCurrentLesson);
        return index;
    }

    public findActivityAlreadyLoaded(stepId: any, subLesson?: boolean): Observable<DataEntity> {
        // if observable granule activity already loaded, need to "next" a new replaySubject with the already loaded activity
        const activities = subLesson ? this.activitiesInSublessonAlreadyLoaded : this.activitiesArray;
        const activityAlreadyLoaded = activities.find((activity) => +activity.id === +stepId.id);
        if (!!activityAlreadyLoaded) {
            /*            const observable = new ReplaySubject<DataEntity>();
                        observable.next(activityAlreadyLoaded);
                        return observable;*/
            return observableOf(activityAlreadyLoaded);
        } else {
            return this.loadActivitiesFromId(stepId.id);
        }
    }

    public batchEditInstruction(granuleActivities: DataEntity[], stepContent): Observable<DataEntity>[] {
        /**
         * granuleActivities: contain the activities of the sub lesson
         *filteredActivities: for now we modify on  the sublesson wich contain qcmu or summary activity*/
        for (const activityLoaded of granuleActivities) {
            const activityInSubLesson = this.activitiesInSublessonAlreadyLoaded
                .find((act) => +act.id === +activityLoaded.id);
            if (!activityInSubLesson) {
                this.activitiesInSublessonAlreadyLoaded.push(activityLoaded);
            }
        }
        const filteredActivities = granuleActivities.filter((granule: DataEntity) => granule.get('metadatas').typology &&
            granule.get('metadatas').typology.label === 'QCMU' || granule.get('metadatas').typology.label === 'summary');
        // set the instruction of each activities
        return filteredActivities.map((granule) => {
            const refEntity = new DataEntity('activity', granule.get('reference'), this.octopusConnect, granule.get('reference').id);
            refEntity.set('wording', stepContent.instruction);
            return refEntity.save();
        });
    }

    /**
     *
     * each activity of the current lesson and the activities of a sub-course (if the activity is a sub-course)
     * are modified with the title and the instruction that the user has modified. if he has not modified it,
     * the activity will keep its title and its instructions
     * @param {DataEntity} entity
     * @returns {Observable<any>}
     */
    public editSubLessonContent(entity: DataEntity): Observable<DataEntity[]> {
        /**
         *  get the activities of the current lesson saved (activities were duplicated so we need to fetch the new activities)
         */
        const obsSteps: Observable<DataEntity>[] = entity.get('lesson_step')
            .map((stepId) => {
                return this.findActivityAlreadyLoaded(stepId, true);
            });

        return combineLatest(obsSteps)
            .pipe(
                mergeMap((steps) => {
                    // steps: contain the activities of the current lesson
                    const entitiesList = steps.map((step, index) => {
                        // stepContent: fetch the right object wich contain title and instruction
                        const stepContent = this.metadatasUsedForOverride[index];

                        // if step is a sub-lesson, need to fetch all activities
                        if (step.get('format').label === 'lesson') {
                            const obsSubSteps: Observable<DataEntity>[] = step.get('reference').map((stepId) => {
                                return this.findActivityAlreadyLoaded(stepId, true);
                            });

                            return combineLatest(obsSubSteps).pipe(
                                mergeMap((granuleActivities) => combineLatest(this.batchEditInstruction(granuleActivities, stepContent))),
                                mapTo(step),
                            );
                        } else if (step.get('metadatas').typology.label === 'Tool') {
                            // Do nothing, there are a specific screen to do this
                        } else { // It is an activity so edit the activity reference with the new instruction
                            const refEntity = new DataEntity('activity', step.get('reference'), this.octopusConnect, step.get('reference').id);
                            // if type of activity is QCMU or Summary, we edit the wording field
                            if (step.get('metadatas').typology.label === 'QCMU' || step.get('metadatas').typology.label === 'summary') {
                                refEntity.set('wording', stepContent.instruction);
                            } else {
                                refEntity.set('instruction', stepContent.instruction);
                            }
                            refEntity.save();
                            return observableOf(step);
                        }
                    });

                    return combineLatest(entitiesList).pipe(
                        mergeMap((entities, index) => combineLatest(entities.map(
                            (step: DataEntity) => this.editMetadatas({title: this.metadatasUsedForOverride[index].title}, step).pipe(
                                tap(() => this.activitiesArray[index] = step),
                                mapTo(step)
                            )
                        )))
                    );
                })
            );
    }

    public loadActivityInterface(id: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('activity', id).pipe(take(1));
    }

    /**
     * edit the metadatas of a granule
     * @param data is an object with the name of metadatas field as key.
     * @param activityEntity
     * @returns {Observable<DataEntity>}
     */
    public editMetadatas(data, activityEntity: DataEntity): Observable<DataEntity> {
        const metadatas = new DataEntity('metadatas', activityEntity.get('metadatas'), this.octopusConnect, activityEntity.get('metadatas').id);
        for (const field in data) {
            metadatas.set(field, data[field]);
        }
        return metadatas.save().pipe(tap(updatedMetadatas => activityEntity.set('metadatas', updatedMetadatas)));
    }

    /**
     * process array of title and instruction when the current lesson is edited
     * to keep the array of title and instruction up to date
     * @param orderContent
     * @param {boolean} init
     */
    public overrideActivityMetadatas(orderContent, init?: boolean): void {
        if (init && !this.metadatasUsedForOverride.length) {
            this.metadatasUsedForOverride = orderContent.map((step) => {
                return {
                    id: step.id,
                    idLesson: this.currentLesson ? this.currentLesson.id : null,
                    title: step.get('metadatas') ? step.get('metadatas').title : '',
                    instruction: step.get('format').label === 'activity' ? step.get('reference').instruction : ''
                };
            });
        }
        const compareArray = [];
        for (const activity of orderContent) {
            const step = this.metadatasUsedForOverride.find((act) => +act.id === +activity.id);
            if (!!step) {
                compareArray.push(step);
            }
        }
        this.metadatasUsedForOverride = compareArray.slice();
    }

    /**
     * open modal for edit title and instruction
     * @param {DataEntity} entity
     */
    public openEditStepComponent(entity: DataEntity): Observable<MatDialogRef<EditStepComponent>> {
        let obs: Observable<string>;
        if (entity.get('metadatas') && entity.get('metadatas').typology === 'tool') {
            obs = this.loadActivityInterface(entity.get('reference')).pipe(
                map(reference => reference.get('instruction'))
            );
        } else {
            obs = observableOf(entity.get('format').label !== 'lesson' ? entity.get('reference').instruction : '');
        }

        return obs.pipe(
            map(instruction => this.dialog.open(EditStepComponent, {
                data: {
                    id: entity.id,
                    title: entity.get('metadatas') ? entity.get('metadatas').title : '',
                    instruction: instruction
                }
            }))
        );
    }

    public loadActivityFormats(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('granule-format').pipe(map(collection => collection.entities));
    }

    /**
     * get activities with typology filtered by typology selected
     * @param {string} type
     * @returns {Observable<DataEntity>}
     */
    public loadActivitiesWithFilteredByTypology(useLessonSearchEndpoint?: boolean, optionsInterface?: CollectionOptionsInterface):
        Observable<{ entities: DataEntity[], paginator: CollectionPaginator }> {
        const filterOptions: any = {
            filter: {..._.cloneDeep({activitiesNoClone: this.settings.activitiesNoClone}), ..._.cloneDeep(optionsInterface.filter)},
            page: 1,
            range: 10
        };
        return this.loadPaginatedActivities(filterOptions, useLessonSearchEndpoint);
    }

    /**
     * show modal with pre filtered activities by type
     * @returns {Observable<any>}
     * TODO: déplacer cette fonction d'ouverture de modal dans un service indépendant.
     */
    public showSelectActivitiesModal(typologiesIds: number[],
                                     useLessonSearchEndpoint?: boolean,
                                     options?: Partial<{
                                         initialValues: { [key: string]: any };
                                         editableLesson: EditableLesson;
                                         keepLastFilters: Subject<{ [key: string]: any }>
                                     }>): Observable<DataEntity> {
        const dataAndColumn: Partial<EditorActivitiesListDialogData> = {
            title: '',
            typologiesIds,
            useLessonSearchEndpoint,
            settings: this.settings,
            getIconInformation: (data: DataEntity | string) => this.getIconInformation(data),
            loadMethods: () => this.getMethods(),
            launchPreview: (id: number | string) => this.internalLaunchPreview(id),
            loadActivities: (optionsInterface?: CollectionOptionsInterface) => {
                if (!!options && !!options.keepLastFilters) {
                    options.keepLastFilters.next(optionsInterface.filter);
                }
                return this.loadActivitiesWithFilteredByTypology(useLessonSearchEndpoint, optionsInterface);
            },
            onPaginateChange: (pageEvent: { [key: string]: any }) => this.onPaginateChange(pageEvent),
            initialValues: {}
        };
        if (typologiesIds.length > 1 || !typologiesIds.length) {
            dataAndColumn.title = 'activities.activities_list_title_default';
        } else {
            const typology = this.allTypes.find((activityType) => +activityType.id === typologiesIds[0]);
            dataAndColumn.title = 'activities.activities_list_title_' + typology.label.toLowerCase();
        }

        if (!!options && !!options.initialValues && !!options.editableLesson) {
            try {
                Object.keys(options.initialValues)
                    .filter(field => (this.settings.keepFiltersEditorActivitiesList || []).includes(field))
                    .forEach(field => {
                        dataAndColumn.initialValues[FORM_CONTROL_MAPPING.find(c => c.endpointFilter === field).formControlField] = options.initialValues[field];
                    });
            } catch (e) {
                console.error('cannot set initials values for this activities');
            }
        }

        /* get activity from the "granule" endpoint
        because we need fields that do not exist in the "granule_search" endpoint*/
        return this.dialog.open(EditorActivitiesListComponent, {data: dataAndColumn})
            .beforeClosed()
            .pipe(
                mergeMap((activity: DataEntity) => activity ? this.loadActivitiesFromId(activity.id) : of(null))
            );
    }


    /**
     * open modal and start activity (preview)
     * @param entity
     * @param activityDoesNotYetExist used for preview activities ghost (does not yet exist)
     * cant load them from drupal...
     * @param row
     */
    public internalLaunchPreview(activityId: number | string,
                                 activityDoesNotYetExist?: DataEntity[],
                                 dataToEditActivities?: { title: string, instruction: string },
                                 activityTitle: string = ''): Observable<void> {
        const data = {
            activityId: activityId,
            activityTitle: activityTitle,
            isLoadBeforeLaunch: true,
            preview: true,
            onLatexKeyboardDisplayChange: this.onLatexKeyboardDisplayChange,
            settings: this.settings,
            loadGranuleActivity: (granuleId: number | string) => this.loadActivitiesFromId(granuleId),
            loadGranulesActivities: (granule: DataEntity) => this.loadGranulesActivities(granule),
            clearLessonState: (resetAssignment: boolean) => this.clearLessonState(resetAssignment)
        };
        if (activityDoesNotYetExist) {
            data['activityDoesNotYetExist'] = activityDoesNotYetExist;
        }
        if (dataToEditActivities) {
            data['dataToEditActivities'] = dataToEditActivities;
        }

        return this.dialog.open(DialogComponent, {
            panelClass: 'activities-list-dialog',
            width: '90%',
            height: '100%',
            data: data
        }).beforeClosed();
    }

    /**
     * load granules activvities and set current activities
     * TODO refacto old editor and player to not store  activities in service
     * @param {DataEntity} granule
     * @returns {Observable<DataEntity[]>}
     */
    public loadGranulesActivities(granule: DataEntity): Observable<DataEntity[]> {
        return this.getActivitiesFromMultiActivityGranule(granule).pipe(
            map((activities: DataEntity[]) => {
                this.setCurrentActivities(activities);
                return activities;
            })
        );
    }

    /**
     * get all granule activities from a granule sub-lesson
     * @param {DataEntity} granule
     * @returns {Observable<DataEntity[]>}
     */
    private getActivitiesFromMultiActivityGranule(granule: DataEntity): Observable<DataEntity[]> {
        return combineLatest<DataEntity[]>(granule.get('reference').map((ref) => this.loadActivitiesFromId(ref.id)));
    }

    /**
     *
     allows you to retrieve the translated name and title of the icon.
     can either receive a dataEntity corresponding to an activity or simply the type of the activity as a string
     * @param data
     * @param dataIsNotAnActivity
     * @returns {object}
     */
    public getIconInformation(data: DataEntity | string): { [key: string]: string } {
        if (typeof data === 'string') {
            return this.getIconNameAndTitle(data);
        }
        return this.getContentTypeIcon(data, 'activity');
    }

    /**
     * create or edit sublesson with their activities.
     * @param {{[p: string]: string}} data
     * @param {DataEntity} activity
     * @param duplicate
     * @returns {Observable<DataEntity>}
     */
    public saveSubLessonActivities(data: { [key: string]: string }, activity: DataEntity, duplicated): Observable<DataEntity> {
        const obsActivities: Observable<DataEntity>[] = activity.get('reference')
            .map((ref: { id: string, type: string }) => this.loadActivitiesFromId(ref.id).pipe(take(1)));

        return combineLatest(obsActivities).pipe(
            mergeMap((granuleActivities) => {
                return combineLatest(granuleActivities
                    .map((originalActivity) => {
                        if (originalActivity.get('format').label === 'activity') {
                            return this.saveGranuleActivity(data, originalActivity, duplicated);
                        }
                        return this.saveGranuleSubLesson(data, originalActivity.get('lesson_id'), originalActivity, duplicated);
                    })
                );
            }),
            mergeMap((newActivities: any) => {
                if (!duplicated && this.settings.saveLessonContentOptions.activityTypesCanBeDuplicate.includes(this.getActivityType(activity))) {
                    const lessonRefData = {
                        lesson_step: newActivities.map((act) => act.id)
                    };
                    const summaryActivity: DataEntity = newActivities.find((act) => act.get('metadatas').typology.label === 'summary');
                    if (summaryActivity) {
                        return this.setRefOfNewSummaryActivity(summaryActivity, newActivities).pipe(
                            mergeMap(() => {
                                return this.createEntitySkeleton('lesson', lessonRefData).pipe(take(1));
                            })
                        );
                    } else {
                        return this.createEntitySkeleton('lesson', lessonRefData).pipe(take(1));
                    }
                } else {
                    return this.loadGenericEntity('lesson', activity.get('lesson_id'));
                }
            }),
        ).pipe(
            mergeMap((lessonEntity: DataEntity) => {
                return this.saveGranuleSubLesson(data, lessonEntity.id, activity, duplicated, true);
            })
        );
    }

    private setRefOfNewSummaryActivity(summaryActivity: DataEntity, newActivities: DataEntity[]): Observable<DataEntity> {
        return this.createEntitySkeleton('summary', {
            granule: newActivities.filter((act) => +act.id !== +summaryActivity.id).map((act) => +act.id)
        }).pipe(
            take(1),
            mergeMap((summaryActivityContent) => {
                return this.loadGenericEntity('activity', summaryActivity.get('reference').id)
                    .pipe(
                        take(1),
                        mergeMap((reference: DataEntity) => {
                            reference.set('activity_content_patch', summaryActivityContent.id);
                            return reference.save(true);
                        })
                    );
            })
        );
    }

    /**
     * create the granule lesson (sublesson) with the lesson reference, metadatas.
     * @param {{[p: string]: string}} data
     * @param {number | string} lessonId
     * @param {DataEntity} activity
     * @param {boolean} duplicate
     * @param {boolean} changeTitleIfSublessonIsStep
     * @returns {Observable<DataEntity>}
     */
    public saveGranuleSubLesson(titleAndInstruction: { [key: string]: string },
                                lessonId: number | string,
                                activity: DataEntity,
                                duplicated: boolean,
                                changeTitleIfSublessonIsStep?: boolean): Observable<DataEntity> {
        if (duplicated) {
            return this.generateDataEntitySublessonAndSave(titleAndInstruction,
                activity,
                changeTitleIfSublessonIsStep);
        } else {
            const activity$ = this.createEntitySkeleton('granule', {reference: lessonId, format: this.getFormatId('lesson')});
            const metadatas$ = this.createEntitySkeleton('metadatas', {title: ''});

            return combineLatest([activity$, metadatas$])
                .pipe(
                    mergeMap((entities: [DataEntity, DataEntity]) => {
                        return this.generateDataEntitySublessonAndSave(titleAndInstruction,
                            activity,
                            changeTitleIfSublessonIsStep,
                            entities[0],
                            entities[1]);
                    })
                );
        }
    }

    /**
     * generate dataentity of sublesson, metadatas  then edit granule sublesson
     * @param titleAndInstruction
     * @param dataFromActivity
     * @param changeTitleIfSublessonIsStep
     * @param newActivity
     * @param newMetadatas
     * @returns {Observable<DataEntity>}
     */
    private generateDataEntitySublessonAndSave(titleAndInstruction,
                                               dataFromActivity,
                                               changeTitleIfSublessonIsStep,
                                               newActivity?,
                                               newMetadatas?): Observable<DataEntity> {
        return this.loadGenericEntity('metadatas', dataFromActivity.get('metadatas').id)
            .pipe(
                mergeMap((metadatas: DataEntity) => {
                    if (newMetadatas) {
                        newMetadatas.set('typology', metadatas.get('typology').id);
                    }
                    /**
                     * todo: watch out if case sublesson in sublesson exist :
                     * all the (child)sublesson in (parent)sublesson will have the same title as the (parent)sublesson.
                     */
                    if (changeTitleIfSublessonIsStep) {
                        newMetadatas ?
                            newMetadatas.set('title', titleAndInstruction.title) : metadatas.set('title', titleAndInstruction.title);
                    }
                    return this.saveSubLesson(newActivity ? newActivity : dataFromActivity, newMetadatas ? newMetadatas : metadatas, newActivity ? dataFromActivity : null);
                })
            );


    }

    /**
     * save granule activity edited with new instruction and title
     * @param {{[p: string]: string}} titleAndInstruction
     * @param {DataEntity} activity
     * @param {DataEntity} subLessonParent if activity is in sublesson, provide sublesson entity
     * @param {boolean} duplicate
     * @returns {Observable<DataEntity>}
     */
    public saveGranuleActivity(titleAndInstruction: { [key: string]: string }, activity: DataEntity, duplicated?: boolean): Observable<DataEntity> {
        // if !!duplicate the  activity is an activity not duplicated. need to duplicate it
        if (!duplicated && this.settings.saveLessonContentOptions.activityTypesCanBeDuplicate.includes(this.getActivityType(activity))) {
            return this.duplicateGranuleActivity(titleAndInstruction, activity);
        } else {
            return this.generateDataEntityActivityAndSave(titleAndInstruction, activity);
        }
    }

    /**
     * Create an activity `Tool` used for display a custom instruction relative to a tool plugin
     * @param pluginSettings
     * @param options
     */
    public createToolActivity(activityType: string, options: GenericActivityOptionsInterface): Observable<DataEntity> {
        return this.createGenericActivity({label: activityType}, options);
    }

    /**
     * Create an activity with the good typology but with potentially no activity_content
     * @param typology should be the typology object or an object with this format {label: string}
     * @param options List of defaults values
     *
     * @remarks Be aware that no activityContent will not work, if you not give an activity content id, you have to add it after.
     */
    public createGenericActivity(typology: { label: string }, options: GenericActivityOptionsInterface = {}): Observable<DataEntity> {
        const requiredMetadatasValues = {
            typology: this.getTypologyId(typology.label),
            title: '',
            language: this.translate.currentLang
        };

        const requiredActivityInterfaceValues = {
            instruction: '',
            activity_content: null,
            config: null
        };

        const metadataObs = this.createGranuleMetadata(_.merge(requiredMetadatasValues, _.get(options, 'metadatas')));
        const activityInterfaceObs = this.createActivitiesActivityInterface(_.merge(requiredActivityInterfaceValues, _.get(options, 'activity')));

        return combineLatest([metadataObs, activityInterfaceObs]).pipe(
            mergeMap(([metadatas, activityInterface]) => {
                return this.createGranuleInterface({
                    format: this.getFormatId('activity'),
                    metadatas: +metadatas.id,
                    reference: +activityInterface.id
                });
            })
        );
    }

    public getFormatId(format: string): number {
        const entityActivityFormat = this.activityFormats.find((formatEntity) => formatEntity.get('label') === format);
        return entityActivityFormat ? +entityActivityFormat.id : null;
    }

    public getTypologyId(typology: string): number {
        const typeSelected = this.allTypes.find((type) => type.label.toLowerCase() === typology.toLowerCase());
        return typeSelected ? +typeSelected.id : 0;
    }

    public createActivitiesActivityInterface(Activity: ActivitiesActivityInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('activity', Activity).pipe(take(1));
    }

    public createGranuleInterface(Granule: GranuleInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('granule', Granule).pipe(take(1));
    }

    /**
     * Create metadata values of one granule
     * @param metadata : metadata values
     * TODO not really good to remove tagModified & theme here
     */
    public createGranuleMetadata(metadata: MetadataInterface): Observable<DataEntity> {
        delete metadata['tagModified']; // remove tagModified before metadata save
        const metadataTemp = {...metadata};
        delete metadataTemp['theme']; // remove theme before metadata save
        return this.octopusConnect.createEntity('metadatas', metadataTemp).pipe(take(1));
    }

    private duplicateGranuleActivity(titleAndInstruction: { [p: string]: string }, activity: DataEntity): Observable<DataEntity> {
        return this.createEntitySkeleton('granule', {title: ''})
            .pipe(
                take(1),
                mergeMap((activityEntity: DataEntity) => {
                    const newActivityEntity: DataEntity = this.generateDataEntity(activity.attributes,
                        'granule',
                        activityEntity.id
                    );
                    return this.generateDataEntityActivityAndSave(titleAndInstruction, activity, newActivityEntity);
                })
            );
    }

    /**
     * generate dataentity of activity, metadatas and reference then edit granule activity
     * @param titleAndInstruction
     * @param dataFromActivity
     * @param newActivity
     * @param newMetadatas
     * @param newReference
     * @returns {Observable<DataEntity>}
     */
    private generateDataEntityActivityAndSave(titleAndInstruction, dataFromActivity, newActivity?): Observable<DataEntity> {
        const idReference = typeof dataFromActivity.get('reference') === 'string' ? dataFromActivity.get('reference') : dataFromActivity.get('reference').id;
        const reference = this.generateDataEntity(dataFromActivity.get('reference'), 'activity', idReference);
        const metadatas = this.generateDataEntity(dataFromActivity.get('metadatas'), 'metadatas', dataFromActivity.get('metadatas').id);
        if (newActivity) {
            const metadatas$: Observable<DataEntity> = this.createEntitySkeleton('metadatas', {
                title: titleAndInstruction.title,
                typology: metadatas.get('typology').id
            });

            const reference$: Observable<DataEntity> = this.createEntitySkeleton('activity', {
                instruction: dataFromActivity.get('reference').instruction,
                activity_content: this.getActivityContent(reference),
                config: dataFromActivity.get('reference').config
            });

            return combineLatest([metadatas$, reference$]).pipe(
                mergeMap(([newMetadatas, newReference]: [DataEntity, DataEntity]) => {
                    newReference.set(this.getActivityType(dataFromActivity) === 'QCMU' ? 'wording' : 'instruction', titleAndInstruction.instruction);
                    return this.saveActivity(newActivity, [newMetadatas.save(true), newReference.save(true)], +dataFromActivity.id);
                })
            );
        } else {
            metadatas.set('title', titleAndInstruction.title);
            if (titleAndInstruction.instruction && titleAndInstruction.instruction !== '') {
                reference.set(this.getActivityType(dataFromActivity) === 'QCMU' ? 'wording' : 'instruction', titleAndInstruction.instruction);
            }
            return this.saveActivity(dataFromActivity, [metadatas.save(true), reference.save(true)]);
        }
    }

    private getActivityContent(reference): number {
        if (!reference.get('activity_content') && reference.get('activity_content_patch')) {
            return +reference.get('activity_content_patch');
        } else {
            if (reference.get('activity_content') && reference.get('activity_content')[0]) {
                return +reference.get('activity_content')[0].id;
            } else {
                if (reference.get('activity_content') && typeof reference.get('activity_content') !== 'string') {
                    return +reference.get('activity_content').id;
                }
            }
        }
    }

    /**
     * save the DataEntity of type Lesson with their metadata
     * @param {DataEntity} activityEntity
     * @param {DataEntity[]} metadatasEntity
     * @returns {Observable<DataEntity>}
     */
    private saveSubLesson(activityEntity: DataEntity, metadatasEntity: DataEntity, originalActivity?: DataEntity): Observable<DataEntity> {
        return metadatasEntity.save(true).pipe(
            mergeMap((metadatas: DataEntity) => {
                activityEntity.set('metadatas', metadatas.id);
                if (originalActivity && originalActivity.id !== activityEntity.id) {
                    activityEntity.set('original', originalActivity.id);
                }
                return activityEntity.save(true);
            })
        );
    }

    /**
     * save DataEntity of type activity with their metadata and reference
     * @param {DataEntity} activityEntity
     * @param {Observable<DataEntity>[]} metadatasAndReferenceObs
     * @returns {Observable<DataEntity>}
     */
    private saveActivity(activityEntity: DataEntity, metadatasAndReferenceObs: Observable<DataEntity>[], originalActivity?: number): Observable<DataEntity> {
        return combineLatest(metadatasAndReferenceObs)
            .pipe(
                mergeMap(([metadatas, reference]: [DataEntity, DataEntity]) => {
                    activityEntity.set('format', +activityEntity.get('format').id);
                    activityEntity.set('metadatas', metadatas.id);
                    activityEntity.set('reference', reference.id);
                    if (originalActivity && +originalActivity !== +activityEntity.id) {
                        activityEntity.set('original', originalActivity);
                    }
                    return activityEntity.save(true);
                })
            );
    }

    /**
     * generate "new" or "edit" DataEntity easily...
     * @param {EntityDataSet} attributes
     * @param {string} endpoint
     * @param id
     * @returns {DataEntity}
     */
    public generateDataEntity(attributes: EntityDataSet, endpoint: string, id?): DataEntity {
        if (!!id) {
            return new DataEntity(
                endpoint,
                attributes,
                this.octopusConnect,
                id
            );
        } else {
            return new DataEntity(
                endpoint,
                attributes,
                this.octopusConnect
            );
        }
    }

    /**
     * create entity with any endpoint
     * @param endpoint
     * @param {{[p: string]: any}} data
     * @returns {Observable<DataEntity>}
     */
    private createEntitySkeleton(endpoint, data: { [key: string]: any }): Observable<DataEntity> {
        return this.octopusConnect.createEntity(endpoint, data).pipe(take(1));
    }

    public isMultimediaActivity(activity: DataEntity): boolean {
        return this.getActivityType(activity) === 'MULTI';
    }

    public isToolActivity(activity: DataEntity): boolean {
        return this.getActivityType(activity) === 'Tool';
    }

    /**
     * get activity type from the metadatas
     * @param {DataEntity} activity
     * @returns {string}
     */
    public getActivityType(activity: DataEntity): string {
        try {
            return activity.get('metadatas').typology.label;
        } catch (e) {
            return undefined;
        }
    }

    /**
     * create entity granule of type multimedia with metadatas,
     * activity's interface(reference) and activity content
     * @param {GenericActivityOptionsInterface} options
     * @returns {Observable<DataEntity>}
     */
    public createMultimediaActivity(options: GenericActivityOptionsInterface): Observable<DataEntity> {
        return this.createMedia({
            granule: []
        }).pipe(
            mergeMap((mediaEntity: DataEntity) => {
                const newOptions = _.merge({}, options, {activity: {activity_content: +mediaEntity.id}});
                return this.createGenericActivity({label: 'MULTI'}, newOptions);
            })
        );
    }

    /**
     * create activity content for activity multimedia
     * @param {MediaInterface} granule
     * @returns {Observable<DataEntity>}
     */
    public createMedia(granule: MediaInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('media', granule).pipe(take(1));
    }

    /**
     * create  resource media text and associate resource to activity multimedia
     * @param {DataEntity} activityContent
     * @param {{text: string}} data
     * @returns {Observable<DataEntity>}
     */
    public createResourceTextAndAddToActivity(activityContent: DataEntity, data: { text: string }): Observable<DataEntity> {
        return combineLatest([
            this.createEntitySkeleton('corpus-text-ressource', data),
            this.createEntitySkeleton('metadatas', {title: 'resource text'})
        ]).pipe(
            take(1),
            mergeMap((entities: [DataEntity, DataEntity]) => {
                return this.createEntitySkeleton('granule', {
                    parent: null,
                    format: this.getFormatId('text'),
                    reference: +entities[0].id,
                    metadatas: +entities[1].id,
                });
            }),
        );
    }

    /**
     * create or edit old resource if resource format is text or set activity content with resource
     * @param resource
     * @param activityContent
     * @param resourcesToEdit
     */
    public setMultimediaPageInGranule(resource, activityContent, resourcesToEdit = []): Observable<DataEntity> {
        const oldResourceText = resourcesToEdit.filter((res) => !!res).find((res) => res.format && res.format.label === 'text');
        if (resource.type === 'text' && typeof resource.media === 'string') {
            if (oldResourceText) {
                const existingReferenceText = this.generateDataEntity(oldResourceText.reference, 'corpus-text-ressource', oldResourceText.reference.id);
                if (typeof resource.media === 'string') {
                    existingReferenceText.set('text', resource.media);
                } else {
                    existingReferenceText.set('text', resource.media.get('reference').text);
                }
                return existingReferenceText.save(true).pipe(
                    mergeMap((ref: DataEntity) => of(oldResourceText))
                );
            } else {
                return this.createResourceTextAndAddToActivity(activityContent, {text: resource.media}).pipe(take(1));
            }
        } else {
            const resourceEntity = this.generateDataEntity(resource.media.attributes, 'granule', resource.media.id);
            return of(resourceEntity);
        }
    }

    /**
     * associate resource media to activity multimedia (set activity content)
     * @param media
     * @param {DataEntity} activityContent
     * @returns {Observable<DataEntity>}
     */
    public associateMediaToActivity(allMediaInPage: MultimediaPage, activityContent: DataEntity, resourcesToEdit?: any[]): Observable<DataEntity> {
        const resourcesObs: Observable<DataEntity>[] = [];
        if (allMediaInPage.first) {
            resourcesObs.push(this.setMultimediaPageInGranule(allMediaInPage.first, activityContent, resourcesToEdit));
        }
        if (allMediaInPage.second) {
            resourcesObs.push(this.setMultimediaPageInGranule(allMediaInPage.second, activityContent, resourcesToEdit));
        }
        return combineLatest(resourcesObs).pipe(
            mergeMap((entities: DataEntity[]) => this.replaceCorpusResourcesOfActivity(activityContent, entities.map((res) => +res.id)))
        );
    }

    /**
     * create entity lesson with activity multimedia and create sublesson multimedia
     * @param {string} metadatasTitle
     * @param {Observable<DataEntity>[]} multimediaPages
     * @returns {Observable<DataEntity>}
     */
    public createSubLessonMultimedia(metadatasTitle: string, multimediaPages: Observable<DataEntity>[]): Observable<DataEntity> {
        if (multimediaPages.length) {
            return combineLatest(multimediaPages).pipe(
                mergeMap((activityMedias: DataEntity[]) =>
                    this.createGranuleMultimediaSublesson(metadatasTitle, activityMedias)
                )
            );
        } else {
            return this.createGranuleMultimediaSublesson(metadatasTitle, []);
        }
    }

    /**
     * create lesson reference, metadatas and granule sublesson multimedia
     * @param {string} metadatasTitle
     * @param {DataEntity[]} activityMedias
     * @returns {Observable<DataEntity>}
     */
    private createGranuleMultimediaSublesson(metadatasTitle: string, activityMedias: DataEntity[]): Observable<DataEntity> {
        return this.createEntitySkeleton('lesson', {
            lesson_step: activityMedias.map((actMedia: DataEntity) => actMedia.id)
        }).pipe(
            mergeMap((lessonRef: DataEntity) =>
                this.createEntitySkeleton('metadatas', {
                    title: metadatasTitle,
                    typology: this.getTypologyId('MULTI')
                }).pipe(
                    mergeMap((metadatas: DataEntity) =>
                        this.createEntitySkeleton('granule', {
                            reference: lessonRef.id,
                            format: this.getFormatId('lesson'),
                            metadatas: metadatas.id
                        })),
                )
            )
        );
    }

    /**
     * save lesson reference and force renload in case of lesson reference contet does not change
     * @param {DataEntity} activity
     * @param {DataEntity[]} activityMedias
     * @param lessonReferenceId
     * @returns {Observable<DataEntity>}
     */
    public editSubLessonMultimedia(activity: DataEntity, activityMedias: DataEntity[], lessonReferenceId): Observable<DataEntity> {
        return this.loadGenericEntity('lesson', lessonReferenceId).pipe(
            take(1),
            mergeMap((lessonEntity) => {
                lessonEntity.set('lesson_step', activityMedias.map((actMedia: DataEntity) => actMedia.id));
                return lessonEntity.save(true).pipe(
                    mergeMap(() => this.loadActivitiesFromId(activity.id)
                        .pipe(
                            take(1)
                        )
                    )
                );
            })
        );
    }

    /**
     *
     * @param save resource if the resource does not exist
     * and associate to activity multimedia
     * @param activityContent : activity_content in activity reference
     * @returns {Observable<DataEntity>}
     */
    public saveMediaFromPageMultimedia(page, activityContent, resourcesToEdit = []): Observable<DataEntity> {
        return this.associateMediaToActivity(page, activityContent, resourcesToEdit);
    }

    /**
     * load any Entity.
     * @param {string} endPoint
     * @param {number | string} id
     * @returns {Observable<DataEntity>}
     */
    public loadGenericEntity(endPoint: string, id: number | string): Observable<DataEntity> {
        return this.octopusConnect.loadEntity(endPoint, id);
    }

    /**
     * Reset all activities in service cache.
     */
    public resetActivitiesInCache(): void {
        this.setCurrentActivities([]);
        this.setActivitiesListWithIds([]);
        this.activities = [];
        this.activityEntities = [];
        this.activitiesInSublessonAlreadyLoaded = [];
        this.currentAssignment = null;
        this.currentAssignmentID = null;
        this.downloadedActivity = null;
        this.lessons = [];
        this.selectedActivities = [];
        this.savedAnswers = [];
        this.sequences = [];
    }

    /**
     * if user edit lesson, remove from back activities unused
     * @param {DataEntity} oldActivity
     * @param {DataEntity[]} newActivities
     * @returns {Observable<boolean>}
     */
    public removeUnusedActivities(oldActivity: { id: string, label: string }, newActivities: DataEntity[]): Observable<boolean> {
        return this.loadActivitiesFromId(oldActivity.id).pipe(
            take(1),
            mergeMap((activity: DataEntity) => {
                if ((!!activity.get('original') || this.getActivityType(activity) === 'MULTI' || this.getActivityType(activity) === 'Tool') &&
                    !newActivities.map((act) => +act.id).includes(+activity.id)) {
                    if (activity.get('format').label === 'lesson') {
                        // case activity is sub-lesson, remove child
                        const childrenActivities: Observable<DataEntity>[] = activity.get('reference').map((act: DataEntity) => this.loadActivitiesFromId(act.id));
                        return combineLatest(childrenActivities).pipe(
                            take(1),
                            map((activities: DataEntity[]) =>
                                activities.filter((act: DataEntity) =>
                                    !!act.get('original') || this.getActivityType(act) === 'MULTI' || this.getActivityType(act) === 'Tool')
                            ),
                            mergeMap((childrenToRemove: DataEntity[]) => {
                                if (childrenToRemove.length) {
                                    return this.removeActivities(childrenToRemove).pipe(
                                        mergeMap(() => activity.remove())
                                    );
                                } else {
                                    return activity.remove();
                                }
                            }),
                        );
                    } else {
                        return activity.remove();
                    }
                } else {
                    // no activity to remove
                    return of(true);
                }
            })
        );
    }

    /**
     * remove activities children
     * @param activities
     * @private
     */
    private removeActivities(activities: DataEntity[]): Observable<boolean[]> {
        return combineLatest(
            activities.map((act: DataEntity) => act.remove())
        );
    }

    public loadWarnings(): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('comments-list');
    }

    /**
     * Return true if current displayed activity is the recap (last activity of a lesson)
     */
    public currentActivityIsRecap(): boolean {
        return this.playScreenStatus === 3;
    }

    public get isCurrentActivityLast(): boolean {
        return this.activitiesArray.length <= this.presentArrayElementIndex + 1;
    }

    public constructUrlAndNavigateToLesson(lessonId: number, assignment: boolean, forceNextLesson = false, options?: NavigateToLessonOptions): void {
        const queryParams: { [k: string]: string | number } = {};

        const queryParamsStepIndex = _.get(options ? options : {}, 'startOnStepIndex');
        if (queryParamsStepIndex || queryParamsStepIndex === 0) {
            queryParams['startOnStepIndex'] = queryParamsStepIndex.toString();
        }
        const navigationExtras = {
            // Jamais retourner sur /player en utilisant la fonctionnalité 'back/précedant' du navigateur
            skipLocationChange: true,
            queryParams
        };
        if (assignment) {
            //  fix: if current url is the same as the new url (new lesson to start), we have an error "outlet of null".
            if (forceNextLesson) {
                this.router.navigateByUrl('/followed/list', {skipLocationChange: true})
                    .then(() => this.router.navigate(['/followed', 'assignment', 'lessons', lessonId, 'player'], navigationExtras));
            } else {
                this.router.navigate(['/followed', 'assignment', 'lessons', lessonId, 'player'], navigationExtras);
            }
        } else {
            this.router.navigate(['lessons', lessonId, 'player'], navigationExtras);
        }
    }


    public metacognition(forceNextLesson?: boolean): void {
        if (this.isCurrentActivityLast || forceNextLesson) {
            const indexCurrent = this.currentAssignment.get('assignated_nodes').findIndex(((lesson) => +lesson.id === +this.currentLesson.id));
            if (this.currentAssignment.get('assignated_nodes').length > indexCurrent + 1) {
                this.constructUrlAndNavigateToLesson(+this.currentAssignment.get('assignated_nodes')[indexCurrent + 1].id, true, true, {});
            } else {
                this.navigateToRecapOrReward();
            }
        } else {
            this.loadNextActivity();
        }
    }

    /**
     * navigate to recap or to reward use for use reward page instead of standard recap
     * @param isReward
     */
    public navigateToRecapOrReward(subLessonId: string = null): void {
        let endPath = 'recap';
        const queryParams = {};
        if (subLessonId) {
            endPath = 'reward';
            queryParams['subLessonId'] = subLessonId;
        }
        this.playScreenStatus = 3;
        this.endScreenSeen = true;
        if (this.currentAssignment) {
            this.router.navigate(['/followed', 'assignment', 'lessons', this.currentLesson.id, 'player', endPath], {skipLocationChange: false, queryParams});
        } else {
            this.router.navigate(['lessons', this.currentLesson.id, 'player', endPath], {skipLocationChange: false});
        }
    }

    public typeActivitiesToSkip(activity: DataEntity): boolean {
        if (!this.authenticationService.isLearner() && this.settings.typeActivitiesToSkip && this.settings.typeActivitiesToSkip.length &&
            activity.get('metadatas') && activity.get('metadatas').typology) {
            return !this.settings.typeActivitiesToSkip.includes(activity.get('metadatas').typology.label);
        }
        return true;
    }

    public mustSkipLocationBetweenSubActivities(): boolean {
        return this.settings.navigationInStepsAllowed;
    }
}
