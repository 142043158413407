<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form">
            <div *ngIf="settings.displayLoginLogo" class="logo">
                <img *ngIf="!brandLogoSvg" src="assets/{{ brand }}/images/logos/brand-square.png">
                <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>

            <h2 class="title mat-title">{{ 'authentication.title' | translate }}</h2>

            <form [formGroup]="loginForm" name="loginForm" novalidate>

                <mat-form-field *ngIf="!this.enableCode">
                    <input
                        matInput
                        [attr.disabled]="renewPassword && settings.activeChangePasswordStrategy"
                        data-e2e="input-login"
                        formControlName="login"
                        placeholder="{{ 'authentication.login' | translate }}"
                        required>

                    <mat-error *ngIf="loginFormErrors.login.required">
                        {{ 'authentication.login.required' | translate }}
                    </mat-error>

                    <mat-error *ngIf="loginFormErrors.login.invalid">
                        {{ 'authentication.login.invalid' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input *ngIf="this.enableCode"
                           matInput
                           registrationCode
                           [attr.disabled]="(renewPassword && settings.activeChangePasswordStrategy) || loginFormErrors.emailValidation.invalid"
                           [type]="'text'"
                           data-e2e="input-password"
                           formControlName="password"
                           placeholder="{{ 'authentication.student-code' | translate }}"
                           required>
                    <small *ngIf="this.enableCode">e.g. XXXX-XXXX-XXXX</small>

                    <input *ngIf="!this.enableCode"
                           matInput
                           [attr.disabled]="(renewPassword && settings.activeChangePasswordStrategy) || loginFormErrors.emailValidation.invalid"
                           [type]="hide ? 'password' : 'text'"
                           data-e2e="input-password"
                           formControlName="password"
                           placeholder="{{ 'authentication.password' | translate }}"
                           required>

                    <mat-icon *ngIf="!this.enableCode" (click)="hide = !hide" matSuffix
                              svgIcon="{{hide ? 'visibility' : 'visibility_off'}}">
                    </mat-icon>

                    <mat-error *ngIf="loginFormErrors.password.required">
                        {{ this.enableCode?'authentication.student-code.required':'authentication.password.required' | translate }}
                    </mat-error>

                    <mat-error *ngIf="loginFormErrors.password.invalid">
                        {{ this.enableCode?'authentication.student-code.invalid':'authentication.password.invalid' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="renewPassword && settings.activeChangePasswordStrategy">
                    <input
                        matInput
                        [type]="hideNewPassword ? 'password' : 'text'"
                        autocomplete="off"
                        data-e2e="input-changePassword"
                        formControlName="newPassword"
                        name="changePassword"
                        placeholder="{{ 'authentication.new.password' | translate }}"
                        required>
                    <mat-icon (click)="hideNewPassword = !hideNewPassword" matSuffix
                              svgIcon="{{hideNewPassword ? 'visibility' : 'visibility_off'}}">
                    </mat-icon>

                    <mat-error *ngIf="loginFormErrors.newPassword.required">
                        {{ 'authentication.password.required' | translate }}
                    </mat-error>

                    <mat-error *ngIf="loginFormErrors.newPassword.samePasswordAsOrigine">
                        {{ 'authentication.password.must.be.change' | translate }}
                    </mat-error>

                </mat-form-field>

                <mat-form-field *ngIf="renewPassword && settings.activeChangePasswordStrategy">
                    <input
                        matInput
                        [type]="hideConfirmPassword ? 'password' : 'text'"
                        autocomplete="off"
                        data-e2e="input-password"
                        formControlName="confirmPassword"
                        name="confirmPassword"
                        placeholder="{{ 'authentication.new.password' | translate }}"
                        required>
                    <mat-icon (click)="hideConfirmPassword = !hideConfirmPassword" matSuffix
                              svgIcon="{{hideConfirmPassword ? 'visibility' : 'visibility_off'}}">
                    </mat-icon>

                    <mat-error *ngIf="loginFormErrors.confirmPassword.notMatch">
                        {{ 'account-management.password-confirm.not-identical' | translate }}
                    </mat-error>
                </mat-form-field>

                <div *ngIf="!this.enableCode" class="remember-forgot-password" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">

                    <a [routerLink]="'/forgot-password'" class="forgot-password">
                        {{ 'authentication.forgotten-password' | translate }}
                    </a>
                </div>

                <button (click)="login()"
                        *ngIf="!loginFormErrors.emailValidation.invalid" [disabled]="renewPassword && settings.activeChangePasswordStrategy && loginForm.invalid" class="submit-button" color="accent"
                        data-e2e="button-submit"
                        mat-raised-button>
                    {{ 'authentication.btn-login' | translate }}
                </button>

                <!--if mail was not validated error msg and button to send again email link -->
                <mat-error *ngIf="loginFormErrors.emailValidation.invalid">
                    {{ 'generic.email.validation.invalid' | translate }}
                </mat-error>
                <mat-error *ngIf="newLinkSendError">
                    {{'generic.email.not.send' | translate }}
                </mat-error>

                <p *ngIf="newLinkSendSuccess">
                    {{'generic.email.send' | translate }}
                </p>

                <button (click)="sendLinkToValidateEmail()"
                        *ngIf="loginFormErrors.emailValidation.invalid" color="accent" data-e2e="sendLinkToValidateEmail"
                        mat-raised-button>
                    {{ 'generic.email.validation.invalid.send.new.link' | translate }}
                </button>
                <a *ngIf="this.enableCode" (click)="switchLoginForm()" class="link">{{"authentication.link-go-default-login" | translate}}</a>
                <a *ngIf="!this.enableCode" (click)="switchLoginForm()" class="link">{{"authentication.link-go-student-code-login" | translate}}</a>
                <a *ngIf="quickConnectUsers.length > 0"  [routerLink]="'/connect'" class="link">{{"authentication.choose-account" | translate}}</a>

            </form>

            <div *ngIf="signup && !selfSignup"
                 class="signup"
                 fxLayout="row"
                 fxLayoutAlign="center center"
                 fxLayoutGap="5px">
                <span class="text">{{"authentication.you-are-trainer" | translate}}</span>
                <a [routerLink]="'/requestsubcribe'"
                   class="link">
                    {{ "authentication.request-subscribe-admin" | translate }}</a>
            </div>

            <div class="social-connect">
                <button (click)="loginSSO()" *ngIf="settings.enableSSO" class="submit-button" color="accent"
                        mat-raised-button>
                    {{ 'authentication.btn-login-sso' | translate }}
                </button>
            </div>

            <div class="register"
                 *ngIf="signup && selfSignup">
                <span *ngIf="!this.enableCode" class="text">{{ 'account-management.no-account' | translate }}</span>
                <a class="link"
                   [routerLink]="settings.registerPath"
                   ariaLabelTranslate="{{ 'account-management.register' | translate }}">
                    {{ 'account-management.register' | translate }}</a>
            </div>

        </div>

    </div>

    <div id="login-sso-wrapper" fxLayout="column" fxLayoutAlign="center center" *ngIf="this.modeSSO && !this.enableCode">
        <div id="login-sso">
            <div class="logo" *ngIf="settings.displayLoginLogo">
                <img *ngIf="!brandLogoSvg" src="assets/{{ brand }}/images/logos/brand-square.png">
                <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>
            <h2> {{ 'authentication.sso.in-progress' | translate}} </h2>
        </div>
    </div>


</div>
