import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivityComponentInterface} from '@modules/activities/core/lessons/editor/models/activity-component.interface';
import {EditableActivity} from '@modules/activities/core/lessons/editor/models/editable-activity.class';
import {EditableMultimediaActivity} from '@modules/activities/core/lessons/editor/models/editable-multimedia-activity.class';
import {ReplaySubject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {MultimediaPage} from '@modules/activities/core/lessons/editor/models/multimedia-page.class';
import {CommunicationCenterService} from '@modules/communication-center';
import {LessonEditionService} from '@modules/activities/core/lessons/editor/services/lesson-edition.service';

@Component({
    selector: 'app-multi-line-activity',
    templateUrl: './multi-line-activity.component.html',
})
export class MultiLineActivityComponent implements ActivityComponentInterface, OnInit {

    @Input() editableActivity: EditableMultimediaActivity;
    @Input() toggle: ReplaySubject<EditableActivity>;
    @Input() canJumpBelow = false;
    @Output() delete = new EventEmitter<EditableActivity>();
    @Output() edit = new EventEmitter<EditableActivity>();
    @Output() play = new EventEmitter<EditableActivity>();
    @Output() editChild = new EventEmitter<MultimediaPage>();
    @Output() playChild = new EventEmitter<MultimediaPage>();
    @Output() addChild = new EventEmitter<EditableActivity>();
    @Output() jumpBelow = new EventEmitter<EditableActivity>();

    public isToggle = false;

    ngOnInit(): void {
        this.toggle.pipe(
            tap((editableActivity) => this.internalDoToggle(editableActivity !== this.editableActivity)),
        ).subscribe();
    }

    public getContentTypeIcon(): string {
        return this.editableActivity.type;
    }

    public selfDelete(): void {
        this.delete.emit(this.editableActivity);
    }

    public selfEdit(): void {
        this.edit.emit(this.editableActivity);
    }

    public selfPlay(): void {
        this.play.emit(this.editableActivity);
    }

    public selfAddChild(): void {
        this.addChild.emit(this.editableActivity);
    }

    private internalDoToggle(value = !this.isToggle): void {
        this.isToggle = value;
    }

    public doToggle(): void {
        this.toggle.next(this.isToggle ? this.editableActivity : null);
    }

    public deletePage(page: MultimediaPage): void {
        this.editableActivity.deletePage(page);
    }

    public selfJumpBelow(): void {
        if (this.canJumpBelow) {
            this.jumpBelow.emit(this.editableActivity);
        }
    }

    public childCanJumpBelow(page: MultimediaPage): boolean {
        return this.editableActivity.pages.indexOf(page) !== this.editableActivity.pages.length - 1;
    }

    public childJumpBelow(page: MultimediaPage): void {
        if (this.childCanJumpBelow(page)) {
            this.editableActivity.jumpBelow(page);
        }
    }

    public editPage(page: MultimediaPage): void {
        this.editChild.emit(page);
    }

    public playPage(page: MultimediaPage): void {
        this.playChild.emit(page);
    }
}
