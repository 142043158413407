<mat-card class="activities-card inner-scroll mat-elevation-z0">

    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [wording]="wording" [wordingAudio]="wordingAudio" [instructionAudio]="instructionAudio" (isReading)="speakStateChanged($event, uuid)"
                           questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
            </app-consignes>
            <!-- to set false qcmOption and qcuOptions and send answerStatus -->
            <app-options [activityId]="activityId" [answerStatus]="answerStatus" [contextId]="assignmentId"
                         [mode]="'textMatching'">
            </app-options>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [ngClass]="{ 'vertical': isVertical}">
        <ng-container *ngIf="!!currentImageToGuess">
            <ng-container>
                <div #readMe [ngClass]="{'tts-reading': isTTSReadingText}"
                     class="guessing-item tts {{getGuessingItemClasses()}}"
                     appReadable [uuid]="uuid" (speaking)="speakStateChanged($event)">

                    <span appReplaceTargetContent [innerHtml]="currentImageToGuess.source | stringToHtml"></span>
                    <mat-icon *ngIf="getTypeOfInstruction() === 'text'" [svgIcon]="isTTSReadingText ? 'sound_on':'sound_off'" (click)="clickToTts()"></mat-icon>
                </div>
            </ng-container>
            <div class="available-answers" [ngClass]="getAvailableAnswersClasses()">
                <button mat-raised-button (click)="onAnswerClick(item)" *ngFor="let item of availableAnswers"
                        [ngClass]="item.state" class="button-image"
                        [disabled]="item.state === 'was-correct' || disableAllAnswers"
                ><span [innerHtml]="item.target | stringToHtml"></span></button>
            </div>
        </ng-container>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.app.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>
    </mat-card-footer>

</mat-card>
