
import { HttpClient } from '@angular/common/http';
import { CommunicationCenterService } from '@modules/communication-center';
import { Injectable } from '@angular/core';
import { DataEntity } from 'octopus-connect';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import XAPI, { Actor, Statement, Verb, Result } from '@xapi/xapi';

const settingsStructure: ModelSchema = new ModelSchema({
    api_endPoint: Structures.string(),
    authorization_token: Structures.string()
});


@Injectable({
  providedIn: 'root'
})
export class LrsService {
    public settings: { [key: string]: any };

    userId = '0'; // Drupal user id for all statements
    actor: Actor = {
        objectType: 'Agent',
          name: '0',
          mbox: `mailto:0@tralalere.com`
    };
    private xapi: XAPI;


/**
 *  Statement activité avec résultat
 * {
	"timestamp": "2015-12-18T12:17:00+00:00",
	"actor":{
        "objectType": "Agent",
		"name":"Example Learner",
		"mbox":"mailto:example.learner@adlnet.gov"
	},
	"verb":{
		"id":"http://adlnet.gov/expapi/verbs/attempted",
		"display":{
			"en-US":"attempted"
		}
	},
	"object":{
		"id":"http://example.adlnet.gov/xapi/example/simpleCBT",

TODO : 
VOIR https://github.com/adlnet/xAPI-Spec/blob/master/xAPI-Data.md#appendix-c-example-definitions-for-activities-of-type-cmiinteraction SI ON A des definitions intérrésantes


		"definition":{
			"name":{
				"en-US":"simple CBT course"
			},
			"description":{
				"en-US":"A fictitious example CBT course."
			}
		}
	},
	"result":{
		"score":{
			"scaled":0.95
		},
		"success":true,
		"completion":true,
		"duration": "PT1234S"  ISO8601
	}
}
 * 
 * 
 */

postStatement(statement: Statement): Promise<any> {
    if (this.actor.name !== '0') {
        return this.xapi.sendStatement({statement: statement});
    }
}
private prepareStatement(activityId: String, verb: Verb, result?: Result): Statement {
    const statement: Statement = {
        actor: this.actor,
        verb: verb,
        object: {
            id: `https://api.tralalere.com/${activityId}`,
            objectType: 'Activity'
        },
    };
    if (result) {
        statement['result'] = result;
    }
    return statement;
}

constructor(private communicationService: CommunicationCenterService, httpService: HttpClient) {  
    
    this.settings = settingsStructure.filterModel(modulesSettings.lrs);
    this.xapi = new XAPI({endpoint: this.settings.api_endPoint, auth: this.settings.authorization_token});
    this.communicationService
        .getRoom('authentication')
        .getSubject('userData')
        .subscribe((data: DataEntity) => {
            if (data) {
                this.userId = data.id.toString();
                this.actor.name = data.id.toString();
                this.actor.mbox = `mailto:${this.userId}@tralalere.com`;
            } else {
                this.userId = '0';
            }
        });

    this.communicationService
    .getRoom('lrs')
    .getSubject('activity_attempt')
    .subscribe((data: any) => {
        if (data.id) {
            const verb = XAPI.Verbs.ANSWERED;
            const statement = this.prepareStatement(data.id, verb, data.result);
            this.postStatement(statement);
        }
    });

    this.communicationService
    .getRoom('lrs')
    .getSubject('activity_initialize')
    .subscribe((data: any) => {
        if (data.id) {
            const verb = XAPI.Verbs.INITIALIZED;
            const statement = this.prepareStatement(data.id, verb);
            this.postStatement(statement);
        }
    });

    this.communicationService
    .getRoom('lrs')
    .getSubject('activity_complete')
    .subscribe((data: any) => {
        if (data.id) {
            const verb = XAPI.Verbs.COMPLETED;
            const statement = this.prepareStatement(data.id, verb, data.result);
            this.postStatement(statement);
        }
    });

    this.communicationService
    .getRoom('lrs')
    .getSubject('activity_resume')
    .subscribe((data: any) => {
        if (data.id) {
            const verb = XAPI.Verbs.RESUMED;
            const resumed = this.prepareStatement(data.id, verb);
            this.postStatement(resumed).then(() => {
                // Selon la norme tincan un initialized DOIT suivre un resumed 
                const initVerb = XAPI.Verbs.INITIALIZED;
                const initialized = this.prepareStatement(data.id, initVerb);
                this.postStatement(initialized);
            });
        }
    });

    this.communicationService
    .getRoom('lrs')
    .getSubject('activity_open')
    .subscribe((data: any) => {
        if (data.id) {
            // Verb openend is not in the XAPI Lib shortcuts
            const verb = {
                'id': 'http://adlnet.gov/expapi/verbs/opened',
                'display': {
                    'en-US': 'opened'
                }
            };
            const resumed = this.prepareStatement(data.id, verb);
            this.postStatement(resumed).then(() => {
                // Selon la norme tincan un initialized DOIT suivre un resumed 
                const initVerb = XAPI.Verbs.INITIALIZED;
                const initialized = this.prepareStatement(data.id, initVerb);
                this.postStatement(initialized);
            });
        }
    });

    this.communicationService
    .getRoom('lrs')
    .getSubject('activity_suspend')
    .subscribe((data: any) => {
        if (data.id) {
            const verb = XAPI.Verbs.SUSPENDED;
            const suspend = this.prepareStatement(data.id, verb);
            this.postStatement(suspend).then(() => {
                // terminated indicates that the user quitted the activity (stops futher tracking)
                const terminateVerb = XAPI.Verbs.TERMINATED;
                const terminated = this.prepareStatement(data.id, terminateVerb);
                this.postStatement(terminated);
            });
        }
    });
 }

 
}
