<div class="container" [ngClass]="{
  'locked': (creature.creature.attributes.unLocked === false && userPoints < creature.creature.attributes.price),
  'unlockable': (creature.creature.attributes.unLocked === false && userPoints >= creature.creature.attributes.price),
  'selected': creature.creature.attributes.selected === true,
  'available': creature.creature.attributes.unLocked === true
}" [title]="(creature.creature.attributes.unLocked === false && userPoints < creature.creature.attributes.price) ? '?' : creature.creature.attributes.label">
  <img [src]="imgToDisplay">
  <div class="locked" *ngIf="creature.creature.attributes.unLocked === false">
    <div class="btn unlock" *ngIf="userPoints >= creature.creature.attributes.price">{{creature.creature.attributes.price}}<div>
        <mat-icon svgIcon="credits">
        </mat-icon>
      </div>
    </div>
    <div class="btn locked-icon" *ngIf="userPoints < creature.creature.attributes.price">
      <mat-icon svgIcon="lock"></mat-icon>
    </div>
  </div>
  <div class="unlocked" *ngIf="creature.creature.attributes.unLocked !== false && creature.creature.attributes.userImage">
    <div class="avatar-icon" [ngClass]="creature.creature.attributes.selected === true ? 'selected' : null" (click)="setAvatar($event)">
      <mat-icon svgIcon="avatar"></mat-icon>
      <div class="avatar-default">
        <mat-icon svgIcon="step_success"></mat-icon>
      </div>
    </div>
  </div>
</div>
