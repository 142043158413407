
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {ThemesService} from '@modules/activities/core/themes/themes.service';
import {Subject} from 'rxjs';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
    selector: 'app-theme-consulted',
    templateUrl: './theme-consulted.component.html',
    styleUrls: ['./theme-consulted.component.scss']
})
export class ThemeConsultedComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private datacardService: DatacardService,
        private themesService: ThemesService,
        private lessonsService: LessonsService,
    ) {
    }

    ngOnInit(): void {
        const callbacks = {
            'downloadDoc': (resource) => this.datacardService.downloadDoc(resource),
            'play': (resource) => this.lessonsService.loadAndNavigateToLesson(resource)
        };

        // TODO load themes from the appropriate endpoint to get the latest consulted ones
        this.themesService.getThemes().pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((themes) => {
                    this.dataCards = this.datacardService.processResources(themes.slice(0, 4), callbacks, 'themes');
                },
                (err) => console.log(err));
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}
