import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicMenuService} from 'app/navigation/services/dynamic-menu.service';
import {MainMenuElementGenericBase} from '../../models/main-menu-element-generic.base';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject} from 'rxjs/index';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html'
})
export class MainMenuComponent implements OnInit, OnDestroy {
    public menu: MainMenuElementGenericBase[] = [];
    private unsubscribeTakeUntil: Subject<any> = new Subject<any>();

    constructor(private dynamicMenuService: DynamicMenuService, private communicationCenter: CommunicationCenterService) {
    }

    ngOnInit(): void {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeTakeUntil.next();
        this.unsubscribeTakeUntil.complete();
    }

    private postAuthentication(): void {
        this.dynamicMenuService.refreshMenuSubject.pipe(
            takeUntil(this.unsubscribeTakeUntil),
            tap((menu: MainMenuElementGenericBase[]) => this.menu = menu)
        ).subscribe();
    }

    private postLogout(): void {
        this.dynamicMenuService.refreshMenu(false);
    }

}
