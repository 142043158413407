import {ChangeDetectorRef, Component} from '@angular/core';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {v4 as uuidv4} from 'uuid';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';
import * as _ from 'lodash';


// format de l'activity_content
interface AwarenessActivityContentInterface {
    flashcards: FlashCardInterface[];
}


// format du champ config de l'activité
interface AwarenessActivityConfigInterface {
    instructionMode?: 'picture' | undefined;
}

// format du champ référence de l'activité
type AwarenessActivityReferenceInterface = ActivityReferenceInterface<AwarenessActivityContentInterface[], AwarenessActivityConfigInterface>;

@Component({
    selector: 'app-awareness',
    templateUrl: './awareness.component.html'
})
export class AwarenessComponent extends BaseActivityComponent<AwarenessActivityContentInterface[], AwarenessActivityConfigInterface> {
    public uuid: string = uuidv4();
    public availableFlashCards: FlashCardInterface[] = [];
    private referenceActivityGranule: AwarenessActivityReferenceInterface;
    private selectedFlashCards: FlashCardInterface[] = [];

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    public onStateChange($event: 'is-playing' | 'is-selected', flashcard: FlashCardInterface): void {
        if ($event === 'is-selected') {
            this.selectedFlashCards = _.uniqBy([...this.selectedFlashCards, flashcard], 'id');
        }
    }

    public isDone(): boolean {
        return this.selectedFlashCards.length === this.availableFlashCards.length;
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number; newGrade: number; } {
        throw new Error('Method not implemented.');
    }

    protected setContentData(data): void {
        this.isTTSSpeaking = null;
        this.referenceActivityGranule = data.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.availableFlashCards = this.referenceActivityGranule.activity_content.reduce((acc, ac) => [...acc, ...ac.flashcards], []);
    }

    // API Save Answer functionality
    protected saveAnswer(): Observable<null> {
        return of(null);
    }
}
