<div class="page-layout simple fullwidth inner-scroll" fxLayout="column">
    <!--model lesson and user lessons in two tab-->
    <mat-tab-group *ngIf="!lessonsService.settings.isOnlyModelLesson"
                   dynamicHeight="true" (selectedIndexChange)="lessonsService.selectedIndexChange($event)"
                   [selectedIndex]="lessonsService.selectedTabIndex">
        <mat-tab label="{{ 'generic.lessons' | translate }}"
                 *ngIf="lessonsService.checkAccess(['trainer', 'administrator'])">
            <fuse-app-lessons-tab class="page-layout simple" fxFlex type="currentUser"></fuse-app-lessons-tab>
        </mat-tab>
        <mat-tab label="{{ 'generic.models' | translate }}">
            <fuse-app-lessons-tab class="page-layout simple" fxFlex type="byRole"
                                  [creatorsRolesFilter]="creatorsRolesFilter"></fuse-app-lessons-tab>
        </mat-tab>
    </mat-tab-group>
    <!--only model lesson without tab beacause only one choice enable-->
    <fuse-app-lessons-tab *ngIf="lessonsService.settings.isOnlyModelLesson"
                          class="page-layout simple" fxFlex type="byRole"
                          [creatorsRolesFilter]="creatorsRolesFilter"></fuse-app-lessons-tab>
</div>