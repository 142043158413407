<mat-card class="activities-card inner-scroll mat-elevation-z0" [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes [wording]="wording" [instruction]="instruction" [wordingAudio]="wordingAudio" [instructionAudio]="instructionAudio"
                questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
            </app-consignes>
            <!-- to set false qcmOption and qcuOptions and send answerStatus -->
            <app-options [mode]="'appaire'" [activityId]="activityId" [contextId]="assignmentId"
                [answerStatus]="answerStatus">
            </app-options>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content [ngClass]="{ 'vertical': isVertical}">
        <div dragula="ANSWERS" class="bag left" [(dragulaModel)]="quesImages">
            <div *ngFor="let item of quesImages; let i = index;" class="block-left drag-block-{{i}}">
                <span [innerHtml]="item.source | stringToHtml"></span>
                <span class="mat-mini-fab warning" *ngIf="(testAnswer || displaySolution) && !item.cloned">
                    <mat-icon svgIcon="warning"></mat-icon>
                </span>
            </div>
        </div>
        <div class="bag">
            <div *ngFor="let container of dragContainers" class="answers-bag drag-block-{{container.index}}"
                dragula="ANSWERS" [(dragulaModel)]="container.items">
                <div *ngFor="let item of container.items"
                    [ngClass]="{ 'static-block': !item.draggable, 'block-left': item.draggable}">
                    <span *ngIf="item.draggable" [innerHtml]="item.source | stringToHtml"></span>
                    <span *ngIf="!item.draggable" [innerHtml]="item.target | stringToHtml"></span>
                    <span *ngIf="(testAnswer || displaySolution) && item.dropped?.errorid === 2" class="mat-mini-fab error">
                        <mat-icon svgIcon="cross"></mat-icon>
                    </span>
                    <span *ngIf="(testAnswer || displaySolution) && item.dropped?.errorid === 1" class="mat-mini-fab success">
                        <mat-icon svgIcon="check"></mat-icon>
                    </span>
                    <span *ngIf="!(testAnswer || displaySolution) && !isPreview  && item.dropped?.errorid" class="mat-mini-fab check">
                        <mat-icon svgIcon="save"></mat-icon>
                    </span>
                </div>
            </div>
        </div>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.app.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>
        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>

</mat-card>
