<mat-card class="activities-card inner-scroll mat-elevation-z0" [ngClass]="{'two-columns-layout': isTwoColumns}">

    <mat-card-header>
        <mat-card-title>
            <app-consignes [wording]="wording"
                           [wordingAudio]="wordingAudio"
                           (isReading)="speakStateChanged($event)"
                           questionTypeName="{{ 'activities.questionTypeName.app-fill-in-blanks' | translate }}"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="false"></app-consignes>
        <app-fill-in-blanks-child *ngFor="let item of items"
                (onOptionChange)="onOptionChange($event)"
                [ngClass]="{'hide': displaySolution && answerFeedback}"
                [referenceActivityGranule]="item"
                [childQuestion]="fillInBlanksWording | addInput: fillInBlanksWording.answer_text: fillInBlanksWording.answers"
                [disableOnChange]="disableOnChange"
                [isLatexKeyboard]="isFormula"
                (clickAnswer)="clickAnswer()"
                [readiness]="childReadiness"
                [ttsChange]="ttsChange">
        </app-fill-in-blanks-child>
        <div id="container-for-answers-available" class="available-answers" *ngIf="(isSuggestedButtons || isFormula) && autoCorrection">
            <ng-container *ngIf="isSuggestedButtons">
                <button
                    *ngFor="let option of availableAnswers"
                    class="btn-option"
                    [class]="option.state"
                    (click)="clickAnswer(option)"
                    [disabled]="isDisabledOptions">
                    <span *ngFor="let answer of getParsedAnswer(option.answer)">{{answer}}</span>
                </button>
            </ng-container>
        </div>
        <div *ngIf="displaySolution && answerFeedback" [innerHTML]="answerFeedback | stringToHtml"></div>
    </mat-card-content>
    <!-- answer state start -->
    <ng-container *ngIf="displayFeedback">
        <div class="answer-state-msg"
             [class.wrongReply]="isCurrentAnswerState('wrong')"
             [class.missingReply]="isCurrentAnswerState('missing')">
            <p>{{ feedback | translate }}</p>
        </div>
    </ng-container>
    <!-- answer state end -->

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>
            <ng-container *ngFor="let button of buttons">
                <button
                    *ngIf="button.display"
                    mat-raised-button
                    [disabled]="button.disable"
                    (click)="onAction(button)">
                    <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                    <span>{{ button.title | translate }}</span>
                </button>
            </ng-container>

    </mat-card-footer>
</mat-card>
