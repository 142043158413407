import * as _ from 'lodash';

export class Field {
    public label: string;
    public options: { [p: string]: string[] };

    constructor(public raw: string) {
        this.initialize();
    }

    private initialize(): void {
        this.label = FieldsOptions.getLabel(this.raw);
        this.options = FieldsOptions.getFieldOptions(this.raw);
    }
}

export abstract class FieldsOptions {
    private static delimiter = '|';
    private static subDelimiter = ':';

    public static getFieldOptions(field: string): { [p: string]: string[] } {
        const options = field.split(this.delimiter);
        options.shift(); // on retire la premiere entrée qui est le nom du champ
        return options.reduce((acc, option) => {
            const subOptions = option.split(this.subDelimiter);
            const optionLabel = subOptions.shift();
            acc[optionLabel] = subOptions;
            return acc;
        }, {});

    }

    private static getOption(field: Field, option: string): string[] | null {
        if (_.has(field.options, option)) {
            return field.options[option];
        }

        return null;
    }

    public static isRequired(field: Field): boolean {
        const requiredOptions = this.getOption(field, 'required');
        return requiredOptions === null
            ? false
            : requiredOptions.length === 0 || requiredOptions[0] === 'true';
    }

    public static getLabel(field: string): string {
        const options = field.split(this.delimiter);
        return options.shift();
    }
}
