export const authentication = {
    displayLoginLogo: true,
    enableSSO: false,
    enableCode: true,
    firstConnexionRedirection: {
        trainer: '/groups/list/groups'
    },
    activeChangePasswordStrategy: false,
    rolesAllowedToLogin: [5, 6],
    registerPath: '/register-user',
    quickConnect: true
};
