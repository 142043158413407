
import {take, map} from 'rxjs/operators';
import {combineLatest, Observable, ReplaySubject} from 'rxjs';
import {DataCollection, DataEntity, OctopusConnectService} from 'octopus-connect';

export class FormProfile {

    public picture: string;
    public label: string;
    public email: string;
    public you_are: string;
    public find_us: string;
    public region: string;
    public level: string;
    public newsletter: boolean;
    public role: number;

    public level_field: Array<object>;
    public region_field: Array<object>;
    public role_field: Array<object>;
    public contact_email: string;

    public completeLoading = new ReplaySubject();

    private regionObs: Observable<DataEntity[]>;
    private levelObs: Observable<DataEntity[]>;

    constructor(user, octopusConnect: OctopusConnectService) {

        if (!this.regionObs) {
            this.regionObs = octopusConnect.loadCollection('regions').pipe(
                map((regions: DataCollection) => regions.entities));
        }

        if (!this.levelObs) {
            this.levelObs = octopusConnect.loadCollection('educational_level').pipe(
                map((levels: DataCollection) => levels.entities));
        }

        combineLatest(this.regionObs, this.levelObs).pipe(
            take(1))
            .subscribe((data: DataEntity[][]) => {
                this.region_field = data[0];
                this.level_field = data[1];
                this.completeLoading.next();
            });

        this.picture = user.picture || '';
        this.label = user.label || '';
        this.email = user.email || '';
        this.you_are = user.you_are || '';
        this.find_us = user.find_us || '';
        this.region = user.region || '';
        this.level = user.level || null;
        this.newsletter = user.newsletter || false;
        this.role = user.role || 2;
        this.contact_email = user.contact_email || '';

        // Look like useless
        this.role_field = [
                {
                    name : 'Authentifié',
                    value : 2,
                },
                {
                    name : 'Formateur',
                    value : 5,
                },
                {
                    name : 'Apprenant',
                    value : 6,
                }


            ];
    }

}

export class RequestSubscribeFormProfile {
  public you_are_field: Array<object>;
  public name: string;
  public surname: string;
  public city: string;
  public email: string;

  constructor(user) {

    this.surname = user.surname || '';
    this.name = user.name || '';
    this.city = user.city || '';
    this.email = user.email || '';


    this.you_are_field = [
      {
        name : 'generic.teacher',
        value : 'teacher',
      },

      {
        name : 'generic.parent',
        value : 'parent',
      },
      {
        name : 'generic.other',
        value : 'other',

      },

    ];
  }

}
