import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';

@Component({
    selector: 'app-zone-generator',
    templateUrl: './zone-generator.component.html'
})
/**
 * generate a matrix with x row and x column in regard of sizeOfZone
 * row and column begin at zero [{row:0,column:0}] who is at top left place of matrix
 * some matrix element are good answers
 * the answers is an array of answer that are true or false element are put inside the matrix
 * begining from left top and add row by row:
 * 1  2  3  4
 * 5  6  7  8
 * 9  10 11 12
 * 13 14 15 16
 * a method permit to correspond response and matrix case
 * an event is send to parent at each click to say if answer click is good or not
 * example of use :
 * <app-zone-generator (clickEvent)="zoneClickEvent($event)"
 * [sizeOfZone]="4"
 * [answers]="answers""></app-zone-generator>
 * where answers are like this:
 * [
 {id: '1', answer: '', correct_answer: false, state: ItemAnswerState.missing, select: false},
 {id: '2', answer: '', correct_answer: true, state: ItemAnswerState.missing, select: false}]
 there is one answer in the array for each matrix possible element
 */
export class ZoneGeneratorComponent {
    @Input() answers: ItemAnswerInterface[] = [];
    @Input() sizeOfZone = 4; // it's the number of row and column of the matrix 4 will generate 4 * 4 square
    @Input() showAnswers = false;
    @Output() clickEvent: EventEmitter<ItemAnswerInterface> = new EventEmitter<ItemAnswerInterface>();

    public zoneSelected: { row: number, column: number, isTrueAnswer: boolean }[] = [];

    constructor() {
    }

    /**
     * is element  [row,column] check if it is an element who must be selected or not
     * and return a different class in regard oof result
     * @param row row of the matrix begin to 0
     * @param column column of the matrix begin to 0
     */
    public returnClassInRegardOfZoneState(row: number, column: number): string {
        const answer = this.answers[this.getIndex(row, column)];
        if (answer.select === false) {
            // not selected nothing to add
            return 'colored-zone';
        }
        return answer.correct_answer ? 'selected-true colored-zone' : 'selected-false colored-zone';
    }

    /**
     * check state of zone clicked :
     * if is a good one send data if not already select
     * if it s a false one send data in all case
     * @param row
     * @param column
     */
    public storeTrueFalseZoneSelected(row: number, column: number): void {
        const answerSelected = this.answers[this.getIndex(row, column)];
        // answer is pass to select by parent false answer must be send again and again
        // for being like other exo but good answers must be send only once
        if (!answerSelected.select || !answerSelected.correct_answer) {
            this.clickEvent.emit(answerSelected);
        }
    }

    /**
     * determine in regard of row and column wich answer inside the answers is concern
     * @param row
     * @param column
     */
    public caseNumber(row: number, column: number): string {
        return this.answers[this.getIndex(row, column)].id + this.answers[(column + row * this.sizeOfZone)].correct_answer;
    }

    /**
     * return the index of the answers array concern in regard of row and column selected in the matrix
     * @param row
     * @param column
     * @private
     */
    private getIndex(row: number, column: number): number {
        return column + (row * this.sizeOfZone);
    }

    /**
     * generate an array is use to generate the matrix
     */
    public generateArray(): Array<any> {
        return new Array(this.sizeOfZone);
    }

    public returnClassInRegardOfAnswerState(row: number, column: number): string {
        if (this.showAnswers === false) {
            return;
        }
        const answer = this.answers[this.getIndex(row, column)];
        // if (answer.select === false) {
        //     // not selected nothing to add
        //     return 'colored-zone';
        // }
        return answer.correct_answer ? 'selected-true colored-zone' : 'selected-false colored-zone';
    }
}
