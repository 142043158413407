<div class="pollContainer">
    <!--tooltip current year-->
    <div class="popup" *ngIf="isShowingPopUp" [style.left]="positionTooltip"><div class="verticalLinePopUp"></div>{{pollControl?.value}}</div>

    <!--slider-->
    <input type="range" name="sliderCutom"
           [attr.min]="minValue"
           [attr.max]="assignatedCount"
           [formControl]="pollControl"
           (input)="sliderMove($event)"
           (click)="sliderClick($event)"
           (touchend)="sliderClick($event)"
           (mousedown)="showPopUp()"
           (mouseup)="hidePopUp()">
    <div class="line" [style.width]="positionTooltip"></div>
    <div class="endLine" *ngIf="valPol==='100%'"></div>
    <h1 class="pourcent" [style.left]="positionTooltip">{{valPol}}</h1>

    <div class="poll-legend">
        <p>{{'poll.legend.not_ok' | translate}}</p>
        <p>{{'poll.legend.ok' | translate}}</p>
    </div>
</div>
