import {Injectable} from '@angular/core';
import {CollectionOptionsInterface, DataEntity, OctopusConnectService, PaginatedCollection} from 'octopus-connect';
import {map, take} from 'rxjs/operators';
import {Observable} from 'rxjs/index';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService {
    private dataPaginated: PaginatedCollection;

    constructor(private octopusConnectService: OctopusConnectService) {
    }

    /**
     * return flashcard formated
     */
    public loadFlashCard(page: number = 1): Observable<FlashCardInterface[]> {
        return this.loadData(page).pipe(
            map((data: DataEntity[]) => data.map(d => ({
                    id: d.get('id'),
                    title: d.get('title'),
                    image: d.get('image'),
                    audio: d.get('audio'),
                    data: d.get('data')
                }))
            ));
    }

    /**
     * get flashCard
     */
    private loadData(page: number = 1): Observable<DataEntity[]> {
        const filterOptions: CollectionOptionsInterface = {filter: {}, page: +page, range: 20};
        this.dataPaginated = this.octopusConnectService.paginatedLoadCollection('flashcard_search', filterOptions);
        return this.dataPaginated.collectionObservable
            .pipe(
                take(1),
                map(collection => collection.entities)
            );
    }
}
