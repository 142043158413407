
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DatacardService} from '../../../../../../shared/datacard.service';
import {ThemesService} from '@modules/activities/core/themes/themes.service';
import {CollectionOptionsInterface} from 'octopus-connect';
import {Subject} from 'rxjs';
import {CardsService} from 'fuse-core/components/card/cards.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
    selector: 'app-theme-favorites',
    templateUrl: './theme-favorites.component.html',
    styleUrls: ['./theme-favorites.component.scss']
})
export class ThemeFavoritesComponent implements OnInit, OnDestroy {
    public dataCards: any[] = [];

    private optionsInterface: CollectionOptionsInterface;
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private datacardService: DatacardService,
        private cardService: CardsService,
        private lessonsService: LessonsService,
        private themesService: ThemesService
    ) {
        this.optionsInterface = {
            filter: {
                bookmarks_theme: true, // filter favorites
            },
            page: 1,
            range: 6
        };
    }

    ngOnInit() {
        this.cardService.settingBookmark.subscribe(() => {
            this.loadThemes();
        });

        this.loadThemes();
    }

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }

    /**
     * Loads themes that have been bookmarked
     */
    private loadThemes(): void {
        const callbacks = {
            'downloadDoc': (resource) => this.datacardService.downloadDoc(resource),
            'play': (resource) => this.lessonsService.loadAndNavigateToLesson(resource)
        };

        this.themesService.getThemes(this.optionsInterface).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((themes) => {
                    this.dataCards = this.datacardService.processResources(themes, callbacks, 'themes');
                },
                (err) => console.log(err));
    }
}
