<div fxLayout="column" class="tools page-layout simple fullwidth" fxFlex>
    <div class="header" fxlayout="row" fxlayoutalign="start center">
        <h2>{{ 'generic.multimedia_title' | translate }}</h2>
    </div>

    <div class="content">
        <form [formGroup]="activityForm" (ngSubmit)="activityFormOnSubmit()" *ngIf="renderIsReady else elseBlock">
            <div>
                <mat-form-field>
                    <mat-label>{{ 'multimedia_editor.title' | translate }}</mat-label>
                    <input matInput id="title" type="text" formControlName="title"
                           [placeholder]="'multimedia_editor.title' | translate">
                </mat-form-field>
            </div>

            <div>
                <mat-form-field>
                    <mat-label>{{ 'multimedia_editor.instruction' | translate }}</mat-label>
                    <input matInput id="instruction" type="text" formControlName="instruction"
                           [placeholder]="'multimedia_editor.instruction' | translate">
                </mat-form-field>
            </div>

            <div class="container list-item"
                 [(dragulaModel)]="draggableMedias"
                 [dragula]="formIsSaving ? null : 'draggableMedias'">
                <div *ngFor="let resource of draggableMedias" class="itemDragula activity-item">
                    <div class="handle" fxFlex="0 0 40px" fxLayoutAlign="center center">
                        <button type="button" >
                            <mat-icon svgIcon="drag"></mat-icon>
                        </button>
                    </div>
                    <div class="activity-main-item" fxFlex fxLayoutAlign="left center"
                         [ngSwitch]="resource.format.label">
                        <img *ngSwitchCase="'image'" src="{{resource.reference.uri}}"
                             alt="{{resource.metadatas.title}}">

                        <video *ngSwitchCase="'video'" controls>
                            <source src="{{resource.reference.uri}}" type="{{resource.reference.filemime}}">
                            Your browser does not support HTML5 video.
                        </video>

                        <app-video-with-markers *ngSwitchCase="'videoUrl'"
                                                [videoConfig]="{config: {src : resource.reference.url}}"></app-video-with-markers>

                        <audio *ngSwitchCase="'audio'" controls>
                            <source [src]="resource.reference.uri" [type]="resource.reference.filemime">
                            Your browser does not support the audio tag.
                        </audio>

                        <div *ngSwitchCase="'text'">
                            <div class="ck-editor-output" [innerHTML]="resource.reference.text"></div>
                        </div>

                        <div *ngSwitchDefault>
                            <div>{{'multimedia_editor.format_not_implemented' | translate}} {{resource.format.label}}</div>

                        </div>
                    </div>

                    <div class="actionBtns" fxFlex="0 0 150px" fxLayoutAlign="center center">
                        <button *ngIf="canEdit(resource)" type="button" aria-label="edit" matTooltip="{{ 'generic.edit' | translate }}"
                                (click)="editMedia(resource)">
                            <mat-icon svgIcon="edit"></mat-icon>
                        </button>
                        <button type="button" aria-label="delete" matTooltip="{{ 'generic.delete' | translate }}"
                                (click)="deleteMedia(resource)">
                            <mat-icon svgIcon="delete"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>


            <div class="footer h-64" fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button type="submit" [disabled]="formIsSaving || isUserShouldSave() === false"
                        class="save-button mat-accent">
                    <span>{{ 'generic.save' | translate }}</span>
                    <mat-icon svgIcon="save"></mat-icon>
                </button>
                <button type="button" mat-raised-button (click)="goBack()">
                    <span>{{ 'generic.exit' | translate }}</span>
                    <mat-icon svgIcon="exit"></mat-icon>
                </button>

                <div class="addBtnBottomRight">
                    <button [matMenuTriggerFor]="menu" type="button" aria-label="More" class="add-file-button" mat-fab
                            matTooltip="More">
                        <mat-icon svgIcon="add"></mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" class="form-activities-menu" backdropClass="horizontalAddSubMenu">
                        <div mat-menu-item>
                            <button type="button" mat-fab (click)="createGenericMedia()"
                                    [disabled]="canAddGenericMedia() === false">
                                <mat-icon svgIcon="eye"></mat-icon>
                            </button>
                            <span
                                (click)="canAddGenericMedia() && createGenericMedia()">{{ 'multimedia_activity.generic_media' | translate }}</span>
                        </div>
                        <div mat-menu-item>
                            <button type="button" mat-fab (click)="createTextMedia()"
                                    [disabled]="canAddTextMedia() === false">
                                <mat-icon svgIcon="sheet"></mat-icon>
                            </button>
                            <span
                                (click)="canAddTextMedia() && createTextMedia()">{{ 'multimedia_activity.generic_text' | translate }}</span>
                        </div>
                    </mat-menu>
                </div>
            </div>
        </form>

        <ng-template #elseBlock>
            <mat-spinner></mat-spinner>
        </ng-template>
    </div>
</div>