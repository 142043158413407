import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {take} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';

@Component({
    selector: 'app-link-child',
    templateUrl: './link-child.component.html',
})
export class LinkChildComponent implements OnInit {
    isLoading = false;
    isChildNotFound = false;
    childIdentifierControl: FormControl = new FormControl('', Validators.required);
    childLabel: string;
    public isSuccess = false;

    constructor(private translate: TranslateService, private profileSvc: ProfileService) {
    }

    ngOnInit(): void {
    }

    canLink(): boolean {
        return this.isLoading !== true && this.childIdentifierControl.valid;
    }

    onLinkClick($event: MouseEvent): void {
        if (this.childIdentifierControl.valid) {
            this.isSuccess = false;
            this.isChildNotFound = false;
            this.isLoading = true;
            this.childLabel = this.childIdentifierControl.value;
            this.profileSvc.linkChild(this.childLabel).pipe(take(1)).subscribe((success) => {
                this.isLoading = false;
                if (success) {
                    this.isSuccess = true;
                } else {
                    this.isChildNotFound = true;
                }
            });

        }
    }
}
