import {DataEntity} from 'octopus-connect';
import {GamificationService} from './gamification.service';
import {Observable} from 'rxjs/index';

export class CreatureCollection {

    private _gamificationService: GamificationService;

    creaturesStatus: 'loading' | 'loaded' | 'not-loaded';
    universesStatus: 'loading' | 'loaded' | 'not-loaded';
    creatures: Array<Creature> = [];
    universes: Array<DataEntity> = [];


    constructor(gamificationService: GamificationService) {
        this._gamificationService = gamificationService;
        this.creaturesStatus = 'not-loaded';
        this.universesStatus = 'not-loaded';
    }

    /**
     * Loads collection if not loaded
     */
    loadCreatures(force?: boolean): void {
        if (this.creaturesStatus === 'not-loaded' || force) {
            this.creaturesStatus = 'loading';
            this._gamificationService.getBadges(BadgeType.Creature).subscribe(animals => {
                this.creatures = animals.entities.map(creature => {
                    return new Creature(this._gamificationService, creature, this);
                });
                this.creaturesStatus = 'loaded';
            });
        }
    }

    /**
     * Loads universes if not loaded
     */
    loadUniverses(force?: boolean, openEditPopup = false): void {
        if (this.universesStatus === 'not-loaded' || force) {
            this.universesStatus = 'loading';
            this._gamificationService.getBadges(BadgeType.Univers).subscribe(universes => {
                this.universes = universes.entities;
                this.universesStatus = 'loaded';
                if (openEditPopup) {
                    this._gamificationService.activeTab = 'universes';
                }
            });
        }
    }

    // TODO USELESS CODE !
    updateBadge(res: DataEntity, forceReload: boolean = false, openEditPopup = false): void {
        if (res.attributes.type.name === BadgeType.Accessoire) {
            const creatureIndex = this.creatures.findIndex(cr => !!res.attributes.parent.find(p => p === cr.creature.id));
            if (creatureIndex >= 0) {
                const accessoryIndex = this.creatures[creatureIndex].accessories.findIndex(ac => ac.id === res.id);
                if (accessoryIndex >= 0) {
                    this.creatures[creatureIndex].accessories[accessoryIndex] = res;
                }
                this.creatures[creatureIndex].loadAccessories(forceReload, openEditPopup);
            }
        } else if (res.attributes.type.name === BadgeType.Creature) {
            const creatureIndex = this.creatures.findIndex(cr => cr.creature.id === res.id);
            if (creatureIndex >= 0) {
                this.creatures[creatureIndex].creature = res;
            }
            this.loadCreatures(forceReload);
        } else if (res.attributes.type.name === BadgeType.Univers) {
            const universeIndex = this.universes.findIndex(u => u.id === res.id);
            if (universeIndex >= 0) {
                this.universes[universeIndex] = res;
            }
            this.loadUniverses(forceReload, openEditPopup);
        }
    }
}

export class Creature {

    private _gamificationService: GamificationService;
    private _collection: CreatureCollection;

    creature: DataEntity;
    status: 'loading' | 'loaded' | 'not-loaded';
    accessories: DataEntity[] = [];

    public get univers(): DataEntity {
        return this._collection.universes.find((universe: DataEntity) => +this.creature.get('backgroundBadge') === +universe.id);
    }
    public set univers(value: DataEntity) {
        this.creature.set('backgroundBadge', +value.id);
    }
    // TODO ce serait mieux que les univers possible soient pas porté ici.
    public get availableUniverses(): DataEntity[] {
        return this._collection.universes;
    }

    constructor(gamificationService: GamificationService, creature: DataEntity, collection: CreatureCollection) {
        this.creature = creature;
        this._collection = collection;
        this._gamificationService = gamificationService;
        this.status = 'not-loaded';
    
        if (this.univers === undefined) {
            const defaultUniverse = this._collection.universes.find(u => u.attributes.unLocked === true);
            if (defaultUniverse !== undefined) {
                this.univers = defaultUniverse;
            }
        }
    }

    /**
     * Loads collection if not loaded
    */
    loadAccessories(force?: boolean, openEditPopup = false): void {
        if (this.status === 'not-loaded' || force) {
            this.status = 'loading';
            this._gamificationService.getBadges(BadgeType.Accessoire, this.creature.id.toString()).subscribe(accessories => {
                this.accessories = accessories.entities.map((entity) => {
                    // // If there are accessories in cache it's because there are new bought accessories. In this case, we need to add it to the avatar but don't save it
                    const accessoriesInCache = this._gamificationService.accessoriesCache[entity.get('stuffType').name];
                    if (!!accessoriesInCache) {
                        if (accessoriesInCache.id === entity.id) {
                            // By doing it we add it the the avatar
                            entity.set('selected', true);
                        } else {
                            // But we have to unselect other items (avatar can't have to items of same type)
                            entity.set('selected', false);
                        }
                    }
                    return entity;
                });
                this.status = 'loaded';
                this._gamificationService.isShowPopup = openEditPopup;
            });
        }
    }

    save(): Observable<DataEntity> {
        return this.creature.save(true);
    }
}

export enum BadgeType {
    Creature = 'créature',
    Accessoire = 'accessoire',
    Univers = 'univers'
}
