import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of, Subject} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';

// format de l'activity_content
interface QcmActivityContentInterface {
    answers: ItemAnswerInterface[];
}

// format du champ config de l'activité
interface QcmActivityConfigInterface {
    direction: string;
}

// format du champ référence de l'activité
type QcmActivityReferenceInterface = ActivityReferenceInterface<QcmActivityContentInterface, QcmActivityConfigInterface>;

@Component({
    selector: 'app-qcm',
    templateUrl: './qcm.component.html'
})

export class QcmComponent extends BaseActivityComponent<any, any> {
    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    public buttonState: ItemAnswerStateEnum = ItemAnswerStateEnum.pristine;
    public feedback: string;
    public displaySaveBtn = false; // Boolean know if we display checkbox or button, data fetched from the api
    public isTwoColumns = false;
    public referenceActivityGranule: QcmActivityReferenceInterface;
    public showSubInstruction: boolean;
    public title: string;
    public type = '';
    public answerStateClass = '';
    public isTTSReading: { id: string, value: boolean };
    public readSecondConsigne = new Subject<boolean>();
    private firstConsigneAutoReadIsDone = false;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    protected setContentData(activityAttributes): void {
        this.showSubInstruction = this.activitiesService.settings.showSubInstruction;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
            this.displaySaveBtn = activityAttributes.reference.config.displaySaveBtn === true;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        try {
            this.type = activityAttributes.reference.config.type ? activityAttributes.reference.config.type : '';
        } catch (ex) {
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wording = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wording'))
        || !this.preview ? this.referenceActivityGranule.wording : '';
        this.wordingAudio = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wordingAudio'))
        || !this.preview ? this.referenceActivityGranule.wordingAudio : '';
        this.setDefaultAnswersAvailable(this.referenceActivityGranule.activity_content.answers);
        this.answerStateClass = '';
        this.answersSelected.forEach((answer: ItemAnswerInterface) => {
            answer.state = ItemAnswerStateEnum.pristine;
        });
        this.loadUserSave();
    }

    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    private setDefaultAnswersAvailable(answers, resetSelectEd?: boolean): void {
        this.answersSelected = answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                select: resetSelectEd ? false : !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.setDefaultAnswersAvailable(this.answersSelected, true);
        this.buttonState = null;
        return super.reset(resetAllSubscribe, type);
    }

    protected setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete' && this.answersSelected) {
            this.answersSelected.forEach((option: ItemAnswerInterface, index: number) => {
                const answerSaved = this.userSave.get('userActivity').entitySave.answers[index];
                option.select = answerSaved && !!+answerSaved.select && option.id === answerSaved.id;
            });
            this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
        }
        this.checkAnswer();
    }

    protected checkAnswer(): void {
        this.answerStatus = answerStatusEnum.missing;
        const answerResult: AnswerResultInterface = {
            id: +this.activityId.id,
            isAnswerCorrect: undefined,
            isLast: undefined
        };
        /**
         * isAtLeastOneCorrectAnswer: au moins une bonne réponse selectionné
         * isAtLeastOneWrongAnswer: au moins une mauvaise réponse selectionné
         * isAtLeastOneMissingAnswer: au moins une bonne réponse  MANQUANTE
         */
        const isAtLeastOneCorrectAnswer = this.answersSelected.some((answer) => answer.correct_answer && answer.select);
        const isAtLeastOneWrongAnswer = this.answersSelected.some((answer) => !answer.correct_answer && answer.select);
        const isAtLeastOneMissingAnswer = this.answersSelected.some((answer: ItemAnswerInterface) => !answer.select && answer.correct_answer);
        if (isAtLeastOneCorrectAnswer && isAtLeastOneWrongAnswer) {
            this.feedback = 'activities.feedback.qcm.warning';
        }
        if (isAtLeastOneCorrectAnswer && isAtLeastOneMissingAnswer && !isAtLeastOneWrongAnswer) {
            this.feedback = 'activities.feedback.qcm.missing';
        }
        if (isAtLeastOneWrongAnswer && !isAtLeastOneCorrectAnswer) {
            this.feedback = 'activities.feedback.qcm.correction';
        }
        // toutes les réponses sont correctes
        if (!isAtLeastOneMissingAnswer && !isAtLeastOneWrongAnswer) {
            this.buttonState = ItemAnswerStateEnum.wasCorrect;
            answerResult.isAnswerCorrect = true;
            answerResult.isLast = true;
            this.answerStateClass = 'correct';
        } else {
            this.buttonState = ItemAnswerStateEnum.incorrect;
            answerResult.isAnswerCorrect = false;
            this.answerStateClass = 'not-correct';
        }

        if (this.autoCorrection) {
            super.ifFeedbackOpenModal();
            this.answerResult.next(answerResult);
            this.checkAnswersForAutoCorrection();
        }
        this.answerStatus = isAtLeastOneWrongAnswer ? answerStatusEnum.wrong : answerStatusEnum.correct;
        if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
            this.displayFeedback = (this.answerStatus === 3) && this.activitiesService.settings.displayFeedback;
        }
        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    private checkAnswersForAutoCorrection(): void {
        this.answersSelected.forEach((option: ItemAnswerInterface) => {
            if (option.select) {
                if (option.correct_answer) {
                    option.state = ItemAnswerStateEnum.currentlyCorrect;
                } else {
                    option.state = ItemAnswerStateEnum.incorrect;
                }
            }
        });
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            if (this.buttonState === ItemAnswerStateEnum.incorrect) {
                this.buttonState = ItemAnswerStateEnum.pristine;
            }
            if (this.buttonState === ItemAnswerStateEnum.currentlyCorrect) {
                this.buttonState = ItemAnswerStateEnum.wasCorrect;
            }
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            if (this.buttonState === ItemAnswerStateEnum.wasCorrect) {
                this.doAction('next', ['save']);
            }
            this.answerStateClass = this.answerStateClass === 'not-correct' ? '' : 'correct';
        }, 500);


    }

    protected getGrade(): any {
        let oldGrade = 0;
        let grade = 0;
        if (this.userSave) {
            const answer = this.userSave.get('userActivity').entitySave.answers[0];

            if (answer && !!answer.correct_answer) {
                oldGrade += 1;
            }
        }
        for (const option of this.answersSelected) {
            if (option.select && !!option.correct_answer) {
                grade += 1;
            }
        }
        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    /**
     * in regard of if it's a standart qcm or a text selection exo class will be added to overide default css
     */
    public parentClass(): string {
        let classToAdd = '';
        classToAdd = this.testAnswer || this.displaySolution || this.answerSaved ? 'showFinalAnswers' : '';
        classToAdd = classToAdd + this.testAnswer ? ' answersChecked' : '';

        if (this.type === 'isWordSelecting' || this.type === 'isLetterSelecting') {
            return classToAdd + ' is-word-or-lettersSelecting ' + this.answerStateClass;
        } else {
            return classToAdd;
        }
    }

    public clickAnswer(): void {
        if (this.answersSelected.some((option: ItemAnswerInterface) => !!option.select)) {
            this.checkAnswer();
        }
    }

    public optionChange(): void {
        if (this.answersSelected.some((option: ItemAnswerInterface) => !!option.select)) {
            this.onOptionChange(true);
        }
    }

    public toggleCheck(event, option: ItemAnswerInterface, triggerChange: boolean = true): void {
        event.stopImmediatePropagation();
        if (triggerChange) {
            option.select = !option.select;
            this.optionChange();
        }
    }

    /**
     * method is used for checkbox and for button (keep this boil off plate because it's legacy code)
     * need for validate button to disable if no answer was done => isCheckBox = false for this case
     * @param isCheckBox is button or one of the answers
     */
    public isDisabledOptions(isCheckBox = true): boolean {
        // no answer yet nothing to control button must be disabled
        if (!isCheckBox && this.answersSelected.find(a => a.select === true) === undefined) {
            return true;
        }
        if (this.displaySaveBtn && (this.buttonState !== null && ItemAnswerStateEnum.wasCorrect !== null)) {
            return this.buttonState === ItemAnswerStateEnum.wasCorrect;
        } else {
            return this.displaySolution || // solution displayed
                this.testAnswer || // answer tested
                (this.answerSaved && this.lessonsService.isLessonEvaluation()) || // answer saved and assignment is assessment or homework
                this.lessonsService.isAtLeastTrainer() || // check if user is trainer
                this.lessonsService.isLessonCorrected(); // assignment exist and due date finished
        }
    }

    /**
     * sert aux css pour afficher l'état d'une reponse
     * TODO: on doit faire en sorte que les class css utilise le "state" d'une réponse cf: ItemAnswerInterface
     * pour permettre de retourner simplement le "state" qui sera la également la class css
     * @param option
     */
    public optionState(option: any): string {
        if (this.displaySolution) {
            if (option.correct_answer) {
                return 'correctAnswer';
            }
        }

        if (this.testAnswer) {
            if (option.select) {
                if (option.correct_answer) {
                    return 'correctAnswer';
                } else {
                    return 'wrongAnswer';
                }
            } else {
                if (option.correct_answer) {
                    return 'answerMissed';
                }
            }
        }

        if (option.select) {
            if (this.lessonsService.isLessonEvaluation() && this.answerSaved) {
                return 'validated';
            } else {
                return 'selected';
            }
        }
        return '';
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * set if Text to Speach is currently reading or not
     * @param evt
     */
    public ttsReadingEvent(evt: { id: string, value: boolean }): void {
        // content is hidden we do nothing
        if (this.contentIsHidden) {
            return;
        }
        this.isTTSReading = evt;
        this.isTTSReading = evt;
        if (evt.value === true) {
            this.firstConsigneAutoReadIsDone = true;
        }
        // the fisrt consigne is finishto be read and the content is visible modal is closed an
        if (evt.value === false && this.firstConsigneAutoReadIsDone === true && !this.contentIsHidden) {
            // we send a different value to permit ngOnChange to detect event
            this.readSecondConsigne.next(true);
            this.ref.detectChanges();
        } else {
            this.ref.detectChanges();
        }
    }
}
