<div class="page-layout simple fullwidth mat-white-bg inner-scroll">
    <div class="p-16 content inner-scroll" fxLayout="column" fxFlex>
        <app-followed-filter *ngIf="shouldDisplayFilters()"
            [displayedFilters]="displayedFilters"
            [allTypes]="allTypes"
            [groupsList]="assignationService.groupsList"
            [workgroupsList]="assignationService.workgroupsList"
            [learnersList]="assignationService.learnersList"
            [countEntities]="countEntities"
            (launchSearch)="launchSearch($event)"
        ></app-followed-filter>
        <div class="content touch-scroll" *ngIf="!isLoading" fxFlex>
            <table mat-table
                   #table
                   [dataSource]="dataSource"
                   matSort
                   [@animateStagger]="{value:'50'}">

                <!--pseudo-->
                <ng-container matColumnDef="pseudo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'account-management.pseudo' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.user}}
                        <!--{{row.attributes.assignated_user?.name}}-->
                    </td>
                </ng-container>
                <!--type of exercise-->
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.type' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.type}}
                    </td>
                </ng-container>
                <!--name of exercise-->
                <ng-container matColumnDef="title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.two_steps_selection_Title_underlist' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.activity}}
                    </td>
                </ng-container>

                <!-- count good answers -->
                <ng-container matColumnDef="countGoodAnswers">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
                        {{ 'generic.count_good_answers' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
                        {{row?.goodAnswer}}
                    </td>
                </ng-container>
                <!-- pourcent of success -->
                <ng-container matColumnDef="pourcentSuccess">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.pourcent_success' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row?.percent}}
                    </td>
                </ng-container>
                <!--time to finish exercise-->
                <ng-container matColumnDef="timeToFinish">
                    <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
                        {{ 'generic.time_to_finish' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
                        {{row?.duration}}
                    </td>
                </ng-container>
                <!--date-->
                <ng-container matColumnDef="dateOfEnd">
                    <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
                        {{ 'generic.date_end' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
                        {{timestampToDate(row?.date)}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
                </tr>
            </table>
        </div>
        <mat-spinner *ngIf="isLoading"></mat-spinner>
        <mat-paginator
            [length]="countEntities"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="pageRangeOptions"
            (page)="onPaginateChange($event)">
        </mat-paginator>
    </div>
</div>

