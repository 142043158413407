<ng-template #loading>
    <div fxFill fxLayoutAlign="center center">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<ng-template #defaultIcon>
    <mat-icon svgIcon="tools"></mat-icon>
</ng-template>

<mat-card *ngIf="renderIsReady else loading" class="activities-card inner-scroll mat-elevation-z0">
    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio" [autoRead]="false"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content >
        <img *ngIf="!!iconName else defaultIcon" [alt]="iconName"
             [src]="'assets/' + brand + '/images/tools/' + iconName"/>

    </mat-card-content>
</mat-card>
