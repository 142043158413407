<div #messagesListContainer class="content">
    <div *ngFor="let message of messages" class="motivational-wrapper"
         [ngClass]="[message.get('type')]"
         [ngStyle]="{'cursor':message.get('detail') !== ''?'pointer':'default'}"
         (click)="openDetail(message, null)">
        <div [innerHTML]="message.get('body')"></div>
        <div class="see-more" *ngIf="isMoreDetailToOpen(message)">{{'generic.see_more' | translate}}</div>
    </div>
</div>

    <div class="footer-action">
        <button aria-label="help" (click)="openHelp()"   mat-raised-button>
            {{ 'generic.help.feedback' | translate}}
        </button>
    </div>
