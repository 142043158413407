import {Component, OnDestroy, OnInit} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html'
})
export class CommunityListComponent implements OnInit, OnDestroy {

  constructor(private lessonService: LessonsService ) { }

  ngOnInit(): any {
      this.lessonService.isShareableCommunity = false;
      this.lessonService.showOnlySharedCommunityLesson = true;
  }

  ngOnDestroy(): any {
      this.lessonService.showOnlySharedCommunityLesson = false;
  }

}
