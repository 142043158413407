<mat-spinner *ngIf="showSpinner"></mat-spinner>
<div *ngIf="!showSpinner" id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form">
            <div class="logo">
                <img *ngIf="!brandLogoSvg" src="assets/{{ brand }}/images/logos/brand-square.png" alt="logo">
                <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>
            <h2 class="title mat-title">{{ 'authentication.quick-connect-title' | translate }}</h2>
            <div class="localUser">
                <ng-container *ngFor="let localUser of localUsers">
                    <button *ngIf="!isAuthenticated" mat-button (click)="login(localUser)">
                        <mat-icon *ngIf="(isAuthenticated && localUser['label'] !== currentUser.get('label'))"
                                  class="button" svgIcon="trash" (click)="delete(localUser['label'])"
                                  title="{{'generic.delete' | translate}}"></mat-icon>
                        <span>{{localUser['nickname'] || localUser['label']}}</span>
                    </button>
                    <button *ngIf="(isAuthenticated)" mat-button (click)="delete(localUser['label'])">
                        <mat-icon *ngIf="(isAuthenticated && localUser['label'] !== currentUser.get('label'))"
                                  class="button" svgIcon="btn_trash" title="{{'generic.delete' | translate}}"></mat-icon>
                        <mat-icon *ngIf="isAuthenticated && localUser['label'] === currentUser.get('label')" class="button"
                                  svgIcon="btn_log_out" title="{{'generic.delete' | translate}}"></mat-icon>
                        <span>{{localUser['nickname'] || localUser['label']}}</span>
                    </button>
                </ng-container>
            </div>
            <ul *ngIf="!isAuthenticated">
                <li>
                    <a *ngIf="localUsers.length > 0" class="link"
                       [routerLink]="'/login'">{{"authentication.title" | translate}}</a>
                </li>
                <li>
                    <a class="link" [routerLink]="'/register-user'">{{"account-management.no-account" | translate}}</a>
                </li>
            </ul>
        </div>
    </div>
</div>