<mat-tab-group [(selectedIndex)]="selectedTab" mat-align-tabs="center">
    <mat-tab label="{{ translationTabLabelList | translate}}">
        <ng-template matTabContent>
            <app-followed-list id="id-app-followed-list"></app-followed-list>
        </ng-template>
    </mat-tab>

    <mat-tab label="{{ translationTabLabelLogBook | translate}}">
        <ng-template matTabContent>
            <app-followed-log-book id="id-app-followed-log-book"></app-followed-log-book>
        </ng-template>
    </mat-tab>
</mat-tab-group>
