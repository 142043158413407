<mat-sidenav-container [ngStyle]="{'display' : appVisible?'':'none'}" class="dynamic-sidenav-container"
                       id="main-page" [class.footerVisible]="isAuthenticated && footerVisible">
    <mat-sidenav data-e2e="left_menu" #sidenav [opened]="sideNavIsOpen" [mode]="sideNavMode">
        <mat-icon class="logo-close-svg" data-e2e="close_left_menu" (click)="sidenav.toggle()"
                  svgIcon="close_menu"></mat-icon>
        <mat-icon class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
        <app-main-menu></app-main-menu>
    </mat-sidenav>
    <!--no authenticate => no header no footer; authenticate => header not during exercices and footer during exercice-->
    <mat-sidenav-content [class.headerVisible]="isAuthenticated && headerVisible">
        <app-header *ngIf="isAuthenticated && headerVisible" class="header header-fixed"></app-header>
        <div class="content">
            <router-outlet></router-outlet>
        </div>
        <div class="footer"></div>
    </mat-sidenav-content>
</mat-sidenav-container>