<div id="app-feedback" class="page-layout simple fullwidth">
    <div class="content">
        <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">

            <img src="assets/{{ assetbrand }}/images/logos/feedback.png">

            <mat-form-field>
                <input matInput type="text" placeholder="{{'account-management.email' | translate}}"
                       formControlName="email">
                <mat-error>
                    {{ 'generic.email_error' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-select name="category" placeholder="{{'error_reporting.subject_label' | translate}}"
                            formControlName="category" (selectionChange)="changeCategory($event)">
                    <mat-option *ngFor="let category of categoryList" [value]="category.id">
                        {{ category.label | translate }}</mat-option>
                </mat-select>
                <mat-error>
                    {{ 'generic.field.required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput placeholder="{{'messaging.message' | translate}}"
                          formControlName="content"></textarea>
                <mat-error>
                    {{ 'generic.field.required' | translate }}
                </mat-error>
            </mat-form-field>

            <button type="submit" mat-raised-button color="accent" style="width: 100px; height: 100px;"
                    [disabled]="!feedbackForm.valid">{{'account-management.send_feedback' | translate}}</button>
            <span *ngIf="isMessageSend"> {{'generic.message.sended' | translate}}</span>
            <mat-error *ngIf="messageSendError">
                {{'generic.message.not_send' | translate }}
            </mat-error>
        </form>
    </div>
</div>