import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import {SharedTranslateModule} from '../../../app/shared/shared-translate.module';
import {FuseSharedModule} from 'fuse-core/shared.module';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    MatChipsModule,
    SharedTranslateModule,
    MatSlideToggleModule,
    FuseSharedModule
  ],
  declarations: [CardComponent],
  exports: [
      CardComponent
  ],
})
export class CardModule { }
