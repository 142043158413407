import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {FillInBlanksChildComponent} from './fill-in-blanks-child/fill-in-blanks-child.component';
import {brand} from '../../../../../settings';
import {Observable, of, ReplaySubject, Subject} from 'rxjs';
import {
    BaseActivityComponent
} from '@modules/activities/core/player-components/base-activity.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {v4 as uuidv4} from 'uuid';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';

// format de l'activity_content
interface RbActivityContentInterface {
    answers: ItemAnswerInterface[];
    answer_text: string;
}

// format du champ config de l'activité
interface RbActivityConfigInterface {
    doubleColumn: boolean;
    caseSensitive: boolean;
    latexKeyboard: boolean;
    suggestedButtons: boolean;
    keyboardType: string;
}

// format du champ référence de l'activité
export type RbActivityReferenceInterface = ActivityReferenceInterface<RbActivityContentInterface, RbActivityConfigInterface>;

@Component({
    selector: 'app-fill-in-blanks',
    templateUrl: './fill-in-blanks.component.html'
})
export class FillInBlanksComponent extends BaseActivityComponent<any, any> implements OnInit, OnDestroy {
    @ViewChild(FillInBlanksChildComponent) private child: FillInBlanksChildComponent;
    @Output() answerClick: EventEmitter<String> = new EventEmitter<String>();

    public apiAnswer: ItemAnswerInterface[] = [];
    public answerFeedback: string; // feedback fetch from api
    public answersEntered = [];
    public answerValidated = false;
    public brand = brand;
    public childReadiness: Subject<FillInBlanksChildComponent> = new Subject<FillInBlanksChildComponent>();
    public disableOnChange = new ReplaySubject<boolean>(1);
    public ttsChange = new ReplaySubject<{ id: string, value: boolean }>(1);
    public feedback: string;
    public fillInBlanksWording: any;
    public isCaseSensitive = true;
    public isFormula = false;
    public isSuggestedButtons: boolean;
    public items = [];
    public referenceActivityGranule: RbActivityReferenceInterface;
    public title: string;
    public btnValidateState = ItemAnswerStateEnum.pristine;
    private childIsReady: FillInBlanksChildComponent;

    constructor(
        private elementRef: ElementRef,
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
        // Une subtilité du cycle angular fait qu'à un moment donné, deux FillInBlanksChildComponent existent
        //  Mais le ViewChild derrière "this.child" possède le dernier et qu'il nous faut le différencier du nouveau
        this.childReadiness.subscribe((child: FillInBlanksChildComponent) => {
            this.childIsReady = child;
            if (child && this.answersSelected.length) {
                this.checkAnswer();
            }
        });
    }

    ngOnDestroy(): void {
        this.disableOnChange.next();
        this.disableOnChange.complete();
        super.ngOnDestroy();
    }

    protected setContentData(activityAttributes): void {
        this.referenceActivityGranule = activityAttributes.reference;
        if (this.referenceActivityGranule.config) {
            this.isTwoColumns = this.referenceActivityGranule.config.doubleColumn;
            this.isCaseSensitive = !!this.referenceActivityGranule.config.caseSensitive;
            this.isFormula = this.referenceActivityGranule.config.latexKeyboard ||
                this.activitiesService.settings.latexKeyboard && !this.referenceActivityGranule.config.suggestedButtons;
            this.isSuggestedButtons = this.referenceActivityGranule.config.suggestedButtons;
        }
        if (activityAttributes.reference.feedback) {
            this.answerFeedback = activityAttributes.reference.feedback;
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.fillInBlanksWording = this.referenceActivityGranule.activity_content;
        this.items[0] = this.referenceActivityGranule;
        this.apiAnswer = this.referenceActivityGranule.activity_content.answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                state: ItemAnswerStateEnum.pristine
            };
        });
        const suggestedButton: ItemAnswerInterface = {id: uuidv4(), answer: '["59"]', state: ItemAnswerStateEnum.pristine}; // todo until we get the value from api
        this.availableAnswers = [...this.apiAnswer, suggestedButton];
        this.loadUserSave();
    }

    /**
     * fetch answer in html element or from latex's Keyboard
     * want to use way more simplified 'map' but see todo in function...
     */
    getAnswersFromChild(): ItemAnswerInterface[] {
        const answersEntered = [];
        if (this.child) {
            if (this.isFormula) {
                return this.child.latexKeyboard.getAllLatex().map((answer) =>
                    ({
                        id: uuidv4(),
                        answer: answer,
                        state: ItemAnswerStateEnum.pristine,
                    })
                );
            } else {
                // TODO: Ultra ugly but elementRef.nativeElement.querySelectorAll is a 'nodeList', if i use 'map()' it throw error map is not a function
                for (const el of this.elementRef.nativeElement.querySelectorAll('.renderedInputContent')) {
                    answersEntered.push({
                        id: uuidv4(),
                        answer: el.innerText,
                        state: ItemAnswerStateEnum.pristine,
                    });
                }
            }
        }
        return answersEntered;
    }

    protected setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete') {
            const answers = this.userSave.get('userActivity').entitySave.answers;
            this.answersSelected = answers.map((answer) => ({
                id: answer.id,
                answer: answer,
                state: ItemAnswerStateEnum.pristine,
            }));
            // todo: i dont know why we send user's answer btw...
            this.activitiesService.userAnswer.next(answers);
        }
        this.setUserAnswersToForm();
        this.checkAnswer();
    }

    private setUserAnswersToForm(): void {
        if (this.isFormula) {
            this.child.latexKeyboard.pushAllLatex(this.answersSelected.map((item) => item.answer));
        } else {
            this.elementRef.nativeElement.querySelectorAll('.renderedInputContent')
                .forEach((elem, index) => {
                    elem.innerText = this.answersSelected[index] && this.answersSelected[index].answer || '';
                });
        }
    }

    protected checkAnswer(): void {
        this.answerStatus = this.getAnswersFromChild().length ? answerStatusEnum.answered : answerStatusEnum.missing;
        this.answerValidated = true;
        this.disableOnChange.next(false);
        // réponse rentrées par l'user, elle ne sont pas encore corrigé (état de la réponse par defaut 'pristrine')
        this.answersSelected = this.correctAnswerState(this.getAnswersFromChild());
        this.setAnswerStatus();

        if ((this.childIsReady && this.testAnswer)) {
            this.childIsReady.showError(this.answersSelected, this.testAnswer);
        }

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});

        if (this.testAnswer) {
            this.activitiesService.checkAnswers.next({lessonCorrected: true});
        }

        if (this.autoCorrection && this.isFormula) {
            this.childIsReady.showError(this.answersSelected, this.testAnswer || this.autoCorrection);
            this.animateAnswerState();
        }

    }

    private setAnswerStatus(): void {
        const findMissingAnswer = this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.missing);
        const findWrongAnswer = this.answersSelected.some((answer) => answer.state === ItemAnswerStateEnum.incorrect);
        if (!findWrongAnswer && !findMissingAnswer) {
            this.answerStatus = answerStatusEnum.correct;
            this.btnValidateState = ItemAnswerStateEnum.currentlyCorrect;
        } else {
            this.btnValidateState = ItemAnswerStateEnum.incorrect;
            if (findWrongAnswer && findMissingAnswer) {
                this.feedback = 'activities.feedback.rb.missing_wrong';
                this.answerStatus = answerStatusEnum.wrong;
            } else {
                if (findWrongAnswer) {
                    this.btnValidateState = ItemAnswerStateEnum.incorrect;
                    this.feedback = 'activities.feedback.rb.wrong';
                    this.answerStatus = answerStatusEnum.wrong;
                }
                if (findMissingAnswer) {
                    this.btnValidateState = ItemAnswerStateEnum.missing;
                    this.feedback = 'activities.feedback.rb.missing';
                    this.answerStatus = answerStatusEnum.missing;
                }
            }
        }
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        let oldAnswers: ItemAnswerInterface[] = [];
        if (this.userSave && this.userSave.get('userActivity').entitySave.answers.length) {
            oldAnswers = this.correctAnswerState(this.userSave.get('userActivity').entitySave.answers.map((item) => ({
                id: uuidv4(),
                answer: item,
                state: ItemAnswerStateEnum.pristine
            })));
        }
        return {
            oldGrade: this.calculateGrade(oldAnswers),
            newGrade: this.calculateGrade(this.answersSelected)
        };
    }

    /**
     * corrige les réponses en affectant un etat cf ItemAnswerState
     * @param answerSelectedNotCorrected
     */
    correctAnswerState(answerSelectedNotCorrected: ItemAnswerInterface[]): ItemAnswerInterface[] {
        return this.apiAnswer.map((answerExpected: ItemAnswerInterface, index) => {
            // si réponse existe et que l'on corrige en incluant la sensibilité à la casse, on formate la reponse selon cas.
            if (!this.isCaseSensitive && this.answersSelected[index] && answerSelectedNotCorrected[index]) {
                answerSelectedNotCorrected[index].answer = answerSelectedNotCorrected[index].answer.toLowerCase();
            }
            // si la réponse est manquante
            if (answerSelectedNotCorrected[index] && answerSelectedNotCorrected[index].answer === '' || !answerSelectedNotCorrected[index]) {
                return {
                    id: uuidv4(),
                    answer: '',
                    state: ItemAnswerStateEnum.missing,
                };
            } else {
                if (answerSelectedNotCorrected[index]) {
                    // si la réponse est correcte
                    if (answerSelectedNotCorrected[index].answer.replace(/ /g, '') === this.getParsedAnswer(answerExpected.answer)[0].replace(/ /g, '')) {
                        answerSelectedNotCorrected[index].state = ItemAnswerStateEnum.currentlyCorrect;
                    } else {
                        // si la réponse est incorrecte
                        answerSelectedNotCorrected[index].state = ItemAnswerStateEnum.incorrect;
                    }
                }
                // retourne la réponse avec le bon state cf ItemAnswerState
                return answerSelectedNotCorrected[index];
            }
        });
    }

    private calculateGrade(answersEntered: ItemAnswerInterface[]): number {
        return answersEntered.filter((item: ItemAnswerInterface) => item.state === ItemAnswerStateEnum.wasCorrect).length / this.apiAnswer.length;
    }

    protected seeAnswerSolution(): void {
        this.answersSelected = this.getAnswersFromChild().slice();
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');
        let solutionElements;

        if (this.isFormula) {
            solutionElements = elcolor;
            this.child.latexKeyboard.pushAllLatex(this.apiAnswer.map((answer) => answer.answer));
        } else {
            solutionElements = anstxt;
        }

        solutionElements.forEach((elem, index) => {
            elem.classList.remove('hide');
            const errorElem = msgicon[index];
            errorElem.classList.remove('missingResponse');
            errorElem.classList.remove('wrong');
            errorElem.classList.remove('hide'); // oui mais
            errorElem.classList.add('right');

            if (this.isFormula) {
                wrappers[index].classList.add('correction');
            } else {
                elem.innerText = this.apiAnswer[index].answer;
                elcolor[index].classList.add('hide');
            }
        });
        this.displaySolution = true;
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.answersSelected = [];
        if (resetAllSubscribe) {
            this.childReadiness.next(null);
            this.answerFeedback = null;
            if (this.disableOnChange) {
                this.disableOnChange.next(null);
                this.disableOnChange.complete();
            }
            this.disableOnChange = new ReplaySubject<boolean>(1);
        }
        this.resetField();
        if (this.isFormula) {
            this.child.latexKeyboard.pushAllLatex([]);
        }
        this.answerValidated = false;
        this.disableOnChange.next(false);
        return super.reset(resetAllSubscribe, type);
    }

    private resetField(): void {
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');

        elcolor.forEach((elem, index) => {
            const errorElem = msgicon[index];
            if (errorElem) {
                errorElem.classList.remove('right');
                errorElem.classList.remove('wrong');
                errorElem.classList.remove('validated');
                errorElem.classList.remove('missingResponse');
                errorElem.classList.add('hide');
            }

            if (this.isFormula) {
                /* HACK : L'un des passage pour arriver ici c'est ngOnInit -> initialize -> reset, mais this.child n'est pas encore valorisé
                 *  donc on s'attends à ce que ça plante sur cette ligne.
                 *  Mais comme on est en initialisation "elcolor" est lui aussi vide (tableau vide) donc on passe pas dans cette boucle.
                 */
                wrappers[index].classList.remove('correction');
            } else if (anstxt[index]) {
                elem.innerText = '';
                elem.classList.remove('hide');
                anstxt[index].innerText = '';
                anstxt[index].classList.remove('hide');
            }
        });
    }

    protected reviewAnswer(): void {
        const anstxt = this.elementRef.nativeElement.querySelectorAll('.ans-txt');
        const msgicon = this.elementRef.nativeElement.querySelectorAll('.msg-icon');
        const elcolor = this.elementRef.nativeElement.querySelectorAll('.renderedInputContent');
        const wrappers = this.elementRef.nativeElement.querySelectorAll('.latex-wrapper');
        let solutionElements;

        if (this.isFormula) {
            solutionElements = elcolor;
            this.child.latexKeyboard.pushAllLatex([]);
        } else {
            solutionElements = anstxt;
        }

        solutionElements.forEach((elem, index) => {
            elem.classList.remove('hide');
            const errorElem = msgicon[index];
            errorElem.classList.remove('right');
            errorElem.classList.remove('wrong');
            errorElem.classList.remove('missingResponse');
            errorElem.classList.add('hide');

            if (this.isFormula) {
                wrappers[index].classList.remove('correction');
            } else {
                elem.innerText = '';
                elcolor[index].innerText = '';
                elcolor[index].classList.remove('hide');
            }
        });

        this.setUserAnswersToForm();
        this.displaySolution = false;
    }

    protected saveAnswer(): Observable<any> {
        return of(this.answersSelected.map((item: ItemAnswerInterface) => item.answer));
    }

    public get isPreview(): boolean {
        return this.lessonsService.isLessonTest() || this.lessonsService.isLessonTraining();
    }

    public clickAnswer(option?: ItemAnswerInterface): void {
        if (this.autoCorrection) {
            this.answersSelected = [{
                id: option ? option && option.id : uuidv4(),
                answer: option ? option && this.getParsedAnswer(option.answer)[0] : this.getAnswersFromChild()[0].answer,
                state: ItemAnswerStateEnum.pristine,
            }];
            this.setUserAnswersToForm();
            this.checkAnswer();
        } else {
            this.answersSelected = [{
                id: uuidv4(),
                answer: this.getParsedAnswer(option.answer)[0],
                state: ItemAnswerStateEnum.pristine,
            }];
            this.setUserAnswersToForm();
        }
    }

    public get isDisabledOptions(): boolean {
        // verifie si toutes les bonnes réponses ont été trouvées/selectionnées
        return this.apiAnswer.length === this.answersSelected.length &&
            !this.answersSelected.some((answer: ItemAnswerInterface) => answer.state === ItemAnswerStateEnum.pristine ||
                answer.state === ItemAnswerStateEnum.incorrect ||
                answer.state === ItemAnswerStateEnum.missing);
    }

    public getParsedAnswer(answer: string): string[] {
        return answer && JSON.parse(answer);
    }

    public isCurrentAnswerState(state): boolean {
        return this.answerStatus === ItemAnswerStateEnum[state];
    }

    private animateAnswerState(): void {
        const answerResult: AnswerResultInterface = {
            id: +this.activityId.id,
            isAnswerCorrect: undefined,
            isLast: undefined
        };
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            if (this.isSuggestedButtons) {
                this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
                this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            } else {
                this.btnValidateState = this.btnValidateState !== ItemAnswerStateEnum.currentlyCorrect ? ItemAnswerStateEnum.pristine : ItemAnswerStateEnum.wasCorrect;
                this.resetField();
            }
        }, 500);
        answerResult.isLast = this.isDisabledOptions; // return true if all answer correct cf isDisabledOptions method
        answerResult.isAnswerCorrect = this.isDisabledOptions; // return true if all field correct cf isDisabledOptions method
        // feedback in case of bad answer is store in the good answer in field feedback in other exo it's in bad answers but in this case whe have only one answer
        if (!answerResult.isAnswerCorrect
            && this.referenceActivityGranule.activity_content.answers.length > 0
            && this.referenceActivityGranule.activity_content.answers[0].feedback) {
            super.ifFeedbackOpenModal(null, this.referenceActivityGranule.activity_content.answers[0].feedback);
        }
        this.answerResult.next(answerResult);
        if (this.isDisabledOptions) {
            this.doAction('next', ['save']);
        }
    }

    public speakStateChanged(evt: { id: string, value: boolean }): void {
        this.ttsChange.next(evt);
    }
}
