import {tap, take, takeUntil, filter, mergeMap} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import {BaseActivityComponent} from '../base-activity.component';
import {combineLatest, Observable, of} from 'rxjs';

@Component({
    selector: 'app-multi',
    templateUrl: './multi.component.html'
})
export class MultiComponent extends BaseActivityComponent<any, any>implements OnInit, OnDestroy {
    private subLessonId: string; // id question set
    public hideContent = false; // used to hide content when some modal are opened to not showing in background next exo
    // tslint:disable-next-line:use-lifecycle-interface OnInit est déjà implémenté par BaseActivity
    ngOnInit(): void {
        // when modal is open in some case we want to hide next exercises until pop up is closed
        this.communicationCenter.getRoom('multi').getSubject('hide-content')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((res: boolean) => {
                this.hideContent = res;
            });

        if (!this.lessonsService.currentAssignment && !this.lessonsService.isAtLeastTrainer()) {
            this.communicationCenter
                .getRoom('activities')
                .next('backToLesson', {
                    forceUrl: this.lessonsService.settings.forceUrlByDefaultWhenBackToLessonList,
                    initialiseSubject: true
                });
        } else {
            this.activatedRoute.params.subscribe((params) => {
                this.subLessonId = params['subLessonId'];
                this.initialize();
            });

            this.communicationCenter
                .getRoom('activities')
                .getSubject('previous')
                .pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    filter((doNext: boolean) => !!doNext),
                    tap(() => this.launchPreviousActivity())
                )
                .subscribe();

            this.communicationCenter
                .getRoom('activities')
                .getSubject('next')
                .pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    filter((doNext: boolean) => !!doNext),
                    tap(() => this.launchNextActivity())
                )
                .subscribe();
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter.getRoom('multi').removeSubject('hide-content');
        this.communicationCenter
            .getRoom('activities')
            .next('backToLesson', {
                forceUrl: this.lessonsService.settings.forceUrlByDefaultWhenBackToLessonList,
                initialiseSubject: false
            });
        /**
         * le communication center utilise des replays subject, donc la dernière valeur émise est sauvé et nexté pour la prochaine souscription.
         * Ainsi, on a besoin de reinitialisé la donnée pour éviter un effet de bord au lancement d'un nouveau parcours
         */
        this.communicationCenter.getRoom('activities')
            .getSubject('next')
            .next(false);
        super.ngOnDestroy();
    }

    protected initialize(): void {
        this.reset();
        const activities: DataEntity[] = [];
        this.lessonsService.getLessonObs(this.subLessonId).pipe(
            take(1),
            mergeMap((subLesson: DataEntity) => {
                const activities$: Observable<DataEntity>[] = subLesson.get('reference').map((activity) => this.activitiesService.loadActivitiesFromId(activity.id));
                return combineLatest(activities$).pipe(
                    tap((activitiesEntities: DataEntity[]) => {
                        activitiesEntities.forEach((activity) => {
                            if (this.activitiesService.typeActivitiesToSkip(activity)) {
                                activity.attributes.metadatas.title = subLesson.get('metadatas').title;
                                activities.push(activity);
                            }
                        });
                    })
                );
            })).subscribe(() => {
            this.communicationCenter
                .getRoom('progress-bar-exo')
                .getSubject('numberOfQuestion')
                .next(activities.length);
            this.lessonsService.editSubLessonContentEdited = activities;
            this.playFirstActivity();
            this.communicationCenter.getRoom('header-exo').getSubject('show').next(true);
        });
    }

    protected reset(resetAllSubscribe = false, type = null): Observable<boolean> {
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('reset').next(true);
        if (this.lessonsService.currentAssignment) {
            this.lessonsService.initializeProgressInSubLesson();
        }
        this.lessonsService.editSubLessonContentEdited = [];
        this.lessonsService.editCurrentActivityInSubLesson = null;
        return super.reset(resetAllSubscribe, type);
    }

    public canlaunchPrevious(): boolean {
        return this.indexInSublesson > 0;
    }

    public canlaunchNext(): boolean {
        return this.indexInSublesson < this.lessonsService.subLessonContentEdited.length - 1;
    }

    public launchPreviousActivity(): void {
        if (this.canlaunchPrevious() === false) {
            return;
        }
        let currentActivityIndex = +this.indexInSublesson;
        currentActivityIndex--;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        this.activitiesService.navigateHere(this.lessonsService.subLessonContentEdited[this.indexInSublesson], false, '../..');
    }

    public launchNextActivity(): void {
        if (this.canlaunchNext() === false) {
            if (this.lessonsService.currentAssignment && this.lessonsService.currentLesson) {
                const score = this.lessonsService.calculateAndSaveQuestionSetProgress(this.subLessonId);
                this.activitiesService.playScreenStatus = 0;
                if (this.lessonsService.settings.useRewardPageInsteadOfRecap ||
                    (this.activitiesService.activitiesArray.length <= this.activitiesService.presentArrayElementIndex + 1)) {
                    this.navigateToRecapOrReward(this.subLessonId);
                    if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
                        const statementData = {
                            id: `questionSet/${this.subLessonId}`,
                            result: {
                                score: {
                                    scaled: score / 100,
                                },
                                completion: true
                            }
                        };
                        this.communicationCenter.getRoom('lrs').getSubject('activity_complete').next(statementData);
                    }
                } else {
                    this.activitiesService.loadNextActivity();
                }
            }
        } else {
            let currentActivityIndex = +this.indexInSublesson;
            currentActivityIndex++;
            this.activitiesService.currentActivityIndex = currentActivityIndex + 1;
            this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
            this.activitiesService.navigateHere(this.lessonsService.subLessonContentEdited[currentActivityIndex],
                false,
                '../..',
                this.activitiesService.mustSkipLocationBetweenSubActivities());
        }
    }

    /**
     * navigate to recap or to reward (reward page was done for ubolino and is launch at end af each question set)
     * @private
     */
    private navigateToRecapOrReward(subLessonId): void {
        if (this.lessonsService.settings.useRewardPageInsteadOfRecap === true) {
            // hide progress bar
            this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide').next(true);
            this.activitiesService.navigateToRecapOrReward(subLessonId);
        } else {
            this.activitiesService.navigateToRecapOrReward();
        }
    }

    public displayUserActionButton(UserActionButtonName: string): boolean {
        if (this.lessonsService.settings['actionButtonsInMultiMatrix'][this.getCurrentActivityType()]) {
            return this.lessonsService.settings['actionButtonsInMultiMatrix'][this.getCurrentActivityType()].includes(UserActionButtonName);
        }
        return this.lessonsService.settings['actionButtonsInMultiMatrix']['default'].includes(UserActionButtonName);
    }

    public get indexInSublesson(): number {
        if (this.lessonsService.currentActivityInSubLesson) {
            return this.lessonsService.subLessonContentEdited
                .findIndex((activity) => +activity.id === +this.lessonsService.currentActivityInSubLesson.id);
        }
        return null;
    }

    public getCurrentActivityType(): string {
        return this.indexInSublesson !== null && this.lessonsService.subLessonContentEdited[this.indexInSublesson] ?
            this.lessonsService.subLessonContentEdited[this.indexInSublesson].attributes.metadatas.typology.label : '';
    }

    private playFirstActivity(): void {
        const currentActivityIndex = +this.indexInSublesson;
        this.activitiesService.currentActivityIndex = currentActivityIndex + 1;
        this.lessonsService.editCurrentActivityInSubLesson = this.lessonsService.subLessonContentEdited[currentActivityIndex];
        this.activitiesService.navigateHere(this.lessonsService.subLessonContentEdited[currentActivityIndex], true, '.');
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(attributes): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        throw new Error('Method not implemented.');
    }
}
