<fuse-profile-about *ngIf="!getMode()"></fuse-profile-about>
<fuse-edit-profile-forms *ngIf="getMode()"></fuse-edit-profile-forms>
<div class="hideZone"></div>
<ng-container *ngIf="!getMode()">
    <app-join-class *ngIf="profileProvider.settings.joinClassByCode"></app-join-class>
    <div class="title">{{'account-management.manage-accounts' | translate}}</div>
    <div mat-dialog-actions>
        <button [routerLink]="'/logout'" class="btn-logout" mat-raised-button>
            <mat-icon svgIcon="logout"></mat-icon>
            <span>{{'account-management.logout' | translate}}</span>
        </button>
        <button [routerLink]="'/connect'" class="btn-delete" mat-raised-button>
            <mat-icon svgIcon="trash"></mat-icon>
            <span>{{'account-management.manage-local-accounts' | translate}}</span>

        </button>
        <!-- <button [routerLink]="'/logout'" mat-raised-button>
            <span>{{'account-management.delete-account' | translate}}</span>
            <mat-icon svgIcon="edit"></mat-icon>
        </button> -->
    </div>
</ng-container>