import {Directive, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';

// TODO: Add Angular decorator.
@Directive()
export abstract class AutoUnsubscribeTakeUntilClass implements OnDestroy {
    protected unsubscribeInTakeUntil = new Subject();

    ngOnDestroy(): void {
        this.unsubscribeInTakeUntil.next();
        this.unsubscribeInTakeUntil.complete();
    }
}