<app-generic-activity>
    <div class="inject-left">
        <button mat-fab [disabled]="canJumpBelow === false" (click)="selfJumpBelow()">
            <mat-icon svgIcon="drag_indicator"></mat-icon>
        </button>
    </div>
    <div class="inject-middle">
        <mat-icon svgIcon="{{ getContentTypeIcon() }}"></mat-icon>
        <span>{{editableActivity.title}}</span>
    </div>
    <div class="inject-right">
        <button mat-fab (click)="selfEdit()">
            <mat-icon svgIcon="edit"></mat-icon>
        </button>
        <!-- TODO: remove the ngIf when we will create preview for Tool Activity type -->
        <button  mat-fab *ngIf="editableActivity.type !== 'Tool'" (click)="selfPlay()">
            <mat-icon svgIcon="play"></mat-icon>
        </button>
        <button  mat-fab (click)="selfDelete()">
            <mat-icon svgIcon="trash"></mat-icon>
        </button>
    </div>
</app-generic-activity>
