<div fxFill fxLayoutAlign="center center" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!showSpinner">
    <mat-accordion displayMode="flat">
        <app-assignments-list [chaptersChildrenIds]="chaptersChildrenIds"
                              (assigmentsListEvent)="getAssignmentsList($event)"
                              (autoAssigmentsListEvent)="getAutoAssignmentsList($event)"
                              (closedAssigmentsListEvent)="getClosedAssignmentsList($event)"></app-assignments-list>
        <mat-expansion-panel *ngFor="let chaptersParent of chaptersParents"id="{{chaptersParent.id}}" [expanded]="chaptersParent['openedPanel']">
            <mat-expansion-panel-header [attr.data-e2e]="chaptersChildrenIds" appAddClassInRegardOfTextHeight>
                <mat-panel-title>
                    <span>{{ chaptersParent.name}}</span>
                </mat-panel-title>
                <mat-icon svgIcon="chevron"></mat-icon>
            </mat-expansion-panel-header>
            <ul *ngIf="!loadingAssignments">
                <li *ngFor="let child of getChaptersByParents( chaptersParent.id )">
                    <span class="title">{{ child.label}}</span>
                    <app-lesson-card [params]="params" [chapterId]="[child.id, chaptersParent.id]" [assigmentsList]="assigmentsList"
                                     [autoAssigmentsList]="autoAssigmentsList" [closedAssigmentsList]="closedAssigmentsList"></app-lesson-card>
                </li>
            </ul>
        </mat-expansion-panel>
    </mat-accordion>
    <img *ngIf="showPlaceholder" class="placeholder" src="assets/{{ brand }}/images/backgrounds/placeholder.svg" alt="">
</ng-container>
<app-continue-bar></app-continue-bar>
