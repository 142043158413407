<form name="followedForm">
    <mat-toolbar class="filters">
        <mat-toolbar-row fxLayoutGap="24px">
            <mat-form-field *ngIf="displayFilters('type')">
                <mat-select #typeSelect
                            placeholder="{{ 'generic.type' | translate }}"
                            (selectionChange)="selectFilter($event, 'assignments_type')"
                            [formControl]="typeFilter">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option [value]="option.id" *ngFor="let option of allTypes">
                        {{ localizedType(option?.label) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('title')">
                <input #titleInput="matInput" matInput [formControl]="titleFilter"
                       placeholder="{{ 'generic.title' | translate }}">
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('exoName')">
                <input #titleInput="matInput" matInput [formControl]="titleFilter"
                       placeholder="{{ 'generic.two_steps_selection_Title_underlist' | translate }}">
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('schoolyear')">
                <mat-select name="schoolyear"
                            [value]="currentSchoolYearBegin"
                            placeholder="{{ 'groups-management.chose_school.year' | translate }}"
                            (selectionChange)="selectFilter($event, 'schoolYearDates')">
                    <mat-option [value]="'all'">{{ 'generic.allf' | translate }}</mat-option>
                    <mat-option
                        *ngFor="let schoolYear of schoolYearsList "
                        [value]="schoolYear.get('name')"> {{ displaySchoolYear(schoolYear.get('name'))}} </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('comment')">
                <input #commentInput="matInput" matInput [formControl]="commentFilter"
                       placeholder="{{ 'generic.label_group' | translate }}">
            </mat-form-field>

        </mat-toolbar-row>

        <mat-toolbar-row fxLayoutGap="24px">
            <!-- Groups Field -->
            <mat-form-field *ngIf="displayFilters('group')">
                <mat-select #groupSelect placeholder="{{ 'filter.group' | translate }}"
                            [formControl]="customFilter['groupControl']"
                            (selectionChange)="selectGroups($event, 'group')">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let group of groupsList"
                                [value]="group.groupname">{{ group.groupname }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Workgroups Field -->
            <mat-form-field *ngIf="displayFilters('workgroup')">
                <mat-select #workgroupSelect placeholder="{{ 'filter.workgroup' | translate }}"
                            [formControl]="customFilter['workgroupControl']"
                            (selectionChange)="selectGroups($event, 'workgroup')">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let workgroup of workgroupsList"
                                [value]="workgroup.workgroupname">{{ workgroup.workgroupname }}</mat-option>
                </mat-select>
            </mat-form-field>

            <!--begin-date-->
            <mat-form-field *ngIf="displayFilters('beginDate')">
                <input name="beginDate" [formControl]="beginDate" matInput [matDatepicker]="duepicker"
                       placeholder="{{'generic.begin_period' | translate}}">
                <mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
                <mat-datepicker #duepicker></mat-datepicker>
            </mat-form-field>

            <!--end-date-->
            <mat-form-field *ngIf="displayFilters('endDate')">
                <input name="endDate" [formControl]="endDate" matInput [matDatepicker]="duepicker"
                       placeholder="{{'generic.end_period' | translate}}">
                <mat-datepicker-toggle matSuffix [for]="duepicker"></mat-datepicker-toggle>
                <mat-datepicker #duepicker></mat-datepicker>
            </mat-form-field>

            <!-- learner Field -->
            <mat-form-field *ngIf="displayFilters('learner')">
                <mat-select learnerSelect placeholder="{{ 'assignment.select_learners' | translate }}"
                            (selectionChange)="selectLearners($event)" multiple>
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let learner of learnersList"
                                [value]="learner.id">{{ learner.username }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="displayFilters('state')">
                <mat-select #stateSelect
                            placeholder="{{ 'generic.state' | translate }}"
                            (selectionChange)="selectFilter($event, 'assignments_state')">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option [value]="option.id" *ngFor="let option of allStates">
                        {{ localizedState(option?.get('label')) | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>


            <!-- educationalLevel Field -->
            <mat-form-field *ngIf="displayFilters('educationalLevel')">
                <mat-select #educationalLevelSelect placeholder="{{ 'generic.level' | translate }}"
                            [formControl]="customFilter['educationalLevelControl']"
                            (selectionChange)="selectFilter($event, 'educationalLevel')">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let educationalLevel of educationalLevels"
                                [value]="educationalLevel.id">{{ educationalLevel.get('label') }}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-raised-button (click)="launchSearch()">{{ 'generic.search' | translate }}</button>

            <div class="py-4 font-size-14 count-entities"
                 fxlayout="row"
                 fxlayoutalign="start center">
                {{ countEntities }} {{'generic.results' | translate }}
            </div>

        </mat-toolbar-row>
    </mat-toolbar>
</form>
