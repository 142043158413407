<div id="header">
    <button data-e2e="toogle-menu" role="button" class="btn-menu" (click)="toggleMenu()">
        <mat-icon data-e2e="icon_main_menu" aria-hidden="true" svgIcon="main_menu"></mat-icon>
        <span class="sr-only">{{'accessibility.header.menu' | translate}}</span>
    </button>

    <button data-e2e="back-button" role="button" class="btn-back" *ngIf="canBack || isConnectPage" (click)="back()">
        <mat-icon aria-hidden="true" svgIcon="back"></mat-icon>
        <span class="sr-only">{{'accessibility.header.back' | translate}}</span>
    </button>

    <app-progress data-e2e="progress-wins" [level]="level" [coins]="coins" [img]="'coin'"></app-progress>

    <button *ngIf="showTranslateMenu" mat-button fxHide fxShow.gt-xs
            class="language-button"
            [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
            <mat-icon class="mr-8" *ngIf="!showFlagImg" svgIcon="earth"></mat-icon>
            <img class="flag mr-8" *ngIf="showFlagImg"
                 src="assets/{{ brand }}/images/flags/{{ selectedLanguage.flag }}.png">
            <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
        </div>
    </button>

    <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
            <span fxLayout="row" fxLayoutAlign="start center">
                <img class="flag mr-16" *ngIf="showFlagImg" src="assets/{{ brand }}/images/flags/{{lang.flag}}.png">
                <span class="iso">{{lang.title}}</span>
            </span>
        </button>

    </mat-menu>

</div>
