<div>
    <div #readMe appReadable [uuid]="uuid" class="rb-question-text renderedContent-{{uuid}} tts"
         [autoRead]="false"
         [forceText]="forceText"
         [ngClass]="{'tts-reading': isTTSReadingText}"
         (speaking)="speakStateChanged($event, uuid)">
        <div appReplaceTargetContent [innerHtml]="question | stringToHtml"></div>
        <mat-icon [svgIcon]="isTTSReadingText?'sound_on':'sound_off'" (click)="clickToTts()"></mat-icon>
    </div>

    <div [ngClass]="{hide: !latexKeyboard}" [id]="'latex-keyboard-'+uuid"></div>
</div>