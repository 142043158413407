<div id="tooltip" [style.top.px]="customSettingsTooltip?.tooltipY" [style.left.px]="customSettingsTooltip?.tooltipX" [ngClass]="customSettingsTooltip?.type">
  <div class="tooltips">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <div [innerHtml]="this.customSettingsTooltip?.modalContent?.header"></div>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
          <div [innerHtml]="this.customSettingsTooltip?.modalContent?.content"></div>
      </mat-card-content>
    </mat-card>
  </div>
</div>




