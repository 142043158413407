import {dateTimeOptions} from '../settings';
import * as moment from 'moment';
import {Observable, Observer} from 'rxjs';
import * as _ from 'lodash';

export const localizedDate = function (date: number, lang?: string): string {
    return new Date(date * 1000).toLocaleDateString(lang ? lang : navigator.language, dateTimeOptions);
};

export const localizedTime = function (date: number, lang?: string): string {
    return new Date(date * 1000).toLocaleTimeString(lang ? lang : navigator.language, dateTimeOptions);
};

export const currentTimestamp = function (): number {
    const current = new Date();
    return Math.round(current.getTime() / 1000);
};

export const isEmpty = function (object: object): boolean {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
};

export const secondsToHoursMinutesSeconds = function (length: number): string {
    let duration = '';

    if (length) {
        const time = moment.duration(length, 'seconds');

        const hours = time.hours();
        const minutes = time.minutes();
        const seconds = time.seconds();

        if (hours) {
            duration += hours + ':';
        }

        if (minutes < 10 && hours) {
            duration += '0';
        }

        duration += minutes + ':';

        if (seconds < 10 && minutes) {
            duration += '0';
        }

        duration += seconds;
    }

    return duration;
};

/**
 * extract in an array all url contain in a text
 * @param fullText : string with or without url inside
 */
export const extractUrls = function (fullText: string): string[] {
    return fullText ? fullText.match(/\bhttps?:\/\/\S+/gi) : null;
};

/**
 * Load the image, transform and return it as a base64 string
 * @param url
 */
export const getBase64ImageFromURL = (url: string): Observable<string> => {
    return Observable.create((observer: Observer<string>) => {
        // create an image object
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = url;
        if (!img.complete) {
            // This will call another method that will create image from url
            img.onload = () => {
                observer.next(getBase64Image(img));
                observer.complete();
            };
            img.onerror = err => {
                observer.error(err);
            };
        } else {
            observer.next(getBase64Image(img));
            observer.complete();
        }
    });
};

/**
 * Translate an image to a base64 string
 * @param img
 */
export const getBase64Image = (img: HTMLImageElement): string => {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL: string = canvas.toDataURL('image/png');
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
};

/**
 * Set opacity of rgb color
 * @param color RBG value
 * @param factor
 */
export const rgbOpacity = (color: string, factor: string) => {
    const [r, g, b, ...tail] = color.substr(4, color.length - 1 - 4).split(',');
    return `rgb(${r},${g},${b},${factor})`;
};

/**
 * Return a copy of the array in another order
 * @param arr the array to shuffle
 * @param forceChange prevent the rare case to return the same order if true
 */
export const shuffle = <T>(arr: T[], forceChange: boolean = false) => {
    let shuffled = _.shuffle(arr.slice());

    if (forceChange && shuffled.length > 1 && _.isEqual(arr, shuffled)) {
        shuffled = shuffle(arr, true);
    }

    return shuffled;
};
