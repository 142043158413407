
import {takeUntil} from 'rxjs/operators';
import {Component, Input, OnInit} from '@angular/core';
import { MatRadioChange } from "@angular/material/radio";
import {ActivitiesService} from "@modules/activities/core/activities.service";
import {Subject} from "rxjs";

@Component({
    selector: 'app-trainer-feedback',
    templateUrl: './trainer-feedback.component.html'
})
export class TrainerFeedbackComponent implements OnInit {
    @Input('activity') public activity: any;
    @Input('contextId') private contextId: string;
    @Input('displayMode') private mode: string;

    public chooseActionForActivity = 'seen';
    private unsubscribeInTakeUntil = new Subject();

    constructor(
        private activitiesService: ActivitiesService
    ) {}

    ngOnInit() {
        this.loadUserSave();
    }

    public get canEdit(): boolean {
        return this.mode === 'feedback';
    }

    private loadUserSave(): void {
        this.activitiesService.getUserSave(this.activity.id, this.contextId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if (userSave) {
                    this.setFeedback(userSave.get('feedback'));
                }
            });
    }

    private setFeedback(feedback: string): void {
        if (feedback) {
            this.chooseActionForActivity = feedback;
        }
    }

    public radioChange($event: MatRadioChange) { }
}
