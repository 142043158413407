<div fxFill fxLayoutAlign="center center" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
<ng-container *ngIf="!showSpinner">
    <div class="button-tab"><span>{{ 'educational_list.button_tab' | translate }}</span></div>
    <mat-form-field>
        <mat-label>{{ 'educational_list.select_label' | translate }}</mat-label>
        <mat-icon svgIcon="chevron" matSuffix></mat-icon>
        <mat-select data-e2e="select_first_level" [(ngModel)]="selectedLevel"
                    (selectionChange)="onEducationalLevelChanged($event.value)">
            <mat-option [attr.data-e2e]="level.id" [value]="level"
                        *ngFor="let level of levelsDataEntity">{{level.get('label')}}</mat-option>
        </mat-select>
    </mat-form-field>
    <ng-container *ngFor="let concept of levelConcepts">
        <app-concept-card [attr.data-e2e]="concept.id" [data]="concept" [hasAssignedLessons]="userHasAssignmentPending(concept.id)"
                          [selectedLevelId]="selectedLevel?.id" [userProgress]="getConceptProgress(concept.id)"
                          (idConcept)="storeIddConceptSelected($event)"></app-concept-card>
    </ng-container>
    <img *ngIf="showPlaceholder" class="placeholder" src="assets/{{ brand }}/images/backgrounds/placeholder.svg" alt="">
</ng-container>
