<div class="container md-head head-marg-top">
    <div #readMe *ngIf="(wording ||instruction)" appReadable [autoRead]="autoRead" [wordingAudio]="wordingAudio" [instructionAudio]="instructionAudio"
         (speaking)="speakStateChanged($event)"
         [ngClass]="{'tts': true, 'wording': true, 'tts-reading': isTTSSpeaking, 'is-instruction':!!instruction && !imgTag}">
        <div *ngIf="wording" [ngClass]="{'wording': true, 'tts-reading': isTTSSpeaking }"
            appReplaceTargetContent
            [innerHtml]="wording | stringToHtml"></div>
        <div *ngIf="instructionUpdated"
            [ngClass]="{'instruction': true, 'tts-reading': isTTSSpeaking, 'is-picture':imgTag, 'is-video':instruction.includes('<video') }"
            appReplaceTargetContent
            [innerHtml]="instructionUpdated | stringToHtml">
        </div>

        <span *ngIf="additionalHiddenText && additionalHiddenText!==''"
            class="precision"
            appReplaceTargetContent>{{additionalHiddenText}}}</span>
        <mat-icon *ngIf="wording || (instruction && !instruction.includes('<img'))"
                  [svgIcon]="isTTSSpeaking?'sound_on':'sound_off'"></mat-icon>
    </div>
    <button mat-button [matMenuTriggerFor]="menu">
        {{ questionTypeName }}
    </button>
</div>
<div *ngIf="imgTag" id="consigneImg">
    <div [innerHtml]="imgTag | stringToHtml" [ngClass]="{'instruction': !this.fullscreenService.fullscreenImage, 'is-picture': !this.fullscreenService.fullscreenImage, 'fullscreen': this.fullscreenService.fullscreenImage}"></div>
    <div class="action-buttons" *ngIf="activitiesService.settings.imageFullscreenButton">
        <mat-icon role="button" *ngIf="!this.fullscreenService.fullscreenImage" class="material-icons" (click)="this.fullscreenService.openFullscreen('consigneImg')">
            fullscreen
        </mat-icon>
    </div>
</div>
<mat-menu #menu="matMenu">
    <button mat-menu-item>
        <mat-icon>format_list_bulleted</mat-icon>
        {{ 'generic.qcm' | translate }}</button>
    <button mat-menu-item>
        <mat-icon>format_list_numbered</mat-icon>
        {{ 'generic.qcu' | translate }}</button>
    <button mat-menu-item>
        <mat-icon>border_color</mat-icon>
        {{ 'generic.crt' | translate }}</button>
</mat-menu>