<div fxLayout="column" id="register">
    <div *ngIf="accountProvider.settings.selfSignup && !requestValidate" fxLayout="column" fxLayoutAlign="center center"
         id="register-form-wrapper">
        <div fxLayout="column" fxLayoutGap="30px" id="register-form" ngClass.lt-md="w-100-p">
            <div *ngIf="settingsAuth.displayLoginLogo" class="logo">
                <img *ngIf="!brandLogoSvg" class="logo-icon-png" src="assets/{{ brand }}/images/logos/brand-square.png">
                <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>
            <div *ngIf="userIsAdultOrMinor"> <!--TODO replace by ng-container -->
                <div class="title">{{ 'account-management.title' | translate}}</div>
                <div *ngIf="settings.displaySubTitle" class="sub-title">{{ 'account-management.you-are' | translate}}
                    <span>{{ 'generic.you-are.teacher' | translate}}</span></div>
                <form [formGroup]="registerForm" fxLayout="row wrap" name="registerForm" novalidate>
                    <mat-form-field *ngIf="displayField('label')" class="pseudo">
                        <input [required]="isRequired('label')" formControlName="pseudo"
                               matInput placeholder="{{ 'account-management.pseudo' | translate}}">
                        <mat-error *ngIf="registerFormErrors.pseudo?.required || registerFormErrors.label?.required">
                            {{ 'generic.field.error.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="registerFormErrors.pseudo?.invalid || registerFormErrors.label?.invalid">
                            {{ 'authentication.email_error_wrong' | translate }}
                        </mat-error>
                        <mat-error *ngIf="registerFormErrors.pseudo?.errorTypo || registerFormErrors.label?.errorTypo">
                            {{ 'generic.error_typo_not_allowed' | translate }}
                        </mat-error>
                        <mat-error
                            *ngFor="let error of flat(registerFormErrors.pseudo?.customErrors, registerFormErrors.label?.customErrors)">
                            {{ error | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="displayField('contact_email')">
                        <input [required]="isRequired('contact_email')" formControlName="contact_email"
                               matInput placeholder="{{ 'account-management.email_contact_sentence' | translate}}">
                        <mat-error *ngIf="registerFormErrors.contact_email.required">
                            {{ 'authentication.email.required' | translate}}
                        </mat-error>
                        <mat-error
                            *ngIf="!registerFormErrors.contact_email.required && registerFormErrors.contact_email.email">
                            {{ 'authentication.email.invalid' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="displayField('email')" class="field-email">
                        <input [required]="isRequired('email')" formControlName="email"
                               matInput placeholder="{{ 'account-management.email' | translate}}">
                        <mat-error *ngIf="registerFormErrors.email.required">
                            {{ 'generic.field.error.required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="!registerFormErrors.email.required && registerFormErrors.email.email">
                            {{ 'authentication.email.invalid' | translate }}
                        </mat-error>
                        <mat-error *ngFor="let error of registerFormErrors.email.customErrors">
                            {{ error | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="displayField('you_are')" class="field-youAre">
                        <mat-select [required]="isRequired('you_are')"
                                    formControlName="you_are"
                                    placeholder="{{ 'account-management.you-are' | translate}}">
                            <mat-option *ngFor="let item of profileService.getYouAreValues()" [value]="item">
                                {{ 'generic.you-are.' + item | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerFormErrors.you_are">
                            {{ 'account-management.you-are.required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <ng-container *ngIf="displayField('password')">
                        <mat-form-field>
                            <input [required]="isRequired('password')" formControlName="password"
                                   matInput
                                   placeholder="{{ 'account-management.password' | translate }}" type="password">
                            <mat-error *ngIf="registerFormErrors.password.required">
                                {{ 'authentication.password.required' | translate}}
                            </mat-error>
                            <mat-error *ngIf="registerFormErrors?.password?.passwordPolicyError">
                                {{ 'authentication.password.passwordPolicyError' | translate}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="displayField('password')">
                            <input [required]="isRequired('password')" formControlName="passwordConfirm"
                                   matInput
                                   placeholder="{{ 'account-management.password-confirm' | translate }}"
                                   type="password">
                            <mat-error *ngIf="registerFormErrors.passwordConfirm.required">
                                {{ 'account-management.password-confirm.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="registerFormErrors.passwordConfirm.passwordsNotMatch">
                                {{ 'account-management.password-confirm.not-identical' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>

                    <mat-form-field *ngIf="displayField('institution')">
                        <input [required]="isRequired('institution')" formControlName="institution"
                               matInput placeholder="{{ 'account-management.institution' | translate }}">
                    </mat-form-field>


                    <mat-form-field *ngIf="displayField('find_us')">
                        <mat-select [required]="isRequired('find_us')"
                                    formControlName="find_us"
                                    placeholder="{{ 'account-management.find-us' | translate:translateRegisterParams }}">
                            <mat-option *ngFor="let item of profileService.getFindUsValues()" [value]="item">
                                {{ 'account-management.find-us.' + item | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerFormErrors.find_us">
                            {{ 'account-management.find-us.required' | translate:translateRegisterParams }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="displayField('level')">
                        <mat-select [required]="isRequired('level')"
                                    formControlName="level"
                                    placeholder="{{ 'account-management.level' | translate:translateRegisterParams }}">
                            <mat-option *ngFor="let item of userInformation.level_field; let i = index"
                                        [value]="item.id">
                                {{item.attributes.label | translate}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerFormErrors.level">
                            {{ 'account-management.level.required' | translate:translateRegisterParams }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field *ngIf="displayField('region')">
                        <mat-select [required]="isRequired('region')"
                                    formControlName="region"
                                    placeholder="{{ 'account-management.region' | translate:translateRegisterParams }}">
                            <mat-option *ngFor="let item of userInformation.region_field; let i = index"
                                        [value]="item.id">
                                {{item.attributes.label | translate}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerFormErrors.region">
                            {{ 'account-management.region.required' | translate:translateRegisterParams }}
                        </mat-error>
                    </mat-form-field>

                    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start" class="form-footer">
                        <mat-checkbox *ngIf="displayField('newsletter')"
                                      aria-label="Je souhaite recevoir les actualités"
                                      formControlName="newsletter" name="newsletter">
                            {{ 'account-management.newsletter' | translate:translateRegisterParams }}
                        </mat-checkbox>
                        <mat-checkbox aria-label="I read and accept" formControlName="terms" name="terms" required>
                            {{ 'account-management.accept' | translate }}
                            &nbsp;<a
                            [routerLink]="'/' +  ('account-management.cgupath' | translate:translateRegisterParams) ">{{ 'account-management.cgu' | translate }}
                            *</a>
                        </mat-checkbox>
                        <mat-error *ngIf="termsAdultNotAccepted">
                            {{ 'account-management.terms.required' | translate }}
                        </mat-error>

                        <!-- The value of the form control will be the verification token -->
                        <ng-hcaptcha *ngIf="captcha === 'hcaptcha'" formControlName="captcha" name="captcha"
                                     required></ng-hcaptcha>
                        <mat-error *ngIf="captchaPassed === false">
                            {{ 'generic.captchaNotPassed' | translate }}
                        </mat-error>

                        <button (click)="newRegister()" [disabled]="this.registerForm.invalid" class="submit-button"
                                color="accent"
                                mat-raised-button>{{ 'account-management.create' | translate}}
                        </button>
                    </div>
                </form>
                <div class="register go-login-link-wrapper" fxLayout="column" fxLayoutAlign="center center">
                    <span class="text">{{ 'account-management.account_already' | translate }} </span>
                    <a [routerLink]="'/login'" class="link">{{ 'authentication.btn-login' | translate}}</a>
                </div>
            </div>

            <div *ngIf="!userIsAdultOrMinor" class="bloc-minor-adult" fxFlexAlign="center center" fxLayout="column"
                 fxLayoutGap="30px">
                <div fxFlexAlign="center" fxLayout="column">
                    <button (click)="selectMinorOrAdult('minor')" class="submit-button w-100-p" color="accent"
                            mat-raised-button>
                        {{'account-management.user_minor' | translate }}
                    </button>
                </div>
                <div fxFlexAlign="center" fxLayout="column">
                    <button (click)="selectMinorOrAdult('adult')" class="submit-button w-100-p" color="accent"
                            mat-raised-button>
                        {{'account-management.user_adult' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div #widgetFC *ngIf="captcha === 'friendlyCaptcha'" class="frc-captcha" data-sitekey="FCMO9NIITAUMS1VJ"
             style="position:absolute!important; right: 0; bottom: 0;"></div>

    </div>

    <fuse-mail-confirm *ngIf="requestValidate" [data]="formData"></fuse-mail-confirm>
</div>
