<div class="preview_content">
    <div mat-dialog-title>
        <button class="close"
                (click)="close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div class="white">
        <app-animated-canvas [previewBadge]="badge"></app-animated-canvas>
    </div>
    <ng-container *ngIf='isUnlocked; then toggle; else buy'></ng-container>
    <ng-template #buy>
        <button class="action-button" mat-raised-button (click)="toggleBadge('buy')"
                [disabled]="this.userCoin < badge.get('price')">
            <span>{{"buy" | translate}}</span>
            <div class="mat-icon-group">
                <span>{{badge.get('price')}}</span>
                <mat-icon svgIcon="coin"></mat-icon>
            </div>
        </button>
    </ng-template>

    <ng-template #toggle>
        <button class="action-button" mat-raised-button *ngIf='!isSelected'
                (click)="toggleBadge('equip')">{{ 'gamification.badge.type.equip' | translate }}</button>

        <button (click)="toggleBadge('equip')" class="equipped-button" mat-raised-button
                *ngIf='isSelected'>{{ 'gamification.badge.type.equiped' | translate }}</button>
    </ng-template>
</div>

