<app-animated-canvas></app-animated-canvas>
<mat-list>
    <mat-list-item *ngFor="let badgeType of badges;let i=index;" (click)="goToBadgeTypeGallery(badgeType)">
        <button>
            {{badgeType.badgeTypeName}}
            <span *ngIf="badgeType.badgeTypeHasNewItem"> new*</span>
            <mat-icon
                [ngClass]="gamificationService.settings.badgeImageIconBigger.includes(gamificationService.settings.badgeImageIcon[i])?'bigger':''"
                [svgIcon]="gamificationService.settings.badgeImageIcon[i]"></mat-icon>
        </button>
    </mat-list-item>
</mat-list>