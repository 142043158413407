<mat-card class="activities-card inner-scroll mat-elevation-z0">

    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [wording]="wording" [wordingAudio]="wordingAudio" [instructionAudio]="instructionAudio" (isReading)="speakStateChanged($event, uuid)"
                           questionTypeName="{{ 'activities.questionTypeName.app-appaire' | translate }}">
            </app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <app-flashcard [flashCard]="flashcard" *ngFor="let flashcard of availableFlashCards" (state)="onStateChange($event, flashcard)"></app-flashcard>
    </mat-card-content>

    <div *ngIf="displayFeedback" class="answer-state-msg wrongreply">
        <p>{{ 'activities.feedback.app.wrong' | translate }}</p>
    </div>

    <mat-card-footer>
        <div class="gradePercentil"
             *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>
        <ng-container *ngFor="let button of buttons">
            <button
                [ngClass]="button.classCss"
                *ngIf="button.display"
                mat-raised-button
                [disabled]="isDone() === false || button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>

</mat-card>
