<!-- div class="page-layout simple " fxFlex--><!-- TODO remettre ce wrapper -->
<div fxFill fxLayoutAlign="center center" *ngIf="showSpinner">
    <mat-spinner></mat-spinner>
</div>
<mat-toolbar class="filters" *ngIf="!showSpinner">
    <mat-toolbar-row>
        <app-search-filters [fields]="fieldsToDisplay" [customLists]="{methods:methods}" [countEntities]="countEntities"
                            [customFieldName]="customFieldName"
                            [orderFields]="true"
                            [displayedFiltersIcons]="displayedFiltersIcons"
                            (launchSearch)="launchSearch($event)"></app-search-filters>
    </mat-toolbar-row>
    <mat-toolbar-row>
        <!--help video button-->
        <button *ngIf="videoUrlToLaunch()" mat-raised-button color="primary"  (click)="playHelpVideo()"
        class="btn-videoUrlToLaunch" >{{ 'generic.lessons.video.help' | translate }}</button>
    </mat-toolbar-row>
    <mat-toolbar-row>
        <!--help basic page button-->
        <button *ngIf="shouldDisplayHelpModalPage()" mat-raised-button color="primary"  (click)="displayHelpModalPage()">
            {{ 'generic.lessons.help.modal_page' | translate }}
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<div *ngIf="displayCreateLessonHelper && showAddLessonButton" class="mat-body create-lesson-helper">
    {{'lessons.create_lesson_helper'|translate}}
</div>

<div *ngIf="displayCommunityLessonHelper" class="mat-body create-lesson-helper">
    {{'lessons.community_lesson_helper'|translate}}
</div>

<div *ngIf="showLoader" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-spinner></mat-spinner>
</div>

<div class="cards content touch-scroll"
     [ngStyle]="{'visibility':showLoader?'hidden':'visible'}"
     fxLayout="row" fxLayoutAlign="start start" #scrollContainer>

    <div *ngIf="!showAddLessonButton && showAddLessonButtonCard" class="cardLayout-wrapper">
        <button (click)="lessonsService.launchEditor('', 'new', 'lesson')" class="add-file-button add-file-button--cardLayout">
            <mat-icon svgIcon="icon-lesson-add"></mat-icon>
            <span>{{ 'activities.lesson.dialog_new' | translate }}</span>
        </button>
    </div>

    <div *ngFor="let dataCard of dataCards" class="p-16" fxFlex="auto" fxFlex.gt-xs="50" fxFlex.gt-lg="33">
        <app-card [dataCard]="dataCard" [ngClass]="lessonsService.settings.cardLayout" fxFlex></app-card>
    </div>
</div>

<mat-paginator [length]="countEntities" [pageIndex]="pageIndex" [pageSizeOptions]="pageRangeOptions"
               (page)="onPaginateChange($event, type)">
</mat-paginator>

<div *ngIf="showAddLessonButton && !showAddLessonButtonCard" class="addBtnBottomRight" fx-layout="row"
     fx-flex fx-layout-align="end">
    <button [title]="'generic.create_lesson_title' | translate" (click)="lessonsService.launchEditor('', 'new', 'lesson')" mat-fab class="add-file-button">
        <mat-icon svgIcon="add"></mat-icon>
    </button>
</div>
