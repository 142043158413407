<form>
    <div class="mb-24 fontBold">{{ 'generic.select_'+ type +'_help' | translate}}</div>
    <div  class="mb-24"
          fxLayoutAlign="start start">
        <mat-form-field fxFlex="100">
            <input class="w-100-p" [formControl]="titleFilter" matInput placeholder="{{ 'generic.'+ type | translate }}">
        </mat-form-field>
    </div>

    <div class="mb-24 fontBold">{{tagsSelected.length}}
        <span *ngIf="tagsSelected.length < 2">{{ 'generic.selected_'+ type | translate}}</span>
        <span *ngIf="tagsSelected.length > 1">{{ 'generic.selected_'+ type +'_plural' | translate}}</span>
        <button  mat-icon-button (click)="resetTags()" *ngIf="tagsSelected.length > 0">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div class="mb-24 h-400">

        <table mat-table #table [dataSource]="dataSource">
            <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let entity">
                    <p class="text-truncate">{{entity.name}}</p>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let entity; columns: displayedColumns;"
                class="entity"
                (click)="selectedChapter(entity)"
                matRipple
                [ngClass]="{'mat-light-blue-50-bg': checkSelected(entity) > -1}">
            </tr>
        </table>
    </div>

</form>
