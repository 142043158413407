<div class="activity-bar">
    <div class="left">
        <ng-content select=".inject-left">
        </ng-content>
    </div>
    <div class="middle">
        <ng-content select=".inject-middle">
        </ng-content>
    </div>
    <div class="right">
        <ng-content select=".inject-right">
        </ng-content>
    </div>
</div>
<div class="below">
    <ng-content select=".inject-below">
    </ng-content>
</div>
