<div id="forgot-password" fxLayout="column">

    <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="forgot-password-form">

            <div class="logo" *ngIf="settingsAuth.displayLoginLogo">
                <img *ngIf="!brandLogoSvg" class="logo-icon-png" src="assets/{{ brand }}/images/logos/brand-square.png">
                <mat-icon *ngIf="brandLogoSvg" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>

            <div class="title">{{ 'forgot-password.title' | translate }}</div>

            <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate>

                <mat-form-field>
                    <input matInput placeholder="{{ 'forgot-password.email' | translate }}" formControlName="email">
                    <mat-error *ngIf="forgotPasswordFormErrors.email.required">
                        {{ 'authentication.email.required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="!forgotPasswordFormErrors.email.required && forgotPasswordFormErrors.email.email">
                        {{ 'authentication.email.invalid' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-error *ngIf="emailNotValidated">
                    {{ 'generic.email.validation.invalid' | translate }}
                </mat-error>

                <button (click)="sendResetLink()" mat-raised-button class="submit-button" color="accent"
                        aria-label="SEND RESET LINK">
                    {{ 'forgot-password.link.reset' | translate }}
                </button>
            </form>

            <div class="login go-login-link-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">{{ 'authentication.btn-login' | translate }}</a>
            </div>

        </div>

    </div>

</div>
