<div class="activities-dialog-title">
    <button mat-mini-fab mat-dialog-close color="white"
            class="close mat-elevation-z1"
            aria-label="Close dialog">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<mat-dialog-content [ngClass]="{'js-latex-is-open' : isLatexKeyboardDisplayed}">

    <!-- in case user launch a sub lesson, need button to navigate in sub lesson-->
        <div *ngIf="displayNavigation" class="preview-wrapper">
            <button mat-fab (click)="launchPrevious()"
                    [disabled]="canGoToPrevious === false"
                    [style.visibility]="settings?.hideUserActionButtonsWhenDisabled && !canGoToPrevious?'hidden':'visible'"
                    class="preview"
                    aria-label="preview">
                <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
            </button>
        </div>

        <div class="multi-content">
            <app-qcm #activeComponent *ngIf="template === 'QCM'" [activityId]='currentActivity'></app-qcm>
            <app-qcu #activeComponent *ngIf="template === 'QCMU'" [activityId]='currentActivity' [preview]="preview" [refresh]="refreshSubject"></app-qcu>
            <app-summary-activity #activeComponent *ngIf="template === 'summary'" [activityId]='currentActivity' [refresh]="refreshSubject"></app-summary-activity>
            <app-true-false #activeComponent *ngIf="template === 'VF'" [activityId]='currentActivity'></app-true-false>
            <app-short-answer #activeComponent *ngIf="template === 'CRT'" [activityId]='currentActivity'></app-short-answer>
            <app-ordon #activeComponent *ngIf="template === 'ORD'" [activityId]='currentActivity'></app-ordon>
            <app-text-matching #activeComponent *ngIf="template === 'TXTMAT'" [activityId]='currentActivity'></app-text-matching>
            <app-awareness #activeComponent *ngIf="template === 'awareness'" [activityId]='currentActivity'></app-awareness>
            <app-appaire #activeComponent *ngIf="template === 'APP'" [activityId]='currentActivity'></app-appaire>
            <app-fill-in-blanks #activeComponent *ngIf="template=='RB'" [activityId]='currentActivity'></app-fill-in-blanks>
            <app-divider #activeComponent *ngIf="template === 'divider'" [activityId]='currentActivity'></app-divider>
            <app-media #activeComponent *ngIf="isMedia" [activityId]='currentActivity'></app-media>
            <app-external #activeComponent *ngIf="template === 'EXT'" [activityId]='currentActivity'></app-external>
            <app-interactive-image #activeComponent *ngIf="template === 'IMGI'" [activityId]='currentActivity'></app-interactive-image>
            <app-multimedia #activeComponent *ngIf="template === 'MULTI'" [activityInput]='currentActivity' [refresh]="refreshSubject"></app-multimedia>
        </div>

        <div *ngIf="displayNavigation" class="next-wrapper">
            <button mat-fab (click)="launchNext()"
                    [disabled]="canGoToNext === false"
                    [style.visibility]="settings?.hideUserActionButtonsWhenDisabled && !canGoToNext?'hidden':'visible'"
                    class="next"
                    aria-label="next">
                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
            </button>
        </div>



</mat-dialog-content>
<mat-dialog-actions *ngIf="showBtnAction">
    <app-user-action-button
                            fxfill
                            [activityId]="currentActivity?.id"
                            [activityTitle]="currentActivity?.get('title')?currentActivity?.get('title'): data.activityTitle"
                            [isMedia]="isMedia"
                            [ShowPrevNextBtns]='true'></app-user-action-button>
</mat-dialog-actions>
