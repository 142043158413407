import {EditableActivity} from '@modules/activities/core/lessons/editor/models/editable-activity.class';
import {MultimediaPage} from '@modules/activities/core/lessons/editor/models/multimedia-page.class';

export class EditableMultimediaActivity extends EditableActivity {

    private _pages: MultimediaPage[] = [];
    private _toDeletePages: MultimediaPage[] = [];

    public get pages(): MultimediaPage[] {
        return this._pages;
    }

    public set pages(pages: MultimediaPage[]) {
        this._pages = pages;
    }

    public get deletedPages(): MultimediaPage[] {
        return this._toDeletePages;
    }

    public addPage(page: MultimediaPage): void {
        this._pages = [...this._pages, page];
    }

    public deletePage(page: MultimediaPage): void {
        this._toDeletePages.push(page);
        this._pages = this._pages.filter(p => p !== page);
    }

    public jumpBelow(page: MultimediaPage): void {
        this._pages = this.switchPositions(this._pages, this._pages.indexOf(page), this._pages.indexOf(page) + 1);
    }

    // TODO remove it because it's the same than EditableLesson
    private switchPositions<T> (arr: T[], oldIndex: number, newIndex: number): T[] {
        const tempArr = arr.slice();
        const tempValue = tempArr[oldIndex];
        tempArr[oldIndex] = tempArr[newIndex];
        tempArr[newIndex] = tempValue;
        return tempArr;
    }
}
