<div class="tools page-layout simple inner-scroll" >
    <!-- HEADER -->
    <div class="header p-16" fxLayout="row" fxLayoutAlign="start center">
        <h2 *ngIf="displayHeaderTitle">{{ 'generic.tools' | translate }} > {{ 'activities.forms_model_title' | translate }}</h2>
        <app-getting-started [alias]="lessonsService.settings.gettingStarted.formModel"></app-getting-started>
    </div>
    <!-- / HEADER -->
    <ng-template #archiveButton>
        <mat-form-field class="filters-field-archive">
            <mat-select placeholder="{{ 'generic.archive' | translate }}" [(ngModel)]="archiveFilter" (selectionChange)="applyFilters($event, 'archived')">
                <mat-option value="false">{{ 'generic.notArchived' | translate }}</mat-option>
                <mat-option value="all">{{ 'generic.all' | translate }}</mat-option>
                <mat-option value="true">{{ 'generic.archived' | translate }}</mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>

    <ng-container *ngIf="displayArchiveOptionToolbar">
        <mat-toolbar class="filters">
            <mat-toolbar-row>
                <ng-container *ngTemplateOutlet="archiveButton"></ng-container>
            </mat-toolbar-row>
        </mat-toolbar>
    </ng-container>

    <ng-container *ngIf="!displayArchiveOptionToolbar">
        <div class="pl-24 pr-24">
            <ng-container *ngTemplateOutlet="archiveButton"></ng-container>
        </div>
    </ng-container>


    <div class="cards content" fxLayout="row" fxLayoutAlign="start start">
        <div class="card" *ngFor="let ressource of ressourcesFiltered">
            <div class="card-preview">
            <div class="fuse-card" (click)="navigateToLesson(ressource)">
                <div class="p-16 toolCardsHeader" fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex>{{ ressource.embeddings.metadatas.attributes.title }}</div>
                    <div fxFlex="0 0 40px">
                        <button mat-icon-button class="mat-icon-button" aria-label="More" matTooltip="More" [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"><mat-icon svgIcon="submenu"></mat-icon></button>
                        <mat-menu #menu="matMenu">
                            <button *ngIf="(activitiesService.userData.id === ressource.get('owner')) && !ressource.get('locked')" mat-menu-item (click)="lessonsService.launchEditor(ressource.id, 'edit', 'form')">
                                <mat-icon svgIcon="edit"></mat-icon> {{ 'generic.edit' | translate }}
                            </button>
                            <button mat-menu-item (click)="openAssign(ressource)">
                                <mat-icon svgIcon="assign_hand"></mat-icon> {{ 'generic.assign' | translate }}
                            </button>
                            <button *ngIf="(activitiesService.userData.id === ressource.get('owner')&&((ressource.get('archived')=== false)||(ressource.get('archived')=== null)))" mat-menu-item (click)="archiveService.archive(ressource)">
                                <mat-icon svgIcon="archive"></mat-icon> {{ 'generic.archive' | translate }}
                            </button>
                            <button *ngIf="(activitiesService.userData.id === ressource.get('owner'))&&(ressource.get('archived')=== true)" mat-menu-item (click)="archiveService.unarchive(ressource)">
                                <mat-icon svgIcon="unarchive"></mat-icon> {{ 'generic.unarchive' | translate }}
                            </button>
                            <button *ngIf="(activitiesService.userData.id === ressource.get('owner')) && !ressource.get('locked')" mat-menu-item (click)="lessonsService.openDeleteDialog(ressource)">
                                <mat-icon svgIcon="delete"></mat-icon> {{ 'generic.delete' | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="p-16">
                    <p>{{ 'generic.created_on' | translate }} {{ localeDate(ressource.embeddings.metadatas.attributes.created) }} {{ 'generic.by' | translate }} {{ ressource.get('owner-name') }}</p>
                    <hr>
                    <p>{{ (ressource.embeddings.metadatas.attributes.description.length>120)? (ressource.embeddings.metadatas.attributes.description | slice:0:120) + '..':(ressource.embeddings.metadatas.attributes.description) }}</p>
                    <mat-icon class="icon-lock" *ngIf="ressource.get('owner-role').indexOf('gestionnaire') > -1">lock</mat-icon>
                </div>
            </div>
            </div>
        </div>
    </div>



    <div class="addBtnBottomRight" fx-layout="row" fx-flex fx-layout-align="end">
        <button mat-fab class="add-file-button" (click)="lessonsService.launchEditor('', 'new', 'form')">
            <mat-icon svgIcon="add"></mat-icon>
        </button>
    </div>
</div>
