<ul>
    <ng-container *ngIf="reward!==4">
        <li [className]="reward >= 1 ? 'star colored animate' : 'star' ">
            <label [className]="coin(1) ? '' : 'hidden' ">{{ coin(1) }}
                <mat-icon svgIcon="coin"></mat-icon>
            </label>
            <div>star</div>
        </li>
        <li [className]="reward >= 2 ? 'star colored animate' : 'star' ">
            <label [className]="coin(2) ? '' : 'hidden' ">{{ coin(2) }}
                <mat-icon svgIcon="coin"></mat-icon>
            </label>
            <div>star</div>
        </li>
        <li [className]="reward === 3?'star colored animate':'star test' ">
            <label [className]="coin(3) ? '' : 'hidden' ">{{ coin(3) }}
                <mat-icon svgIcon="coin"></mat-icon>
            </label>
            <div>star</div>
        </li>
    </ng-container>

    <li *ngIf="reward === 4" class="diamond animate">
        <label *ngIf="coin(4)">{{ coin(4) }}
            <mat-icon svgIcon="coin"></mat-icon>
        </label>
        <div>diamond</div>
    </li>
</ul>



