<ng-template #pageTip>
    <span class="add-page-tips">
        {{'multimedia_line_activity.page_tip' | translate}}
    </span>
</ng-template>

<app-generic-activity>
    <div class="inject-left">
        <button [disabled]="canJumpBelow === false" (click)="selfJumpBelow()" mat-fab>
            <mat-icon svgIcon="drag_indicator"></mat-icon>
        </button>
    </div>
    <div class="inject-middle">
        <mat-icon svgIcon="{{ getContentTypeIcon() }}"></mat-icon>
        <span>{{editableActivity.title}}</span>
    </div>
    <div class="inject-right">
        <button (click)="selfEdit()" mat-fab>
            <mat-icon svgIcon="edit"></mat-icon>
        </button>
        <button (click)="selfPlay()" mat-fab>
            <mat-icon svgIcon="play"></mat-icon>
        </button>
        <button (click)="selfDelete()" mat-fab>
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
        <button (click)="doToggle()" mat-fab>
            <mat-icon [svgIcon]="isToggle ? 'keyboard_arrow_down' : 'keyboard_arrow_up' "></mat-icon>
        </button>
    </div>
    <div *ngIf="isToggle === false" class="inject-below">
        <ng-container *ngIf="editableActivity.pages.length > 0 else pageTip">
            <app-multi-line-child (delete)="deletePage($event)"
                                  (edit)="editPage($event)"
                                  (play)="playPage($event)"
                                  (jumpBelow)="childJumpBelow($event)"
                                  *ngFor="let page of editableActivity.pages"
                                  [canJumpBelow]="childCanJumpBelow(page)"
                                  [page]="page"></app-multi-line-child>
        </ng-container>

        <button (click)="selfAddChild()" class="add-page" mat-fab>
            <mat-icon svgIcon="add-page"></mat-icon>
        </button>
        <span class="add-page-label">
            {{'multimedia_line_activity.add_page' | translate}}
        </span>
    </div>
</app-generic-activity>