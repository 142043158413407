<button
    *ngFor="let item of data"
    [ngClass]="item.state"
    type="button"
    [attr.aria-label]="'accessibility_card_to_return' |translate"
    (click)="changeState(item.uid)">
    <img [ngStyle]="{'visibility':item.select?'visible':'hidden'}"
         [attr.src]="item.image.uri"
         [attr.alt]="item.image.alt">
</button>



