import {NgModule} from '@angular/core';
import {DynamicComponentDirective} from 'fuse-core/directives/dynamic-component/dynamic-component.directive';
import {MoveElementDirective} from 'fuse-core/directives/move-element/move-element.directive';
import {DiableAutofillDirective} from '@fuse/directives/disable-autofill/disable-autofill.directive';
import {AddClassInRegardOfTextSizeDirective} from 'fuse-core/directives/add-class-in-regard-of-text-size/add-class-in-regard-of-text-size.directive';
import {AddClassIfContainsPictureDirective} from 'fuse-core/directives/add-class-if-contains-picture/add-class-if-contains-picture.directive';
import {AddClassInRegardOfTextHeightDirective} from 'fuse-core/directives/add-class-in-regard-of-text-height/add-class-in-regard-of-text-height.directive';
import {ReplaceTargetContentDirective} from 'fuse-core/directives/replace-target-content.directive';

@NgModule({
    declarations: [
        DynamicComponentDirective,
        MoveElementDirective,
        DiableAutofillDirective,
        AddClassInRegardOfTextSizeDirective,
        AddClassInRegardOfTextHeightDirective,
        AddClassIfContainsPictureDirective,
        ReplaceTargetContentDirective
    ],
    imports: [],
    exports: [
        DynamicComponentDirective,
        MoveElementDirective,
        DiableAutofillDirective,
        AddClassInRegardOfTextSizeDirective,
        AddClassInRegardOfTextHeightDirective,
        AddClassIfContainsPictureDirective,
        ReplaceTargetContentDirective
    ]
})
export class FuseDirectivesModule {
}
