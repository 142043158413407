import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, BehaviorSubject, ReplaySubject, Subject, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {AccountManagementProviderService} from '../account-management-provider.service';
import {defaultApiURL, modulesSettings} from '../../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {CommunicationCenterService} from '@modules/communication-center';
import {Field} from '../../../../shared/fieldsOptions';
import {AuthenticationService} from '../../../authentication/core/authentication.service';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {tap} from 'rxjs/operators';

const settingsStructure: ModelSchema = new ModelSchema({
    allowedExtensions: Structures.array(['*']),
    allowedMaxSize: Structures.number(10),
    canUnlinkParent: Structures.array([]), // Should be roles
    canLinkChild: Structures.array([]), // Should be roles
    canEditAvatar: Structures.array(['learner', 'trainer', 'manager', 'administrator']),
    canSelfDelete: Structures.array([]),
    fields: Structures.object({
        default: ['label', 'email', 'password', 'you_are', 'find_us', 'newsletter', 'picture', 'bottomActionButton']
    }),
    joinClassByCode: Structures.boolean(false),
    youAreValues: Structures.array(['teacher', 'parent', 'teen', 'district', 'other']),
    findUsValues: Structures.array(['advertisement', 'mouth', 'research', 'tradeshow', 'training', 'program', 'other']),
    userIsAdultOrMinorOption: Structures.boolean(true),
    learnerCanEditMail: Structures.boolean(false),
});

const URL_FILE_UPLOAD = defaultApiURL + 'api/file-upload';

@Injectable()
export class ProfileService {
    public userInformation: UserDataEntity | null;
    public userInformationOnChanged = new BehaviorSubject<any>({});
    public settings: { [key: string]: any };
    private fileOnChanged = new BehaviorSubject<any>({});
    private file: any;

    constructor(
        private http: HttpClient,
        private communicationService: CommunicationCenterService,
        private accountManagementProvider: AccountManagementProviderService,
        private authenticationService: AuthenticationService,
        private octopusConnectService: OctopusConnectService
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
        this.accountManagementProvider.userIsLogged$.subscribe(isLogged => {
            this.userInformation = this.accountManagementProvider.loggedUser;
            this.userInformationOnChanged.next(this.userInformation);
        });
    }

    private _selectedMode = false;

    get selectedMode(): boolean {
        return this._selectedMode;
    }

    set editMode(mode: boolean) {
        this._selectedMode = mode || false;
    }

    set editfile(data: any) {
        this.file = data || null;
        this.fileOnChanged.next(data);
    }

    get accessToken(): any {
        return this.accountManagementProvider.userAccessToken;
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {

        return new Promise((resolve, reject) => {

            const userData$ = <Observable<UserDataEntity>>this.octopusConnectService.authenticated('http');

            userData$.subscribe((userData) => {
                this.userInformation = userData;
                this.userInformationOnChanged.next(userData);
                resolve();
            }, () => {
                resolve();
            });

        });
    }

    public getFields(): Field[] {
        let fields = this.settings.fields[this.authenticationService.accessLevel];

        if (fields === null || fields === undefined) {
            fields = this.settings.fields['default'] || [];
        }

        return fields.map(field => new Field(field));
    }

    public youAreValues(): string[] {
        return this.settings.youAreValues;
    }

    public learnerCanEditMail(): boolean {
        return this.settings.learnerCanEditMail;
    }

    /**
     * Save user data
     * @param user
     * @return Subject with true if success, false if error
     */
    public editUser(user): Subject<boolean> {
        return this.file ? this.uploadFile(user, this.file) : this.editUserProfile(user);
    }

    editValidateMail(userData): void {
        this.accountManagementProvider.loggedUser = userData;
        this.accountManagementProvider.editUserValidateEmail();
    }

    /**
     * Call Authentication service for logout current user
     * @returns {ReplaySubject} emitted while logout is complete
     */
    public deleteCurrentProfile(): ReplaySubject<void> {
        const onDeleteComplete = new ReplaySubject<void>(1);

        this.accountManagementProvider
            .deleteCurrentLoggedUser()
            .subscribe((successfullyDeletion) => {
                if (successfullyDeletion) {
                    // suppress user of local storage list of possible user
                    const userToDelete = this.authenticationService.loggedUser.get('label');
                    this.authenticationService.deleteQuickConnectUsers(JSON.parse(localStorage.getItem('localUsers')), userToDelete);

                    this.communicationService
                        .getRoom('authentication')
                        .next('do-logout', () => onDeleteComplete.next());
                } else {
                    throw new Error('Unable to delete currently logged user');
                }
            });

        return onDeleteComplete;
    }

    public getFindUsValues(): any[] {
        return this.settings.findUsValues;
    }

    public getYouAreValues(): any[] {
        return this.settings.youAreValues;
    }

    public getCanSelfDelete(): any[] {
        return this.settings.canSelfDelete;
    }

    public getAllowedExtensions(): string[] {
        return this.settings.allowedExtensions;
    }

    public getAllowedMaxSize(): number {
        return this.settings.allowedMaxSize;
    }

    public getCanEditAvatar(): boolean {
        return this.settings.canEditAvatar.includes(this.authenticationService.accessLevel);
    }

    /**
     * Get the current associated parent label or null if there are no parent.
     */
    public getLinkedParentLabelOrNull(): string | null {
        return !!this.userInformation ? this.userInformation.get('parent') : null;
    }

    /**
     * Delete the current relation between the current logged user and his parent (should be a trainer)
     */
    public unlinkParent(): Observable<{}> {
        return this.octopusConnectService.createEntity('users-link', {action: 'unlink'}).pipe(
            tap(() => this.userInformation.set('parent', null))
        );
    }

    /**
     * Set the child account as a children of current user account.
     *
     * @remarks return nothing if the users is found or not
     * @param childIdentifier
     */
    public linkChild(childIdentifier: string): Observable<DataEntity> {
        return this.octopusConnectService.createEntity('users-link', {child: childIdentifier}).pipe(
            tap(() => this.communicationService.getRoom('groups-management').next('refreshLearners', undefined))
        );
    }

    /**
     * Return true if user is authorized to unlink parent account
     */
    public getCanUnlinkParent(): boolean {
        return (<string[]>this.settings.canUnlinkParent).includes(this.authenticationService.accessLevel)
            && !!this.getLinkedParentLabelOrNull();
    }

    /**
     * Return true if user is authorized to link a child account
     */
    public getCanLinkChild(): boolean {
        return (<string[]>this.settings.canLinkChild).includes(this.authenticationService.accessLevel);
    }

    private editUserProfile(user): Subject<boolean> {
        const editCompleteSubject = new Subject<boolean>();

        if (user.password && user.password === '') {
            delete user.password;
        }

        this.accountManagementProvider.editUser(user, (isSuccess: boolean) => {
            if (isSuccess) {
                this.editMode = false;
                this.userInformationOnChanged.next(this.accountManagementProvider.loggedUser);
            }

            editCompleteSubject.next(isSuccess);
        });

        return editCompleteSubject;
    }

    private uploadFile(user, fileToUpload: File): Subject<boolean> {
        const editCompleteSubject = new Subject<boolean>();


        const formData = new FormData();
        formData.append('file', fileToUpload);

        this.http
            .post<any>(URL_FILE_UPLOAD, formData, {headers: {'access-token': this.accessToken}})
            .subscribe((res) => {
                user.picture = res.data[0][0].id;
                this.editfile = null;
                this.editUserProfile(user)
                    .subscribe(isSuccess => editCompleteSubject.next(isSuccess));
            });

        return editCompleteSubject;
    }
}
