import {Observable, of as observableOf} from 'rxjs';
import {mergeMap, take, takeUntil} from 'rxjs/operators';
import {AfterViewChecked, ChangeDetectorRef, Component, Input} from '@angular/core';
import {DataEntity} from 'octopus-connect';
import Keyboard from 'latex-keyboard';
import * as _ from 'lodash';
import {BaseActivityComponent} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivatedRoute} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {of, ReplaySubject, Subject} from 'rxjs/index';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {v4 as uuidv4} from 'uuid';
import {TranslateService} from '@ngx-translate/core';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';

// format de l'activity_content
interface ShortAnswerActivityContentInterface {
    answers: ItemAnswerInterface[];
}

// format du champ config de l'activité
interface ShortAnswerActivityConfigInterface {
    displaySaveBtn?: boolean;
    doubleColumn?: boolean;
    caseSensitive?: boolean;
    isWordingImage?: boolean;
    latexKeyboard?: boolean;
    keyboardType?: string;
    poll?: boolean;
}

// format du champ référence de l'activité
type ShortAnswerActivityReferenceInterface = ActivityReferenceInterface<ShortAnswerActivityContentInterface, ShortAnswerActivityConfigInterface>;

@Component({
    selector: 'app-short-answer',
    templateUrl: './short-answer.component.html'
})
export class ShortAnswerComponent extends BaseActivityComponent<any, any> implements AfterViewChecked {
    public static activityLabel = 'shortAnswer';

    @Input() public disabled: boolean;
    @Input() hideCorrection: boolean;

    public answerFeedback: string;
    public assignatedCount = 20;
    public displaySaveBtn: boolean;
    public disable: boolean;
    public generateArrayToIterateAndDisplayKeyboard = [];
    public isCaseSensitive = true;
    public isFormula = false;
    public isPoll = false;
    public isWordingImage = false;
    public options: any;
    public questionObject: any;
    public referenceActivityGranule: ShortAnswerActivityReferenceInterface;
    public reply: ItemAnswerInterface;
    public uuid: string;
    public validated = false;
    private callbackToTriggerOnAfterViewChecked: Function;
    private latexAnswer: string;
    private latexKeyboard: Keyboard;
    private userAccessCorrection = false;

    public symbols: any;
    public optionsFromSettings: any;
    public blacklistTabs: string[] = [];
    public answerCorrected: ReplaySubject<ItemAnswerInterface> = new ReplaySubject<ItemAnswerInterface>(1);

    public isTTSReading: { id: string, value: boolean };
    public readSecondConsigne = new Subject<boolean>();
    private firstConsigneAutoReadIsDone = false;

    private isCheckingAnswer = false; // is user currently check an answer boolean use to avoid multi true click for only one answer

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected communicationCenter: CommunicationCenterService,
        protected lessonsService: LessonsService,
        private translate: TranslateService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
        document.getElementsByTagName('body')[0].classList.add('short-answer');
        // TODO bad fix but bad layout behaviour and no time (header get out with latex keyboard)
    }


    protected initialize(activity?): void {
        this.generateArrayToIterateAndDisplayKeyboard = [];
        super.initialize(activity);
    }

    /**
     * permet d'afficher la solution de l'exercice
     */
    protected seeAnswerSolution(): void {
        if (this.isFormula) {
            this.latexAnswer = this.latexKeyboard.getLatex();
            this.latexKeyboard.pushLatex(this.options[0].answer);
        }
        this.disable = true;
    }

    protected reviewAnswer(): void {
        if (this.isFormula) {
            this.latexKeyboard.pushLatex(this.latexAnswer);
        }
        this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
    }

    protected setContentData(activityAttributes): void {
        this.isCheckingAnswer = false; // we change the answer so reset
        this.referenceActivityGranule = activityAttributes.reference;
        if (this.referenceActivityGranule.config) {
            this.displaySaveBtn = this.referenceActivityGranule.config.displaySaveBtn === true;
            this.isTwoColumns = !!this.referenceActivityGranule.config.doubleColumn;
            this.isCaseSensitive = !!this.referenceActivityGranule.config.caseSensitive;
            this.isWordingImage = !!this.referenceActivityGranule.config.isWordingImage;
            this.isFormula = this.activitiesService.settings.latexKeyboard;
        }

        if (this.referenceActivityGranule.config.poll) {
            /**
             * endpoint "assignation_search" called if user came from assignment list.
             * endpoint "assignations" called if user came from player (lesson).
             */
            if (this.activitiesService.currentAssignment && this.activitiesService.currentAssignment.get('assignatedCount')) {
                this.assignatedCount = this.activitiesService.currentAssignment.get('assignatedCount');
            } else {
                this.assignatedCount = 20;
            }

            this.isTwoColumns = false;
            this.reply = {
                id: uuidv4(),
                answer: '',
                state: ItemAnswerStateEnum.pristine
            };
            this.isPoll = true;
        }

        if (this.isFormula) {
            const keyboardType: string = this.referenceActivityGranule.config['keyboardType'] || 'alphabetic';
            this.symbols = this.referenceActivityGranule && this.referenceActivityGranule.config
                && this.activitiesService.settings.symbolsForLatexKeyboard
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType]
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType].data;

            this.optionsFromSettings = this.activitiesService.settings.symbolsForLatexKeyboard
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType]
                && this.activitiesService.settings.symbolsForLatexKeyboard[keyboardType].options || {};

            // TODO DEBUG will be removed when activities will be correctly implemented in drupal
            /*          this.symbols = this.activitiesService.settings.symbolsForLatexKeyboard['numeric'].data;
                        this.optionsFromSettings = this.activitiesService.settings.symbolsForLatexKeyboard
                            && this.activitiesService.settings.symbolsForLatexKeyboard['numeric'].options || {};*/

            if (this.optionsFromSettings.validateButton) {
                this.translate.get('generic.validate').subscribe((translation: string) => this.optionsFromSettings.validateButtonText = translation);
            }

            if (this.activitiesService.isPrimary(this.referenceActivityGranule)) {
                this.blacklistTabs.push('123 secondaire');
            } else {
                this.blacklistTabs.push('123 primaire');
            }
            this.generateArrayToIterateAndDisplayKeyboard.push(this.referenceActivityGranule);
        }

        this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();

        if (this.referenceActivityGranule.feedback) {
            this.answerFeedback = activityAttributes.reference.feedback;
        }

        this.questionObject = activityAttributes.reference;
        this.instruction = this.questionObject.instruction;
        this.wording = this.questionObject.wording;
        this.instructionAudio = this.questionObject.instructionAudio;
        this.wordingAudio = this.questionObject.wordingAudio;
        this.options = [];
        for (const obj of this.questionObject.activity_content.answers) {
            this.options.push(_.clone(obj));
        }
        this.loadUserSave();

        if (this.lessonsService.isTrainerSeeCorrection()) {
            this.userAccessCorrection = true;
        }

        this.activityStepIndex = this.activitiesService.presentArrayElementIndex;
        // read the secondConsigne when switch to next instruction
        this.readSecondConsigne.next(true);
    }

    // tslint:disable-next-line:use-lifecycle-interface OnDestroy est déjà implémenté par BaseActivity
    ngOnDestroy(): void {
        super.ngOnDestroy();

        const haveToSaveOnDestroy = this.activitiesService.settings['saveOnDestroy'].includes(ShortAnswerComponent.activityLabel);

        let todoBeforeDestroy: Observable<any>;
        if (haveToSaveOnDestroy) {
            this.checkAnswer();
            todoBeforeDestroy = this.saveAnswer();
        } else {
            todoBeforeDestroy = observableOf([]);
        }

        todoBeforeDestroy.pipe(
            take(1))
            .subscribe(() => {
                if (this.latexKeyboard) {
                    this.latexKeyboard.destroy();
                }
            });

        document.getElementsByTagName('body')[0].classList.remove('short-answer');
    }

    protected loadUserSave(): void {
        this.isSaving = true;
        this.activitiesService.getUserSave(this.activityId.id, this.assignmentId).pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(userSave => {
                if ((userSave && !this.isPoll) || (userSave && this.isPoll && +userSave.get('step') === this.activitiesService.presentArrayElementIndex)) {
                    this.userSave = userSave;
                    if (this.activitiesService.settings.setAnswerWithUserSave) {
                        this.setAnswer();
                    }
                    this.activityPercentile = Math.round(this.getGrade().oldGrade * 100);
                } else if (this.lessonsService.isMyAssignment()) {
                    this.save();
                } else if (this.lessonsService.isTrainerSeeCorrection()) {
                    this.checkAnswer();
                }

                this.isSaving = false;
            });
    }

    protected setAnswer(): void {
        if (this.userSave && this.userSave.get('state') !== 'incomplete' && this.options) {
            let answer;

            if (this.userSave instanceof DataEntity) {
                answer = this.userSave.get('userActivity').entitySave.answers[0];
            } else {
                answer = this.userSave;
            }

            if (answer) {
                this.reply.answer = answer.answer;

                if (this.isFormula) {
                    this.latexKeyboard.pushLatex(this.reply.answer);
                }

                this.checkAnswer();
                this.activitiesService.doesUserResponsed.next(true);
            }

            this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
        } else if (this.lessonsService.isTrainerSeeCorrection()) {
            this.checkAnswer();
        }
    }

    protected getGrade(): {
        newGrade: number,
        oldGrade: number
    } {
        let oldGrade = 0;
        let grade = 0;

        for (const option of this.options) {
            if (this.userSave) {
                const answer = this.userSave.get('userActivity').entitySave.answers[0];
                if (answer && answer.answer === option.answer) {
                    oldGrade += 1;
                }
            }
            if (option.answer === this.reply.answer) {
                grade += 1;
            }
        }

        return {
            newGrade: grade,
            oldGrade: oldGrade
        };
    }

    protected saveAnswer(): Observable<any> {
        return this.activitiesService
            .saveAnswer({answer: this.reply.answer}, 'answer')
            .pipe(
                take(1),
                mergeMap((answer: DataEntity) => of(answer && [answer.id.toString()] || []))
            );
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.isPoll = false;
        if (this.latexKeyboard) {
            this.latexKeyboard.pushLatex('');
        }
        this.answerFeedback = null;
        this.validated = false;
        this.disable = this.lessonsService.isAtLeastTrainer() && !this.lessonsService.isLessonTest();
        this.reply = {
            id: uuidv4(),
            answer: '',
            state: ItemAnswerStateEnum.pristine
        };
        return super.reset(resetAllSubscribe, type);
    }

    public answerState(): string {
        if (this.displaySolution) {
            return 'correctReply';
        } else {
            if (!this.hideCorrection && this.testAnswer) {
                if (this.isReplyCorrect()) {
                    return 'correctReply';
                } else if (!this.reply.answer) {
                    return 'missingReply';
                } else {
                    return 'wrongReply';
                }
            }

            if (this.validated) {
                return 'validated';
            }
        }

        return '';
    }

    checkAnswer(): any {
        if (this.isFormula && this.latexKeyboard) {
            this.reply.answer = this.latexKeyboard.getLatex();
        }

        if (this.options) {
            const answerResult: AnswerResultInterface = {
                id: +this.activityId.id,
                isAnswerCorrect: undefined,
                isLast: undefined
            };
            if (this.reply.answer) {
                if (this.isReplyCorrect()) {
                    answerResult.isAnswerCorrect = true;
                    answerResult.isLast = true;
                    this.reply.state = ItemAnswerStateEnum.currentlyCorrect;
                    this.answerStatus = answerStatusEnum.correct;
                } else {
                    this.isCheckingAnswer = false;
                    answerResult.isAnswerCorrect = false;
                    this.reply.state = ItemAnswerStateEnum.incorrect;
                    this.answerStatus = answerStatusEnum.wrong;
                    // feedback in case of bad answer is store in the good answer in field feedback in other exo it's in bad answers but in this case whe have only one answer
                    if (this.referenceActivityGranule.activity_content.answers.length > 0 && this.referenceActivityGranule.activity_content.answers[0].feedback) {
                        super.ifFeedbackOpenModal(null, this.referenceActivityGranule.activity_content.answers[0].feedback);
                    }
                }
            } else {
                this.isCheckingAnswer = false;
                answerResult.isAnswerCorrect = false;
                this.reply.state = ItemAnswerStateEnum.missing;
                this.answerStatus = answerStatusEnum.missing;
            }

            this.answerResult.next(answerResult);
            if (this.autoCorrection) {
                this.animateAnswerState();
            }

            if (this.lessonsService.isLessonTraining() && !this.lessonsService.isAtLeastTrainer()) {
                if (!this.hideCorrection && this.answerStatus === answerStatusEnum.wrong) {
                    this.displayFeedback = this.activitiesService.settings.displayFeedback;
                }
            }

            this.activitiesService.isUserAnswerStatus
                .next({status: this.answerStatus, index: this.activityStepIndex});

            if (this.userAccessCorrection) {
                this.activitiesService.checkAnswers.next({lessonCorrected: true});
            }
        }
    }


    private animateAnswerState(): void {
        // TODO faire une meilleure animation angular
        this.answerCorrected.next(this.reply);
        setTimeout(() => {
            if (this.reply.state !== ItemAnswerStateEnum.currentlyCorrect) {
                this.reply.state = ItemAnswerStateEnum.pristine;
                this.reply.answer = '';
            }
            this.answerCorrected.next(this.reply);
        }, 2000);
        if (this.isReplyCorrect()) {
            this.doAction('next', ['save']);
        }
    }

    public onNgModelChange(event): void {
        this.activitiesService.doesUserResponsed.next(event !== '');
        this.onOptionChange(event !== '');
    }

    public ngAfterViewChecked(): void {
        if (this.callbackToTriggerOnAfterViewChecked) {
            this.callbackToTriggerOnAfterViewChecked();
            this.callbackToTriggerOnAfterViewChecked = null;
        }
    }

    public isReplyCorrect(): boolean {
        return this.options.some(option => option.answer.replace(/ /g, '') === this.reply.answer.toString().replace(/ /g, '') ||
            (this.reply && this.reply.answer &&
                !this.isCaseSensitive && option.answer.replace(/ /g, '').toLowerCase() === this.reply.answer.toString().replace(/ /g, '').toLowerCase()));
    }

    /**
     * set the value to save in the field of the short answer
     * @param sliderPosition : value of slider equal nbre of vote
     */
    public onMoveSlider(sliderPosition: number): void {
        this.reply.answer = sliderPosition.toString();
    }

    /**
     * set the value to save in the field of the short answer
     * @param sliderPosition : value of slider equal nbre of vote
     */
    public onClickSlider(sliderPosition: number): void {
        this.reply.answer = sliderPosition.toString();
    }

    public get isLessonWithStep(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.lessonStep;
    }

    /**
     * userClick button validate/register
     * @param userClicked
     */
    clickAnswer(userClicked: boolean): void {

        if (userClicked && !this.isCheckingAnswer) {
            this.isCheckingAnswer = true;
            this.checkAnswer();
        }
    }

    public doesUserResponded(val: boolean): void {
        this.activitiesService.doesUserResponsed.next(val);
    }

    public onKeyboardReady($event: Keyboard): void {
        this.latexKeyboard = $event;
    }

    /**
     * set if Text to speach is currently reading or not
     * @param evt
     */
    public ttsReadingEvent(evt: { id: string, value: boolean }): void {
        // content is hidden we do nothing
        if (this.contentIsHidden) {
            return;
        }

        this.isTTSReading = evt;
        if (evt.value === true) {
            this.firstConsigneAutoReadIsDone = true;
        }
        // the fisrt consigne is finish to be read and the content is visible modal is closed an
        if (evt.value === false && this.firstConsigneAutoReadIsDone === true && !this.contentIsHidden) {
            this.readSecondConsigne.next(true);
            this.ref.detectChanges();
        } else {
            this.ref.detectChanges();
        }
    }
}
