<mat-card class="activities-card inner-scroll mat-elevation-z0 ">
    <mat-card-header>
        <mat-card-title>
            <app-consignes [instruction]="instruction" [instructionAudio]="instructionAudio"></app-consignes>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content class="content">
        <app-consignes [wording]="''" [wordingAudio]="wordingAudio"></app-consignes>

        <div class="rowHeaderTable">
            <div class="headerCellTable"></div>
            <div class="headerCellTable" *ngFor="let answer of answers">{{answer.answer}}</div>
        </div>
        <!--all the questions-->
            <app-multiple-choice *ngFor="let multipleChoiceData of multipleChoiceDatas"
                                 (selectedValues)="updateAnswers($event)"
                                 [data]="multipleChoiceData"
                                 [multipleChoices]="multipleChoiceData.multiple_choice">
            </app-multiple-choice>
    </mat-card-content>
    <mat-card-footer>
        <ng-container *ngFor="let button of buttons">
            <button
                *ngIf="button.display"
                mat-raised-button
                [disabled]="button.disable"
                (click)="onAction(button)">
                <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                <span>{{ button.title | translate }}</span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>

