import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {brand, brandLogoSvg, defaultLoginRoute} from '../../../../settings';
import {AccountManagementProviderService} from '../account-management-provider.service';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {TranslateService} from '@ngx-translate/core';
import {WidgetInstance} from 'friendly-challenge';
import {Router} from '@angular/router';
import {AuthenticationService} from '@modules/authentication';
import {Observable} from 'rxjs/index';
import {CommunicationCenterService} from '@modules/communication-center';
import {tap} from 'rxjs/operators';

/**
 * this component is used to create account without setting a password
 * user create account step by step
 * in reality login is created but with a rules and user never see it
 * login = codeid + @opasswordregistration.tralalere.com
 */
@Component({
    selector: 'app-register-easly',
    templateUrl: './register-easly.component.html'
})
export class RegisterEaslyComponent implements OnInit, AfterViewInit, OnDestroy {
    registerForm: FormGroup;
    public requestValidate: boolean;
    @ViewChild('widgetFC') widgetFC: ElementRef;
    private friendlyCaptchaSolution: string;
    private widget: any;
    public step = 0;
    public currentUser: DataEntity;
    public captchaStatus = false;
    private youAre = ''; // teen or teacher
    public errorCodeClass = ''; // if class code set is not good
    public brand = brand;
    public brandLogoSvg = brandLogoSvg;
    public showLoader = false;
    // different title that can be added
    public stepTitle = ['account-management.title-1', 'account-management.title-2', 'account-management.title-3', 'account-management.title-4'];
    // different info that can be added in regard of step position
    public stepInfo = [{part1: 'account-management.info-1', part2: '', part3: ''},
        {part1: 'account-management.info-2', part2: '', part3: ''},
        {part1: 'account-management.info-3-part-1', part2: 'account-management.info-3-part-2', part3: 'account-management.info-3-part-3'},
        {part1: 'account-management.info-4-part-1', part2: 'account-management.info-4-part-2', part3: ''}];
    public educationalLevels: DataEntity[] = [];
    public isSelectLevelEnabled = false;
    private levelSelectedId = null;

    constructor(private formBuilder: FormBuilder,
                public accountProvider: AccountManagementProviderService,
                private octopusConnect: OctopusConnectService,
                public profileService: ProfileService,
                private translate: TranslateService,
                private router: Router,
                private authenticationService: AuthenticationService,
                private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-login-or-register-active');
    }

    ngOnInit(): void {
        this.accountProvider.getEducationalLevels().pipe(
            tap((levelEntities: DataEntity[]) => this.educationalLevels = levelEntities)
        ).subscribe();
        this.requestValidate = false;
        this.generateRegisterForm();
        this.managePseudoError();
    }

    /**
     * manage error :
     * set error when pseudo has a bad format
     * and reset error when we type a new code class
     * @private
     */
    private managePseudoError(): void {
        this.registerForm.controls['pseudo'].valueChanges.subscribe(pseudo => {
            if (!this.validateField(pseudo)) {
                this.registerForm.controls['pseudo'].setErrors({badFormat: true});
            } else {
                this.registerForm.controls['pseudo'].setErrors(null);
            }
        });

        this.registerForm.controls['class_code'].valueChanges.subscribe(classeCode => {
            if (classeCode !== '') {
                this.errorCodeClass = '';
            }
        });
    }

    ngAfterViewInit(): void {
        this.initFriendlyCaptcha();
    }

    /**
     * only letter without accent , number and unserscore withour space
     * @param field : form field value to test
     */
    validateField(field): boolean {
        let re;
        re = /^\w+(\s\w+)*$/; // only letter without accent, number and underscore no space possible except one beetween word
        return re.test(String(field).toLowerCase());
    }

    /**
     * add control to form
     * @private
     */
    private generateRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
            pseudo: ['', Validators.required],
            class_code: ['', null]
        });
    }

    /**
     * store role of user to create
     * @param role
     */
    public setRole(role: string): void {
        this.youAre = role;
        this.step++;
    }

    // todo when create part of teacher make an extend of standard register to manage diff without code all
    registerTeacher(): void {
        this.router.navigate(['/register'], {queryParams: {role: 'teacher'}});
    }

    /**
     * form pass to next step and show next field to fullfill
     */
    public nextStep(): void {
        if (this.step === 2 && (this.codeClassEntered && this.codeClassEntered !== '' || this.levelSelectedId)) {
            this.errorCodeClass = '';
            this.showLoader = true;
            this.createUser().subscribe((userData) => {
                this.isSelectLevelEnabled = false;
                this.currentUser = userData;
                this.step++;
                this.showLoader = false;
            }, error => {
                this.errorCodeClass = error.data.response.title;
                this.registerForm.controls['class_code'].setValue('');
                this.showLoader = false;
            });
        }

        // ask for creating code class and create account
        if (this.step === 2 && (!this.codeClassEntered || this.codeClassEntered === '')) { // NJ435 coce class for test
            this.isSelectLevelEnabled = true;
        }

        // goto home page logged
        if (this.step === 3) {
             this.autoConnectNewUser();

        }
        // all step gone to next automaticly except during creating user because we check if class code exist
        if (this.step !== 2) {
            this.step++;
        }
    }

    /**
     * create new user with no password and
     * @private
     */
    private createUser(): Observable<DataEntity> {
        let newUser;
        newUser = {
            noPasswordRegistration: true,
            label: this.registerForm.value.pseudo,
            groupCode: this.registerForm.value.class_code,
            you_are: this.youAre
        };
        if (this.levelSelectedId) {
            newUser.level = this.levelSelectedId;
        }
        return this.octopusConnect.createEntity('user-registration', newUser);
    }

    /**
     * connect user previously created and launch app
     * @private
     */
    private autoConnectNewUser(): void {
        this.showLoader = true;
        // account is generated with the rule codeid and @opasswordregistration.tralalere.com
        const account = this.currentUser.get('codeid') + '@nopasswordregistration.tralalere.com';
        this.authenticationService.authenticateIn('http', account, this.currentUser.get('codeid'))
            .subscribe(user => {
                this.router.navigate([defaultLoginRoute]);
                this.showLoader = false;
            }, err => {
                console.log(err);
                this.showLoader = false;
            });
    }

    public currentStepHasError(): boolean {
        // pseudo
        if (this.step === 1) {
            // todo add message wait for verification you are human
            return this.registerForm.controls['pseudo'].status === 'INVALID' || !this.captchaStatus;
        }
        return false;
    }

    /**
     * init friendly captcha
     * @private
     */
    private initFriendlyCaptcha(): void {
        const doneCallback = (solution) => {
            this.friendlyCaptchaSolution = solution;

            this.accountProvider.verifyFriendlyCaptcha(this.friendlyCaptchaSolution).subscribe(verifyResult => {
                    if (verifyResult['success'] === true) {
                        this.captchaStatus = true;
                    }
                },
                error => {
                    if (error.status !== 200) {
                        this.captchaStatus = true;
                    }
                });
        };

        const errorCallback = (err) => {
            console.log('There was an error when trying to solve the Captcha.');
            console.log(err);
        };

        const element = this.widgetFC.nativeElement;
        const options = {
            startMode: 'none',
            doneCallback: doneCallback,
            errorCallback: errorCallback,
            language: this.translate.currentLang
        };

        this.widget = new WidgetInstance(element, options);
        this.widget.start();
    }

    /**
     * code is shown in format XXXX-XXXX-XXXX
     */
    formatCodeClass(clipboard?: boolean): string {
        if (this.currentUser) {
            let separator = '-';
            if (clipboard !== true) {
                // add span to custom display
                separator = '<span>-</span>';
            }
            return this.currentUser.get('codeid').substring(0, 4)
                + separator + this.currentUser.get('codeid').substring(4, 8)
                + separator + this.currentUser.get('codeid').substring(8, this.currentUser.get('codeid').length);
        } else {
            return '';
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-login-or-register-active');
    }

    public selectLevel(classSelected: DataEntity): void {
        this.levelSelectedId = classSelected.id;
        this.nextStep();
    }

    public get codeClassEntered(): string {
        return this.registerForm && this.registerForm.controls['class_code'].value;
    }
}



