import {ChangeDetectorRef, Component} from '@angular/core';
import {mergeMap, map, tap} from 'rxjs/operators';
import {DataEntity} from 'octopus-connect';
import * as _ from 'lodash';
import {GenericPluginsService, PluginType} from '@modules/activities/core/services/generic-plugins.service';
import {brand} from '../../../../../settings';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {Observable, of} from 'rxjs';

@Component({
    selector: 'app-tool',
    templateUrl: './tool.component.html'
})
export class ToolComponent extends BaseActivityComponent<any, any> {
    public renderIsReady = false;
    private activityGranule: DataEntity;
    private activityInterface: DataEntity;
    public brand = brand;

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected communicationCenter: CommunicationCenterService,
        protected lessonsService: LessonsService,
        private genericPluginsService: GenericPluginsService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    /**
     * Return the svg identifier of the current activity tool image.
     * This image should be set while creating the tool activity.
     * If it's not, the default tool image `tools-circle` will be showed
     */
    public get iconName(): string {
        const toolId = _.get(this.activityInterface, 'attributes.config.tool');

        const toolSetting = this.genericPluginsService.getPluginsByType(PluginType.lessonTool).find(
            pluginSetting => pluginSetting.toolIdentifier === toolId
        );

        return _.get(toolSetting, 'setting.iconIdentifier');
    }

    // tslint:disable-next-line:use-lifecycle-interface OnInit est déjà implémenté par BaseActivity
    ngOnInit(): void {
        this.renderIsReady = false;
        this.activatedRoute.params.pipe(
            map(params => params['activityId']),
            mergeMap(activityId => this.activitiesService.loadActivitiesFromId(activityId)),
            tap(activityGranule => this.activityGranule = activityGranule),
            mergeMap(activityGranule => this.activitiesService.loadActivityInterface(activityGranule.get('reference'))),
            tap(activityInterface => {
                this.activityInterface = activityInterface;
                this.renderIsReady = true;
                this.instruction = _.get(this.activityInterface, 'attributes.instruction');
            })
        ).subscribe();
    }

    protected initialize(): void {
        throw new Error('Method not implemented.');
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected setContentData(): void {
        throw new Error('Method not implemented.');
    }

    protected getGrade(): { oldGrade: number, newGrade: number } {
        throw new Error('Method not implemented.');
    }
}
