import {ChangeDetectorRef, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '../../activities.service';
import {LessonsService} from '../../lessons/lessons.service';
import {CommunicationCenterService} from '@modules/communication-center';
import {v4 as uuidv4} from 'uuid';
import {Subject} from 'rxjs/index';
import {PlayState} from '@modules/activities/core/player-components/memory/memory-card-info/memory-card-info.component';
import {FlashCardInterface} from '@modules/activities/core/models/flash-card.interface';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';

/**
 * Memory activity :
 * take a list of flashcard in entry user must select two item
 * first item selected make the card returned and a sound play
 * after sound is played user can returned another card second card is returned and sound is played
 * a color inicate if it s an error or not if it s a true answer card will be returned but cannot be selectable anymore
 * if it's wrong they are returned but can be selected again
 */

// format de l'activity_content
interface IMemoryActivityContentInterface {
    flashcards: FlashCardInterface[];
}


// format du champ config de l'activité i keep same as text matching but not use it in reality for moment
interface IMemoryActivityConfigInterface {
    columns?: number;
    direction: string;
    // define the mode of the inputs
    mode?: 'picture' | 'text' | undefined;
    // InstructionMode is a bad name, the mode defined the guessing item in the activity content mode, not the instruction, undefined = should be a text
    instructionMode?: 'picture' | undefined;
}

// format du champ référence de l'activité
type IMemoryActivityReferenceInterface = ActivityReferenceInterface<IMemoryActivityContentInterface[], IMemoryActivityConfigInterface>;

@Component({
    selector: 'app-memory',
    templateUrl: './memory.component.html'
})
export class MemoryComponent extends BaseActivityComponent<IMemoryActivityContentInterface, IMemoryActivityConfigInterface> {
    public uuid: string = uuidv4();
    private referenceActivityGranule: IMemoryActivityReferenceInterface;
    public stateOfLastSelectedItems: { uid: number, state: ItemAnswerStateEnum }[] = [];
    private uidItemsFound: number[] = [];
    public leftCard: ICardInfo = this.returnDefaultValue('1', true);
    public rightCard: ICardInfo = this.returnDefaultValue('2', false);
    public data: FlashCardInterface[] = [];

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    /**
     * listen if a card is playing the song
     * store the current state in the card
     * check if it's the second card and if it s launch the check of answer after end of read
     * @param playState play paused or stop
     * @param targetCard the leftCard or the right card of the memory is emitting the event
     */
    public cardPlayState(playState: string, targetCard: string): void {
        if (targetCard === 'left') {
            this.leftCard.currentPlayState = playState;
        }
        if (targetCard === 'right') {
            this.rightCard.currentPlayState = playState;
        }
        // if the left card was returned and the sound is ended of be played the right card begin to be active
        if (targetCard === 'left' && playState === PlayState.stop && this.data.filter(d => d.select === true).length === 1) {
            this.rightCard.isActive = true;
        }
        // second card is turned and audio file read is endeed we check the answer
        if (this.rightCard.currentPlayState === PlayState.stop && this.data.filter(d => d.select === true).length >= 2) {
            this.checkAnswer();
        }
    }

    /**
     * a click is made on a card we check if a click on it is allowed
     * if yes we push data on left or right card and pass the card on selected state
     * after card is select a sound is play : during this time where a card play a sound automaticly we can't do nothing
     * we must wait to select the second card
     * @param uid : unique id of the card
     */
    public changeState(uid: number): void {
        if (this.blockActionOnClick(uid)) {
            return;
        }

        if (this.data.filter(d => d.select === true).length === 0) {
            // one card will be selected
            this.setLeftCardData(uid);
            this.ref.detectChanges(); // to force update of card before playing song
            this.leftCard.play.next(true); // play sound of current card returned
        } else {
            // two card will be selected the second is returned it s the right card
            this.setRightCardData(uid);
            this.ref.detectChanges(); // to force update of card before playing song
            this.rightCard.play.next(true); // play sound of current card returned

        }
        this.data.find(d => d.uid === uid).select = true;
    }

    /**
     * set data
     * @param uid : id unique
     * @private
     */
    private setRightCardData(uid: number): void {
        this.rightCard.info = this.data.find(d => d.uid === uid).title;
        this.rightCard.image = this.data.find(d => d.uid === uid).image.uri;
        this.rightCard.altImage = this.data.find(d => d.uid === uid).image.alt;
        this.rightCard.title = '2';
        this.rightCard.sound = this.data.find(d => d.uid === uid).audio.uri;
    }

    /**
     * set data
     * @param uid: id unique
     * @private
     */
    private setLeftCardData(uid: number): void {
        this.leftCard.info = this.data.find(d => d.uid === uid).title;
        this.leftCard.image = this.data.find(d => d.uid === uid).image.uri;
        this.leftCard.altImage = this.data.find(d => d.uid === uid).image.alt;
        this.leftCard.title = '1';
        this.leftCard.sound = this.data.find(d => d.uid === uid).audio.uri;
    }

    /**
     * check the two element returned
     * @private
     */
    protected checkAnswer(): void {
        const itemCurrentlySelected = [...this.data.filter(d => d.select === true)];
        if (this.data.filter(d => d.select === true)[0].data === this.data.filter(d => d.select === true)[1].data) {
            this.storeFoundedElement();
            this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.wasCorrect);
            this.data.filter(d => d.select === true).map(d => d.select = false);

            setTimeout(() => {
                this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.currentlyCorrect);

                this.resetCardsInfos();

                if (this.uidItemsFound.length === this.data.length) {
                    // user found all items
                    setTimeout(() => {
                        this.doAction('next');
                    }, 1000);
                }
            }, 1000);

        } else {
            this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.incorrect);
            this.data.filter(d => d.select === true).map(d => d.select = false);

            setTimeout(() => {
                this.setLastSelectedItems(itemCurrentlySelected, ItemAnswerStateEnum.pristine);
                this.resetCardsInfos();
            }, 1000);
        }
    }

    /**
     * all found element are store we push the last one founded
     * @private
     */
    private storeFoundedElement(): void {
        this.uidItemsFound.push(this.data.filter(d => d.select === true)[0].uid);
        this.uidItemsFound.push(this.data.filter(d => d.select === true)[1].uid);
    }

    /**
     * in some case the click on a card must not fire any action
     * already returned nothing to do or already finded nothing to do in a transitory state nothing to do
     * one card is playing the sound nothing to do user must wait the ended
     * state is showing bad or good answer color
     */
    public blockActionOnClick(uid: number): boolean {
        return this.data.filter(d => d.select === true).length >= 2 || this.uidItemsFound.includes(uid) || this.data.find(d => d.uid === uid).select === true
            || this.rulesForDisabledAllItems();
    }

    /**
     * some common rules to block and disable effect sound played two items selected...
     */
    public rulesForDisabledAllItems(): boolean {
        return this.leftCard.currentPlayState === PlayState.play || this.rightCard.currentPlayState === PlayState.play
            || (this.stateOfLastSelectedItems.length === 2 &&
                (this.stateOfLastSelectedItems[1].state === ItemAnswerStateEnum.incorrect || this.stateOfLastSelectedItems[1].state === ItemAnswerStateEnum.wasCorrect));
    }

    /**
     * set the last item state
     * use to change the class of memory zone component to change design in regard of state
     * @param itemCurrentlySelected : item selected
     * @param currentState : pristine => not touch, was correct : it's good answer, currently-correct : it's good we not have to touch it anymore
     * @private
     */
    private setLastSelectedItems(itemCurrentlySelected: FlashCardInterface[], currentState: ItemAnswerStateEnum): void {
        this.stateOfLastSelectedItems = [];
        this.stateOfLastSelectedItems.push({uid: itemCurrentlySelected[0].uid, state: currentState});
        this.stateOfLastSelectedItems.push({uid: itemCurrentlySelected[1].uid, state: currentState});
    }

    /**
     * card are now returned and not show anymore we reset the info
     * @private
     */
    private resetCardsInfos(): void {
        this.leftCard.info = '';
        this.leftCard.image = '';
        this.leftCard.sound = '';
        this.leftCard.isActive = true;
        this.rightCard.info = '';
        this.rightCard.image = '';
        this.rightCard.sound = '';
        this.rightCard.isActive = false;
    }

    protected setContentData(data): void {
        this.wordingAlreadyReadWithTts = false;
        this.isTTSSpeaking = null;
        this.referenceActivityGranule = data.reference;
        if (this.referenceActivityGranule.config) {
            this.isVertical = (this.referenceActivityGranule.config.direction === 'vertical');
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.wording = this.referenceActivityGranule.wording;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.data = this.shuffle(this.referenceActivityGranule.activity_content[0].flashcards);
        let i = 0;
        this.data.map(d => {
            d.select = false;
            d.state = ItemAnswerStateEnum.pristine;
            d.uid = i;
            i++;
        });
    }

    /**
     * shuffle data of an array
     * @param array an array
     * @private
     */
    private shuffle(array: any): any {
        let currentIndex = array.length, randomIndex;

        // While there remain elements to shuffle.
        while (currentIndex !== 0) {

            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
        return array;
    }

    /**
     * list of item selected currently
     */
    public selectedItems(): number[] {
        return this.data.filter(d => d.select === true) ? this.data.filter(d => d.select === true).map(data => data.uid) : [];
    }

    /**
     * initialise default value of card
     * @private
     */
    private returnDefaultValue(title: string, isActive): ICardInfo {
        return {
            title: title,
            image: '',
            info: '',
            sound: '',
            altSound: '',
            altImage: '',
            play: new Subject<boolean>(),
            currentPlayState: PlayState.stop,
            isActive: isActive
        };
    }

    /*   public returnedCard(): any[] {
           return this.data.filter(d => d.select === true);
       }*/

    public getGuessingItemClasses(): string {
        return 'instruction-as-' + this.getTypeOfInstruction();
    }

    /** legacy code i take it from text-matching component to manage instruction **/

    private getTypeOfInstruction(): string {
        const mode = this.referenceActivityGranule.config.instructionMode;

        if (!!mode) {
            return mode;
        }

        return 'undefined';
    }

    public get isTTSReadingText(): boolean {
        return this.isTTSSpeaking && this.isTTSSpeaking.id === this.uuid && this.isTTSSpeaking.value;
    }

    /* code from base activity inheritance not used for this activity*/

    protected saveAnswer(): Observable<any> {
        // not used
        return undefined;
    }

    protected reviewAnswer(): void {
        // not used
    }

    protected getGrade(): { oldGrade: number; newGrade: number } {
        // not used
        return {newGrade: 0, oldGrade: 0};
    }

    protected seeAnswerSolution(): void {
        // not used
    }

    protected setAnswer(): void {
        // not used
    }
}

export interface ICardInfo {
    title: string;
    image: string; // url of image
    info: string;
    sound: string; // url of sound
    altSound?: string; // for accessibility
    altImage?: string; // for accessibility
    play: Subject<boolean>; // to play sound inside card
    currentPlayState: string; // to store the current card play state
    isActive: boolean; // is currently the target of the answer
}

