<mat-card class="activities-card mat-elevation-z0" >
    <mat-card-header>
        <mat-card-title>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content #iframeViewport fxLayout="column" fxLayoutAlign="space-around center">

            <iframe #iframeView *ngIf="iframeUrl" (load)="onIframeLoad()"
                    allow="microphone; fullscreen"
                    [src]="iframeUrl" allowfullscreen="true"></iframe>


        <div *ngIf="displayUserActionButton()" fxFlex="none" fxLayout="column" fxLayoutAlign="center none" class="pl-8 mt-24 mb-24 mr-40">
            <button mat-fab class="next" (click)="launchNextActivity()" aria-label="next">
                <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>