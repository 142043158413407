<div mat-dialog-title>
    <h2>{{ "edit_lesson_warning_modal.title" | translate }}</h2>
    <button (click)="cancel()" aria-label="Close dialog" class="close" mat-mini-fab>
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    {{'edit_lesson_warning_modal.content' | translate}}
    {{'edit_lesson_warning_modal.count' | translate}} {{data.count}}
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="accept()"
            class="mat-accent"
            mat-raised-button>
        {{ 'edit_lesson_warning_modal.accept' | translate }}
    </button>
    <button (click)="cancel()"
            class="mat-accent"
            mat-raised-button>
        {{ 'edit_lesson_warning_modal.cancel' | translate }}
    </button>
</mat-dialog-actions>
