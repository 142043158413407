<div id="register-easly">
    <div id="register-easly-form-wrapper">
        <div id="register-easly-form">

            <div class="logo">
                <img *ngIf="!brandLogoSvg" src="assets/{{ brand }}/images/logos/brand-square.png">
                <mat-icon *ngIf="brandLogoSvg && step!==4" class="logo-icon-svg" svgIcon="brand_square"></mat-icon>
            </div>

            <!--title-->
            <div class="title">{{ stepTitle[step] | translate}}</div>
            <div class="info-container">
                <div class="step" *ngIf="step !== 0 && step !== 4">{{step}}/3</div>
                <div *ngIf="step!==4" [ngClass]="step === 3 ? 'info info-code':'info'">
                    <span>
                        {{ stepInfo[step].part1 | translate}}
                        <span *ngIf="step===2 || step===3" class="info-pseudo"> {{this.registerForm.value['pseudo']}}</span>
                        <span *ngIf="step===2 || step===3">!</span>
                    </span>

                    <ng-container *ngIf="stepInfo[step].part2 && stepInfo[step].part2 !== ''">
                        <br>
                        <span class="info-code-part-2">{{ stepInfo[step].part2 | translate}}</span>
                    </ng-container>
                    <ng-container *ngIf="stepInfo[step].part2 && stepInfo[step].part3 !== ''">
                        <br>
                        <span class="info-code-part-3">{{ stepInfo[step].part3 | translate}}</span>
                    </ng-container>
                </div>
                <!--show unique child generate code-->
                <div class="user-code" *ngIf="step === 3">
                    <div [innerHTML]="formatCodeClass()"></div>
                    <button
                        [cdkCopyToClipboard]="formatCodeClass(true)">{{ 'generic.copy-to-clipboard' | translate}}</button>
                </div>
                <div *ngIf="showLoader" fxLayout="row" fxLayoutAlign="center center">
                    <mat-spinner></mat-spinner>
                </div>
            </div>

            <ng-container *ngIf="!showLoader">
                <form [formGroup]="registerForm" name="registerForm" novalidate>
                    <div class="field-youAre" *ngIf="step === 0">
                        <button (click)="setRole('teen')"
                                class="submit-button"
                                color="accent"
                                mat-raised-button>{{ 'account-management.teen' | translate}}
                        </button>

                        <button (click)="registerTeacher()"
                                class="submit-button"
                                color="accent"
                                mat-raised-button>{{ 'account-management.teacher' | translate}}
                        </button>
                    </div>
                    <!--pseudo-->
                    <mat-form-field class="pseudo" *ngIf="step === 1">
                        <input [required]="true" formControlName="pseudo"
                               matInput placeholder="{{ 'account-management.pseudo' | translate}}">
                        <mat-error
                            *ngIf="this.captchaStatus && this.registerForm.value['pseudo'] !==''">{{"account-management-pseudo-not-allowed" | translate}}</mat-error>
                    </mat-form-field>
                    <span [ngStyle]="step === 2 && errorCodeClass!=='' ?
                      {'visibility':'visible', 'height':'auto'} : {'visibility':'hidden', 'height':'0'}"
                          class="info-captcha">{{"account-management-captcha-info"|translate}}</span>

                    <!--code class-->
                    <mat-form-field *ngIf="step === 2 && !isSelectLevelEnabled">
                        <input formControlName="class_code"
                               matInput placeholder="{{ 'account-management.class-code' | translate }}">
                    </mat-form-field>
                    <span [ngStyle]="step === 2 && errorCodeClass!=='' ?
                      {'visibility':'visible', 'height':'auto'} : {'visibility':'hidden', 'height':'0'}"
                          class="class-code-error">{{'account-management.class-code-error'|translate}} {{errorCodeClass}}</span>

                    <ng-container *ngIf="step === 2 && isSelectLevelEnabled">
                        <button class="submit-button" *ngFor="let level of educationalLevels"
                                (click)="selectLevel(level)"
                                mat-raised-button>{{level.get('label')}}</button>
                    </ng-container>
                </form>
                <!--next step button-->
                <div class="submit-button-wrapper">
                    <button *ngIf="step !== 0 && step !==4 && !isSelectLevelEnabled" (click)="nextStep()"
                            [disabled]="currentStepHasError()" class="submit-button"
                            color="accent"
                            mat-raised-button>{{( step !== 3 ? 'account-management.next' : 'account-management.login') | translate}}
                    </button>
                </div>

                <div *ngIf="step===0 || step===1" class="register go-login-link-wrapper" fxLayout="column"
                     fxLayoutAlign="center center">
                    <span class="text">{{ 'account-management.account_already' | translate }} </span>
                    <a [routerLink]="'/login'" class="link">{{ 'authentication.btn-login' | translate}}</a>
                </div>
            </ng-container>
        </div>
    </div>
    <div #widgetFC class="frc-captcha" data-sitekey="FCMO9NIITAUMS1VJ"
         style="position:absolute!important; right: 0; bottom: 0;"></div>
</div>

