import {catchError, map, take, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication/core/authentication.service';
import {captcha, defaultApiURL, defaultRoute, friendlyCaptchaSecret, modulesSettings} from '../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {CommunicationCenterService} from '@modules/communication-center';
import {HttpClient} from '@angular/common/http';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';

const settingsStructure: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    signup: Structures.boolean(true),
    passwordPolicy: Structures.object(null),
    validateEmailStrategyActivated: Structures.boolean(false),
    showFormTextRedirection: Structures.boolean(false),
    mailConfirmActionButton: Structures.boolean(false),
});

@Injectable()
export class AccountManagementProviderService {

    message: any;
    userIsLogged$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    loggedUser: UserDataEntity;
    userData: UserDataEntity;

    public settings: { [key: string]: any };
    public data: BehaviorSubject<Object> = new BehaviorSubject({});
    captcha: string = captcha;
    friendlyCaptchaSecret: string = friendlyCaptchaSecret;

    constructor(
        private router: Router,
        private connector: OctopusConnectService,
        private authenticationService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
        private http: HttpClient
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: UserDataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                    this.connector.createEntity('authenticated', {id: this.userData.id, myType: 'authenticated'}).pipe(take(1));
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('account-management')
            .getSubject('refreshUser')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.getCurrentUser;
                }
            });
        this.communicationCenter
            .getRoom('authentication').next('userAccessToken', this.userAccessToken);
        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
    }

    private postLogout(): void {

    }

    private postAuthentication(): void {
        this.getCurrentUser;
    }

    createUser(user, callback, callbackError): void {
        this.connector.createEntity('user-registration', user).subscribe((userData) => {
            if (this.settings.validateEmailStrategyActivated) {
                callback();
            }else{
                this.userData = <UserDataEntity> userData;
                this.autoConnectUser(user, callback);
            }
        }, (error: Object) => {
            if (callbackError) {
                callbackError(error);
            } else {
                console.error(error);
            }
        });
    }

    /**
     * auto connect user after create account without verifying mail before
     * old process Marque blanche common process not use it anymore
     * @param user : user data
     * @param callback callback : pass screen to info saying email confirmation was send
     * @private
     */
    private autoConnectUser(user: any, callback: any): void {
        let user_email = user['email'];
        if (!user_email) {
            user_email = user['label'];
        }
        this.authenticationService.authenticateIn('http', user_email, user['password']).subscribe((userData) => {
            this.loggedUser = userData;
            callback();
        }, err => {
            console.log(err);
        });
    }

    sendMessage(message, callback): void {
        this.connector.createEntity('contact-message', message).subscribe(() => {
            callback();
        });
    }

    get getCurrentUser(): Observable<boolean> {

        const userData$ = <Observable<UserDataEntity>> this.connector.authenticated('http');

        userData$.subscribe((userData) => {
            this.loggedUser = userData;
            this.data.next(this.loggedUser);
            this.userIsLogged$.next(true);

        }, () => {
            this.userIsLogged$.next(false);
        });

        return this.userIsLogged$;
    }

    /**
     * Save user data
     * @param user data
     * @param callback with true if success, else false
     */
    public editUser(user, callback): void {
        this.loggedUser.set('label', user.label);
        this.loggedUser.set('email', user.email);
        this.loggedUser.set('institution', user.institution);
        this.loggedUser.set('you_are', user.you_are);
        this.loggedUser.set('find_us', user.find_us);
        this.loggedUser.set('level', user.level);
        this.loggedUser.set('region', user.region);
        this.loggedUser.set('newsletter', user.newsletter);
        this.loggedUser.set('_picture', user.picture);
        // this.loggedUser.set('role', user.role );
        if (user.password) {
            this.loggedUser.set('password', user.password);
        }
        this.loggedUser.save().subscribe((userEdited) => {
            this.loggedUser = userEdited;
            this.data.next(this.loggedUser);
            callback(true);
        }, error => callback(false));
    }

    editUserValidateEmail(): void {
        if (this.loggedUser) {
            this.loggedUser.set('email_status', true);
            this.loggedUser.save();
        } else {
            this.router.navigate(['/']);
        }
    }

    get userAccessToken(): any {
        return JSON.parse(localStorage.getItem('http_accessToken'));
    }

    /**
     * Remove current logged user
     */
    public deleteCurrentLoggedUser(): Observable<boolean> {
        return this.loggedUser.remove();
    }

    /**
     * return default route instance
     * @returns {string}
     */
    public get defaultRoute(): string {
        return defaultRoute;
    }

    public verifyToken(token: string): Observable<any> {
        return this.http
            .post<any>(defaultApiURL + 'api/recaptchav3', {'response': token})
            .pipe(map(res => res));
    }

    public verifyFriendlyCaptcha(solution: string): Observable<any> {
        return this.http
            .post<any>('https://friendlycaptcha.com/api/v1/siteverify', {'solution': solution, 'secret': this.friendlyCaptchaSecret})
            .pipe(map(res => res),
                catchError((error: any) => {
                    return throwError(error);
                })
            );
    }


    /**
     * Obtain list of educational levels
     */
    public getEducationalLevels(): Observable<DataEntity[]> {
        return this.connector.loadCollection('educational_level').pipe(map(collection => collection.entities));
    }
}
