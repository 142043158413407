import {Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FlashCardInterface} from 'fuse-core/components/flashcard/flash-card.interface';

@Component({
    selector: 'app-flashcard',
    templateUrl: './flashcard.component.html',
    styleUrls: ['./flashcard.component.scss']
})
export class FlashcardComponent implements OnInit {

    @HostBinding('class.is-playing') showPlayingCss = false;
    @HostBinding('class.is-selected') showSelectedCss = false;

    @Output() state = new EventEmitter<'is-playing' | 'is-selected'>();
    @Input() flashCard!: FlashCardInterface;
    @Input() readBlocked = false;

    private player: HTMLAudioElement;

    constructor() {
    }

    @ViewChild('stream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
        this.player = ref.nativeElement;
    }

    ngOnInit(): void {
    }

    @HostListener('click')
    onClick($event): void {
        if (this.readBlocked) {
            return;
        }
        if (this.showSelectedCss || this.showPlayingCss) {
            // don't change state but replay the sound
        } else {
            this.showPlayingCss = true;
            this.player.onended = () => {
                this.showPlayingCss = false;
                this.showSelectedCss = true;
                this.state.emit('is-selected');
            };
        }
        this.state.emit('is-playing');
        this.player.play();
    }
}

