<div mat-dialog-title>
    <h2>{{'error_reporting.title' | translate}}</h2>
    <button mat-mini-fab
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form [formGroup]="reportingForm" *ngIf="reportingForm !== undefined">
        <mat-card-subtitle>
            {{'error_reporting.home_text' | translate}}
        </mat-card-subtitle>

        <mat-form-field>
            <mat-label>
                {{'error_reporting.mail_label' | translate}}
            </mat-label>
            <input matInput type="text" formControlName="mail">
        </mat-form-field>

        <mat-form-field>
            <mat-label>
                {{'error_reporting.subject_label' | translate}}
            </mat-label>
            <input matInput type="text" formControlName="subject">
        </mat-form-field>

        <mat-form-field *ngIf="reportingForm.get('objectTitle')">
            <mat-label>
                {{'error_reporting.object_title' | translate}}
            </mat-label>
            <input matInput type="text" formControlName="objectTitle">
        </mat-form-field>

        <mat-form-field>
            <mat-label>
                {{'error_reporting.comment_label' | translate}}
            </mat-label>
            <textarea matInput formControlName="message"></textarea>
        </mat-form-field>

    </form>

    <div class="message-box error" *ngIf="isFormOnError">
        {{'error_reporting.error' | translate}}
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <!--    <button (click)="" mat-raised-button class="mat-accent">Envoyer</button>-->
    <ng-container *ngIf="isFormPending === false else loading">
        <button (click)="submitForm()" mat-raised-button class="mat-accent" [disabled]="reportingForm.valid === false || isFormOnError === true">
            {{'error_reporting.submit' | translate}}
        </button>
    </ng-container>

    <ng-template #loading>
        <button mat-raised-button class="mat-accent" disabled>
            <mat-spinner class="mat-accent"></mat-spinner>
        </button>
    </ng-template>

    <button class="mat-accent" mat-raised-button (click)="dialogRef.close(false)" [disabled]="isFormPending">{{'error_reporting.cancel' | translate}}</button>
</mat-dialog-actions>
