<div mat-dialog-title>
    <h2>{{ 'lesson_editor.delete_activity_title' | translate }}</h2>
    <button (click)="dialogRef.close(false)"
            aria-label="Close dialog"
            class="close"
            mat-mini-fab>
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    {{ 'lesson_editor.delete_activity_content' | translate }}
</mat-dialog-content>

<mat-dialog-actions>
    <button (click)="dialogRef.close(true)" class="mat-accent"
            mat-raised-button>{{'generic.confirm' | translate}}</button>
    <button (click)="dialogRef.close(false)"
            mat-raised-button>{{'generic.cancel' | translate}}</button>
</mat-dialog-actions>
