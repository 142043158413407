import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IsUserLogged} from '../../../guards/is-user-logged.class';
import {RouterModule, Routes} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {OctopusConnectModule} from 'octopus-connect';
import {ActivitiesComponent} from '@modules/activities/core/activities.component';
import {DataEntity} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {ActivitiesListComponent} from './activities-list/activities-list.component';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {CustomMatPaginatorIntl} from 'fuse-core/matPaginatorI18n';
import {ActivitiesPipesModule} from '@modules/activities/core/pipes/activitiesPipes.module';
import {DragulaModule} from 'ng2-dragula';
import {FlagService} from '../../../shared/flag.service';
import {ActivityServicesModule} from '@modules/activities/core/services/activity-services.module';
import {PlayersModule} from '@modules/activities/core/player-components/players.module';
import {DialogComponent} from './shared-components/dialog/dialog.component';
import {ArchiveService} from '../../../shared/archive.service';
import {ActivitiesSharedModule} from '@modules/activities/core/activities-shared.module';
import {FuseSharedModule} from 'fuse-core/shared.module';
import {SharedModule} from '../../../shared/shared.module';
import {ChaptersModule} from 'fuse-core/components/chapters-selection/chapters.module';
import {TagsModule} from 'fuse-core/components/tags-selection/tags.module';
import {EditorsModule} from '@modules/activities/core/editor-components/editors.module';
import {EditorActivitiesListComponent} from '@modules/activities/core/lessons/editor/components/editor-activities-list/editor-activities-list.component';
import {TraceService} from '../../../shared/trace.service';
import {SearchFiltersModule} from 'fuse-core/components';
import {ActivitiesHomeComponent} from '@modules/activities/core/activities-home/activities-home.component';
import {ActivitiesListEducationalLevelComponent} from '@modules/activities/core/activities-list-educational-level/activities-list-educational-level.component';
import {ChaptersListComponent} from '@modules/activities/core/activities-home/chapters-list/chapters-list.component';
import {LessonCardComponent} from '@modules/activities/core/activities-home/lesson-card/lesson-card.component';
import {AssignmentsListComponent} from '@modules/activities/core/activities-home/assignments-list/assignments-list.component';
import {ConceptCardComponent} from '@modules/activities/core/activities-home/concept-card/concept-card.component';
import {MyProgressComponent} from '@modules/activities/core/my-progress/my-progress/my-progress.component';
import {MyCurrentProgressComponent} from '@modules/activities/core/my-progress/my-current-progress/my-current-progress.component';
import {MyStatisticProgressComponent} from '@modules/activities/core/my-progress/my-statistic-progress/my-statistic-progress.component';
import {MyCurrentProgressThumbnailComponent} from '@modules/activities/core/my-progress/my-current-progress-thumbnail/my-current-progress-thumbnail.component';
import {RewardPageComponent} from '@modules/activities/core/reward-page/reward-page.component';
import {EndScreenScoreComponent} from '@modules/activities/core/end-screen-score/end-screen-score.component';
import {RewardUnlockedComponent} from '@modules/activities/core/reward-unlocked/reward-unlocked.component';
import {ContinueBarComponent} from '@modules/activities/core/activities-home/continue-bar/continue-bar.component';
import {DictionaryComponent} from '@modules/activities/core/dictionary/dictionary.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';

const routes: Routes = [
    {
        path: 'activities',
        canActivate: [IsUserLogged],
        component: ActivitiesComponent,
        children: [
            {
                path: 'list',
                component: ActivitiesListComponent
            },
        ]
    },
    {
        path: 'my-progress',
        canActivate: [IsUserLogged],
        component: MyProgressComponent
    },
    {
        path: 'dictionary',
        canActivate: [IsUserLogged],
        component: DictionaryComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        FuseSharedModule,
        SharedModule,
        OctopusConnectModule,
        RouterModule.forChild(routes),
        ActivitiesSharedModule,
        DragulaModule,
        ActivityServicesModule.forRoot(),
        PlayersModule,
        ActivitiesPipesModule,
        ChaptersModule,
        TagsModule,
        EditorsModule,
        SearchFiltersModule,
        InfiniteScrollModule,

    ],
    declarations: [
        ActivitiesComponent,
        DialogComponent,
        EditorActivitiesListComponent,
        ActivitiesHomeComponent,
        ChaptersListComponent,
        LessonCardComponent,
        ActivitiesListEducationalLevelComponent,
        AssignmentsListComponent,
        ConceptCardComponent,
        MyProgressComponent,
        MyCurrentProgressComponent,
        MyStatisticProgressComponent,
        MyCurrentProgressThumbnailComponent,
        EndScreenScoreComponent,
        RewardPageComponent,
        RewardUnlockedComponent,
        ContinueBarComponent,
        DictionaryComponent
    ],
    exports: [RouterModule, EndScreenScoreComponent]
})
export class ActivitiesModule {

    constructor(
        private authService: AuthenticationService,
        private communicationCenter: CommunicationCenterService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

    }

    static forRoot(): ModuleWithProviders<ActivitiesModule> {
        return {
            ngModule: ActivitiesModule,
            providers: [
                ArchiveService,
                FlagService,
                TraceService,
                {
                    provide: MatPaginatorIntl,
                    useClass: CustomMatPaginatorIntl
                }
            ]
        };
    }

    private postLogout(): void {
    }

    private postAuthentication(): void {
        // TODO menu
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'Activities',
        //     'title': 'Activities',
        //     'translate': 'activities.title',
        //     'type': 'item',
        //     'icon': 'activity',
        //     'url': '/activities/list'
        // });
        //
        // this.dynamicNavigation.registerModuleMenu('level0', {
        //     'id': 'tools',
        //     'title': 'Outils',
        //     'translate': 'generic.tools',
        //     'type': 'collapse',
        //     'icon': 'outils2'
        // });
        // this.dynamicNavigation.addChildTo('level0', 'tools', {
        //     'id': 'param-child',
        //     'title': 'Modèles de formulaires de séances',
        //     'translate': 'activities.forms_model_title',
        //     'type': 'item',
        //     'url': '/forms/list',
        //     'weight': 1
        // });

    }
}
