<div [ngClass]="classToAddInRegardOfState()">
    <div class=" answer-number">{{card.title}}</div>
    <div class="image-container">
        <img *ngIf="card.image" [attr.src]="card.image" [attr.alt]="card.altImage">
    </div>
    <div class="answer-text">{{card.info}}</div>
    <button (click)="launchPlay()" [attr.aria-label]="'accessibility-launch-play-audio' |translate"
            class="icone-read">
        <mat-icon [svgIcon]="'sound_on'"></mat-icon>
    </button>
    <!--id si dynamic because he must be unique on a page-->
    <audio [attr.id]="idDivPlaySound"
           controls
           [attr.src]="card.sound" [attr.aria-label]="card.altSound">
        Your browser does not support the
        <code>audio</code> element.
    </audio>
</div>
