import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {Injectable} from '@angular/core';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AuthenticationService} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {modulesSettings} from '../settings';
import {ModelSchema, Structures} from 'octopus-model';
import * as _ from 'lodash';

const settingsStructure: ModelSchema = new ModelSchema({
    cardLayout: Structures.string('card-simple-course'),
    progress: Structures.boolean(),
    grade: Structures.boolean(),
    showMetadataButton: Structures.boolean(false)
});

@Injectable()
export class DatacardService {

    userData: DataEntity;
    public settings: { [key: string]: any };
    private allowedRolesForModelsAssignation: string[] = [];
    private allowedRolesForModelsCreation: string[] = [];

    constructor(
        public octopusConnect: OctopusConnectService,
        public http: HttpClient,
        public accountManagementProvider: AccountManagementProviderService,
        public authenticationService: AuthenticationService,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                }
            });

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('allowedRolesForModelsAssignation')
            .subscribe((data: string[]) => {
                this.allowedRolesForModelsAssignation = data ? data : [];
            });

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('allowedRolesForModelsCreation')
            .subscribe((data: string[]) => {
                this.allowedRolesForModelsCreation = data ? data : [];
            });

        this.settings = settingsStructure.filterModel(modulesSettings.activities);
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authenticationService.hasLevel(user);
        }
        return false;
    }

    public isDuplicable(): boolean {
        return this.checkAccess(['trainer', 'administrator', 'manager']);
    }

    public isByRole(type): boolean {
        return type === 'byRole';
    }

    public isOwner(resource): boolean {
        return this.userData.id === resource.get('owner');
    }

    public isLocked(resource): boolean {
        return !!+resource.get('locked');
    }

    public isManager(): boolean {
        return this.checkAccess(['manager']);
    }

    /**
     * Return true if user is allowed to update or delete resource
     *
     * @remarks
     * If a user is allowed to update/delete a model, is allowed to update/delete any models (event if he's not the owner)
     *
     * @param resource
     * @param type as 'byRole' (for models) or 'currentUser'
     */
    public isEditableAndErasable(resource, type): boolean {
        return (this.isByRole(type) && this.allowedRolesForModelsCreation.includes(this.authenticationService.accessLevel))
            || (!this.isByRole(type) && this.isOwner(resource) && !this.isLocked(resource));
    }

    /**
     * Return true if type (model or not) of page and current user roles allow assignments
     * @param type if it's a model, value is 'byRole'
     */
    public isAssignable(type): boolean {
        if (this.isByRole(type)) {
            return this.allowedRolesForModelsAssignation.includes(this.authenticationService.accessLevel);
        } else {
            return this.checkAccess(['trainer', 'administrator']);
        }

    }

    public get showMetadata(): boolean {
        return this.settings.showMetadataButton;
    }

    public processResources(resources: any, callbacks: { [p: string]: (...any) => any }, type, settings = {}): any[] {
        return resources.map((resource) => {
            let originalResource;
            originalResource = resource;

            if (resource.ressourceEntity !== undefined) {
                resource = resource.ressourceEntity;
            }

            let dataCard = {
                'resource': resource,
                'originalResource': originalResource,
                'displayAssignedCount': !this.isByRole(type),
                'displayDuplicationCount': this.isByRole(type),
                'displayMetadata': this.showMetadata,
                'displayLesson': (data) => false,
                'displayTheme': (data) => false,
                'isAssignable': settings['assignable'] !== false && this.isAssignable(type),
                'isDuplicable': this.isDuplicable(),
            };

            dataCard = _.merge(
                dataCard,
                callbacks,
                {
                    'playAlt': callbacks.play,
                    'download': callbacks.play
                }
            );

            return dataCard;
        });
    }

    /***
     * download document and open in a new tab
     * @param path : path of the document
     */
    public downloadDoc(path: string): void {
        window.open(path, '_blank');
    }

}
