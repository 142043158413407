import {trackingID} from '../app/settings';

export function buildApiUrl(brand: string): string {
    return 'https://preprod.api.ubolino.tralalere.com/';
}

export function buildUrl(brand: string): string {
    return `https://preprod.app.ubolino.tralalere.com/`;
}

export function buildNodeUrl(brand: string): string {
    return `https://node.ubolino.tralalere.com/`;
}

export function lrsAuthToken(): string {
    return `MTAyNjZiYmZhNzZiMWRmM2Y2MmEyZDY2NDRkYWYzYmY0MjkxMmQwNDo5NjYyZjM4MjY3ZjBmY2ZlZmZkOTE2Njk1MGE5OTA1YWI3YjY4MTJi`;
}

export const environment = {
    production: true,
    hmr       : false,
    baseApiUrl: buildApiUrl,
    baseNodeUrl: buildNodeUrl,
    baseUrl: buildUrl
};

export function getTrackingID(trackingId?: string): string {
    return trackingId;
}

export function getTrackingIDMatomo(trackingId?: string): string {
    return trackingId; // default
}
