import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EditableActivity} from '@modules/activities/core/lessons/editor/models/editable-activity.class';
import {ActivityComponentInterface} from '@modules/activities/core/lessons/editor/models/activity-component.interface';

@Component({
  selector: 'app-one-line-activity',
  templateUrl: './one-line-activity.component.html',
})
export class OneLineActivityComponent implements ActivityComponentInterface {
    @Input() canJumpBelow = false;
    @Input() editableActivity: EditableActivity;
    @Output() edit = new EventEmitter<EditableActivity>();
    @Output() play = new EventEmitter<EditableActivity>();
    @Output() delete = new EventEmitter<EditableActivity>();
    @Output() jumpBelow = new EventEmitter<EditableActivity>();

    public getContentTypeIcon(): string {
        return this.editableActivity.type;
    }

    public selfDelete(): void {
        this.delete.emit(this.editableActivity);
    }

    public selfJumpBelow(): void {
        if (this.canJumpBelow) {
            this.jumpBelow.emit(this.editableActivity);
        }
    }

    public selfEdit(): void {
        this.edit.emit(this.editableActivity);
    }

    public selfPlay(): void {
        this.play.emit(this.editableActivity);
    }
}
