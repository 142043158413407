import {filter, map, take, takeUntil, tap} from 'rxjs/operators';
import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {UserActionsService} from '@modules/activities/core/services/user-actions.service';
import {Subject, combineLatest, ReplaySubject} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {FullscreenService} from '@fuse/services/fullscreen.service';
import {AuthenticationService} from '@modules/authentication';
import {defaultLoginRoute} from '../../../../settings';
import {LayoutAlign, StepItemType, StepperOptions} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';
import {StepperPosition} from '@modules/activities/core/shared-components/basic-stepper-container/stepper-wrapper.component';
import {GenericPluginsService, PluginType} from '@modules/activities/core/services/generic-plugins.service';
import {PluginSetting} from '../models/plugin.setting';
import {brand} from 'app/settings';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {CommunicationCenterService} from '@modules/communication-center';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';

@Component({
    selector: 'fuse-app-lessons',
    templateUrl: './lessons.component.html',
})
export class LessonsComponent implements OnInit, OnDestroy {
    public lessonTitle: string;
    public activityTitle = '';
    public stepTitle: string;
    public checkAnswer: boolean;
    public withoutAnyUserResponse: boolean;
    public enabledUserActionButtons: boolean;
    public doUserSeeAnswer = false;
    public hideStepper = true;
    public currentActivityID: number;
    public userAnswered: any = [];
    public isFullscreen = false;
    public isLoading = true;
    public learnerInfo: { classes: string, groups: string, name: string, uid: string };
    public isLatexKeyboardDisplayed: boolean;
    public onloadActivityAsCurrent = new Subject<any>();
    public stepperOptions: StepperOptions;
    public stepperPosition: StepperPosition | null = this.lessonsService.settings.stepper ? StepperPosition[<string>this.lessonsService.settings.stepper.position] : null;
    public currentStep = '1';
    public brand = brand;
    private previousConceptIdList = '';
    public numberOfQuestion = 0;
    public answersCopy = [];
    public barIsHidden = false;
    isLastTrueAnswer = false;
    public showHeaderExo = false; // use when reward page is opened
    public get isUserActionButtonsAllowed(): boolean {
        return this.actionButtonAllowedForUserRole &&
            (this.lessonsService.settings.hideUserActionButtons === false || this.lessonsService.isAssignmentWithMetacognition()) &&
            this.activitiesService.playScreenStatus === 0;
    }

    /**
     * is current user role allowed action button, manage by setting
     * (array of roles not allowed ex : ['trainer', 'learner']
     * empty array = no restriction
     */
    private get actionButtonAllowedForUserRole(): boolean {
        return this.lessonsService.settings.hideUserActionButtonsByRole.filter(role => role === this.authService.accessLevel).length <= 0;
    }

    private unsubscribeInTakeUntil = new Subject();
    private visitedActivity: any = [];
    private unSeenFirst: number;
    // contain all data from multiple lesson present in assignment with mulple lessons
    private currentActivity: DataEntity;
    public preloadedGranuleLessons: any[] = [];
    public preloadedGranuleActivities: any[] = []; // can be object[] from lesson reference or dataEntity[] cf combineLatest
    public preloadedGranuleUserSaves: any[] = [];
    public preloadedGranuleActivitiesSubject: ReplaySubject<any[]> = new ReplaySubject(1);
    public allowedToolsPlugins: { toolIdentifier: string; setting: PluginSetting }[] = [];

    private currentActivityPlayedIndex: number = null;
    private userSavesOfCurrentLesson: DataEntity[] = [];

    constructor(
        private router: Router,
        private location: Location,
        public activitiesService: ActivitiesService,
        private userActionsService: UserActionsService,
        private activatedRoute: ActivatedRoute,
        private genericPluginsService: GenericPluginsService,
        public lessonsService: LessonsService,
        private authService: AuthenticationService,
        private fullscreenService: FullscreenService,
        public dialog: MatDialog,
        private communicationCenter: CommunicationCenterService,
        private translate: TranslateService) {
        this.listenProgressBarExoEvent();

        this.communicationCenter
            .getRoom('app')
            .getSubject('visibility')
            .next(true);

        this.communicationCenter.getRoom('header-exo').getSubject('show')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(res => {
                this.showHeaderExo = res;
            });

        // when user make an answer if it's wrong and if this answer has a feedback this must open a modal with feedback
        this.communicationCenter.getRoom('header-exo').getSubject('show-custom-feedback')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((res: { feedback: string, title: string }) => {
                this.help(res.feedback, res.title);
            });

        this.communicationCenter.getRoom('activities')
            .getSubject('backToLesson')
            .subscribe(({forceUrl, initialiseSubject}: { forceUrl: boolean, initialiseSubject: boolean }) => {
                if (initialiseSubject) {
                    forceUrl
                        ? this.exitLesson(['lessons', 'grade', this.lessonsService.currentLesson.get('metadatas').educationalLevel[0].id, 'concept', this.previousConceptIdList])
                        : this.exitLesson();
                }
            });

        this.communicationCenter.getRoom('activities').getSubject('retryCurrentActivity')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                filter((val: boolean) => !!val)
            )
            .subscribe(() => {
                this.activitiesService.playScreenStatus = 1;
                this.navigateToActivity(this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.currentActivity.id));
            });

        this.communicationCenter.getRoom('activities').getSubject('nextActivity')
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                filter((val: boolean) => !!val),
                tap(() => this.activitiesService.playScreenStatus = 1)
            )
            .subscribe(() => {
                const indexCurrentActivity = this.lessonsService.currentLesson.get('reference').findIndex((activity) => +activity.id === +this.currentActivity.id);
                if (indexCurrentActivity < this.lessonsService.currentLesson.get('reference').length - 1) {
                    this.navigateToActivity(indexCurrentActivity + 1);
                }
            });

        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-player-active');
    }

    /**
     * listen all event that change the status of progress bar:
     * number of question of the current activity
     * answer made state : true or false
     * reset answers to do when change of activity
     * all event are send from exercices to here
     * @private
     */
    private listenProgressBarExoEvent(): void {
        // number of question for the current exercice
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('numberOfQuestion').subscribe(res => {
            this.numberOfQuestion = res;
        });

        // state of last answer is a true or is a false answer
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('answerResult').subscribe(res => {
            this.addAnswer(res);
        });
        // reset answers state
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('reset').subscribe(res => {
            this.activitiesService.answersProgressBarMultiZone = [];
            this.answersCopy = [];
        });

        // hide or show
        this.communicationCenter.getRoom('progress-bar-exo').getSubject('hide')
            .pipe(takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(res => {
                this.barIsHidden = res;
            });
    }

    /**
     * all the constructor code is move here because we need to listen
     * load of same component with other id and launch all the code again in that case
     */
    initialiseState(): void {
        this.currentActivityID = this.activitiesService.getLessonActivityID();
        this.activitiesService.endScreenSeen = false;
        this.activitiesService.doesUserResponsed.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data: boolean) => {
                this.enabledUserActionButtons = data;
                this.doUserSeeAnswer = false;
            });
        this.activitiesService.isUserAnswerStatus.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe((data) => {
                if (this.activitiesService.activitiesArray.length) {
                    if (data.status) {
                        this.activitiesService.activityAnswerResult[data.index] = data.status;
                        this.userAnswered = this.activitiesService.activityAnswerResult;
                        this.refreshStepper();
                    }
                }
            });
        this.activitiesService.checkAnswers.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(tempValue => {
            this.withoutAnyUserResponse = tempValue['withoutAnyUserResponse'];
            this.checkAnswer = tempValue['showAnswers'];
        });

        if (this.authService.isAtLeastTrainer() && this.activitiesService.currentAssignment && this.lessonsService.displayLearnerInfo()) {
            this.learnerInfo = this.activitiesService.currentAssignment.get('assignated_user');
        }

        this.allowedToolsPlugins = this.getToolsPlugins();

        this.refreshStepper();
    }

    ngOnInit(): void {
        this.defaultHeaderVisible(false);
        this.defaultFooterVisible(true);
        // use for multi assignment because component is not destroy before changing content
        this.activatedRoute.params.pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(params => {
            this.reset(false);
            this.initialiseState();
            this.initSubscribes();
            this.gotoEndPage();
        });

        this.listenlastConceptSelected();

    }

    /**
     * when navigate in exo if we close we want to come back to previous concept listing
     * header with close button is here so we store it in case of closing exo
     */
    private listenlastConceptSelected(): void {
        this.communicationCenter.getRoom('concept').getSubject('lastConceptId').pipe(
            takeUntil(this.unsubscribeInTakeUntil)
        ).subscribe(id => {
            this.previousConceptIdList = id;
        });
    }

    /**
     * move from ngOninit for beeing able to launch it only
     * most of content are subscribing content
     */
    private initSubscribes(): void {
        // TODO check if no regressions vith MB
        if (!this.lessonsService.currentAssignment && this.lessonsService.getAllowedRolesForAutoAssignmentCreation().includes(this.authService.accessLevel) === false) {
            this.router.navigate([defaultLoginRoute]);
        } else {
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((urlSegment) => {
                    this.stepTitle = this.lessonStep ? this.lessonStep.typeSteps[this.activitiesService.presentArrayElementIndex].label : '';
                });

            this.lessonsService.lessonButtonClicked.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(value => {
                    this.userAnswered = this.activitiesService.activityAnswerResult;
                    const screenState = this.activitiesService.playScreenStatus;
                    this.hideStepper = false;
                    if ([0, 1, 3].includes(screenState)) {
                        this.hideStepper = true;
                    }
                    this.setActivityVisitInfo();
                });

            this.loadLessonById(this.activatedRoute.snapshot.params['lessonId'], this.activatedRoute.snapshot.queryParams['startOnStepIndex']);

            this.activitiesService.activityActionsHandler.pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe(data => {
                    if (data['resetAll']) {
                        this.activitiesService.unsetAnswerTempSave();
                        this.userAnswered = this.activitiesService.activityAnswerResult;
                    }
                });

            this.activitiesService.onLatexKeyboardDisplayChange.subscribe((isDisplayed: boolean) => {
                this.isLatexKeyboardDisplayed = isDisplayed;
            });
            this.onloadActivityAsCurrent.subscribe(stepItem => {
                if (stepItem !== null) {
                    // check if we can navigate in previous activity in the stepper
                    if (this.canNavigateToStep(stepItem)) {
                        this.currentActivity = stepItem;
                        this.gotoCurrentPage(this.activitiesService.activitiesArray.indexOf(stepItem));
                        this.currentStep = (this.activitiesService.activitiesArray.indexOf(stepItem) + 1).toString();
                    }
                } else {
                    this.gotoEndPage();
                }
            });
        }

        this.lessonsService.activityIdChange.pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe(() => {
            this.updateActivity();
        });

    }

    /**
     * check if we can navigate in previous activity in the stepper
     * @param stepItem
     * @returns {boolean}
     */
    public canNavigateToStep(stepItem): boolean {
        if (this.activitiesService.settings['navigationInStepsAllowed']) {
            if (this.lessonsService.isAssignmentWithMetacognition() &&
                this.activitiesService.activitiesArray.indexOf(stepItem) < this.activitiesService.activitiesArray.indexOf(this.currentActivity)) {
                return false;
            }
            if (!this.activitiesService.settings['navigateInPreviousStep']) {
                return this.activitiesService.activitiesArray.indexOf(stepItem) >= this.activitiesService.activitiesArray.indexOf(this.currentActivity);
            }
            return true;
        } else {
            return false;
        }
    }

    private refreshStepper(): void {

        if (this.lessonsService.settings.stepper === null) {
            this.stepperOptions = null;
            return;
        }

        const isStepActive = (actno) => {
            if (this.lessonsService.settings.stepper.stepValidationNeedSave) {
                return this.visitedMediaActivity.includes(actno) || this.userAnswered[actno] === 1 || this.userAnswered[actno] === 3;
            }
            return this.visitedActivity.includes(actno);
        };

        // check if visited activity for stepper color point
        const isUsersaveForActivity = (activ) => {
            return this.lessonsService.isAssignmentWithMetacognition() &&
                !!this.userSavesOfCurrentLesson
                    .find((usersave) => usersave.get('granule')[0] === activ.id.toString() &&
                        this.lessonsService.currentLesson && +this.lessonsService.currentLesson.id === +usersave.get('granuleParent'));
        };
        if (!this.visitedActivity.length) {
            this.visitedActivity.push(+this.currentStep - 1); // if no visited activities, set current activity as visited
        }
        this.stepperOptions = {
            steps: this.activitiesService.activitiesArray.map((activity, actno) => {
                return {
                    encapsulatedObject: activity,
                    isActive: this.lessonsService.isAssignmentWithMetacognition() ? isUsersaveForActivity(activity) : isStepActive(actno),
                    isCurrent: this.lessonsService.isAssignmentWithMetacognition() ? +this.currentActivityPlayedIndex === actno : (this.visitedActivity.includes(actno)),
                    isArrowActive: (this.activitiesService.presentArrayElementIndex === actno
                        && this.activitiesService.playScreenStatus !== 1
                        && this.activitiesService.playScreenStatus !== 3),
                    type: this.getActivityType(activity)
                };

            }),
            onStepItemClick: this.onloadActivityAsCurrent,
            recap: {
                isActive: this.activitiesService.playScreenStatus === 3 || this.activitiesService.endScreenSeen,
                isAllowed: this.lessonsService.settings.stepper.showRecap && this.isAllowedRecap,
                isArrowActive: this.activitiesService.playScreenStatus === 3,
            },
            stepperLayout: {
                align: LayoutAlign[<string>this.lessonsService.settings.stepper.align],
            }
        };
    }

    /**
     * if we have severals lessons in assignment, we check if we are a the last lesson for showing recap
     * @returns {boolean}
     */
    get isAllowedRecap(): boolean {
        const assignment = this.activitiesService.currentAssignment;
        if (assignment &&
            assignment.get('assignated_nodes') &&
            assignment.get('assignated_nodes').length && this.lessonsService.currentLesson) {
            return this.lessonsService.currentLesson.id === +assignment.get('assignated_nodes')[assignment.get('assignated_nodes').length - 1].id;
        }

        return true;
    }

    get visitedMediaActivity(): number[] {
        return this.activitiesService.visitedMediaActivity;
    }

    ngOnDestroy(): void {
        this.communicationCenter.getRoom('header-exo').removeSubject('show');
        this.communicationCenter.getRoom('header-exo').removeSubject('show-custom-feedback');
        this.communicationCenter.getRoom('multi').getSubject('hide-content').next(false);

        if (this.unsubscribeInTakeUntil) {
            this.unsubscribeInTakeUntil.next();
            this.unsubscribeInTakeUntil.complete();
        }
        this.reset(false);
        this.defaultHeaderVisible(true);
        this.defaultFooterVisible(false);
        this.activitiesService.answersProgressBarMultiZone = [];
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-player-active');
    }

    /**
     * reset all data and state
     */
    private reset(resetAssignment: boolean): void {
        this.activitiesService.clearLessonState(resetAssignment);
        this.userAnswered = [];
        this.lessonTitle = '';
        this.activityTitle = '';
        this.stepTitle = '';
        this.checkAnswer = false;
        this.withoutAnyUserResponse = false;
        this.enabledUserActionButtons = false;
        this.doUserSeeAnswer = false;
        this.hideStepper = true;
        this.currentActivityID = null;
        this.userAnswered = [];
        this.isFullscreen = false;
        this.isLoading = true;
        this.learnerInfo = null;
        this.isLatexKeyboardDisplayed = false;
        this.onloadActivityAsCurrent = new Subject<any>();
        this.stepperOptions = null;
        this.stepperPosition = this.lessonsService.settings.stepper ? StepperPosition[<string>this.lessonsService.settings.stepper.position] : null;
        this.currentStep = '1';
        this.visitedActivity = [];
        this.unSeenFirst = null;
    }

    @HostListener('window:beforeunload', ['$event'])
    checkSaved($event: any): void {
        if (this.lessonsService.savingAssignment) {
            $event.returnValue = true;
        }
    }

    public setActivityVisitInfo(): any {
        const actno = this.activitiesService.presentArrayElementIndex;
        const stepitem = this.activitiesService.activitiesArray[actno];
        const mediaTypes = ['divider', 'media', 'image', 'video', 'audio', 'document', 'url', 'videoUrl'];
        if (stepitem !== undefined && mediaTypes.includes(stepitem.type) && !this.visitedMediaActivity.includes(actno)) {
            this.visitedMediaActivity.push(actno);
        }
        if (this.activitiesService.playScreenStatus === 0) {
            this.visitedActivity.push(this.activitiesService.presentArrayElementIndex);
        }

        // find the first activity unseen in the current lesson
        if (this.activitiesService.activitiesArray.length) {
            const activities = this.activitiesService.activitiesArray;
            this.unSeenFirst = activities.findIndex((activity) => {
                if (activities.length > this.activitiesService.presentArrayElementIndex + 1) {
                    return +activity.id === +activities[this.activitiesService.presentArrayElementIndex + 1].id;
                }
                return +activity.id === +activities[this.activitiesService.presentArrayElementIndex].id;
            });
        }
        this.refreshStepper();
    }

    public checkLastVisited(): boolean {
        return this.visitedActivity.includes(this.activitiesService.activitiesArray.length - 1);
    }

    public gotoEndPage(): void {
        /*todo: in progress, if we reach the last activity in the current lesson for assignment with multiple lesson,
        need to know if we are in the last lesson to navigate to recap*/
        if (this.activitiesService.playScreenStatus === 1 || this.lessonsService.isAssignmentWithMetacognition()) {
            return;
        }

        if (this.activitiesService.endScreenSeen || this.checkLastVisited() || !this.authService.isLearner()) {
            let allSeen = this.activitiesService.activitiesArray.length;
            if (this.authService.isLearner()) {
                allSeen = this.visitedActivity.length;
            }
            if (this.activitiesService.activitiesArray.length <= allSeen + 1) {
                this.activitiesService.playScreenStatus = 3;

                this.activitiesService.endScreenSeen = true;
                this.router.navigate(['recap'], {relativeTo: this.activatedRoute});

                this.checkAnswer = false;
                this.withoutAnyUserResponse = false;
                this.userActionsService.resentFinalAnswerParametersProperties(false, false, false);
                this.lessonsService.lessonButtonClicked.next(true);
            }
        }
    }

    public gotoCurrentPage(index): void {
        if (this.activitiesService.playScreenStatus === 1) {
            return;
        }

        if (
            !this.lessonsService.isLessonTest() &&
            !this.visitedActivity.includes(index) &&
            this.unSeenFirst !== index
        ) {
            return;
        }

        const altPath = this.activitiesService.playScreenStatus === 3;
        this.activitiesService.playScreenStatus = 0;
        this.activitiesService.loadActivityByStep(index, altPath);
        this.lessonsService.lessonButtonClicked.next(true);
        this.activitiesService.activityChange.next(true);
    }

    public resetAll(): void {
        this.doUserSeeAnswer = false;
        this.checkAnswer = this.enabledUserActionButtons = false;
    }

    /**
     * generic way to find last entity edited
     * used for find last usersave edited
     * @param values
     * @returns {DataEntity}
     */
    getLastEntity(values): DataEntity {
        const listChangedDate = values.map((item) => +item.get('changed'));
        return values.find((item) => +item.get('changed') === Math.max(...listChangedDate));
    }

    /**
     * preload all data : granule lesson, activities, usersave in assignment with multiple lessons
     * @param granuleLessons
     * @returns {any}
     */
    preload(granuleLessons): any {
        return combineLatest<DataEntity[]>(granuleLessons)
            .pipe(
                takeUntil(this.unsubscribeInTakeUntil),
                tap(granules => this.preloadedGranuleLessons = granules),
                map((granules: DataEntity[]) => {
                    const allGranuleActivitiesInAllLessons = [];
                    return granules.map((granule) => granule.get('reference').map((ref) => {
                        // if observable granule activity already loaded, need to "next" a new replaySubject with the already loaded activity
                        const activityAlreadyLoaded = allGranuleActivitiesInAllLessons.find((activity) => +activity.id === +ref.id);
                        if (!!activityAlreadyLoaded) {
                            const observable = new ReplaySubject<DataEntity>();
                            observable.next(activityAlreadyLoaded);
                            return observable;
                        } else {
                            allGranuleActivitiesInAllLessons.push(ref);
                            return this.activitiesService.loadActivitiesFromId(ref.id);
                        }
                    }));
                }),
                map((refs) =>
                    refs.map((ref) =>
                        combineLatest([ref]).pipe(
                            tap(entities => {
                                return this.preloadedGranuleActivities.push(entities);
                            })
                        ).subscribe(() => {
                            // check if all activities form all lesson are loaded exp: length => [[activity][activity]] === [lesson, lesson]
                            if (this.preloadedGranuleActivities.length === this.preloadedGranuleLessons.length) {
                                this.preloadedGranuleActivitiesSubject.next(this.preloadedGranuleActivities);
                            }
                        })
                    ),
                ));
    }

    /**
     * Reset data, load lesson, activities, saves, estimate the last unseen activity and set this activity has current
     * @param lessonId
     * @param loadOnStep index of activity to set as current for overwrite the default current activity (first unseen activity that can be deduced by the absence of save)
     */
    private loadLessonById(lessonId: string, loadOnStep?: string): void {

        if (this.activitiesService.activitiesArray.length === 0) {
            this.activitiesService.isLessonPlay = true;
            this.activitiesService.playScreenStatus = 1;
            this.activitiesService.presentArrayElementIndex = 0;
            this.activitiesService.activityAnswerResult = [];
            this.userAnswered = this.activitiesService.activityAnswerResult;
            this.lessonsService.loadLessonById(lessonId).pipe(
                takeUntil(this.unsubscribeInTakeUntil))
                .subscribe((lessonEntity: DataEntity) => {
                    this.processLesson(lessonEntity, loadOnStep);
                });

        } else {
            if (this.activitiesService.currentLesson) {
                this.lessonTitle = this.activitiesService.currentLesson.get('metadatas').title;
            }
        }
    }

    /**
     * process usersave state for the stepper and navigate to the activity or load recap if it is the last activity
     * @param activities
     * @param saves
     * @param loadOnStep
     * @param {boolean} alreadyLessonLoaded
     */
    processActivitiesAndUsersaves(activities: DataEntity[], saves: DataEntity[], loadOnStep, alreadyLessonLoaded = true): void {
        let unseenStep = 0;

        if (saves && this.lessonsService.settings.stepperLoadStatus) {
            let index: number;
            let status: number;

            for (const save of saves) {
                index = activities.findIndex(activity => activity.id === save.get('granule')[0]);

                if (index > -1) {
                    switch (save.get('state')) {
                        case 'validated':
                            status = 1;
                            break;
                        case 'incomplete':
                            status = 2;
                            break;
                        case 'correct':
                            status = 3;
                            break;
                        default:
                            status = 2;
                            break;
                    }

                    if (!this.activitiesService.activityAnswerResult[index] || this.activitiesService.activityAnswerResult[index] === 2) {
                        this.activitiesService.activityAnswerResult[index] = status;
                    }

                    if (index >= unseenStep) {
                        unseenStep = index + 1;
                    }
                }
            }

            const actno = unseenStep + 1;
            const stepitem = this.activitiesService.activitiesArray[unseenStep];
            // if media, url or divider is the last step go to recap
            if (actno === this.activitiesService.activitiesArray.length
                && ['audio', 'video', 'image', 'document', 'media', 'divider', 'url', 'videoUrl']
                    .includes(stepitem.type)
            ) {
                unseenStep += 1;
            }

            this.userAnswered = this.activitiesService.activityAnswerResult;
            // tslint:disable-next-line:no-shadowed-variable
            for (let index = 0; index < this.activitiesService.activityAnswerResult.length; index += 1) {
                this.visitedActivity.push(index);

                if (
                    ['audio', 'video', 'image', 'document', 'media', 'divider', 'url', 'videoUrl']
                        .includes(this.activitiesService.activitiesArray[index].type)
                ) {
                    this.visitedMediaActivity.push(index);
                }
            }
        }

        if (loadOnStep) {
            unseenStep = +loadOnStep;
            this.activitiesService.presentArrayElementIndex = +loadOnStep;
            this.currentStep = (+loadOnStep + 1).toString();
        }
        let isActivityNotAnsweredFounded: DataEntity;
        if (this.lessonsService.isAssignmentWithMetacognition()) {
            this.activitiesService.playScreenStatus = 0;
            this.lessonsService.lessonButtonClicked.next(true);
            // check if number of user-save equal activities in current lesson
            this.userSavesOfCurrentLesson = saves.filter((save: DataEntity) => +save.get('lesson') === +this.lessonsService.currentLesson.id);
            if (this.userSavesOfCurrentLesson.length && this.userSavesOfCurrentLesson.length === this.lessonsService.currentLesson.get('reference').length) {
                const userSaveWithNoAnswer = this.userSavesOfCurrentLesson.find((save) => save.get('created') === save.get('changed'));
                isActivityNotAnsweredFounded = userSaveWithNoAnswer ? activities.find((activity: DataEntity) => +activity.id === +userSaveWithNoAnswer.get('granule')[0]) : null;
            }

            if (!isActivityNotAnsweredFounded && this.userSavesOfCurrentLesson.length &&
                this.userSavesOfCurrentLesson.length < this.lessonsService.currentLesson.get('reference').length) {
                const userSaveCreatedByDefault = this.userSavesOfCurrentLesson.find((userSave) => userSave.get('created') === userSave.get('changed'));
                if (userSaveCreatedByDefault) {
                    isActivityNotAnsweredFounded = activities.find((activity) => +activity.id === +userSaveCreatedByDefault.get('granule')[0]);
                } else {
                    const userSaves: number[] = this.userSavesOfCurrentLesson.map((save) => +save.get('granule')[0]);
                    isActivityNotAnsweredFounded = activities.filter((activity) => userSaves.includes(+activity.id) === false)[0];
                }
            }
            if (!!isActivityNotAnsweredFounded || !this.userSavesOfCurrentLesson.length) {
                this.lessonsService.saveProgressInAssignmentWithMetacognition(false);
                unseenStep = isActivityNotAnsweredFounded ? activities.findIndex((activity: DataEntity) => +activity.id === +isActivityNotAnsweredFounded.id) : 0;
                this.activitiesService.loadActivityByStep(unseenStep, true);
            } else {
                this.lessonsService.saveProgressInAssignmentWithMetacognition(true);
                this.activitiesService.metacognition(true);
            }
            this.currentActivityPlayedIndex = unseenStep;
        } else {
            this.navigateToActivity(unseenStep);
        }
        this.refreshStepper();

        this.isLoading = false;
    }

    navigateToActivity(unseenStep: number): void {
        if (this.activitiesService.playScreenStatus === 1) {
            this.lessonsService.lessonButtonClicked.next(true);
            this.activitiesService.playScreenStatus = 0;
            if (unseenStep < this.lessonsService.currentLesson.get('reference').length) {
                this.activitiesService.loadActivityByStep(unseenStep, true);
            } else {
                this.lessonsService.loadRecapScreen();
            }
        }
    }

    /**
     * set activitiesService with the lesson selected, the activities in the lesson, the step to navigate
     * @param lessonEntity
     * @param loadOnStep
     * @param {any} usersaves
     * @param {any} alreadyLessonLoaded
     */
    processLesson(lessonEntity, loadOnStep, usersaves = null, alreadyLessonLoaded = null): void {
        this.activitiesService.currentLesson = lessonEntity;
        this.lessonsService.currentLesson = lessonEntity;
        if (lessonEntity.get('metadatas')) {
            this.lessonTitle = lessonEntity.get('metadatas').title;
        }
        this.activitiesService.pushLessonFromAssignment.next(lessonEntity);
        const activitiesArray = this.activitiesService.getPropertyFromNestedObject(lessonEntity, ['attributes', 'reference']);
        this.activitiesService.setActivitiesListWithIds(activitiesArray);
        this.currentActivity = this.activitiesService.activitiesArray[+loadOnStep ? +loadOnStep : 0];
        if (usersaves) {
            this.processActivitiesAndUsersaves(activitiesArray, usersaves, loadOnStep, alreadyLessonLoaded);
        } else {
            if (this.lessonsService.currentAssignment) {
                const obsList = [
                    this.lessonsService.loadLessonActivities(lessonEntity),
                    this.lessonsService.loadUserSaves(this.lessonsService.currentAssignment.id.toString())
                ];
                combineLatest(obsList).pipe(
                    takeUntil(this.unsubscribeInTakeUntil),
                    tap(([activityEntities, userSaveEntities]) => this.processActivitiesAndUsersaves(activityEntities, userSaveEntities, loadOnStep))
                ).subscribe();
            } else {
                this.lessonsService.launchLessonAutoAssignment(lessonEntity, null, null, loadOnStep);
            }
        }

    }

    /**
     * change from full-screen or revert to not full-screen mode
     */
    public fullscreenMode(): void {
        this.fullscreenService.fullscreen();
        this.isFullscreen = this.fullscreenService.mode;
    }

    /**
     * exit lesson if modal confirm is need open it before to ask confirmation
     */
    public goBack(): void {
        if (this.lessonsService.settings.isModalConfirmBeforeCloseExo) {
            this.openConfirmExitModal();
        } else {
            this.exitLesson();
        }
    }

    /**
     * open modal to confirm exit
     * if confirm then exit else stay here
     */
    private openConfirmExitModal(): void {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: 'activities.title_modal_stop',
            labelTrueDialog: 'activities.title_modal_quit',
            labelFalseDialog: 'activities.title_modal_cancel',
            bodyDialog: 'activities.title_modal_content',
        };

        this.translate.get(dialogConfig.data.titleDialog).subscribe((translation: string) => dialogConfig.data.titleDialog = translation);
        this.translate.get(dialogConfig.data.labelTrueDialog).subscribe((translation: string) => dialogConfig.data.labelTrueDialog = translation);
        this.translate.get(dialogConfig.data.labelFalseDialog).subscribe((translation: string) => dialogConfig.data.labelFalseDialog = translation);
        this.translate.get(dialogConfig.data.bodyDialog).subscribe((translation: string) => dialogConfig.data.bodyDialog = translation);

        dialogConfig.panelClass = 'help_close_modal'; // make modal more from bottom not centered
        dialogConfig.backdropClass = 'backdrop-blur';
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                this.exitLesson(['lessons', 'grade', this.lessonsService.currentLesson.get('metadatas').educationalLevel[0].id, 'concept', this.previousConceptIdList]);
            }
        });
    }

    /**
     * exit lesson
     */
    private exitLesson(forceRouting: string[] = []): void {
        if (this.isFullscreen) {
            this.fullscreenMode();
        }
        if (forceRouting !== []) {
            this.router.navigate(forceRouting);
        } else if (this.lessonsService.currentAssignment) {
            this.router.navigate(['/followed/list']);
        } else {
            const backRoute = this.lessonsService.exitLessonUrl ? this.lessonsService.exitLessonUrl : '/lessons/list';
            this.lessonsService.exitLessonUrl = '';
            this.router.navigate([backRoute]);
        }
        // Ubolino case : the player exits the activity. Suspends the lrs tracking
        if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('activity_suspend')
                .next({id: `questionSet/${this.currentActivity.id}`});
        }

        this.lessonsService.resetActivitiesArray();
        this.activitiesService.resetActivitiesInCache();
    }

    public isMedia(): boolean {
        if (this.activitiesService.activitiesArray.length === 0) {
            return false;
        }

        return this.activitiesService.activitiesArray[this.activitiesService.presentArrayElementIndex].type === 'media';
    }

    public getCurrentActivityOrMediaId(): number {
        if (this.activitiesService.activitiesArray.length === 0) {
            return 0;
        }

        return this.activitiesService.activitiesArray[this.activitiesService.presentArrayElementIndex].id;
    }

    /**
     * update the current activity and set title
     */
    public updateActivity(): void {
        this.currentActivity = this.activitiesService.getCurrentActivity(this.activitiesService.presentArrayElementIndex);
        if (this.currentActivity) {
            if (this.currentActivity.get('metadatas').typology && this.currentActivity.get('metadatas').typology.label === 'video') {
                this.activityTitle = this.currentActivity.get('reference').activity_content[0].granule[0].metadatas['title'];
            } else {
                this.activityTitle = this.currentActivity.get('metadatas').title;
            }
        }
    }

    public get showActivityTitle(): boolean {
        return this.lessonsService.settings.showActivityTitle;
    }

    public get showActivityQuestionIndex(): boolean {
        return this.lessonsService.settings.showActivityQuestionIndex;
    }

    public get showLessonTitle(): boolean {
        return this.lessonsService.settings.showLessonTitle;
    }

    public get showProgressBarExo(): boolean {
        return this.lessonsService.settings.showProgressBarExo && !this.barIsHidden;
    }

    public get lessonTitleIcon(): boolean {
        return this.lessonsService.settings.showIconTitleLesson;
    }

    private get lessonStep(): any {
        return this.lessonsService.settings.lessonStep;
    }

    public get hasLessonSteps(): boolean {
        return this.lessonStep && this.lessonStep.typeSteps && this.lessonStep.typeSteps.length > 0;
    }

    private getActivityType(activity): StepItemType {
        switch (activity.type) {
            case 'audio' :
            case 'video' :
            case 'image' :
            case 'document' :
            case 'media' :
            case 'url' :
            case 'videoUrl' : {
                return StepItemType.media;
            }
            case 'divider' : {
                return StepItemType.divider;
            }
            default: {
                return StepItemType.activity;
            }
        }
    }

    public get isLessonWithStep(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.lessonStep;
    }

    public get displayFullscreenButton(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.displayFullscreenButton;
    }

    /**
     * use png or svg in regard of settings
     */
    public get displayCloseButtonPng(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.closeButtonPng;
    }

    /**
     * show help button or not
     */
    public get displayHelpButton(): boolean {
        return this.lessonsService.settings && this.lessonsService.settings.showHelp;
    }

    /**
     * Obtains the list of Plugins of type tools
     */
    public getToolsPlugins(): { toolIdentifier: string; setting: PluginSetting }[] {
        return this.genericPluginsService.getPluginsByType(PluginType.lessonTool);
    }

    /**
     * Execute the tool from the service and give it the lesson and the step for allow the service to reload the lesson at the current step.
     * @param tool
     */
    public executeTool(tool: { toolIdentifier: string; setting: PluginSetting }): void {
        this.lessonsService.executeToolFromLesson(tool, {lesson: this.lessonsService.currentLesson, step: this.activitiesService.presentArrayElementIndex}).pipe(
            take(1)
        ).subscribe();
    }

    /**
     * progression of the learner on the current assignement in number format
     */
    get progression(): number {
        return this.activitiesService.currentAssignment
        && this.activitiesService.currentAssignment.attributes.progress ? +this.activitiesService.currentAssignment.attributes.progress : 0;
    }

    /**
     * Display feedbacks section boolean
     */
    public get displayFeedbacks(): boolean {
        return this.lessonsService.settings.displayFeedbacks // setting authorize feedbacks
            && this.lessonsService.currentAssignment.get('hideFeedbacks') === false // Current assignment don't override feedback's authorization
            && this.activitiesService.currentActivityIsRecap() === false; // But don't show the feedback menu in the last "page" : the recap !
    }

    /**
     * if arrow navigation enable, navigate to next activity
     */
    public nextActivity(): void {
        if (this.activitiesService.activitiesArray[+this.currentStep]) {
            this.onloadActivityAsCurrent.next(this.activitiesService.activitiesArray[+this.currentStep]);
        }
    }

    /**
     * if arrow navigation enable, navigate to previous activity
     */
    public previousActivity(): void {
        if (this.activitiesService.activitiesArray[+this.currentStep - 2]) {
            this.onloadActivityAsCurrent.next(this.activitiesService.activitiesArray[+this.currentStep - 2]);
        }
    }

    /**
     * if arrow navigation enable, show arrow for navigation
     * @param action
     */
    public isArrowNavigationEnabled(action: string): boolean {
        const roles: string[] = Object.keys(this.activitiesService.settings.isArrowNavigationEnabled);
        if (action === 'next') {
            return (roles.length && roles.includes(this.authService.accessLevel) ?
                this.activitiesService.settings.isArrowNavigationEnabled[this.authService.accessLevel] :
                this.activitiesService.settings.isArrowNavigationEnabled.default) &&
                this.activitiesService.activitiesArray[+this.currentStep];
        }
        if (action === 'previous') {
            return (roles.length && roles.includes(this.authService.accessLevel) ?
                this.activitiesService.settings.isArrowNavigationEnabled[this.authService.accessLevel] :
                this.activitiesService.settings.isArrowNavigationEnabled.default) &&
                this.activitiesService.activitiesArray[+this.currentStep - 2];
        }

        return false;
    }

    /**
     * send information that an exercices is launch and default header with menu and progress must be hide
     * @private
     */
    private defaultHeaderVisible(visible: boolean): void {
        this.communicationCenter
            .getRoom('header')
            .getSubject('visibility').next(visible);
    }

    /**
     * send information that an exercices is launch and default footer must be visible
     * @private
     */
    private defaultFooterVisible(visible: boolean): void {
        this.communicationCenter
            .getRoom('footer')
            .next('visibility', visible);
    }

    /**
     * open help modal or feedback modal ( help is launch by button feedback is launch in some exo on some bad answer (not all)
     * @param feedBack : optionnal feedback replace the default value is use when bad answer is link with a feedback
     */
    public help(feedBack: string = null, title?: string): void {
        // if whe use error message that mean help is not open by button but by communication center
        // after having a bad answer who have a comment associate
        const feedbackMsg = feedBack ? feedBack : this.getFeedBack();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: title ? title : 'activities.title_modal_help',
            labelTrueDialog: 'activities.title_modal_understood',
            enableTTS: this.lessonsService.settings.enableTTS
        };
        this.translate.get(dialogConfig.data.titleDialog).subscribe((translation: string) => dialogConfig.data.titleDialog = translation);
        this.translate.get(dialogConfig.data.labelTrueDialog).subscribe((translation: string) => dialogConfig.data.labelTrueDialog = translation);

        dialogConfig.data.bodyDialog = '<span>' + feedbackMsg + '</span>';
        dialogConfig.panelClass = 'help_close_modal'; // make modal more from bottom not centered
        dialogConfig.backdropClass = 'backdrop-blur';
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

        if (this.lessonsService.settings.activitiesBroadcastLifeCycle) {
            // Keep track of the presentation of the help modal
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('activity_open')
                .next({id: `help/${this.currentActivity.id}`});
        }

        dialogRef.afterClosed().subscribe(() => {
            this.communicationCenter.getRoom('multi').getSubject('hide-content').next(false);
        });
    }

    /**
     * get feedback in regard of where he is if it's a lesson or not
     */
    public getFeedBack(): string {
        try {
            let feedBack = '';
            if (this.currentActivity.get('format').label === 'lesson') {
                if (!this.lessonsService.currentActivityInSubLesson && this.lessonsService.currentActivityInSubLesson.get('reference').feedback) {
                    throw Error('not feedback inside lesson found');
                }
                feedBack = this.lessonsService.currentActivityInSubLesson.get('reference').feedback;
            } else {
                feedBack = this.currentActivity.get('reference').feedback;
            }
            return feedBack;
        } catch (ex) {
            return '';
        }
    }

    /**
     * a new answer was done she s true or false
     * @param val: boolean is a true ansnwer or is a false answer
     */
    public addAnswer(val: AnswerResultInterface): void {
        this.activitiesService.answersProgressBarMultiZone.push(val);
        this.answersCopy = [...this.activitiesService.answersProgressBarMultiZone]; // for forcing launch change make a copy of array
    }

    /**
     * number of exercices to do
     */
    public totalOfExercises(): number {
        return this.lessonsService.subLessonContentEdited ? this.lessonsService.subLessonContentEdited.length : 0;
    }
}
