<div class="container">
    <div class="title">{{ 'account-management.attach-to-a-class' | translate }}</div>

    <div class="list-user-class-container" *ngFor="let userClass of userClassList">
        <div class="user-class-joined">{{userClass.code}}</div>
        <button
            (click)="removeClass(userClass.id)"
            color="accent"
            class="btn-delete"
            mat-raised-button>
            <mat-icon svgIcon="trash"></mat-icon>
        </button>
    </div>
    <div class="join-class">
        <input type="text"
               [formControl]="classCode"
               matInput placeholder="{{ 'account-management.class-code' | translate }}">
        <button
            [disabled]="false" (click)="joinClass()"
            color="accent">
            <mat-icon svgIcon="check"></mat-icon>
        </button>
    </div>
    <mat-error *ngIf="errorMessage!==''"> {{errorMessage|translate}}</mat-error>
</div>
