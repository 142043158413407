import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CommunicationCenterService} from '@modules/communication-center';

@Component({
    selector: 'app-activities-home',
    templateUrl: './activities-home.component.html'
})
export class ActivitiesHomeComponent implements OnInit, AfterViewInit, OnDestroy {
    constructor(private communicationCenter: CommunicationCenterService) {
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'position-top-lower-for-content');
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'position-top-lower-for-content');
    }
}
