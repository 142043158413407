<div class="page-layout simple fullwidth mat-white-bg inner-scroll">

    <div class="header p-24" *ngIf="displayHeader" fxLayout="row" fxLayoutAlign="start center">
        <h2>{{ 'assignment.title' | translate }}</h2>
        <app-getting-started [alias]="assignationService.settings.gettingStarted.form"></app-getting-started>
    </div>

    <div class="p-16 content inner-scroll" fxLayout="column" fxFlex>
        <app-followed-filter *ngIf="shouldDisplayFilters()"
            [displayedFilters]="displayedFilters"
            [allStates]="allStates"
            [allTypes]="allTypes"
            [educationalLevels]="educationalLevels"
            [currentSchoolYearBegin]="assignationService.currentSchoolYearBegin"
            [checkDefaultFiltersInUrl]="settings?.checkDefaultFiltersInUrl"
            [schoolYearsList]="assignationService.schoolYearsList"
            [schoolYearDates]="assignationService.currentSchoolYearBegin"
            [groupsList]="assignationService.groupsList"
            [workgroupsList]="assignationService.workgroupsList"
            [learnersList]="learnersList"
            [countEntities]="countEntities"
            (launchSearch)="launchSearch($event)"
        ></app-followed-filter>

        <div class="content touch-scroll" fxFlex>

            <table mat-table
                   #table
                   [dataSource]="dataSource"
                   matSort
                   [@animateStagger]="{value:'50'}">

                <!-- checkbox Column -->
                <ng-container matColumnDef="checkbox">
                    <th mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (change)="updateCheck()" [(ngModel)]="selectAll"></mat-checkbox>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox [checked]="isChecked" id="{{ row.id }}" name="corpusCheckboxe"></mat-checkbox>
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.type' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                            <span class="pastille" [ngClass]="row.attributes.type_term.label"
                                  *ngIf="row.attributes.type_term">
                                <mat-icon svgIcon="{{row.attributes.type_term.label}}"
                                          title="{{ 'assignment.type.' + row.attributes.type_term.label | translate }}">
                                </mat-icon>
                            </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="assigned_node_title">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.title' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{getTitleAssignment(row)}}
                    </td>
                </ng-container>

                <!-- schoolyear Column -->
                <ng-container matColumnDef="schoolyear">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
                        {{ 'generic.school.year' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
                        {{assignationService.schoolYears(row.attributes.created)}}

                    </td>
                </ng-container>

                <!-- Changed Date Column -->
                <ng-container matColumnDef="changed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
                        {{ 'generic.changedDate' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
                        {{ assignationService.localeDate(row.get('changed')) }}
                    </td>
                </ng-container>

                <!-- start_date Column -->
                <ng-container matColumnDef="start_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
                        {{ 'generic.start_date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'disabled' : checkState(row) === 'correct'}" fxHide
                        fxShow.gt-md>
                            <span *ngIf="row.attributes.dates">
                                <span *ngIf="row.attributes.dates.value && row.attributes.dates.value > 0">
                                    <ng-template #localeDate>
                                        <span>
                                            {{assignationService.localeDate(row.attributes.dates.value)}}
                                        </span>
                                    </ng-template>
                                    <span
                                        *ngIf="settings.showHours && row.attributes.type_term && row.attributes.type_term.label === 'assessment' else localeDate">
                                        {{assignationService.localeTime(row.attributes.dates.value)}}
                                    </span>
                                </span>
                            </span>
                    </td>
                </ng-container>

                <!-- end_date Column -->
                <ng-container matColumnDef="end_date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        {{ 'generic.deadline' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" [ngClass]="{'disabled' : checkState(row) === 'correct'}" fxHide
                        fxShow.gt-xs>
                              <span *ngIf="row.attributes.dates">
                                  <span *ngIf="row.attributes.dates.value && row.attributes.dates.value > 0">
                                      <span *ngIf="row.attributes?.type_term?.label !== 'assessment'
                                                && row.attributes?.type_term?.label !== 'training'">
                                          {{assignationService.localeDate(row.attributes.dates.value2)}}
                                      </span>
                                      <span *ngIf="row.attributes?.type_term?.label === 'assessment'">
                                          {{assignationService.localeTime(row.attributes.dates.value2)}}
                                      </span>
                                  </span>
                              </span>
                    </td>
                </ng-container>

                <!-- Type Column -->
                <ng-container matColumnDef="assigned_user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.learner' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row.attributes.assignated_user?.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="assignatedCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.assignatedCount' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{ row.attributes.assignatedCount }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="educationalLevel">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.level' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{ getEducationalLevels(row.attributes.assignated_node?.educationalLevel) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="class">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.label_group' | translate }}
                    </th>
                    <!-- TODO: see if we can use assignation group because field class exist -->
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{settings?.showNameClass ? row.attributes.comment : row.attributes.assignated_user?.classes}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="comment">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.label_group' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{row.attributes?.comment}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="group">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md>
                        {{ 'generic.group' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-md>
                        {{row.attributes.assignated_user?.groups}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-xs>
                        {{ 'generic.state' | translate }}
                    </th>
                    <td mat-cell ngClass="{{checkState(row)}}" *matCellDef="let row" fxHide fxShow.gt-xs>
                        {{localizedState(checkState(row)) | translate}}
                        <span *ngIf="stateWithIcon">
                                <mat-icon svgIcon="{{getIconState(row)}}"></mat-icon>
                            </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="consulted">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'generic.consulted' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon class="logo-icon-svg" svgIcon="check"
                                  [ngClass]="{'active': getConsultedAssignment(row) }"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="progress">
                    <th mat-header-cell *matHeaderCellDef fxHide fxShow.gt-xs mat-sort-header>
                        {{ 'generic.progress' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide fxShow.gt-xs>
                        {{getProgress(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="score">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.grade' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{getGrade(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="gratification">
                    <th class="cup" mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.gratification' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        <mat-icon class="cup cup-hover" *ngIf="row.get('progress')>0" svgIcon="cup"
                                  (click)="openGratification($event, row)"></mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="difficulty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.difficulty' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{getDifficulties(row)}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="chapters">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header fxHide.xs>
                        {{ 'generic.chapters' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row" fxHide.xs>
                        {{getChapters(row)}}
                    </td>
                </ng-container>

                <!-- Buttons Column -->
                <ng-container matColumnDef="buttons">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <div *ngIf="checkAccess(['trainer', 'manager', 'administrator'])">
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="More"
                                    (click)="$event.stopPropagation();">
                                <mat-icon svgIcon="submenu"></mat-icon>
                            </button>
                            <mat-menu #moreMenu="matMenu">
                                <div *ngIf="displayButton('delete')">
                                    <button mat-menu-item aria-label="delete"
                                            (click)="delete(row)">
                                        <mat-icon svgIcon="unassign"></mat-icon>
                                        <span>{{ 'assignment.delete' | translate }}</span>
                                    </button>
                                </div>
                                <div *ngIf="displayButton('unassign')" matTooltip="{{getTooltipDeassignLabel(row)}}">
                                    <button [disabled]="cantBeDeassign(row)" mat-menu-item aria-label="edit"
                                            (click)="unassign(row)">
                                        <mat-icon svgIcon="{{!cantBeDeassign(row) ? 'unassign' : 'lock'}}"></mat-icon>
                                        <span>{{ 'assignment.unassign' | translate }}</span>
                                    </button>
                                </div>
                                <button *ngIf="displayButton('edit')" mat-menu-item aria-label="edit"
                                        (click)="edit(row)">
                                    <span>{{ 'generic.edit' | translate }}</span>
                                </button>
                            </mat-menu>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                    (click)="openAssignment(row)"
                    [ngClass]="{'mat-light-blue-50-bg': isSelected(row), 'disabled': assignmentDisabled(row)}"
                    matRipple
                    [@animate]="{value:'*',params:{y:'100%'}}">
                </tr>
            </table>

        </div>

        <a *ngIf="isAtLeastTrainer() && settings.followedList.formCreationLink?.length > 0"
           [routerLink]=" settings.followedList.formCreationLink[0]"><span>{{ 'assignment.link.form_creation' | translate}}</span></a>


        <mat-paginator
            [length]="countEntities"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="pageRangeOptions"
            (page)="onPaginateChange($event)">
        </mat-paginator>


    </div>
</div>

