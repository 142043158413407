import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs/index';
import {AutoUnsubscribeTakeUntilClass} from '../../../../../../shared/models/auto-unsubscribe-take-until.class';
import {takeUntil} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

/**
 * Card to use in memory game to show the card selected
 * it permit to show an image a title and to play a sound
 */
@Component({
    selector: 'app-memory-card-info',
    templateUrl: './memory-card-info.component.html',
})
export class MemoryCardInfoComponent extends AutoUnsubscribeTakeUntilClass implements OnInit, AfterViewInit {


    @Input() card: { title: string, image: string, info: string, sound: string, altSound?: string, altImage?: string, play: Subject<boolean>, isActive: boolean } = {
        title: '1',
        image: '',
        info: '',
        sound: '',
        altSound: '',
        altImage: '',
        play: new Subject<boolean>(),
        isActive: false // is the card is the current one waiting for data
    };
    @Output() playState = new EventEmitter<string>();

    private sound: any;
    private soundIsPlaying = false;
    public idDivPlaySound = uuidv4(); // id si dynamic because he must be unic on a page if component is used most of one time same id will be more than once

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.listenMediaEvent();
    }


    /**
     * add class in regard of sound is playing or not and an image is visible or not
     */
    public classToAddInRegardOfState(): string {
        let classToReturn = this.soundIsPlaying ? 'play ' : 'stop ';
        if (this.card && this.card.image) {
            classToReturn = classToReturn + 'answer-container image-selected ';
        } else {
            classToReturn = classToReturn + 'answer-container no-image-selected ';
        }
        if (this.card && this.card.isActive) {
            classToReturn = classToReturn + 'card-is-active ';
        } else {
            classToReturn = classToReturn + 'card-is-not-active ';
        }

        return classToReturn;
    }

    /**
     * listen play stop paused etc.. event launch by parent to is children
     * @private
     */
    private listenMediaEvent(): void {
        this.card.play.pipe(
            takeUntil(this.unsubscribeInTakeUntil))
            .subscribe(launchPlay => {
                if (launchPlay) {
                    this.playSound();
                }
            });
    }

    /**
     * launch play on click if is not playing and if image exist and if card is active
     */
    public launchPlay(): void {
        if (!this.soundIsPlaying && this.card.isActive && this.card.image) {
            this.playSound();
        }

    }

    /**
     * launch read of the sound and listen the event of end of play
     * @private
     */
    private playSound(): void {
        if (!this.sound) {
            this.sound = document.getElementById(this.idDivPlaySound);
        }
        this.sound.play();
        this.soundIsPlaying = true;
        this.playState.emit(PlayState.play);
        this.sound.onended = () => {
            this.playState.emit(PlayState.stop);
            this.soundIsPlaying = false;
        };
        this.sound.onpause = () => {
            this.playState.emit(PlayState.pause);
            this.soundIsPlaying = false;
        };
    }
}

export enum PlayState {
    play = 'play',
    pause = 'pause',
    stop = 'stop'
}
