import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {StepperOptions} from '@modules/activities/core/shared-components/generic-stepper/generic-stepper.component';

export enum StepperPosition { // Attention, l'ordre est important pour calculer l'inverse
    top,
    left,
    right,
    bottom,
}

@Component({
    selector: 'app-stepper-wrapper',
    templateUrl: './stepper-wrapper.component.html'
})
export class StepperWrapperComponent implements OnInit {

    @Input() position: StepperPosition;
    @Input() stepperOptions: StepperOptions|null ;

    public get displayStepper(): boolean {
        return this.stepperOptions !== null;
    }

    public get customStepperOptions(): StepperOptions {
        this.stepperOptions.stepperLayout.arrowPosition = 3 - this.position;
        return this.stepperOptions;
    }

    public get layout(): string {
        let row, inverse = false;
        switch (this.position) {
            case StepperPosition.top : {
                row = false;
                inverse = false;
                break;
            }
            case StepperPosition.bottom: {
                row = false;
                inverse = true;
                break;
            }
            case StepperPosition.right: {
                row = true;
                inverse = true;
                break;
            }
            case StepperPosition.left:
            default : {
                row = true;
                inverse = false;
            }
        }

        return (row ? 'row' : 'column') + (inverse ? '-reverse' : '');
    }

    constructor() {
    }

    ngOnInit(): void {
        if (this.position === undefined) {
            throw Error('The stepper position has to be passed to the StepperWrapper component');
        }
    }
}
