import {Component, OnInit} from '@angular/core';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {Observable} from 'rxjs';
import {DataEntity, OctopusConnectService} from 'octopus-connect';
import {AccountManagementProviderService} from '@modules/account-management/core/account-management-provider.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import { MatDialog } from '@angular/material/dialog';
import {ModalPageComponent} from 'fuse-core/components/basic-page/modal-page/modal-page.component';
import {UserDataEntity} from '@modules/authentication/core/models/user-data-entity.type';

@Component({
    selector: 'fuse-validate-email',
    templateUrl: './validate-email.component.html',
    styleUrls: ['./validate-email.component.scss'],
})
export class FuseValidateEmailComponent implements OnInit {

    token: string;
    public validateEmailStrategyActivated = false;

    constructor(private connector: OctopusConnectService,
                private route: ActivatedRoute,
                public router: Router,
                public usersProvider: AccountManagementProviderService,
                private profileProvider: ProfileService,
                private communicationCenter: CommunicationCenterService,
                public dialog: MatDialog) {
        this.validateEmailStrategyActivated = this.usersProvider.settings.validateEmailStrategyActivated;
    }

    ngOnInit(): void {
        this.route.params.subscribe((value: Object) => {
            this.token = value['token'];
            this.setToken(this.token);

        });
    }

    private setToken(token): void {

        const myDate = new Date();
        let timestampDate;
        myDate.setHours(myDate.getHours() + 24);
        timestampDate = Math.floor(new Date(myDate).getTime());

        localStorage.setItem('http_accessToken', JSON.stringify(token));
        localStorage.setItem('http_expires_in', JSON.stringify(timestampDate));
        localStorage.setItem('http_currentUser', JSON.stringify({}));

        console.log(localStorage.getItem('http_currentUser'));

        const userData$ = <Observable<UserDataEntity>> this.connector.authenticated('http');

        userData$.subscribe((userData) => {
            this.profileProvider.editValidateMail(userData);
            this.usersProvider.loggedUser = userData;
            this.profileProvider.userInformation = userData;
            if (this.usersProvider.settings.validateEmailStrategyActivated) {
                this.initProfileAfterConnectingByValidatingEmail(userData);
            }

        }, () => {
            console.log('error');
        });

    }

    /**
     * some init are necessary to have menu and profile working fine
     * init data like if user was connected by login password
     * @param userData : DataEntity contain user data
     * @private
     */
    private initProfileAfterConnectingByValidatingEmail(userData: DataEntity): void {
        this.communicationCenter.getRoom('authentication').next('connectAfterValidatedAccountByEmail', userData);
        this.communicationCenter.getRoom('authentication').next('userData', userData);
        this.dialog.open(ModalPageComponent,
            {
                panelClass: ['toast-dialog'],
                hasBackdrop: false,
                /** Whether the user can use escape or clicking on the backdrop to close the modal. */
                disableClose: true,
                closeOnNavigation: false,
                data: {alias: 'emailvalidated', isToaster: true, time: 5000}
            }
        );
        this.router.navigate(['/dashboard']);
    }

    public get termForBackRoute(): string {
        return 'generic.back_to_' + this.usersProvider.defaultRoute;
    }

}
