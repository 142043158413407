import {AfterViewInit, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AccountManagementProviderService} from '../account-management-provider.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {FormProfile} from '@modules/account-management/core/form-profil.class';
import {TralaTranslationLoaderService} from '../../../../trala-translation-loader.service';
import {brand, brandLogoSvg, langs, modulesSettings, captcha, defaultLoginRoute} from '../../../../settings';
import {ModelSchema, Structures} from 'octopus-model';
import {OctopusConnectService} from 'octopus-connect';
import {ProfileService} from '@modules/account-management/core/profile/profile.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {WidgetInstance} from 'friendly-challenge';
import {Field, FieldsOptions} from '../../../../shared/fieldsOptions';
import * as _ from 'lodash';
import {CommunicationCenterService} from '@modules/communication-center';
import {ActivatedRoute} from '@angular/router';

// Attention, le meme settings est utilisé dans profile.service
const settingsStructure: ModelSchema = new ModelSchema({
    fields: Structures.object({
        default: Structures.array(['label|require', 'email|require', 'password|require', 'you_are|require', 'find_us|require', 'newsletter'])
    }),
    displayDialogMinorAdult: Structures.boolean(true),
    displaySubTitle: Structures.boolean(false),
    defaultValues: Structures.object({}),
    mailConfirmActionButton: Structures.boolean(false),
    showFormTextRedirection: Structures.boolean(false),
});
const settingsAuthStructure: ModelSchema = new ModelSchema({
    displayLoginLogo: Structures.boolean(false),
    urlSSO: Structures.boolean(false),
});

type UserType = 'adult' | 'minor';
type ValidatorType = (control: AbstractControl) => (ValidationErrors | null);

@Component({
    selector: 'fuse-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
})
export class FuseRegisterComponent implements OnInit, AfterViewInit, OnDestroy {
    userInformation: FormProfile;
    translateRegisterParams = {product: 'Marque Blanche', resources: 'google'};
    registerForm: FormGroup;
    registerFormErrors: any;
    termsAdultNotAccepted = false;
    userIsAdultOrMinor: null | UserType = null;
    brandLogoSvg = brandLogoSvg;
    recaptchaV3Service: any;

    public brand = brand;
    public captcha = captcha;
    public settings: { [key: string]: any };
    public settingsAuth: { [key: string]: boolean };
    public formData: any = {
        title: 'account-management.email_confirmation',
        content: ['account-management.email_confirmation_first_sentence', 'account-management.email_confirmation_second_sentence'],
        redirection: 'generic.back_to_dashboard',
        textRedirection:  'account-management.email_confirmation_text_redirection',
        showFormTextRedirection:  false,
        formMessageLinkText:  'generic.back_to_dashboard',
        redirectionLink: defaultLoginRoute,
        mailConfirmActionButton: false,
        action: 'account-management.email_confirmation_action'
    };
    public requestValidate: boolean;
    public captchaPassed: boolean;
    @ViewChild('widgetFC') widgetFC: ElementRef;
    private fields: Field[];
    private friendlyCaptchaSolution: string;
    private widget: any;
    private singleExecutionSubscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        public accountProvider: AccountManagementProviderService,
        private translationLoader: TralaTranslationLoaderService,
        private octopusConnect: OctopusConnectService,
        public profileService: ProfileService,
        private translate: TranslateService,
        private injector: Injector,
        private communicationCenter: CommunicationCenterService,
        private route: ActivatedRoute
    ) {
        this.communicationCenter
            .getRoom('skeleton')
            .next('addClass', 'is-login-or-register-active');
    }

    ngOnInit(): void {
        if (this.captcha === 'recaptcha') {
            this.recaptchaV3Service = <ReCaptchaV3Service>this.injector.get(ReCaptchaV3Service);
        }

        this.userInformation = new FormProfile({}, this.octopusConnect);

        this.settings = settingsStructure.filterModel(modulesSettings.accountManagement);
        this.settingsAuth = settingsAuthStructure.filterModel(modulesSettings.authentication);
        this.formData.showFormTextRedirection = this.settings.showFormTextRedirection;
        this.formData.mailConfirmActionButton = this.settings.mailConfirmActionButton;
        this.initFields();

        const defaultLang: string[] = langs.map((lang) => lang.id);
        this.translationLoader.loadTranslations(...defaultLang);

        this.registerFormErrors = {
            you_are: {},
            find_us: {},
            region: {},
            level: {},
            email: {},
            pseudo: {},
            contact_email: {},
            password: {},
            passwordConfirm: {},
            terms: {},
            captcha: {},
        };

        if (this.accountProvider.settings && this.accountProvider.settings.passwordPolicy) {
            this.registerFormErrors['passwordPolicy'] = {};
        }

        this.userIsAdultOrMinor = this.settings.userIsAdultOrMinorOption ? null : 'adult';

        this.route.queryParams.subscribe(params => {
            if (params['role'] === 'teacher') {
                this.userIsAdultOrMinor = 'adult';
            }
        });

        this.requestValidate = false;
        this.generateRegisterForm();
        this.subscribeOnFormChanges();

        if (!this.settings.displayDialogMinorAdult) {
            this.selectMinorOrAdult('adult');
        }
    }

    ngAfterViewInit(): void {
        if (this.captcha === 'friendlyCaptcha') {
            const doneCallback = (solution) => {
                this.friendlyCaptchaSolution = solution;
            };

            const errorCallback = (err) => {
                console.log('There was an error when trying to solve the Captcha.');
                console.log(err);
            };

            const element = this.widgetFC.nativeElement;
            const options = {
                startMode: 'none',
                doneCallback: doneCallback,
                errorCallback: errorCallback,
                language: this.translate.currentLang
            };

            this.widget = new WidgetInstance(element, options);

            // this makes the widget fetch a puzzle and start solving it.
            this.widget.start();
        }
    }

    private getValidatorsIfDisplayed(name, customValidators: ValidatorFn[]): ValidatorFn[] {
        return this.displayField(name) && !!customValidators
            ? customValidators.filter(v => !!v)
            : [];
    }

    private onRegisterFormValueChanged(field: string, control: FormControl): void {
        this.registerFormErrors[field] = {};

        if (!this.registerForm.get('terms').errors) {
            this.termsAdultNotAccepted = false;
        }

        if (control && control.dirty && !control.valid) {
            this.registerFormErrors[field] = control.errors;
        }

        if (field === 'pseudo' && !this.validateField(control.value)) {
            control.setErrors({invalid: true});
            if (control.value !== '') {
                this.registerFormErrors.pseudo.errorTypo = true;
            }
        }

    }

    newRegister(): void {
        if (this.registerForm.get('terms').errors) {
            this.termsAdultNotAccepted = true;
        }

        if (!this.registerForm.invalid) {
            let newUser;
            newUser = {
                password: this.registerForm.value.password,
                newsletter: this.registerForm.value.newsletter,
                you_are: this.registerForm.value.you_are,
                find_us: this.registerForm.value.find_us,
                level: this.registerForm.value.level,
                region: this.registerForm.value.region,
                institution: this.registerForm.value.institution,
                translate: TranslateService,
            };

            if (this.displayField('label')) {
                newUser.label = this.registerForm.value.pseudo;
            }

            if (this.displayField('email')) {
                newUser.email = this.registerForm.value.email;
            }

            if (this.displayField('contact_email')) {
                newUser.contact_email = this.registerForm.value.contact_email;
            }

            let captchaOk = false;

            switch (this.captcha) {
                case 'recaptcha':
                    if (this.singleExecutionSubscription) {
                        this.singleExecutionSubscription.unsubscribe();
                    }
                    this.singleExecutionSubscription = this.recaptchaV3Service.execute('registerForm')
                        .subscribe((token) => {
                                this.accountProvider.verifyToken(token).subscribe(verifyResult => {
                                    // if score >= 0.7 (0 = bot -> 1 = human)
                                    // (google api is returning success: boolean, challenge_ts: string, hostname: string, score: number, action: string)
                                    if (verifyResult['data'][0]['score'] >= 0.7) {
                                        this.captchaPassed = true;
                                        captchaOk = true;
                                        this.sendRegistration(newUser);
                                    } else {
                                        this.captchaPassed = false;
                                    }
                                });
                            }
                        );
                    break;

                case 'friendlyCaptcha':
                    if (this.friendlyCaptchaSolution) {
                        this.accountProvider.verifyFriendlyCaptcha(this.friendlyCaptchaSolution).subscribe(verifyResult => {
                                if (verifyResult['success'] === true) {
                                    this.captchaPassed = true;
                                    captchaOk = true;
                                    this.sendRegistration(newUser);
                                } else {

                                    this.captchaPassed = false;
                                }
                            },
                            error => {
                                if (error.status !== 200) {  // Verification Best practices https://docs.friendlycaptcha.com/#/installation
                                    this.captchaPassed = true;
                                    captchaOk = true;
                                    this.sendRegistration(newUser);
                                } else {
                                    this.captchaPassed = false;
                                }
                            });
                    }
                    this.widget.reset(); // the widget need to be reset because it's working only one time
                    this.widget.start();
                    break;

                case 'hcaptcha':
                    if (this.registerForm.get('captcha').status === 'VALID' && this.registerForm.get('captcha').value !== '') {
                        captchaOk = true;
                        this.captchaPassed = true;
                    } else {
                        this.captchaPassed = false;
                    }
                    break;

                default:
                    console.log('no captcha in setting');
            }

        }
    }

    sendRegistration(newUser): void {
        this.accountProvider.createUser(newUser, () => this.getFormConfirmation(), (err) => this.handleRegistrationError(err));
    }

    selectMinorOrAdult(mode: UserType): void {
        this.userIsAdultOrMinor = mode;

        if (this.displayField('label') === false) {
            delete this.registerFormErrors.pseudo;
        } else {
            this.registerFormErrors.pseudo = {};
        }

        if (this.displayField('contact_email') === false) {
            delete this.registerFormErrors.contact_email;
        } else {
            this.registerFormErrors.contact_email = {};
        }

        if (this.displayField('email') === false) {
            delete this.registerFormErrors.email;
        } else {
            this.registerFormErrors.email = {};
        }

        if (this.userIsAdultOrMinor === 'minor') {
            const getValidatorsOf = (label: string, ...customsValidators: ValidatorType[]) => {
                return this.getValidatorsIfDisplayed(label, [this.getRequiredValidatorIfNeeded(this.getFieldByLabel(label)), ...customsValidators]);
            };

            this.registerForm = this.formBuilder.group({
                contact_email: ['', getValidatorsOf('contact_email', Validators.email)],
                pseudo: ['', getValidatorsOf('label', Validators.maxLength(60))],
                you_are: ['', getValidatorsOf('you_are')],
                find_us: ['', getValidatorsOf('find_us')],
                region: ['', getValidatorsOf('region')],
                level: ['', getValidatorsOf('level')],
                password: ['', getValidatorsOf('password', this.confirmPassword)],
                passwordConfirm: ['', getValidatorsOf('password', this.confirmPassword)],
                newsletter: [false],
                terms: [false, Validators.requiredTrue],
                institution: ['', getValidatorsOf('institution')],
                captcha: ['', Validators.required]
            });

            this.subscribeOnFormChanges();
        }

    }

    /**
     * only letter without accent , number and unserscore withour space
     * @param field : form field value to test
     */
    validateField(field): boolean {
        let re;
        re = /^\w+(\s\w+)*$/; // only letter without accent, number and underscore no space possible except one beetween word
        return re.test(String(field).toLowerCase());
    }

    getFormConfirmation(): void {
        this.requestValidate = true;
    }

    displayField(name: string): boolean {
        return !!this.getFieldByLabel(name);
    }

    public confirmPasswordPolicy(control: AbstractControl): any {
        if (!control || !control.parent) {
            return;
        }

        const password = control.parent.get('password');

        if (password && password.value) {
            if (this.accountProvider.settings && !this.accountProvider.settings.passwordPolicy) {
                return;
            }
            if (!this.accountProvider.settings.passwordPolicy.regex.test(password.value)) {
                return {
                    passwordPolicyError: true
                };
            }
        }
        return;
    }

    public flat<T>(...errors: T[][]): T[] {
        return _.flatten(errors);
    }

    public isRequired(label: string): Boolean {
        let result = false;
        try {
            result = FieldsOptions.isRequired(this.getFieldByLabel(label));
        } catch (e) {
            // on avale l'erreur
        }

        return result;
    }

    private getFieldByLabel(label: string): Field {
        return this.fields.find(f => f.label === label);
    }

    private handleRegistrationError(error: any): void {
        const mapField = (backendField: string) => {
            return backendField === 'label' ? 'pseudo' : backendField;
        };

        const handleFieldsError = (field: string, messages: string[]) => {
            const localField = mapField(field);

            if (Object.keys(this.registerFormErrors).indexOf(localField) > -1) {
                this.registerFormErrors[localField].customErrors = messages;
                const control = this.registerForm.get(localField);
                control.setErrors({invalid: true});
            } else {
                console.error(error);
            }
        };

        if (error.code !== 401) {
            console.error(error);
        } else if (error.data.response.hasOwnProperty('errors')) {
            Object.keys(error.data.response.errors).forEach((key) => {
                handleFieldsError(key, error.data.response.errors[key]);
            });
        }
    }

    // TODO refact :  on peut gérer quasiment toute les lignes avec un "default" case et écrire mutualisé l'appel au addControl.
    private generateRegisterForm(): void {
        this.registerForm = this.formBuilder.group({
            terms: [false, Validators.requiredTrue]
        });

        // add captcha
        this.registerForm.addControl('captcha', new FormControl('', this.getValidatorsIfDisplayed('captcha', [Validators.required])));

        this.fields.forEach(field => {
            switch (field.label) {
                case 'email': {
                    // tslint:disable-next-line:max-line-length
                    this.registerForm.addControl('email', new FormControl('', this.getValidatorsIfDisplayed('email', [this.getRequiredValidatorIfNeeded(field), Validators.email])));
                    break;
                }
                case 'find_us': {
                    this.registerForm.addControl('find_us', new FormControl('', this.getValidatorsIfDisplayed('find_us', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'region': {
                    this.registerForm.addControl('region', new FormControl('', this.getValidatorsIfDisplayed('region', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'level': {
                    this.registerForm.addControl('level', new FormControl('', this.getValidatorsIfDisplayed('level', [this.getRequiredValidatorIfNeeded(field)])));
                    break;
                }
                case 'newsletter': {
                    this.registerForm.addControl('newsletter', new FormControl(false));
                    break;
                }
                case 'password': {
                    const validators = [this.getRequiredValidatorIfNeeded(field), (control) => this.confirmPassword(control)];
                    if (this.accountProvider.settings && this.accountProvider.settings.passwordPolicy) {
                        validators.push((c: AbstractControl) => this.confirmPasswordPolicy(c));
                    }
                    this.registerForm.addControl('password', new FormControl('', this.getValidatorsIfDisplayed('password', validators)));
                    // tslint:disable-next-line:max-line-length
                    this.registerForm.addControl('passwordConfirm', new FormControl('', this.getValidatorsIfDisplayed('password', validators)));
                    break;
                }
                case 'label': {
                    // tslint:disable-next-line:max-line-length
                    this.registerForm.addControl('pseudo', new FormControl('', this.getValidatorsIfDisplayed('label', [this.getRequiredValidatorIfNeeded(field), Validators.maxLength(60)])));
                    break;
                }
                case 'institution': {
                    this.registerForm.addControl('institution', new FormControl(''));
                    break;
                }
                case 'you_are': {
                    this.registerForm.addControl('you_are', new FormControl('', this.getValidatorsIfDisplayed('you_are', [this.getRequiredValidatorIfNeeded(field)])));
                    if (this.settings.defaultValues.youAre && this.userIsAdultOrMinor === null ){
                        this.registerForm.controls.you_are.setValue(this.settings.defaultValues.youAre);
                    }
                    if ( this.userIsAdultOrMinor === 'adult' ){
                        this.registerForm.controls.you_are.setValue('adult');
                    }
                    break;
                }
            }
        });
    }

    private subscribeOnFormChanges(): void {
        Object.keys(this.registerForm.controls).forEach(key => {
            const control = <FormControl>this.registerForm.controls[key];
            control.valueChanges.subscribe(() => {
                this.onRegisterFormValueChanged(key, control);
            });
        });
    }

    private initFields(): void {
        let fields = this.settings.fields[this.userIsAdultOrMinor];

        if (fields === null || fields === undefined) {
            fields = this.settings.fields['default'] || [];
        }

        this.fields = fields.map(field => new Field(field));

        console.log(this.fields);
    }

    private getRequiredValidatorIfNeeded(field: Field): ValidatorType {
        return this.isRequired(field.label)
            ? Validators.required
            : undefined;
    }

    private confirmPassword(control: AbstractControl): any {
        if (this.registerFormErrors) {
            this.registerFormErrors.passwordConfirm.passwordsNotMatch = false;
            if (!control || !control.parent) {
                return;
            }

            const password = control.parent.get('password');
            const passwordConfirm = control.parent.get('passwordConfirm');

            if (!password || !passwordConfirm) {
                return;
            }

            if (passwordConfirm.value === '') {
                return;
            }

            if (password.value !== passwordConfirm.value) {
                if (control === password) {
                    passwordConfirm.setErrors({passwordsNotMatch: true});
                    this.registerFormErrors.passwordConfirm.passwordsNotMatch = true;
                } else {
                    return {
                        passwordsNotMatch: true
                    };
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.communicationCenter
            .getRoom('skeleton')
            .next('removeClass', 'is-login-or-register-active');
    }
}


