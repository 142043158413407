import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {CommunicationCenterService} from '@modules/communication-center';
import {CorpusDisplayWrapperComponent} from '@modules/activities/core/shared-components/corpus-display-wrapper/corpus-display-wrapper.component';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {DataEntity} from 'octopus-connect';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

interface AddMediaModalData {
    allowedMediaTypeList: string[];
    allowedExtensionByFormat: string[][];
    corpusResourceMaxSize: number;
}

@Component({
    selector: 'app-add-media-modal',
    templateUrl: './add-media-modal.component.html',
    styleUrls: ['./add-media-modal.component.scss']
})
export class AddMediaModalComponent implements OnInit {
    public isComponentReady = false;
    public showIsFileFormatNotAllowed = false;
    public showIsFileTooBig = false;

    private temporarySelectedResource: DataEntity = null;
    private temporaryUploadedFile: File = null;
    private temporaryUploadedFileType: string = null;

    constructor(
        @Optional() @Inject(MAT_DIALOG_DATA) private data: AddMediaModalData,
        private dialog: MatDialog,
        public selfDialogRef: MatDialogRef<AddMediaModalComponent>,
        private formBuilder: FormBuilder,
        private communicationCenter: CommunicationCenterService,
    ) {
    }

    /**
     * Return a Corpus friendly media type
     * @param type filemime like "image/jpeg"
     */
    private static getResourceTypeFromMediaType(type: string): string {
        /**
         *    Current used corpus resource type src/app/@modules/corpus/core/corpus-ressources-types.class.ts
         *    TEXT = 'text';
         *    URL = 'url';
         *    VIDEO_URL = 'videoUrl';
         *    MEDIA = 'media';
         *    AUDIO = 'audio';
         *    VIDEO = 'video';
         s    DOCUMENT = 'document';
         *    IMAGE = 'image';
         *    DIVIDER = 'divider';
         */

        switch (type) {
            case 'image/jpeg':
            case 'image/png':
            case 'image/svg+xml':
            case 'image/tiff':
                return 'image';
            case 'video/mpeg':
            case 'video/mp4':
            case 'video/ogg':
            case 'video/x-msvideo':
                return 'video';
            case 'audio/acc':
            case 'audio/mp3':
            case 'audio/ogg':
            case 'audio/x-wav':
                return 'audio';
            default:
                throw new Error('not implemented file type');
        }
    }

    ngOnInit(): void {
        this.communicationCenter
            .getRoom('corpus')
            .next('loadFormats', true);

        this.isComponentReady = true;
    }

    /**
     * Return the list of allowed extensions indirectly defined by the parent component
     */
    public getUploadExtensionAllowed(): string [] {
        return this.getAllowedMediaTypeList()
            .filter(format =>  this.data.allowedExtensionByFormat[format])
            .map(format =>  this.data.allowedExtensionByFormat[format])
            .flat();
    }

    /**
     * Triggered when file are selected by drag & drop
     * @param event
     */
    public onFileDropped(files: NgxFileDropEntry[]): void {
        if (files[0].fileEntry.isFile) {
            const fileEntry = files[0].fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
                this.onFileUploaded(file);
            });
        }
    }

    /**
     * Trigger when file a selected from the file system window
     * @param evt
     */
    public onFileSelected(evt: Event): void {
        const file = evt.target['files'][0];
        this.onFileUploaded(file);
    }

    /**
     * Display the modal for choose a media from the corpus
     * @param isMyCorpus if true it must open the current user corpus, else the globally shared corpus.
     */
    public onOpenCorpus(isMyCorpus: boolean): void {
        const data = {
            isMyCorpus: isMyCorpus,
            corpusFormatsAllowed: this.data.allowedMediaTypeList
        };

        const dialogRef = this.dialog.open(CorpusDisplayWrapperComponent, {
            width: '90%',
            height: '100%',
            data
        });

        dialogRef.afterClosed().subscribe((granuleSearchEntity: DataEntity) => {
            if (granuleSearchEntity) {
                this.temporaryUploadedFileType = null;
                this.temporarySelectedResource = granuleSearchEntity;
            }
        });
    }

    /**
     * Force close current modal from template
     */
    public onClose(): void {
        this.selfDialogRef.close();
    }

    /**
     * Create Corpus resource if needed and return it to the parent component
     */
    public onAccept(): void {
        this.isComponentReady = false;

        const callback = (corpusResource: DataEntity) => {
            this.selfDialogRef.close(corpusResource);
        };

        if (this.temporaryUploadedFile !== null) {
            this.createCorpusResourceFromFile(this.temporaryUploadedFile, this.temporaryUploadedFileType, callback);
        } else if (this.temporarySelectedResource !== null) {
            callback(this.temporarySelectedResource);
        }
    }

    /**
     * Return if there are a selected element should be previewed and ready to be return to the parent component
     */
    public isSomethingTemporarySelected(): boolean {
        return (this.temporaryUploadedFileType !== null || this.temporarySelectedResource !== null);
    }

    /**
     * Return the filename of the current selected element (corpus resource or uploaded file).
     */
    public getTemporarySelectedElementName(): string {
        if (this.temporaryUploadedFile !== null) {
            return this.temporaryUploadedFile.name;
        } else if (this.temporarySelectedResource !== null) {
            return this.temporarySelectedResource.get('metadatas').title;
        } else {
            throw new Error('not implemented');
        }
    }

    /**
     * Return the limit size of uploaded file accepted by the corpus (should be imported by the corpus setting)
     */
    public getFileMaxSize(): number {
        return this.data.corpusResourceMaxSize;
    }

    /**
     * Send the file to the Corpus service by communication center for create an official granule resource
     * @param file {@see File}
     * @param currentUploadedFileType like 'image' or another media type
     * @param callback
     */
    private createCorpusResourceFromFile(file: File, currentUploadedFileType: string, callback: (corpusResource: DataEntity) => void): void {
        const data = {
            file: file,
            type: currentUploadedFileType,
            inCorpus: true,
            metadataInterface: {
                title: this.getTemporarySelectedElementName()
            },
            callback: callback
        };

        this.communicationCenter
            .getRoom('corpus')
            .next('createResourceByFile', data);
    }

    /**
     * Return the allowed of media type as string define by the parent component
     */
    private getAllowedMediaTypeList(): string [] {
        return this.data.allowedMediaTypeList;
    }

    /**
     * Return if file are biggest than the allowed size defined by the parent component
     * @param file
     */
    private isSizeTooBig(file: File): boolean {
        return file.size > this.getFileMaxSize();
    }

    /**
     * Return true if file type are allowed by the parent component data
     * @param fileType
     */
    private isUploadedFileTypeAllowed(fileType: string): boolean {
        return this.data.allowedMediaTypeList.includes(fileType);
    }

    /**
     * Should be executed when a file are choose by the user.
     * The file will be checked by universal rules no matter where is from.
     * @param file The file are a html {@link File} object. Not a {@link CorpusResource}
     */
    private onFileUploaded(file: File): void {

        this.showIsFileFormatNotAllowed = false;
        this.showIsFileTooBig = false;

        this.temporaryUploadedFile = null;
        this.temporaryUploadedFileType = null;
        this.temporarySelectedResource = null;

        try {
            const currentUploadedFileType = AddMediaModalComponent.getResourceTypeFromMediaType(file.type);
            if (this.isUploadedFileTypeAllowed(currentUploadedFileType)) {
                if (!this.isSizeTooBig(file)) {
                    this.temporaryUploadedFile = file;
                    this.temporaryUploadedFileType = currentUploadedFileType;
                } else {
                    this.showIsFileTooBig = true;
                }
            } else {
                this.showIsFileFormatNotAllowed = true;
            }
        } catch (e) {
            if (e.message === 'not implemented file type') {
                this.showIsFileFormatNotAllowed = true;
            }
            else {
                throw e;
            }
        }
    }
}
