<div mat-dialog-title>
    <h2>{{ "add_media_modal.title" | translate }}</h2>
    <button mat-mini-fab class="close" (click)="selfDialogRef.close()" aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content >
    <ng-container *ngIf="isComponentReady else elseBlock">
        <!--upload local image-->
        <div class="center text-center">
            <p>{{ 'add_media_modal.allowed_file_extension' | translate }} {{ getUploadExtensionAllowed().join(', ') }}</p>
<!-- TODO déprécié : headertext="{{ 'add_media_modal.drag_and_drop_message' | translate }}"  -->
<!-- TODO déprécié : customstyle="upload-dragndrop"  -->
            <ngx-file-drop (onFileDrop)="onFileDropped($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                    <button mat-raised-button mat-primary class="add-file-button" (click)="openFileSelector()"
                            aria-label="Add file">
                        <span>{{ 'generic.browse' | translate }}</span>
                    </button>
                </ng-template>
            </ngx-file-drop>
        </div>

        <div class="file-uploader">
            <input hidden type="file" [attr.accept]="getUploadExtensionAllowed()" #fileInput
                   (change)="onFileSelected($event)" id="fileToUpload"/>
        </div>

        <span class="mat-error" *ngIf="showIsFileFormatNotAllowed">
            {{"add_media_modal.file_format_not_allowed" | translate}} {{getUploadExtensionAllowed().join(', ')}}
        </span>
        <span class="mat-error"
              *ngIf="showIsFileTooBig">{{"add_media_modal.file_too_big" | translate}} {{getFileMaxSize()}}
        </span>

        <div class="center text-center">
            <p>{{ 'add_media_modal.get_from_corpus' | translate }}</p>
            <button mat-raised-button class="save-button mat-accent"
                    (click)="onOpenCorpus(true) ">{{'add_media_modal.user_corpus' | translate}}
            </button>
            <button mat-raised-button class="save-button mat-accent"
                    (click)="onOpenCorpus(false) ">{{'add_media_modal.global_corpus' | translate}}
            </button>
        </div>

        <ng-container *ngIf="isSomethingTemporarySelected()">

            <p>{{ 'add_media_modal.validate_temporary_selected_element' | translate }} {{getTemporarySelectedElementName()}}</p>

            <div class="center text-center">
                <button mat-raised-button class="save-button mat-accent"
                        (click)="onAccept() ">{{'generic.accept' | translate}}
                </button>
                <button mat-raised-button class="save-button mat-accent"
                        (click)="onClose() ">{{'generic.cancel' | translate}}
                </button>
            </div>
        </ng-container>
    </ng-container>

    <ng-template #elseBlock>
        <mat-spinner></mat-spinner>
    </ng-template>

</mat-dialog-content>