<div id="forms" class="page-layout simple fullwidth" fxLayout="column">

    <!-- CONTENT -->
    <div class="content">
        <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayout.gt-md="row">
            <form fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form" *ngIf="formIsReady">
                <div class="edit-header">
                    <button color="accent" class="cancel" (click)="setMode(false)" mat-raised-button>
                        <mat-icon svgIcon="cross"></mat-icon>
                    </button>
                    <div class="h2 mb-24">{{ 'account-management.edit_profile' | translate }}</div>
                    <button color="accent" class="validate" (click)="editProfile()" mat-raised-button>
                        <mat-icon svgIcon="check"></mat-icon>
                    </button>
                </div>
                <div class="username" fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="displayField('label')">
                    <mat-form-field fxFlex="100" floatLabel="always">
                        <input matInput  placeholder="{{ 'account-management.username' | translate }}"
                               formControlName="label" required>
                        <mat-error *ngIf="formErrors.label.required">
                            {{ 'account-management.username.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div *ngIf="(authenticationService.isAtLeastTrainer() && displayField('email')) || learnerCanEditMail"
                     fxLayout="row"
                     fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field fxFlex="100">
                        <input matInput placeholder="{{ 'account-management.email' | translate }}"
                               formControlName="email" required>
                        <mat-error *ngIf="formErrors.email.required">
                            {{ 'authentication.email.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-form-field *ngIf="displayField('password') && !userInformation?.get('sso')">
                    <input matInput type="password" placeholder="{{ 'account-management.edit-password' | translate }}"
                           formControlName="password" autocomplete="off">
                    <mat-error *ngIf="formErrors.password.required">
                        {{ 'authentication.password.required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="displayField('password') && !userInformation?.get('sso')">
                    <input matInput type="password"
                           placeholder="{{ 'account-management.password-confirm' | translate }}"
                           formControlName="passwordConfirm" autocomplete="off">
                    <mat-error *ngIf="formErrors.passwordConfirm.required">
                        {{ 'account-management.password-confirm.required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="formErrors.passwordConfirm.passwordsNotMatch">
                        {{ 'account-management.password-confirm.not-identical' | translate }}
                    </mat-error>
                </mat-form-field>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto" *ngIf="displayField('institution')">
                    <mat-form-field fxFlex="100">
                        <input matInput placeholder="{{ 'account-management.institution' | translate }}"
                               formControlName="institution">
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('you_are') && !userInformation?.get('sso')">
                    <mat-form-field fxFlex="100">
                        <mat-select placeholder="{{ 'account-management.you-are' | translate }}"
                                    formControlName="you_are" required>
                            <mat-option *ngFor="let item of youAreValues()" [value]="item">
                                {{ 'generic.you-are.' + item | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.you_are.required">
                            Le champ {{ 'account-management.you-are.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('find_us') && !userInformation?.get('sso')">
                    <mat-form-field fxFlex="100">
                        <mat-select
                            placeholder="{{ 'account-management.find-us' | translate }}"
                            formControlName="find_us" required>
                            <mat-option *ngFor="let item of settings.findUsValues" [value]="item">
                                {{ 'account-management.find-us.' + item | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.find_us.required">
                            {{ 'account-management.find-us.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('region') && !userInformation?.get('sso')">
                    <mat-form-field fxFlex="100">
                        <mat-select
                            placeholder="{{ 'account-management.region' | translate }}" formControlName="region"
                            required>
                            <mat-option *ngFor="let item of defaultUserInformation?.region_field" [value]="+item.id">
                                {{item.attributes.label | translate}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.region.required">
                            Le champ {{ 'account-management.region' | translate }} est requis.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto"
                     *ngIf="displayField('level') && !userInformation?.get('sso')">
                    <mat-form-field fxFlex="100">
                        <mat-select placeholder="{{ 'account-management.level' | translate }}" formControlName="level"
                                    required>
                            <mat-option *ngFor="let item of defaultUserInformation.level_field" [value]="+item.id">
                                {{ item.attributes.label | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formErrors.level.required">
                            Le champ {{ 'account-management.level' | translate }} est requis.
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="terms" fxLayout="row" *ngIf="displayField('newsletter') && !userInformation?.get('sso')">
                    <mat-checkbox name="newsletter"
                                  aria-label="{{ 'account-management.newsletter' | translate }}"
                                  formControlName="newsletter">
                        <span>{{ 'account-management.newsletter' | translate }}</span>
                    </mat-checkbox>
                </div>

                <mat-error *ngIf="displayGenericError">{{'generic.form_error' | translate}}</mat-error>

                <div fxLayoutAlign="center center" *ngIf="userInformation?.get('sso')" class="mb-24">
                    <button mat-raised-button class="submit-button" (click)="setMode(false)">
                        <a target="_blank"
                           href="{{ 'account-management.redirect_sso_url' | translate }}">{{ 'account-management.redirect_sso' | translate }}</a>
                    </button>
                </div>

                <div *ngIf="canEditAvatar">
                    <app-upload-file [maxSizeFile]="allowedMaxSize"
                                     (alertSizeFileTooBig)="changeColorIfTooBig($event)"></app-upload-file>
                    <em fxLayout="row"><small>{{ 'corpus.import_url.details' | translate }} {{ allowedExtensions }} </small></em>
                    <em><small
                        [ngClass]="{'mat-error': isPngTooBig}">{{ 'generic.size.max' | translate: {size: allowedMaxSize} }} </small></em>
                </div>
            </form>

        </div>

    </div>
    <!-- / CONTENT -->

</div>


