import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';

@Component({
    selector: 'app-edit-step',
    templateUrl: './edit-step.component.html'
})
export class EditStepComponent implements OnInit {
    public form: FormGroup;

    constructor(public dialogRef: MatDialogRef<EditStepComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { instructionKey: string, title: string, instruction: string } ) {
    }

    ngOnInit(): void {
        this.form = this.createForm();
    }

    public createForm(): FormGroup {
        const config = {
            title: [_.get(this.data, 'title', ''), Validators.required],
            instruction: [_.get(this.data, 'instruction', ''), Validators.required],
        };

        return new FormBuilder().group(config);
    }

    validate(): void {
        this.dialogRef.close(this.form.value);
    }
}
