<mat-card-actions *ngIf="showButtons" class="user-action-btn activities-card" fxFlex>
    <button *ngIf="isButtonDisplayedBySettings('previous') && showPreviousButton && ShowPrevNextBtns" mat-raised-button class="h-32 btn-previous"
            (click)="onClick(UserActionButtonLabels.goBack)">
        {{ 'generic.previous' | translate }}
    </button>

    <button *ngIf="displayTestAnswer" mat-raised-button class="h-52"
            (click)="onClick(UserActionButtonLabels.testAnswer)" [disabled]="!enabledUserActionButtons">
        {{ 'activities.lesson.test_answer' | translate }}
        <mat-icon class="ml-8" svgIcon="question_answer"></mat-icon>
    </button>

    <button
        *ngIf="(isShowSaveButton && !loading) || (displayRegister && !isShowSaveButton) || (displayRegistered && loading && !isShowSaveButton)"
        mat-raised-button class="h-52"
        (click)="onClick(UserActionButtonLabels.saveAnswer)" [disabled]="disableBtnRegister || loading">
        {{ 'generic.register' | translate }}
        <mat-icon *ngIf="(disableBtnRegister && !displayRegistered) || !loading" class="ml-8" svgIcon="save"></mat-icon>
        <mat-spinner *ngIf="(!disableBtnRegister || displayRegistered) && loading" class="ml-8"></mat-spinner>
    </button>

    <button *ngIf="isShowButtonPreviousInMulti && !loading" id="previous-question-button" mat-raised-button
            (click)="onClick(UserActionButtonLabels.launchPreviousActivity)">
        <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
        <span>{{ 'activities.previous_question' | translate }}</span>
    </button>

    <button *ngIf="isShowButtonNextInMulti && !loading" id="next-question-button" mat-raised-button
            (click)="onClick(UserActionButtonLabels.launchNextActivity)">
        <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
        <span>{{ multiTitleButton | translate }}</span>
    </button>

    <!--TODO: Useless button ? and duplicate with button register line 13  -->
    <button *ngIf="displayRegistered && !loading" mat-raised-button class="h-52 registered" disabled>
        {{ 'generic.registered' | translate }}
        <mat-icon class="ml-8" svgIcon="save"></mat-icon>
    </button>

    <button *ngIf="displayReset" mat-raised-button class="h-52"
            (click)="onClick(UserActionButtonLabels.resetAnswer)">
        {{ 'generic.reset' | translate }}
        <mat-icon class="ml-8" svgIcon="reset_answer"></mat-icon>
    </button>

    <button *ngIf="displayModify && !loading" mat-raised-button class="h-52"
            (click)="onClick(UserActionButtonLabels.modifyAnswer)" [disabled]="loading">
        {{ 'generic.modify' | translate }}
        <mat-icon class="ml-8" svgIcon="visibility"></mat-icon>
    </button>

    <button *ngIf="displaySeeSolution" mat-raised-button class="h-52"
            (click)="onClick(UserActionButtonLabels.seeSolution)" [disabled]="!disableBtnSeeSolution">
        {{ 'activities.lesson.see_solution' | translate }}
        <mat-icon class="ml-8" svgIcon="see_solution"></mat-icon>
    </button>

    <button *ngIf="displaySeeAnswer" mat-raised-button class="h-52"
            (click)="onClick(UserActionButtonLabels.seeAnswer)">
        {{ 'activities.lesson.see_answer' | translate }}
        <mat-icon class="ml-8">spellcheck</mat-icon>
    </button>

    <button *ngIf="isButtonDisplayedBySettings('next') && ShowPrevNextBtns" mat-raised-button class="h-32 btn-next"
            (click)="onClick(UserActionButtonLabels.gotoNext)">
        {{ 'generic.next' | translate }}
    </button>

    <button *ngIf="isNextLesson && isButtonDisplayedBySettings('nextLesson') && isLastStepInLesson" mat-raised-button class="h-32"
            (click)="onClick(UserActionButtonLabels.gotoNextLesson)">
        {{ 'generic.nextLesson' | translate }}
    </button>

    <app-error-reporting-trigger-button [type]="isMedia ? 'resource': 'exercise'" [activityId]="activityId"
                                        [objectTitle]="activityTitle"
                                        *ngIf="allowErrorReporting()"></app-error-reporting-trigger-button>
</mat-card-actions>
