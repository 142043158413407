<div mat-dialog-title>
    <h2>{{ 'assignment.assign_to_learners' | translate }}</h2>
    <button mat-mini-fab
            class="close"
            (click)="dialogRef.close()"
            aria-label="Close dialog">
        <mat-icon svgIcon="cross"></mat-icon>
    </button>
</div>

<mat-dialog-content>
    <form [formGroup]="entityForm">
        <div fxLayout="row" fxLayoutAlign="start start" class="mb-5">
            <strong  *ngIf="('assignment.information' | translate) != 'assignment.information'">{{ 'assignment.information' | translate }}</strong>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start">
            <mat-form-field  fxFlex="50" *ngIf="displayField('group')">
                <mat-chip-list #groupsChipList>
                    <mat-chip
                            *ngFor="let groupChip  of groupsChips"
                            [selectable]="groupsSelectable"
                            [removable]="groupsRemovable"
                            (removed)="removeChip(groupChip, 'group')">
                        {{groupChip.groupname}}
                        <mat-icon matChipRemove *ngIf="groupsRemovable">cancel</mat-icon>
                    </mat-chip>
                    <input
                            placeholder="{{ 'groups-management.assign_to_class' | translate }}"
                            #groupsChipInput
                            [formControl]="groupsCtrl"
                            [matAutocomplete]="groups"
                            [matChipInputFor]="groupsChipList"
                            [matChipInputSeparatorKeyCodes]="groupsSeparatorKeysCodes"
                            [matChipInputAddOnBlur]="groupsAddOnBlur"
                            (matChipInputTokenEnd)="blurAllChipsList()"/>

                </mat-chip-list>
                <mat-autocomplete
                    #groups="matAutocomplete"
                    (optionSelected)="chipSelected($event, 'group')" >
                    <mat-option *ngFor="let groupChip of groupsFilteredChips | async" [value]="groupChip" >
                        {{ groupChip.groupname }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field fxFlex="50" *ngIf="displayField('workgroup')">
                <mat-chip-list #workgroupsChipList>
                    <mat-chip
                            *ngFor="let workgroupChip  of workgroupsChips"
                            [selectable]="workgroupsSelectable"
                            [removable]="workgroupsRemovable"
                            (removed)="removeChip(workgroupChip, 'workgroup')">
                        {{workgroupChip.workgroupname}}
                        <mat-icon matChipRemove *ngIf="workgroupsRemovable">cancel</mat-icon>
                    </mat-chip>
                    <input
                            placeholder="{{ 'assignment.by_workgroup' | translate }}"
                            #workgroupsChipInput
                            [formControl]="workgroupsCtrl"
                            [matAutocomplete]="workgroups"
                            [matChipInputFor]="workgroupsChipList"
                            [matChipInputSeparatorKeyCodes]="workgroupsSeparatorKeysCodes"
                            [matChipInputAddOnBlur]="workgroupsAddOnBlur"
                            (matChipInputTokenEnd)="blurAllChipsList()"/>

                </mat-chip-list>
                <mat-autocomplete #workgroups="matAutocomplete" (optionSelected)="chipSelected($event, 'workgroup')">
                    <mat-option *ngFor="let workgroupChip of workgroupsFilteredChips | async" [value]="workgroupChip">
                        {{ workgroupChip.workgroupname }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('learner')">

            <mat-form-field  fxFlex="100">
                <mat-chip-list #learnersChipList required>
                    <mat-chip
                            *ngFor="let learnerChip  of learnersChips"
                            [selectable]="learnersSelectable"
                            [removable]="learnersRemovable"
                            (removed)="removeChip(learnerChip, 'learner')">
                        {{learnerChip.username ? learnerChip.username: learnerChip }}
                        <mat-icon matChipRemove *ngIf="learnersRemovable">cancel</mat-icon>
                    </mat-chip>
                    <input
                            placeholder="{{ 'assignment.select_learners' | translate }}"
                            #learnersChipInput
                            [formControl]="learnersCtrl"
                            [matAutocomplete]="learners"
                            [matChipInputFor]="learnersChipList"
                            [matChipInputSeparatorKeyCodes]="learnersSeparatorKeysCodes"
                            [matChipInputAddOnBlur]="learnersAddOnBlur"
                            (matChipInputTokenEnd)="blurAllChipsList()"/>

                </mat-chip-list>
                <mat-autocomplete #learners="matAutocomplete" (optionSelected)="chipSelected($event, 'learner')">
                    <mat-option value="all">{{'generic.all' | translate}}</mat-option>
                    <mat-option *ngFor="let learnerChip of learnersFilteredChips | async" [value]="learnerChip">
                        {{ learnerChip.username }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <!-- Project Field -->
        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('project') && hasProject">
            <mat-form-field fxFlex>
                <mat-select name="project" placeholder="{{ 'assignment.select_project' | translate }}" formControlName="project" required>
                    <mat-option *ngFor="let project of projectsList" [value]="project">{{ project.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Assignment Type Field -->
        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('assignment_type') && hasType">
            <mat-form-field fxFlex>
                <mat-select [disabled]="disableTypeSelect" name="type" placeholder="{{ 'assignment.select_type' | translate }}" formControlName="type" required>
                    <mat-option *ngFor="let type of typeList" [value]="type.id"> {{ localizedType(type.label) | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Assignment Rating grade Field -->
        <div fxLayout="row" fxLayoutAlign="start start" *ngIf="displayField('assignment_grade') && hasGrade">
            <mat-form-field fxFlex>
                <mat-select name="rating_base" placeholder="{{ 'assignment.rating_base' | translate }}" formControlName="rating_base">
                    <mat-option *ngFor="let ratingBase of ratingBaseList" [value]="ratingBase">
                        {{'assignment.rating_base_on' | translate }}{{ ratingBase }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Start Date and Time Field -->
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="displayField('start_date') && hasCompletionDate || displayField('start_date') && hasCompletionStartDateOnly">
            <mat-form-field fxFlex>
                <input #startDateInput name="startDate" matInput [min]="startDateMinLimit" [max]="maxDateLimit" [matDatepicker]="picker" placeholder="{{'generic.start_date' | translate}}" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex *ngIf="hasCompletionTime">
                <input name="startTime" matInput placeholder="{{'generic.start_time' | translate}}" [ngxTimepicker]="fullTime" [disableClick]="!inputStartDateValue" [min]="minStartTimeLimit" [max]="maxStartTimeLimit" [format]="24" formControlName="startTime" readonly>
                <ngx-material-timepicker #fullTime></ngx-material-timepicker>
            </mat-form-field>
        </div>

        <!-- Due Date and Time Field -->
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" *ngIf="displayField('due_date') && hasCompletionDate">
            <mat-form-field fxFlex>
                <input name="dueDate" matInput [min]="dueDateMinLimit" [matDatepicker]="picker" placeholder="{{'generic.end_date' | translate}}" formControlName="dueDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field fxFlex *ngIf="hasCompletionTime">
                <input name="dueTime" matInput placeholder="{{'generic.end_time' | translate}}" [ngxTimepicker]="fullTime" [disableClick]="!maxDateLimit" [min]="minDueTimeLimit" [max]="maxDueTimeLimit" [format]="24" formControlName="dueTime" readonly>
                <mat-error *ngIf="entityFormErrors.dueTime.timeError">
                    {{ 'assignment.time_error' | translate }}
                </mat-error>
                <ngx-material-timepicker #fullTime></ngx-material-timepicker>
            </mat-form-field>
        </div>

    </form>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button
            (click)="dialogRef.close(entityForm)"
            class="save-button mat-accent"
            [disabled]="entityForm.invalid"
            aria-label="SAVE">
        {{ 'generic.validate' | translate }}
    </button>
</mat-dialog-actions>
