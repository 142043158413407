<mat-card class="card-formplayer"><!-- form-player.component.html -->
    <!-- common header -->
    <mat-card-header>
        <mat-card-title>
            {{ formTitle }}
            <span class="show-trainer"
                  *ngIf="authService.isTrainer() || authService.isManager() || authService.isAdministrator()">
                - {{ formOwnerName }}
            </span>
        </mat-card-title>
        <mat-card-subtitle>
            {{ formDescription }}
        </mat-card-subtitle>
        <button class="cancel-btn" mat-button (click)="getBack()">
            <mat-icon class="shadow-cancel">clear</mat-icon>
        </button>
    </mat-card-header>
    <!-- For Version Google Form -->
    <mat-card-content>
        <div class="content">
            <div *ngFor="let item of formPlayerActivitiesTable">
                <app-qcu *ngIf="item.metadatas.typology.label === 'QCMU'" [activityId]='item' [contextId]="assignmentId" [hideCorrection]="true" [disabled]="!canSave"></app-qcu>
                <app-qcm *ngIf="item.metadatas.typology.label ==='QCM'" [activityId]='item' [contextId]="assignmentId" [hideCorrection]="true" [disabled]="!canSave"></app-qcm>
                <app-true-false *ngIf="item.metadatas.typology.label ==='VF'" [activityId]='item'></app-true-false>
                <app-short-answer *ngIf="item.metadatas.typology.label ==='CRT'" [activityId]='item' [contextId]="assignmentId" [hideCorrection]="true" [disabled]="!canSave"></app-short-answer>
                <app-draw-line *ngIf="item.metadatas.typology.label ==='DRL'" [activityId]='item'></app-draw-line>
                <app-ordon *ngIf="item.metadatas.typology.label ==='ORD'" [activityId]='item'></app-ordon>
                <app-text-matching *ngIf="item.metadatas.typology.label ==='TXTMAT'" [activityId]='item'></app-text-matching>
                <app-awareness *ngIf="item.metadatas.typology.label ==='awareness'" [activityId]='item'></app-awareness>
                <app-appaire *ngIf="item.metadatas.typology.label ==='APP'" [activityId]='item'></app-appaire>
                <app-fill-in-blanks *ngIf="item.metadatas.typology.label ==='RB'" [activityId]='item'></app-fill-in-blanks>
                <app-trainer-feedback *ngIf="showFeedback" [activity]="item" [contextId]="assignmentId" [displayMode]="formMode"></app-trainer-feedback>
                <app-what-is-missing *ngIf="item.metadatas.typology.label ==='missing'" [activityId]='item' [contextId]="assignmentId"></app-what-is-missing>
            </div>
        </div>
    </mat-card-content>

    <textarea *ngIf="formMode === 'feedback'" class="textareaContent" placeholder="{{ 'activities.form.comment' | translate }}" [(ngModel)]="overallActivitiesComments"></textarea>
    <div *ngIf="formMode === 'display'" class="textareaContent">{{ overallActivitiesComments }}</div>

    <mat-card-actions>
        <button *ngIf="canSave" class="envoye-btn active" mat-button (click)="saveAnswers()">{{ 'generic.save' | translate }}</button>
    </mat-card-actions>

</mat-card>
