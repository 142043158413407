import {brand} from '../../../../../settings/ubolino_app';
import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {OptionsComponent} from '@modules/activities/core/shared-components/options/options.component';
import {Observable, of} from 'rxjs';
import {BaseActivityComponent} from '../base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ActivatedRoute} from '@angular/router';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {CommunicationCenterService} from '@modules/communication-center';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';

interface PointimgActivityContentInterface {
    answers: ItemAnswerInterface[];
}

interface PointimgActivityConfigInterface {
    direction: string;
}

type PointimgActivityReferenceInterface = ActivityReferenceInterface<PointimgActivityContentInterface, PointimgActivityConfigInterface>;

@Component({
    selector: 'app-image-zoning',
    templateUrl: './image-zoning.component.html'
})
/**
 * some zone inside the image are true when you click on it they will become green
 */
export class ImageZoningComponent extends BaseActivityComponent<any, any> {
    public assetbrand = brand;

    @ViewChild(OptionsComponent, {static: true}) optionsComponent: OptionsComponent;

    // public displaySaveBtn = false; // Boolean know if we display checkbox or button, data fetched from the api
    public isTwoColumns = false;
    public referenceActivityGranule: PointimgActivityReferenceInterface;
    public showSubInstruction: boolean;
    public title: string;
    public containerClass = 'container';
    public minAnswers; // minimum answers needed to consider exo is finish

    constructor(
        protected activatedRoute: ActivatedRoute,
        protected activitiesService: ActivitiesService,
        protected lessonsService: LessonsService,
        protected communicationCenter: CommunicationCenterService,
        protected ref: ChangeDetectorRef
    ) {
        super(activatedRoute, activitiesService, lessonsService, communicationCenter, ref);
    }

    protected setContentData(activityAttributes): void {
        this.showSubInstruction = this.activitiesService.settings.showSubInstruction;
        if (activityAttributes.reference.config) {
            this.isTwoColumns = activityAttributes.reference.config.doubleColumn !== 0;
        }
        this.referenceActivityGranule = activityAttributes.reference;
        try {
            this.minAnswers = activityAttributes.reference.config.minAnswers ? activityAttributes.reference.config.minAnswers : null;
        } catch (ex) {
        }
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wording = (this.preview && !this.activitiesService.settings['hiddenFieldActivityPreview'].find(field => field === 'wording'))
        || !this.preview ? this.referenceActivityGranule.wording : '';
        this.setDefaultAnswersAvailable(this.referenceActivityGranule.activity_content.answers);
        this.loadUserSave();
    }

    /**
     * a zone was clicked we get the answer corresponding at the zone
     * @param answer : answer clicked
     */
    public zoneClickEvent(answer: ItemAnswerInterface): void {
        this.answersSelected.find(a => a.id === answer.id).select = true;
        this.containerClass = this.answersSelected.find(a => a.id === answer.id).correct_answer ? 'container true-answer' : 'container false-answer';
        // init object for save
        const answerResult: AnswerResultInterface = {
            id: +this.activityId.id,
            isAnswerCorrect: undefined,
            isLast: undefined
        };

        answerResult.isAnswerCorrect = this.answersSelected.find(a => a.id === answer.id).correct_answer;
        answerResult.isLast = this.allAnswerCorrect();

        super.ifFeedbackOpenModal(answer.id);

        this.answerResult.next(answerResult);
        setTimeout(() => {
            if (this.allAnswerCorrect()) {
                this.doAction('next', ['save']);
            } else {
                this.containerClass = 'container';
            }
        }, 500);

        // store the answer status of current answer
        this.answerStatus = answerResult.isAnswerCorrect ? answerStatusEnum.correct : answerStatusEnum.wrong;

        this.activitiesService.isUserAnswerStatus
            .next({status: this.answerStatus, index: this.activityStepIndex});
    }

    /**
     * init array of answers
     * @param answers
     * @param resetSelectEd reset answer selected to false
     * @private
     */
    private setDefaultAnswersAvailable(answers, resetSelectEd?: boolean): void {
        this.answersSelected = answers.map((answer) => {
            return {
                id: answer.id,
                answer: answer.answer,
                select: resetSelectEd ? false : !!+answer.select,
                correct_answer: !!+answer.correct_answer,
                state: ItemAnswerStateEnum.pristine,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
    }

    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.setDefaultAnswersAvailable(this.answersSelected, true);
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * check that all answers are done
     * @private
     */
    private allAnswerCorrect(): boolean {
        // number of good answer is pass in config if all good answer are not needed
        if (this.minAnswers !== null && this.minAnswers !== undefined) {
            return this.answersSelected.filter((option: ItemAnswerInterface) => option.select && option.correct_answer).length === this.minAnswers;
        } else {
            return !this.answersSelected.find((option: ItemAnswerInterface) => !option.select && option.correct_answer);
        }
    }

    /**
     * get the grade calculated with answer correctly answered
     */
    protected getGrade(): { newGrade: number, oldGrade: number } {
        return {
            newGrade: this.answersSelected.filter((answer: ItemAnswerInterface) => answer.select && answer.correct_answer).length,
            oldGrade: this.userSave ?
                this.userSave.get('userActivity').entitySave.answers.filter((answer: ItemAnswerInterface) =>
                    answer.select && answer.correct_answer).length : 0
        };
    }

    /**
     * no need to create answer because answer already exist.
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    protected reviewAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected seeAnswerSolution(): void {
        throw new Error('Method not implemented.');
    }

    protected setAnswer(): void {
        throw new Error('Method not implemented.');
    }

    protected checkAnswer(): void {
        throw new Error('Method not implemented.');
    }

}
