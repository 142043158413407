    <div mat-dialog-title>
        <h2>{{dialogTitle | translate}}</h2>
        <button mat-mini-fab
                class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>


    <mat-dialog-content class="p-24 m-0">

        <form [formGroup]="entityForm"  name="entityForm" novalidate >

            <!-- Form Name Field -->
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex>
                    <input matInput name="title" formControlName="title" placeholder="{{ 'activities.form.name' | translate }}" required>
                </mat-form-field>
            </div>

            <!-- Short Description Field -->
            <div class="mb-24" fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field fxFlex class="wrapper-textarea">
                    <textarea matInput name="description" formControlName="description" rows="10" placeholder="{{ 'activities.form.short_description' | translate }}" required></textarea>
                </mat-form-field>
            </div>

        </form>

    </mat-dialog-content>

    <mat-dialog-actions>

        <button mat-raised-button
                (click)="close()"
                class="save-button mat-accent"
                [disabled]="entityForm.invalid"
                aria-label="SAVE">
            {{ 'generic.save' | translate }}
        </button>

    </mat-dialog-actions>