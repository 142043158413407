<div class="container"
     infinite-scroll
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="200"
     [immediateCheck]="true"
     [scrollWindow]="false"
     (scrolled)="onScroll()"
>

    <app-flashcard
        *ngFor="let flashcard of flashcards"
        [flashCard]="flashcard"
        (state)="onStateChange($event, flashcard)"
        [readBlocked]="readInProgress"
    ></app-flashcard>
</div>
<div *ngIf="loader" class="loader" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
    <mat-spinner></mat-spinner>
</div>
