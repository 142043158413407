<mat-card class="activities-card inner-scroll mat-elevation-z0">

    <mat-card-header>
        <mat-card-title [class.sub-instruction-enabled]="showSubInstruction">
            <app-consignes (isReading)="ttsReadingEvent($event)" [wording]="wording" [wordingAudio]="wordingAudio"
                           [additionalHiddenText]="showSubInstruction?('activities.sub_instruction_qmcu' | translate):''"></app-consignes>
        </mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <!--answer and image zone-->
        <div id="container-for-answers-available"
             [ngClass]="!!answersSelected[0].image?
             'answers-are-images ' + getColumnClass() + ' available-answers grid-square-' + answersSelected.length :columnClass">

            <div *ngFor="let option of answersSelected" class="card-container"
                 [ngClass]="dynamicImgContainerClass(option)">
                <div class="question-container">
                    <mat-icon svgIcon="question"></mat-icon>
                </div>
                <img [src]="option.image"/>
            </div>
        </div>
    </mat-card-content>

    <mat-card-footer>
        <button *ngIf="step===0" class="btn-option btn-next" (click)="nextStep()">
            <span>{{'generic.next' | translate}}</span>
        </button>

        <ng-container *ngIf="showButton">
            <button *ngFor="let option of answersSelected"
                    class="btn-option"
                    [class]="option.state"
                    (click)="clickAnswer(option)"
                    [disabled]="isDisabledOptions">
                <span [innerHTML]="option.answer | stringToHtml"></span>
            </button>
        </ng-container>
    </mat-card-footer>
</mat-card>
