export const mainMenu = {
    menu: {
        default: [
            {
                label: 'generic.lesson.play',
                path: '/lessons/home'
            },
            {
                label: 'generic.my_progress',
                path: '/my-progress'
            },
            {
                label: 'generic.my_avatar',
                path: '/my-avatar'

            },
            {
                label: 'generic.setting',
                path: '/profile/show-profile'
            },
            {
                label: 'account-management.logout',
                path: '/logout'
            }],
        learner: [
            {
                label: 'generic.lesson.play',
                path: '/lessons/home'
            },
            {
                label: 'generic.my_progress',
                path: '/my-progress'
            },
            {
                label: 'generic.my_avatar',
                path: '/my-avatar'

            },
            {
                label: 'generic.setting',
                path: '/profile/show-profile'
            },
            {
                label: 'account-management.logout',
                path: '/logout'
            }
        ],
        trainer: [
            {
                label: 'generic.lesson.play',
                path: '/lessons/home'
            },
            {
                label: 'generic.my_progress',
                path: '/my-progress'
            },
            {
                label: 'generic.my_avatar',
                path: '/my-avatar'
            },
            {
                label: 'generic.setting',
                path: '/profile/show-profile'
            },
            {
                label: 'account-management.logout',
                path: '/logout'
            }
        ]
    },
    sideNavMode: 'over',
    isOpenedByDefault: false,
    // if externalLInk condition is : user have License (free)
    externalLinkWithLicense: {
        checkIfUserHasInstitutionLicense: false,
        checkIfUserHasClassLicense: false,
        checkIfUserHasFreeLicense: true,
        externalLink: {
            label: 'navigation.external-target-link-menu',
            path: 'https://www.uebolino.de/',
            externalUrl: true,
            openInNewTab: true,
            options: {
                customClasses: ['wrapper-menu-item-external-target-link-menu']
            }
        }
    }
};
