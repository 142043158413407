<div fxLayout="column" class="page-layout simple fullwidth inner-scroll markerInfosContainer" fxFlex>

    <div mat-dialog-title>
        <h2 class="mat-dialog-title-text" *ngIf="isEditor">{{addOrEditTitle | translate}}&nbsp;:&nbsp;{{titleType | translate}}</h2>
        <h2 class="mat-dialog-title-text" *ngIf="!isEditor">{{titleType | translate}}</h2>
        <button mat-mini-fab
                class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>


    <mat-dialog-content>
        <form *ngIf="step === 0" [formGroup]="form0" [ngClass]="isEditor?'editor-mode':'play-mode'"
              fxLayout="column" fxLayoutAlign="none">

            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">

                <mat-form-field *ngIf="isEditor && displayField('title')" class="title-field" fxFlex="50">
                    <input matInput formControlName="title" placeholder="{{ 'activities.title-clue' | translate }}"/>
                </mat-form-field>
                <mat-form-field *ngIf="isEditor && displayField('location')" class="location-field" fxFlex="50">
                    <input matInput formControlName="location"
                           placeholder="{{ 'activities.location-clue' | translate }}"/>
                </mat-form-field>

            </div>
            <div fxLayout="row" fxLayoutAlign="center center">
                <div [style.position]="'relative'" [style.width.%]="'100'">
                    <app-video-with-markers
                        [videoConfig]="videoConfig"
                    ></app-video-with-markers>
                    <div class="disable-video-controls"></div>
                </div>
            </div>

            <mat-form-field *ngIf="displayField('question')" class="question-field" >
                <input matInput formControlName="question" placeholder="{{(isEditor?'activities.title_clue' : '') | translate }}"/>
            </mat-form-field>

            <div fxFlex="1" fxLayout="row" fxLayoutGap="8px" class="form-field-row">

                <div fxFlex="50" class="form-field-column-one">
                    <mat-form-field *ngIf="displayField('clue')" fxFill class="field-full-height clue-field">
                        <textarea matInput
                                  id="clue"
                                  formControlName="clue"
                                  cdkTextareaAutosize
                                  placeholder="{{ 'activities.location_clue' | translate }}"></textarea>
                    </mat-form-field>
                </div>

                <div fxFlex="50" fxLayout="column" class="form-field-column-two">
                    <div class="mat-form-field-fusion" fxLayout="column">
                        <mat-form-field *ngIf="displayField('tracks')">
                            <textarea id="tracks"
                                      matInput
                                      formControlName="tracks"
                                      cdkTextareaAutosize
                                      placeholder="{{ 'activities.tracks_clue' | translate }}"></textarea>
                        </mat-form-field>
                        <mat-form-field *ngIf="displayField('url')">
                            <ng-container *ngFor="let data of urlandString">
                                <a *ngIf="data.isUrl" [href]="data.text" target="_blank">{{data.text}}</a>
                                <span *ngIf="!data.isUrl">{{data.text}}</span>
                            </ng-container>

                            <input [hidden]="useUrlLink()" matInput formControlName="url"
                                   placeholder="{{ 'activities.url_clue' | translate }}">


                        </mat-form-field>
                    </div>
                </div>

            </div>

        </form>

        <form *ngIf="step === 1" [formGroup]="form1" fxLayout="column" fxLayoutAlign="start center"
              class="note">
            <h2 class="form-title-text">{{'activities.survey_notes' | translate}}</h2>

            <button *ngIf="isEditor === false" class="download-pdf" (click)="savePDF()" mat-raised-button>
                {{'generic.download_as_pdf' | translate}}
            </button>

            <h3 *ngIf="showVideoMarkersNoteSubtitle" 
                class="form-title-text-subtitle">{{'activities.survey_notes_instruction' | translate}}</h3>

            <mat-form-field fxFlex="100" class="w-100-p">
                <textarea matInput formControlName="survey-notes" placeholder=""></textarea>
            </mat-form-field>
        </form>

    </mat-dialog-content>

    <mat-dialog-actions class="mat-dialog-actions">
        <button *ngIf="isEditor && step === 1" mat-raised-button (click)="previous()">
            <span>{{ 'generic.previous' | translate }}</span>
        </button>
        <button *ngIf="isEditor && step === 0" mat-raised-button (click)="next()">
            <span>{{ 'generic.next' | translate }}</span>
        </button>
        <button *ngIf="!isEditor && step === 1" id="previous-question-button" mat-raised-button (click)="previous()">
            <mat-icon svgIcon="arrow_left_circle_outline"></mat-icon>
            <span>{{ 'generic.previous' | translate }}</span>
        </button>
        <button *ngIf="!isEditor" id="next-question-button" mat-raised-button (click)="next()">
            <mat-icon svgIcon="arrow_right_circle_outline"></mat-icon>
            <span>{{ 'generic.next' | translate }}</span>
        </button>
        <button *ngIf="step === 0 && isEditor" mat-raised-button (click)="save()">
            <span>{{ 'generic.save' | translate }}</span>
            <mat-spinner *ngIf="showSpinner" class="ml-8"></mat-spinner>
        </button>
        <button *ngIf="step === 0 && isEditor && marker && marker.id && openMarkerAutomatic" mat-raised-button
                (click)="delete()">
            <span>{{ 'generic.delete' | translate }}</span>
        </button>

    </mat-dialog-actions>


</div>

