<!--to show toaster only if content is loaded-->
<ng-container *ngIf="!isToaster || (isToaster && page)">
    <div mat-dialog-title>
        <h2>{{ page?.get('label') }}</h2>
        <button *ngIf="!isToaster"
                mat-mini-fab
                class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <mat-dialog-content>
        <app-basic-page [page]="page" [title]="false"></app-basic-page>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button *ngIf="page?.get('document')" (click)="downloadDocument()" mat-raised-button class="mat-accent">
            {{ 'generic.download_document' | translate }}
            <mat-spinner *ngIf="downloading" class="ml-8"></mat-spinner>
        </button>
    </mat-dialog-actions>
</ng-container>
