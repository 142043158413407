
import {of as observableOf, Observable, Subject, combineLatest, ReplaySubject} from 'rxjs';

import {take, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivitiesService} from '@modules/activities/core/activities.service';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from '../../../../settings';
import {UserActionExecution} from '@modules/activities/core/services/user-action-execution.interface';
import {UserActionButtonLabels} from '@modules/activities/core/services/user-action-button-labels.enum';

/**
 * @example
 * { preActionMatrix : {
 *  'launchNextActivity' : ['saveAnswer']
 * }
 * On the execution of a 'launchNextActivity' action, it will execute a 'saveAnswer' action before.
 *
 * Warning ! Do not make an infinite loop by list an action as pre-action of own pre-action.
 */
const settingsStructure: ModelSchema = new ModelSchema({
    'preActionMatrix': Structures.object(),
    'displaySpecificUserActionButton': Structures.array(['next', 'previous']),
});

@Injectable()
export class UserActionsService {
    public checkAnswer: boolean;
    public withoutAnyUserResponse: boolean;

    /**
     * List of distinct subject. Exist for prevent multiple learner to be trigger by the same event.
     */
    private actionExecutedSubjects: Map<string, Subject<UserActionExecution>> = new Map();
    private doesUserResponed: boolean;
    private finalSolutionObject: object;
    private userAnswerTakeUntil = new Subject();
    public settings: { [key: string]: any };

    constructor(
        private activitiesService: ActivitiesService
    ) {
        this.activitiesService.userAnswer.pipe(
            takeUntil(this.userAnswerTakeUntil))
            .subscribe(data => {
                this.doesUserResponed = true;
            });

        this.settings = settingsStructure.filterModel(modulesSettings.activities);
    }

    public clickToTestResponses(optionText: string): Observable<any> {
        return Observable.create(observer => {
            //check answers when replied
            if (optionText === 'checkTheAnswers' && this.doesUserResponed) {
                this.checkAnswer = true;
                this.resentFinalAnswerParametersProperties(true, false, false);
                this.activitiesService.activityActionHandler(true, false, false, false);
                this.activitiesService.doesUserResponsed.next(false);
            }

            //Check the answers when nothing is replied
            else if (optionText === 'checkTheAnswers' && !this.doesUserResponed) {
                this.checkAnswer = true;
                this.withoutAnyUserResponse = false;
                this.activitiesService.doesUserResponsed.next(false);
                this.activitiesService.activityActionHandler(true, false, false, false);
                this.resentFinalAnswerParametersProperties(true, false, false);
                this.doesUserResponed = true;
            }

            //Display the solution with after user replied
            else if (optionText === 'displayTheSolution' && this.doesUserResponed) {
                this.withoutAnyUserResponse = false;
                this.checkAnswer = true;
                this.activitiesService.activityActionHandler(false, false, false, true);
                this.resentFinalAnswerParametersProperties(this.checkAnswer, false, false);
            }

            //display the solution when the user does not replied.
            else if (optionText === 'displayTheSolution' && !this.doesUserResponed) {
                this.withoutAnyUserResponse = false;
                this.checkAnswer = false;
                this.activitiesService.activityActionHandler(false, false, false, true);
                this.resentFinalAnswerParametersProperties(this.checkAnswer, false, false);
            } else {
                this.resentFinalAnswerParametersProperties(this.checkAnswer, false, false);
            }
            // this.activitiesService.checkAnswers.next(this.finalSolutionObject);

            observer.next({
                checkAnswer: this.checkAnswer,
                withoutAnyUserResponse: this.withoutAnyUserResponse
            });
        });

    }

    resentFinalAnswerParametersProperties(showAnswers: boolean, showWithoutResponse?: boolean, resetValue?: boolean): void {
        this.finalSolutionObject = {
            showAnswers: showAnswers,
            withoutAnyUserResponse: showWithoutResponse,
            reinitializeOptions: resetValue,
        };
        this.activitiesService.checkAnswers.next(this.finalSolutionObject);
    }

    /**
     * Create, return, and locally saved a new subject used by {@link UserActionsService.executeUserAction} for retreive the execution context of an action
     * The subject will be save locally and referenced by {executorIdentifier}.
     *
     * @param executorIdentifier Unique identifier of executor. Seems to be an angular component with {@link UserActionExecutor} interface.
     */
    public getActionExecutedSubject(executorIdentifier: string): Subject<UserActionExecution> {
        const subject = new Subject<UserActionExecution>();
        this.actionExecutedSubjects.set(executorIdentifier, subject);
        return subject;
    }

    /**
     * Remove the subject for the locally saved list.
     *
     * @param executorIdentifier Unique identifier of executor. Seems to be an angular component with {@link UserActionExecutor} interface.
     *
     * @remarks
     * Should be a tips about helping the garbage collector to delete useless subjects
     */
    public destroyActionExecutionSubject(executorIdentifier: string): void {
        this.actionExecutedSubjects.delete(executorIdentifier);
    }

    /**
     * Trigger all {@link UserActionsService.actionExecutedSubjects} subscriber by an {@link UserActionButtonLabels}.
     * The subscriber (as `executor`) will be triggered by the `preAction` listed in settings before be triggered by the current actionLabel.
     * Each *before-actions* are recursively called by this method.
     *
     * @param executorIdentifier Unique identifier of executor. Seems to be an angular component with {@link UserActionExecutor} interface.
     * @param actionLabel Unique identifier of action to execute.
     */
    public executeUserAction(executorIdentifier: string, actionLabel: UserActionButtonLabels): Observable<any> {
        const executor = this.actionExecutedSubjects.get(executorIdentifier);

        if (executor === undefined) {
            throw new Error('Executor must be defined before action execution');
        }

        // ReplaySubject is better than Subject because in "preview", the 'emit' is done before the subscription.
        const resultObservable = new ReplaySubject(1);

        const preActions: UserActionButtonLabels[] = this.settings['preActionMatrix'][actionLabel];
        let onPreActionComplete: Observable<any>;

        if (!preActions || preActions.length === 0) {
            onPreActionComplete = observableOf([]);
        } else {
            const listOfPreObs = preActions.map(action => this.executeUserAction(executorIdentifier, action));
            onPreActionComplete = combineLatest(...listOfPreObs);
        }

        onPreActionComplete.pipe(
            take(1))
            .subscribe(() => {
                executor.next({
                    actionLabel,
                    endSubject: resultObservable
            });
        });

        return resultObservable;
    }
}
