import {Component, Input, OnChanges, OnInit} from '@angular/core';

/**
 * show a progress bar whith multiple true false color zone
 * in input we pass :
 * - the number of question
 * - an array with all answers format { id: number, isAnswerCorrect: boolean, isLast: boolean } id is not used but passs
 * in case we need it a day ( be carefull to detect change array send must be a copy : [...array]
 * example of using it
 * html:
 * <app-progress-multi-zone [answers]="answersCopy" [numberOfQuestion]="8"></app-progress-multi-zone>
 * ts:
 *  public answers = [];
 *  public answersCopy = [];
 *  public addAnswer(val: boolean): void {
 *       this.answers.push(val);
 *       this.answersCopy = [...this.answers]; // for forcing launch change make a copy of array
 *   }
 *   we can reload previous state (not use for moment) if we pass the same array at init
 */

@Component({
    selector: 'app-progress-multi-zone',
    templateUrl: './progress-multi-zone.component.html'
})
export class ProgressMultiZoneComponent implements OnInit, OnChanges {
    @Input() answers: { id: number, isAnswerCorrect: boolean, isLast: boolean }[] = [];
    @Input() numberOfQuestion = 0;
    public answersByZone: { isAnswerCorrect: boolean, isLast: boolean, zone: number, index: number }[] = [];
    private lastZone = 1; // last zone where answer was made
    private lastFreeIndex = 1; // last index inside a zone
    constructor() {
    }

    ngOnInit(): void {
        this.answersWithZoneInit();
    }

    ngOnChanges(): void {
        if (this.answers.length > 0) {
            // add last answer push inside the array in good format
            this.addNewAnswersWithZone(this.answers[this.answers.length - 1]);
        } else {
            // reset new array pass is empty
            this.answersWithZoneInit();
        }
    }

    /**
     * progress bar is separate in number of question each one is a zone
     * it return the current zone to use
     */
    public currentZone(): number {
        return this.answers.filter(a => a.isAnswerCorrect === true).length + 1;
    }

    /**
     * init base value and if array is full set value for fullfill progressbar
     * it's use only when component is loaded
     * @private
     */
    private answersWithZoneInit(): void {
        this.answersByZone = [];
        this.lastZone = 1;
        this.lastFreeIndex = 1;
        // following part will be used only if one day we want to see all response done before and pass it on one time
        // for example if we want to load all previous answer for let user continue where he was before
        if (this.answers && this.answers.length > 0) {
            this.answers.forEach(a => {
                this.answersByZone.push({isAnswerCorrect: a.isAnswerCorrect, isLast: a.isLast, zone: this.lastZone, index: this.lastFreeIndex});
                if (a.isAnswerCorrect === true && a.isLast) {
                    this.lastZone = this.lastZone + 1;
                    this.lastFreeIndex = 1;
                } else {
                    this.lastFreeIndex = this.lastFreeIndex + 1;
                }
            });
        }
    }

    /**
     * add a new answer in the answer by zone array
     * @param answer: true or false
     */
    addNewAnswersWithZone(answer: { isAnswerCorrect: boolean, isLast: boolean }): void {
        if (answer !== undefined) {
            this.answersByZone.push({isAnswerCorrect: answer.isAnswerCorrect, isLast: answer.isLast, zone: this.lastZone, index: this.lastFreeIndex});
            if (answer.isAnswerCorrect === true && answer.isLast) {
                this.lastZone = this.lastZone + 1;
                this.lastFreeIndex = 1;
            } else {
                this.lastFreeIndex = this.lastFreeIndex + 1;
            }
        }
    }

    /**
     * is the last true answer possible is done? for example : 4 question 4 true possible only
     */
    public lastTrueAnswersDone(): boolean {
        return this.answersByZone.filter(a => a.isAnswerCorrect === true && a.isLast).length === this.numberOfQuestion;
    }

    /**
     * calcul the  width in pourcent to apply to each answer
     * each progress bar is separate in zone equal to number of question
     * for each zone we can have any numbers of bad answer but only one good answer
     * until a good asnwer is made the width is calculate for the current zone with the following rules :
     * fisrt bad answer use 50% of the zone second bad answer use half of the resting zone third same thing.
     * so zone of 25% => 1/ 12.5 => 2/ 6.25 =>3 /3.12 etc.. until a true one is done
     * when true answer is done the zone is separate equaly between all answers
     * @param answer : current answer
     */
    public widthToApply(answer: { isAnswerCorrect: boolean, isLast: boolean, zone: number, index: number }): number {
        const pourcentByZone = 100 / this.numberOfQuestion;
        // we check if we are in current zone because if we change of zone we never calcul again ratio with a limit for this zone
        // so after a good answer bad and good answer will be recalculate to have equal ratio in regard of their proportion
        if ((answer.isAnswerCorrect === false && answer.zone === this.lastZone) || (answer.isAnswerCorrect === true && !answer.isLast) && answer.zone === this.lastZone) {
            return this.calculPlaceToUseForBadAnswerBeforeHavingGoodOneInRegardOfPlaceInZone(pourcentByZone, answer.index);
        }
        // after a good answer bad and good answer take place in regard of their proportion inside the max zone allowed
        return pourcentByZone / this.answersByZone.filter(z => z.zone === answer.zone).length;
    }

    /**
     * before having a good answer user can do a no limit bad answer so to increment visualy this bad answers
     * we begin at half place dispo and add for each bad answer half of half place dispo etc...
     * we use a recurcive method to divide by 2 previous zone dispo be careful for infinite loop if changing it
     * for example 25% for all zone => we always begin for bad answer with /2 => 12.5% for first bad answer
     * but next bad answer must hav 12.5/2 => 6.25 place only etc...
     * @param pourcentByZone : pourcent of total progress bar dispo for the answer
     * @param index : position of the answer inside the zone first answer second etc...
     * @private
     */
    private calculPlaceToUseForBadAnswerBeforeHavingGoodOneInRegardOfPlaceInZone(pourcentByZone: number, index: number): number {
        const newPourcentByZone = pourcentByZone / 2;
        // under a limit we cannot see change on screen so to avoid memory leak for nothing if someone click 200 times false I return 0
        if (newPourcentByZone < 0.01) {
            return 0;
        }
        if (index > 1) {
            index = index - 1;
            return this.calculPlaceToUseForBadAnswerBeforeHavingGoodOneInRegardOfPlaceInZone(newPourcentByZone, index);
        } else {
            return newPourcentByZone;
        }
    }
}
