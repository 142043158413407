<div class="container" #container>
  <div class="inner-container">
    <div class="controls">
      <div class="control arrow left" [ngClass]="controlsAllowed === false ? 'disabled' : null" (click)="onPrevious()"
        *ngIf="navigationAllowed === true">
        <mat-icon svgIcon="previous" class="white"></mat-icon>
      </div>
      <div class="name">
        <div class="text" *ngIf="isNameEditing === false">{{name}}</div>
        <input type="text" [(ngModel)]="$name" [size]="$name.length" *ngIf="isNameEditing === true"
          (keyup.enter)="submitName()" appAutofocus>
        <div class="control edit" [ngClass]="controlsAllowed === false ? 'disabled' : null" (click)="editName()"
          *ngIf="isNameEditing === false">
          <mat-icon svgIcon="edit"></mat-icon>
        </div>
        <div class="control edit check" [ngClass]="controlsAllowed === false ? 'disabled' : null" (click)="submitName()"
          *ngIf="isNameEditing === true">
          <mat-icon svgIcon="step_success"></mat-icon>
        </div>
      </div>
      <div class="control set-avatar" [ngClass]="{
          'disabled': controlsAllowed === false,
          'selected': creature.creature.attributes.selected === true
        }" (click)="onSetAvatar()" title="{{ 'gamification.avatar.select' | translate }}">
        <mat-icon svgIcon="avatar"></mat-icon>
        <div class="avatar-default">
          <mat-icon svgIcon="step_success"></mat-icon>
        </div>
      </div>
      <div class="control arrow right" [ngClass]="controlsAllowed === false ? 'disabled' : null" (click)="onNext()"
        *ngIf="navigationAllowed === true">
        <mat-icon svgIcon="next"></mat-icon>
      </div>
    </div>
    <div class="xp-bar-container">
      <div class="label">{{ 'generic.xp' | translate }}</div>
      <div class="bar-container">
        <div class="bar" [style.width]="progressBarWidth + '%'"
          [style.backgroundSize]="progressBarBackgroundWidth + '%'"></div>
      </div>
    </div>
  </div>
  <div class="popup-container">
    <app-accessories-popup [creature]="creature" [accessories]="creature.accessories" [universes]="creature.availableUniverses"
      (update)="triggerUpdateEvent($event)" (saveUserImage)="onSaveUserImage($event)" #popup></app-accessories-popup>
  </div>
</div>
