
import {filter, map, mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {Injectable, QueryList} from '@angular/core';
import {DataCollection, DataEntity, OrderDirection, OctopusConnectService} from 'octopus-connect';
import {Observable, BehaviorSubject, Subscription, combineLatest, of, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {FuseConfirmDialogComponent} from 'fuse-core/components/confirm-dialog/confirm-dialog.component';
import {localizedDate, localizedTime} from 'app/shared/utils';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash';
import {PaginatedCollection} from 'octopus-connect';
import {CollectionOptionsInterface} from 'octopus-connect';
import {FlagService} from '../../../shared/flag.service';
import {isArray} from 'lodash';

const settingsStructure: ModelSchema = new ModelSchema({
    columns: Structures.object({
        default: Structures.array(['type', 'assigned_node_title', 'start_date', 'end_date', 'assigned_user', 'class', 'group', 'state', 'progress', 'score', 'buttons'])
    }),
    columnsDashboardWidget: Structures.object({
        default: Structures.array(['type', 'title', 'start_date', 'end_date', 'group', 'workgroup', 'progress'])
    }),
    filters: Structures.object({
        default: Structures.array(['type', 'title', 'group', 'workgroup', 'learner'])
    }),
    actions: Structures.object({
        default: ['unassign']
    }),
    hasType: Structures.boolean(true),
    stateWithIcon: Structures.boolean(false),
    hasCompletionDate: Structures.boolean(true),
    hasCompletionTime: Structures.boolean(true),
    rolesForOverrideAssignmentLaunchToMetadatas: Structures.array([]),
    completionDate: Structures.array(['assessment', 'homework']),
    completionTime: Structures.array(['assessment']),
    completionStartDateOnly: Structures.array(),
    enableGradeType: Structures.array(),
    ratingBase: Structures.array(),
    gettingStarted: Structures.object({}),
    getStateFromDate: Structures.boolean(),
    isLessonCanBeAssignedOnlyOnceByUser: Structures.boolean(false),
    followedList: Structures.object({
        formCreationLink: Structures.array([])
    }),
    formFields: Structures.object({
        default: ['group', 'workgroup', 'learner', 'project', 'assignment_type', 'assignment_grade', 'start_date', 'due_date']
    }),
    initAssignment: Structures.boolean(false),
    checkDefaultFiltersInUrl: Structures.boolean(false),
    noFilterforAssignmentsWidget: Structures.boolean(false),
    useSchoolYearFilter: Structures.boolean(false),
    showNameClass: Structures.boolean(false),
    showHours: Structures.boolean(true),
    useHideFeedbacksToLaunchAdaptativeModal: Structures.boolean(false),
    followedLogBookFields: Structures.object({
        default: ['group', 'type', 'exoName', 'learner', 'beginDate', 'endDate']
    })
});

const projectSettingsStructure: ModelSchema = new ModelSchema(({
    accessProject: Structures.boolean()
}));

@Injectable()
export class AssignationService {
    assignations: any = [];
    public settings: { [key: string]: any };
    public projectSettings: { [key: string]: any };
    private states: DataEntity[] = [];
    followedSubscription: Subscription;
    onFilesChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onFileSelected: BehaviorSubject<any> = new BehaviorSubject({});
    onSelectedResourcesChanged: BehaviorSubject<any> = new BehaviorSubject([]);
    i: number;
    dialogYes: string;
    dialogCancel: string;
    dialogTitle: string;
    dialogDeleteMessage: string;
    userData: DataEntity;
    public assignmentsPaginated: PaginatedCollection;
    private assignmentsPaginatedObs: Observable<DataEntity[]>;
    private currentAssignment: DataEntity;
    private savePending: { [key: string]: Observable<DataEntity> } = {};
    private saveQueue: { [key: string]: DataEntity } = {};
    public unsubscribeInTakeUntil = new Subject();

    rawlearnersList: any[] = [];
    rawGroupsList: any[] = [];
    rawWorkgroupsList: any[] = [];
    selectedProject: any;

    assignmentCollection: DataCollection;
    public assignmentGroupListPaginated: PaginatedCollection;
    private assignmentsGroupListPaginatedObs: Observable<DataEntity[]>;
    private assignmentsGroup: DataEntity[] = [];

    public methodDialogInfos: { title: string, body: string, ok: string } = {title: '', body: '', ok: ''};
    public settingsLicensing: { [key: string]: any } = {'restrict': []};
    public learnerMethods = [];

    private types: any[];
    private _dataSource: MatTableDataSource<any> = new MatTableDataSource();
    get dataSource(): MatTableDataSource<any> {
        return this._dataSource;
    }

    public initAssignment: DataEntity;

    public schoolYearsList: DataEntity[];
    public schoolyearDefaultMatSelectValue = 'all';


    constructor(
        private octopusConnect: OctopusConnectService,
        private communicationCenter: CommunicationCenterService,
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private translate: TranslateService,
        private authService: AuthenticationService,
        private flagService: FlagService
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                if (data) {
                    this.userData = data;
                    this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((assignment: DataEntity) => {
                this.currentAssignment = assignment;
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launchAssignment')
            .subscribe((data) => {
                this.launchAssignment(data).subscribe();
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launchAssignmentInPlayer')
            .subscribe((data) => {
                this.launchAssignmentInPlayer(data);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('createAssignment')
            .subscribe((data: {
                assigment: {[key: string]: any},
                callback: (arg?) => any,
                dontUseSpecificConfiguration?: boolean}) => {
                this.createAssignmentWithCallBack(data);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('comment')
            .subscribe(data => {
                if (this.currentAssignment && data.comment) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('comment', data.comment);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('changeState')
            .subscribe(data => {
                if (this.currentAssignment && data.state) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('state_term', this.getState(data.state));

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveGrade')
            .subscribe((data) => {
                if (this.currentAssignment && data.grade !== undefined) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('grade', data.grade);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveProgress')
            .subscribe((data) => {
                if (this.currentAssignment && data.progress !== undefined) {
                    this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
                    this.currentAssignment.set('progress', data.progress);

                    if (this.savePending[this.currentAssignment.id]) {
                        this.queueSave(this.currentAssignment);
                    } else {
                        this.saveAssignment(this.currentAssignment);
                    }
                }
            });

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('event')
            .subscribe((data) => {
                if (data.id && data.event) {
                    this.octopusConnect.loadEntity('assignations', data.id + '/' + data.event);
                }
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('launch')
            .subscribe((data: string | number) => {
                this.loadAndGetAssignmentById(data).pipe(
                    take(1),
                    mergeMap(assignment => this.launchAssignment(assignment))
                ).subscribe();
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadAndGetAssignmentById$')
            .pipe(
                tap(({context, onComplete}: { context: string | number, onComplete: Subject<Observable<DataEntity>> }) =>
                    onComplete.next(this.loadAndGetAssignmentById(context)))
            ).subscribe();

        this.onFilesChanged.subscribe(data => {
            this.assignations = data;
        });

        this.settings = settingsStructure.filterModel(modulesSettings.assignation);
        this.projectSettings = projectSettingsStructure.filterModel(modulesSettings.projectsManagement);

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadAvailableGroups')
            .subscribe((load: boolean) => {
                if (load) {
                    this.loadPaginatedAssignmentGroupList({}, true);
                } else {
                    this.communicationCenter
                        .getRoom('assignation')
                        .next('assignmentsGroup', {assignmentsGroup: []});
                }
            });

        this.communicationCenter.getRoom('licenses')
            .getSubject('settings')
            .subscribe((data) => {
                this.settingsLicensing = data;
            });

        this.communicationCenter.getRoom('licenses')
            .getSubject('methods')
            .subscribe((entities: DataEntity[]) => {
                this.learnerMethods = entities.filter((entity: DataEntity) => !!entity.get('access'))
                    .map((entity) => entity.attributes.access.id);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('getAssignmentsByLesson$')
            .pipe(
                tap(({lessonId, onComplete}: { lessonId: string | number, onComplete: Subject<Observable<DataCollection>> }) =>
                    onComplete.next(this.loadAndGetAssignmentByLessonId(lessonId)))
            ).subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('deleteLessonAssignments$')
            .pipe(
                tap(({lessonId, onComplete}: { lessonId: string | number, onComplete: Subject<Observable<boolean[]>> }) =>
                    onComplete.next(this.deleteLessonAssignments(lessonId))
                )
            ).subscribe();

        this.communicationCenter
            .getRoom('assignment')
            .next('loadPaginatedAssignmentsCallback', (filterOptions) => {
                return this.octopusConnect.loadCollection('assignation_search', filterOptions);
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('saveSubLessonProgress')
            .pipe(
                tap((assignmentProgressData: {id: string, score: number}) => {
                    this.saveProgressInSubLesson(assignmentProgressData);
                })
            )
            .subscribe();
    }

    loadPaginatedAssignmentGroupList(filterOptions = {}, getForDashboard = false): void {
        if (getForDashboard) {
            this.loadAssignationsTypes().pipe(take(1)).subscribe((data) => {
                this.types = data;
                const trainingType = this.types.find((state) => state.label === 'training');
                const homeworkType = this.types.find((state) => state.label === 'homework');
                const assessmentType = this.types.find((state) => state.label === 'assessment');

                const optionsForAssessment: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: assessmentType ? assessmentType.id : null
                    },
                    orderOptions: [{
                        field: 'endDate',
                        direction: OrderDirection.ASC
                    }]
                };

                const optionsForHomeworkType: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: homeworkType ? homeworkType.id : null
                    },
                    orderOptions: [{
                        field: 'endDate',
                        direction: OrderDirection.ASC
                    }]
                };
                const optionsForTraining: CollectionOptionsInterface = {
                    filter: {
                        inprogress: 1,
                        type_term: trainingType ? trainingType.id : null
                    },
                    orderOptions: [{
                        field: 'startDate',
                        direction: OrderDirection.DESC
                    }]
                };

                if (filterOptions && filterOptions['filter'] && filterOptions['filter']['group']) {
                    optionsForAssessment.filter.group = filterOptions['filter']['group'];
                    optionsForHomeworkType.filter.group = filterOptions['filter']['group'];
                    optionsForTraining.filter.group = filterOptions['filter']['group'];
                }

                const obsTraining = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForTraining)
                    .collectionObservable;
                const obsAssessment = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForHomeworkType)
                    .collectionObservable;
                const obsHomework = this.octopusConnect
                    .paginatedLoadCollection('assignations-group', optionsForAssessment)
                    .collectionObservable;

                combineLatest([obsAssessment, obsHomework, obsTraining])
                    .subscribe((data: [any, any, any]) => {
                        const assignmentsGroupSort = data[0].entities.concat(...data[1].entities).sort((a, b) => a.get('endDate') - b.get('endDate'));
                        assignmentsGroupSort.push(...data[2].entities);

                        this.assignmentsGroup = assignmentsGroupSort.slice();
                        this.communicationCenter
                            .getRoom('assignation')
                            .next('assignmentsGroup', {
                                assignmentsGroup: this.assignmentsGroup,
                                callback: (options, forDashboard) => this.loadPaginatedAssignmentGroupList(options, forDashboard)
                            });
                    });
            });

        } else {
            this.assignmentGroupListPaginated = this.octopusConnect.paginatedLoadCollection('assignations-group', filterOptions);
            this.assignmentsGroupListPaginatedObs = this.assignmentGroupListPaginated.collectionObservable.pipe(map(collection => collection.entities));

            this.assignmentsGroupListPaginatedObs.subscribe((assignmentGroupList: DataEntity[]) => {
                this.assignmentsGroup = assignmentGroupList.slice();
                this.communicationCenter
                    .getRoom('assignation')
                    .next('assignmentsGroup', {assignmentsGroup: this.assignmentsGroup, callback: (options) => this.loadPaginatedAssignmentGroupList(options)});
            });
        }
    }

    loadAssignments(): void {
        const assignedState = this.states.find((state) => state.get('label') === 'assigned');
        const trainingType = this.types.find((state) => state.label === 'training');
        const assessmentType = this.types.find((state) => state.label === 'assessment');
        const homeworkType = this.types.find((state) => state.label === 'homework');
        const optsTraining = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: trainingType.id
            }
        };
        const optsAssessment = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: assessmentType.id
            }
        };
        const optsHomework = {
            filter: {
                'assignated_user': this.authService.userData.id,
                assignments_state: assignedState ? assignedState.id : null,
                assignments_type: homeworkType.id
            }
        };

        const obsTraining = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsTraining)
            .collectionObservable;
        const obsAssessment = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsAssessment)
            .collectionObservable;
        const obsHomework = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optsHomework)
            .collectionObservable;

        combineLatest([obsAssessment, obsHomework, obsTraining])
            .subscribe((data: [any, any, any]) => {
                let assignments = data[0].entities.concat(...data[1].entities, ...data[2].entities);
                const dateNow = Math.floor(Date.now() / 1000);

                assignments = assignments.filter((assignment) => {
                    const startDate = +assignment.get('dates').value;
                    const endDate = +assignment.get('dates').value2;

                    return ((startDate && startDate <= dateNow) || !startDate) && ((endDate && endDate > dateNow) || !endDate);
                });
                this._dataSource.data = assignments.slice(0, 3);
            });
    }

    private postLogout(): void {
        if (this.followedSubscription) {
            this.followedSubscription.unsubscribe();
            this.followedSubscription = null;
        }
    }

    private postAuthentication(): void {
        const learners = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('learnerList');
        const groups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('groupsList');
        const workgroups = this.communicationCenter
            .getRoom('groups-management')
            .getSubject('workgroupsList');
        const project = this.communicationCenter
            .getRoom('project-management')
            .getSubject('selectedProject');

        project.subscribe((selectedProject) => {
            this.selectedProject = selectedProject;
        });

        combineLatest(learners, groups, workgroups).subscribe((data: [any, any, any]) => {
            this.rawlearnersList = data[0];
            this.rawGroupsList = data[1];
            this.rawWorkgroupsList = data[2];
        });

        this.loadAssignationsStates()
            .subscribe((states: DataEntity[]) => {
                this.states = states;
                this.communicationCenter.getRoom('assignments').next('statesList', this.states);
                this.loadAssignationsTypes().subscribe((data) => {
                    this.types = data;
                    if (this.authService.isLearner()) {
                        this.loadAssignmentInit()
                            .subscribe((initAssignment) => {
                                this.initAssignment = initAssignment && initAssignment[0] ? initAssignment[0] : null;
                            });
                        this.loadAssignments();

                    }
                });
                this.loadAssignmentsByRole();

            });
    }

    public get learnersList(): any[] {
        let filteredLearners = this.rawlearnersList;

        if (this.settings.useSchoolYearFilter) {
            filteredLearners = filteredLearners.filter((learner) => !!learner.schoolyear_term && learner.schoolyear_term.name === this.currentSchoolYearBegin.toString());
        }
        return filteredLearners;
    }

    public get groupsList(): any[] {
        let filteredGroups = this.rawGroupsList.filter((group) => !group.archived);
        if (this.selectedProject) {
            filteredGroups = filteredGroups.filter((group) => group.projects.find((project) => +project === +this.selectedProject.id));
        }
        if (this.settings.useSchoolYearFilter) {
            filteredGroups = filteredGroups.filter((group) => group.schoolyear_term && group.schoolyear_term['name'] === this.currentSchoolYearBegin.toString());
        }
        return filteredGroups;
    }

    public get workgroupsList(): any[] {
        let filteredGroups = this.rawWorkgroupsList.filter((group) => !group.archived);
        if (this.selectedProject) {
            filteredGroups = filteredGroups.filter((group) => group.projects.find((project) => +project === +this.selectedProject.id));
        }
        if (this.settings.useSchoolYearFilter) {
            filteredGroups = filteredGroups.filter((group) => group.schoolyear_term && group.schoolyear_term['name'] === this.currentSchoolYearBegin.toString());
        }
        return filteredGroups;
    }


    loadPaginatedAssignments(filterOptions: CollectionOptionsInterface = {}): Observable<Object[]> {
        this.assignmentsPaginated = this.octopusConnect.paginatedLoadCollection('assignation_search', filterOptions);
        this.assignmentsPaginatedObs = this.assignmentsPaginated.collectionObservable.pipe(map(collection => collection.entities));
        return this.assignmentsPaginatedObs;
    }


    loadAssignmentInit(): Observable<DataEntity[]> {
        const typeTermInit = this.types.find((type) => type.label === 'init');
        const optionInterface: CollectionOptionsInterface = {
            filter: {
                ['assignments_type']: typeTermInit ? typeTermInit.id : null,
                ['assignated_user']: this.userData.id
            }
        };
        const obs = this.octopusConnect
            .paginatedLoadCollection('assignation_search', optionInterface);
        return obs.collectionObservable.pipe(map(collection => collection.entities));
    }


    loadAssignmentsByRole(): any {
        if (this.authService.isAtLeastTrainer()) {
            const pendingState = this.states.find((state) => state.get('label') === 'pending');
            const options = {filter: {'assignator': this.userData.id, assignments_state: pendingState ? pendingState.id : null}};

            const obs = this.octopusConnect
                .paginatedLoadCollection('assignation_search', options)
                .collectionObservable;

            obs.subscribe((data) => {
                this.assignmentCollection = data;
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('assignmentList')
                    .next(data.entities);
            });
        } else {
            const assignedState = this.states.find((state) => state.get('label') === 'assigned');
            const closedState = this.states.find((state) => state.get('label') === 'closed');

            const optsAssigned = {filter: {'assignated_user': this.userData.id, assignments_state: assignedState ? assignedState.id : null}};
            const optsClosed = {filter: {'assignated_user': this.userData.id, assignments_state: closedState ? closedState.id : null}};

            const obsAssigned = this.octopusConnect
                .paginatedLoadCollection('assignation_search', optsAssigned)
                .collectionObservable;
            const obsClosed = this.octopusConnect
                .paginatedLoadCollection('assignation_search', optsClosed)
                .collectionObservable;

            combineLatest([obsAssigned, obsClosed]).subscribe((data: [any, any]) => {
                this.communicationCenter
                    .getRoom('assignment')
                    .getSubject('assignmentList')
                    .next(data);
            });
        }
    }

    /**
     * launchAssignement if user is allowed to open lesson and return true or false
     * @param assignment : use to get info on lesson assigned to compare with licence
     * @param reloadAssignmentBefore : if true, assignment will be reload before the launch
     */
    public launchAssignment(assignment: DataEntity, reloadAssignmentBefore = false): Observable<boolean> {
        const obs = reloadAssignmentBefore ? this.loadAndGetAssignmentById(assignment.id) : of(assignment);
        return obs.pipe(
            map(mayBeLoadedAssignment => {
                if (this.isAllowedToOpenLesson(mayBeLoadedAssignment)) {
                    this.currentAssignment = mayBeLoadedAssignment;
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('current', mayBeLoadedAssignment);

                    this.communicationCenter
                        .getRoom('assignation')
                        .next('event', {id: mayBeLoadedAssignment.id, event: 'start'});

                    return true;
                } else {
                    return false;
                }
            })
        );
    }

    /**
     * launch the selected assignmznt
     * @param assignment Dataentity
     * @private
     */
    public launchAssignmentInPlayer(data: {assignment: DataEntity, startOnStepIndex: string}): void {
        if (!this.assignmentDisabled(data.assignment)) {
            // mono assignement
            const assignated_node = data.assignment.get('assignated_node');
            // multi assignement
            const assignated_nodes = data.assignment.get('assignated_nodes');
            let type = '';
            let idLesson = '';
            if (assignated_node === null && assignated_nodes.length > 0) {
                // in case of multi assignation we always take the info of the first assignation to launch
                // in a future us we will take the assignation where user stop before if he begin and stop before end
                type = assignated_nodes[0].type;
                idLesson = assignated_nodes[0].id;
            } else if (assignated_node !== null) {
                type = assignated_node.type;
                idLesson = assignated_node.id;
            } else {
                console.error('fol-334 : error because assignated_node is null and assignated_nodes.length <=0');
                return;
            }

            const route = ['followed', 'assignment'];
            switch (type) {
                case 'form':
                    route.push('forms');
                    break;
                case 'lesson':
                    route.push('lessons');
                    break;
            }

            if (route.length) {
                route.push(idLesson);
            }

            route.push('player');

            const valid = this.dateIsValid(data.assignment.get('state'), data.assignment.get('type_term'), data.assignment.get('dates'));
            if (valid && valid.available) {
                this.launchAssignment(data.assignment, true).pipe(
                    filter(isAllowed => !!isAllowed),
                    tap(() => {
                        let flaggingId;
                        // add value to flaggingId to patch only if current user = flag user
                        if (data.assignment.get('consulted') && data.assignment.get('consulted').consulted_bool && data.assignment.get('consulted').uid === this.userData.id) {
                            flaggingId = data.assignment.get('consulted').flagging_id;
                        }
                        // create or edit flag only if current user is assignated user
                        if (data.assignment.get('assignated_user').uid === this.userData.id) {
                            this.flagService.updateFlagEntity(data.assignment, 'assignations', 'consulted_assignation', flaggingId);
                        }

                        this.router.navigate(route, {relativeTo: this.route, queryParams: {startOnStepIndex: data.startOnStepIndex}});
                    }),
                    take(1)
                ).pipe(takeUntil(this.unsubscribeInTakeUntil)).subscribe();
            }
        }
    }

    public dateIsValid(state, type, date): any {
        const startDate = date ? date.value : null;
        const endDate = date ? date.value2 : null;
        const dateNow = Math.floor(Date.now() / 1000);

        if (type && this.hasCompletionDate(type.label)) {
            if ((startDate && startDate < dateNow) && (endDate && endDate > dateNow)) {
                if (state === 'closed') {
                    return {
                        state: 'valid',
                        available: true,
                    };
                }
            } else if ((startDate && startDate > dateNow) && (endDate && endDate > dateNow)) {
                return {
                    state: 'assigned',
                    available: false,
                };
            } else if (endDate && endDate < dateNow) {
                return {
                    state: 'correct',
                    available: true,
                };
            }
        }

        if (startDate && startDate > dateNow && type.label === 'training') {
            return {
                state: 'assigned',
                available: false,
            };
        }

        return {
            state: 'available',
            available: true,
        };
    }

    public assignmentDisabled(assignment): boolean {
        return this.authService.isLearner() && assignment && this.settings.initAssignment &&
            this.initAssignment && +assignment.id !== +this.initAssignment.id &&
            !this.assignmentInitIsFinished();
    }

    private assignmentInitIsFinished(): boolean {
        return this.initAssignment && this.checkState(this.initAssignment) === 'closed';
    }

    public checkState(assignment): string {
        const state = (assignment.get('state_term') && assignment.get('state_term').label) || assignment.get('state');

        if (!this.stateFromDate) {
            return state;
        } else {
            if (assignment) {
                const valid = this.dateIsValid(state, assignment.get('type_term'), assignment.get('dates'));
                if (valid) {
                    return valid.state;
                }

            }

            return 'assigned';
        }
    }

    public get stateFromDate(): boolean {
        return this.settings.getStateFromDate;
    }

    loadAndGetAssignmentById(assignmentId: string | number): Observable<DataEntity> {
        return this.octopusConnect.loadEntity('assignation_search', +assignmentId);
    }

    private queueSave(assignment: DataEntity): void {
        if (!this.saveQueue[assignment.id]) {
            this.saveQueue[assignment.id] = assignment;
        } else {
            const queuedAssignment = this.saveQueue[assignment.id];
            const diff = assignment.getDiff();

            for (const key in diff) {
                queuedAssignment.set(key, diff[key]);
            }
        }
    }

    private saveAssignment(assignment: DataEntity): void {
        const entity = _.cloneDeep(assignment);
        entity.type = 'assignations';
        this.savePending[entity.id] = entity.save();
        this.communicationCenter
            .getRoom('assignment')
            .next('saving', true);
        this.savePending[entity.id].pipe(take(1)).subscribe((save: DataEntity) => {
            delete this.savePending[entity.id];
            let queued;

            if (this.saveQueue[save.id]) {
                queued = this.saveQueue[save.id];
                delete this.saveQueue[save.id];

                const diff = queued.getDiff();
                for (const key in diff) {
                    save.set(key, diff[key]);
                }
            }

            this.communicationCenter
                .getRoom('assignment')
                .next('current', save);
            this.communicationCenter
                .getRoom('assignment')
                .next('savingDone', true);
            this.communicationCenter
                .getRoom('assignment')
                .next('saving', false);

            if (queued) {
                this.saveAssignment(queued);
            }
        });

        this.communicationCenter // refresh coins and level
            .getRoom('gamification')
            .getSubject('refreshAdvancementData')
            .next(true);
    }

    openDialog(entity: any, checkBoxesList: QueryList<MatCheckbox> = null): void {

        // get translation
        this.translate.get('generic.yes').subscribe((translation: string) => this.dialogYes = translation);
        this.translate.get('generic.cancel').subscribe((translation: string) => this.dialogCancel = translation);
        this.translate.get('generic.delete').subscribe((translation: string) => this.dialogTitle = translation);

        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: this.dialogTitle,
        };

        const checkboxes = document.getElementsByName('corpusCheckboxe');
        const checkboxesChecked = [];
        // loop over them all
        for (this.i = 0; this.i < checkboxes.length; this.i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[this.i]['checked']) {
                checkboxesChecked.push(checkboxes[this.i].id.replace('-input', ''));
            }
        }

        // Return the array if it is non-empty, or null
        // return checkboxesChecked.length > 0 ? checkboxesChecked : null;

        if (entity !== 'multiple') { // for 1 entity
            this.translate.get('generic.confim_delete_single_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
            dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
            dialogConfig.data.labelTrueDialog = this.dialogYes;
            dialogConfig.data.labelFalseDialog = this.dialogCancel;

            const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

            dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                    entity.remove();
                }
            });

        } else { // for 1 or multiple entities
            if (checkboxesChecked.length > 0) {
                this.translate.get('generic.confim_delete_multiple_files').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;
                dialogConfig.data.labelTrueDialog = this.dialogYes;
                dialogConfig.data.labelFalseDialog = this.dialogCancel;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);

                dialogRef.afterClosed().subscribe(result => {
                    if (result === true) {
                        for (this.i = 0; this.i < checkboxesChecked.length; this.i++) {
                            this.octopusConnect.loadEntity('node', checkboxesChecked[this.i]).pipe(take(1)).subscribe((nodeEntity: DataEntity) => nodeEntity.remove());
                        }
                    }
                });

            } else { // no checked checkbox
                this.translate.get('generic.confim_action_no_file').subscribe((translation: string) => this.dialogDeleteMessage = translation);
                dialogConfig.data.bodyDialog = this.dialogDeleteMessage;

                const dialogRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
            }
        }
    }

    // generic function to get the exact object from the nestedObject.
    getPropertyFromNestedObject(mainObject: Object, pathToAttribute: string[]): any {
        return pathToAttribute.reduce((obj, key) =>
            (obj && obj[key] !== 'undefined') ? obj[key] : undefined, mainObject);
    }

    public hasCompletionDate(type: string): boolean {
        return this.settings.hasCompletionDate
            && (!this.settings.completionDate.length
                || (this.settings.completionDate.length
                    && (
                        this.settings.completionDate.includes(type)
                        || this.settings.completionDate.includes('*')
                    )
                )
            );
    }

    public hasCompletionStartDateOnly(type: string): boolean {
        return this.settings.hasCompletionDate
            && this.settings.completionStartDateOnly.length
            && (
                this.settings.completionStartDateOnly.includes(type)
                || this.settings.completionStartDateOnly.includes('*')
            );
    }

    public hasCompletionTime(type: string): boolean {
        return this.settings.hasCompletionDate
            && this.settings.hasCompletionTime
            && (!this.settings.completionTime.length
                || (this.settings.completionTime.length
                    && (
                        this.settings.completionTime.includes(type)
                        || this.settings.completionTime.includes('*')
                    )));
    }

    public hasGrade(type: string): boolean {
        return this.settings.enableGradeType.length
            && this.settings.enableGradeType.includes(type);
    }

    /**
     * create assignement using communication center with a callback when finish
     * @param dontUseSpecificConfiguration: in createAssignment we use lot of settings and we dont want those settings for creation of an assignment
     */
    private createAssignmentWithCallBack(options: {
        assigment: {[key: string]: any},
        callback: (arg?) => any,
        dontUseSpecificConfiguration?: boolean}, dontUseSpecificConfiguration?: boolean): void {
        if (options && options.dontUseSpecificConfiguration) {
            combineLatest(options.assigment.map((assignment) => this.createEntityAssignment(assignment))).pipe(
                tap(() => options.callback())
            ).subscribe();
        } else {
            this.createAssignment(options.assigment).subscribe(assignments => {
                options.callback(assignments[0]);
            });
        }
    }

    /**
     * create assignment entity with data provided
     * @param data
     */
    public createEntityAssignment(data): Observable<DataEntity> {
        return this.octopusConnect.createEntity('assignations', data).pipe(take(1));
    }

    public createAssignment(assignment): Observable<DataEntity[]> {
        const project = assignment.project;
        let startTime = 0;
        let dueTime = 0;

        const assignementType = this.settings.hasType ? assignment.type.label : null;
        if (this.hasCompletionDate(assignementType) || this.hasCompletionStartDateOnly(assignementType)) {
            const startMoment = assignment.startDate;
            const dueMoment = assignment.dueDate;

            if (this.hasCompletionTime(assignementType)) {
                const start = assignment.startTime.split(':').map(element => +element);
                startMoment.add(start[0], 'hours');
                startMoment.add(start[1], 'minutes');

                const due = assignment.dueTime.split(':').map(element => +element);
                dueMoment.add(due[0], 'hours');
                dueMoment.add(due[1], 'minutes');
            } else {
                if (this.hasCompletionDate(assignementType)) {
                    dueMoment.add(23, 'hours');
                    dueMoment.add(59, 'minutes');
                }
            }

            startTime = Math.floor(new Date(startMoment).getTime() / 1000);
            if (this.hasCompletionDate(assignementType)) {
                dueTime = Math.floor(new Date(dueMoment).getTime() / 1000);
            }
        }

        const assignations = [];
        const obs: Observable<DataEntity>[] = [];
        assignment.learners.forEach((learner) => {

            const assignmentObs = this.createEntityAssignment({
                assignator: +this.userData.id,
                assignated_user: learner.id,
                assignated_node: assignment.nodeId,
                dates: {
                    value: startTime,
                    value2: dueTime
                },
                state_term: this.getState('assigned'),
                type_term: this.settings.hasType ? +assignment.type.id : null,
                rating_base: this.settings.hasType ? assignment.rating_base : null,
                assignatedCount: assignment.assignatedCount ? assignment.assignatedCount : null,
                comment: assignment.comment ? assignment.comment : null
            });

            assignmentObs.subscribe((assignmentEntity: DataEntity) => {
                assignations.push(assignmentEntity.id);
                this.sendNotificationAfterCreateAssignment(assignmentEntity.get('assignated_node'), assignmentEntity, learner, project);
            });

            obs.push(assignmentObs);
        });

        const dataEntityArrayObs = combineLatest(...obs);

        dataEntityArrayObs.subscribe(dataArray => {
            this.octopusConnect.createEntity('assignations-group', {
                assignations,
                dates: {
                    value: startTime,
                    value2: dueTime
                },
                date: dueTime,
                type_term: this.settings.hasType ? +assignment.type.id : null,
                rating_base: this.settings.hasType ? assignment.rating_base : null,
                group: assignment.group.map(g => g.id)
            }).pipe(take(1));
        });

        return dataEntityArrayObs;
    }

    /**
     * send notification to learner after assignment created.
     * @private
     */
    private sendNotificationAfterCreateAssignment(assignated_node, assignment, learner, project): void {
        this.communicationCenter
            .getRoom('notifications')
            .next('sendNotification', {
                recipient: learner.id,
                type: 'NEW_ASSIGNATION',
                content: {
                    author: this.userData ? this.userData.get('label') : '',
                    id: assignment.id,
                    formName: assignated_node.title,
                    project: project ? project.id : null
                }
            });
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }

    /**
     * return the scholar year
     */
    public schoolYears(startDate: number): string {
        const date = new Date(startDate * 1000);
        // year begin 1er août and finish 31 juillet
        const month = date.getMonth();
        const year = date.getFullYear();
        let yearBegin: number = null;
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            yearBegin = year;
        }
        // december to aout exclude
        if (month < 7) {
            yearBegin = year - 1;
        }
        return yearBegin + '-' + (yearBegin + 1);
    }

    public get currentSchoolYearBegin(): string {
        // year begin 1er août and finish 31 juillet
        const month = (new Date()).getMonth();
        const year = (new Date()).getFullYear();
        // 1 aout => 31 december
        if (month > 6 && month < 12) {
            return year.toString();
        }
        // december to aout exclude
        if (month < 7) {
            return (year - 1).toString();
        }
    }


    /**
     *
     * @param date
     * @returns {string}
     */
    localeTime(date: number): string {
        return localizedTime(date);
    }

    loadAssignationsTypes(): Observable<any[]> {
        return this.octopusConnect.loadCollection('variables/instance').pipe(
            map((collection: DataCollection) => collection.entities[0].get('assignationTypes')),
            take(1), );
    }

    loadAssignationsStates(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('assignation_state').pipe(
            map((collection: DataCollection) => collection.entities),
            take(1), );
    }

    private getState(stateName: string): number {
        return +(this.states.find((state: DataEntity) => state.get('label') === stateName).id);
    }

    /***
     * check if user have the paper method licence
     * @param entity : use to get info on lesson assigned to compare with licence
     */
    public isAllowedToOpenLesson(entity: DataEntity): boolean {
        const assignated_node = entity.get('assignated_node');
        const assignated_nodes = entity.get('assignated_nodes');
        // multiassignement x lesson to check
        if (assignated_node === null && assignated_nodes.length > 0) {
            return this.isAllLessonsAllowed(assignated_nodes);
        }
        // mono assignment one lesson to check
        return this.isLessonAllowed(assignated_node);
    }

    /**
     * check if all lessons in multiassignement are allowed
     * @param assignated_nodes : array of assignated node with id title and type
     */
    private isAllLessonsAllowed(assignated_nodes: any[]): boolean {
        for (let i = 0; i < assignated_nodes.length; i++) {
            if (!this.isLessonAllowed(assignated_nodes[i])) {
                // one or more lesson are not allowed => exit for all
                return false;
            }
        }
        // all lesson are allowed return true
        return true;
    }

    /**
     * check if licencing is activate for this assignated_node
     * if it is check if user has the right to open it
     * @param assignated_node : assignated_node with id type title
     */
    private isLessonAllowed(assignated_node): boolean {
        if (this.settingsLicensing['restrict'].includes(assignated_node.type)) {
            if (assignated_node.chapters.length === 0) {
                throw new Error('Not implemented : assigned node must have chapters');
                return false;
            } else if (!this.learnerMethods.includes(assignated_node.chapters[0].id)) {
                this.openMethodModalRedirect(assignated_node.chapters[0].label);
                return false;
            }
        }
        return true;
    }

    private openMethodModalRedirect(methodName: string): void {
        this.setTranslateValueForMethodModalRedirect();
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            titleDialog: this.methodDialogInfos.title,
            bodyDialog: this.methodDialogInfos.body + methodName,
            labelTrueDialog: this.methodDialogInfos.ok
        };
        const dialogRedirect = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        dialogRedirect.afterClosed().subscribe(() => {
            this.router.navigate(['profile/licensing']);
        });
    }

    private setTranslateValueForMethodModalRedirect(): void {
        this.translate.get('assignment.method_modal.title').subscribe((translation: string) => this.methodDialogInfos.title = translation);
        this.translate.get('assignment.method_modal.body').subscribe((translation: string) => this.methodDialogInfos.body = translation);
        this.translate.get('assignment.method_modal.button.ok').subscribe((translation: string) => this.methodDialogInfos.ok = translation);
    }

    /**
     * Obtain list of educational levels
     */
    public getEducationalLevels(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('educational_level').pipe(map(collection => collection.entities));
    }

    /**
     * Obtain allowed actions like 'edit' or 'unassign' for current user or default
     */
    public getAllowedActions(): string[] {
        const allowedActions = this.settings.actions[(this.settings.actions[this.authService.accessLevel] ? this.authService.accessLevel : 'default')];
        return isArray(allowedActions) ? allowedActions : [];
    }

    public deleteAssignment(assignment: DataEntity): Observable<boolean> {
        const entity = new DataEntity('assignations', assignment.attributes, this.octopusConnect, assignment.id);
        return entity.remove().pipe(
            filter(success => success),
            tap(() => this.communicationCenter
                .getRoom('assignment')
                .next('unassign', assignment.get('assignated_node'))
            )
        );
    }

    public loadAndGetAssignmentByLessonId(lessonId: string | number): Observable<DataCollection> {
        return this.octopusConnect.loadCollection('assignation_search', {assignated_lesson_id: lessonId});
    }

    private deleteLessonAssignments(lessonId: string | number): Observable<boolean[]> {
        return this.loadAndGetAssignmentByLessonId(lessonId).pipe(
            take(1),
            mergeMap(dataCollection => combineLatest(
                dataCollection.entities.map(
                    entity => this.deleteAssignment(entity)
                )
            ))
        );
    }

    loadSchoolyears(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('schoolyears').pipe(
            map(collection => collection.entities),
            take(1)
        );
    }

    public displaySchoolYear(schoolyear: string): string {
        const nextSchoolyear = +schoolyear + 1;
        return schoolyear + '-' + nextSchoolyear;
    }

    public translationTabLabelListByRole(): string {
        return this.authService.isAtLeastTrainer() ? 'assignment.followed_data_trainer' : 'assignment.followed_data' ;
    }
    public translationTabLabelLogBookByRole(): string {
        return this.authService.isAtLeastTrainer() ? 'assignment.followed_diary_trainer' : 'assignment.followed_diary' ;
    }

    private saveProgressInSubLesson(assignmentProgressData: { id: string; score: number }): void {
        if (this.currentAssignment) {
            const config = JSON.parse(this.currentAssignment.get('config')) || {};
            config[assignmentProgressData.id] = +assignmentProgressData.score;
            this.currentAssignment.set('changed', Math.floor(new Date().getTime() / 1000));
            this.currentAssignment.set('config', JSON.stringify(config));
            if (this.savePending[this.currentAssignment.id]) {
                this.queueSave(this.currentAssignment);
            } else {
                this.saveAssignment(this.currentAssignment);
            }
        }
    }
}
