<div fxLayout="column" class="page-layout simple fullwidth inner-scroll" fxFlex>
    <div class="content">

        <mat-card class="short-answer activities-card mat-elevation-z0" [ngClass]="{'two-columns-layout': isTwoColumns}">

            <mat-card-header>
                <mat-card-title>
                    <app-consignes
                        class="centeredContent"
                        (isReading)="ttsReadingEvent($event)"
                        [wording]="wording">
                    </app-consignes>
                </mat-card-title>
            </mat-card-header>

            <mat-card-content [ngClass]="{'instruction-is-picture':instruction?.includes('<img')}">
                <app-consignes
                    [read]="readSecondConsigne"
                    [autoRead]="false"
                    class="centeredContent"
                    [class.isWordingImage]="isWordingImage"
                    [instruction]="instruction"
                    questionTypeName="{{ 'activities.questionTypeName.app-short-answer' | translate }}">
                </app-consignes>

                <textarea *ngIf="!isPoll && !testAnswer && !validated && !displaySolution && !isFormula && !disabled"
                          [disabled]="disable || isSaving"
                          class="responseTextarea"
                          type="text"
                          placeholder="{{'activities.placeholder_reply_here' | translate}}"
                          [(ngModel)]="reply.answer"
                          (ngModelChange)="onNgModelChange($event)">
                </textarea>

                <app-poll *ngIf="isPoll" [value]="reply.answer" [assignatedCount]="assignatedCount"
                          (moveSlider)="onMoveSlider($event)"
                          (clickSlider)="onClickSlider($event)"></app-poll>

                <div class="latex-wrapper"
                     [ngClass]="[isFormula && !(displaySolution && answerFeedback) ? '' : 'hide']">
                    <span class="correction" [ngClass]="answerState()"></span>
                    <app-keyboard-latex-field
                        *ngFor="let reference of generateArrayToIterateAndDisplayKeyboard"
                        [symbols]="symbols"
                        [optionsFromSettings]="optionsFromSettings"
                        [blacklistTabs]="blacklistTabs"
                        [disable]="disable"
                        [answerCorrected]="answerCorrected"
                        (clickAnswer)="clickAnswer($event)"
                        (doesUserResponded)="doesUserResponded($event)"
                        (onOptionChange)="onOptionChange($event)"
                        (onKeyboardReady)="onKeyboardReady($event)"
                    ></app-keyboard-latex-field>
                </div>

                <div *ngIf="!isPoll && (testAnswer || validated || disabled) && (!displaySolution || !answerFeedback) && !isFormula"
                     class="divResponseTextarea"
                     [ngClass]="answerState()">
                    <span *ngIf="!displaySolution">{{reply.answer}}</span>
                    <span *ngIf="displaySolution">{{options[0].answer}}</span>
                </div>
                <ng-content></ng-content>
                <div *ngIf="!isPoll && displaySolution && answerFeedback" [innerHTML]="answerFeedback | stringToHtml"></div>
            </mat-card-content>

            <div *ngIf="!isPoll && displayFeedback" class="answer-state-msg wrongreply">
                <p>{{ 'activities.feedback.try_correct' | translate }}</p>
            </div>

            <mat-card-footer>
                <div class="gradePercentil"
                     *ngIf="isPercentileDisplayAllowed">{{ 'generic.score' | translate}}{{showPercentile}}</div>

                <ng-container *ngFor="let button of buttons">
                    <button
                        *ngIf="button.display"
                        mat-raised-button
                        [disabled]="button.disable"
                        (click)="onAction(button)">
                        <mat-icon *ngIf="button.svgIcon" [svgIcon]="button.svgIcon"></mat-icon>
                        <span>{{ button.title | translate }}</span>
                    </button>
                </ng-container>
            </mat-card-footer>
        </mat-card>

        <div fxFill fxLayoutAlign="center center" *ngIf="isLessonWithStep && isSaving">
            <mat-spinner></mat-spinner>
        </div>

    </div>
</div>