import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {LessonsService} from '@modules/activities/core/lessons/lessons.service';

@Component({
    templateUrl: 'only-lesson-lessons-list.component.html',
    selector: 'app-only-lesson-lessons-list',
    encapsulation: ViewEncapsulation.None,
})
export class OnlyLessonLessonsListComponent implements OnInit, OnDestroy {
    constructor(public lessonsService: LessonsService) {}

    ngOnInit(): any {
        if (this.lessonsService.isUserManager) {
            this.lessonsService.isShareableModel = !!this.lessonsService.settings.shareableModel;
        } else {
            this.lessonsService.isShareableCommunity = true;
        }
    }

    ngOnDestroy(): any {
        this.lessonsService.isShareableCommunity = false;
        this.lessonsService.isShareableModel = false;
    }
}

