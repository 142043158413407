<div class="dialog-content-wrapper">

    <div mat-dialog-title>
        <h2 translate>{{'generic.' + type + '_filter_title' | translate}}</h2>
        <button mat-mini-fab
                class="close"
                (click)="dialogRef.close()"
                aria-label="Close dialog">
            <mat-icon svgIcon="cross"></mat-icon>
        </button>
    </div>

    <div mat-dialog-content class="p-24 m-0">
        <app-tags-selection-core [type]="type"
                                 [dataSource]="dataSource"
                                 [displayedColumns]="displayedColumns"
                                 [tagsSelected]="tagsSelected"
                                 [titleFilter]="titleFilter"></app-tags-selection-core>
    </div>

    <div mat-dialog-actions>

        <button mat-raised-button
                (click)="dialogRef.close(tagsSelected)"
                class="save-button mat-accent"
                aria-label="SAVE">
            {{ 'generic.validate' | translate }}
        </button>

    </div>
</div>
