import {Component} from '@angular/core';
import {
    BaseActivityComponent
} from '@modules/activities/core/player-components/base-activity.component';
import {AnswerResultInterface} from '@modules/activities/core/models/answer-result.interface';
import {ActivityReferenceInterface} from '@modules/activities/core/models/activity-reference.interface';
import {shuffle} from '../../../../../shared/utils';
import {Observable} from 'rxjs/index';
import {of} from 'rxjs';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {ItemAnswerInterface} from '@modules/activities/core/models/item-answer.interface';
import {answerStatusEnum} from '@modules/activities/core/models/answer-status.enum';

// format de l'activity_content
interface OrderingMatchingActivityContentInterface {
    answers: ItemAnswerInterface[];
}

// format du champ config de l'activité
interface OrderingMatchingActivityConfigInterface {
    direction: string;
    doubleColumn: number;
    type?: string;
}

// format du champ référence de l'activité
type OrderingMatchingActivityReferenceInterface = ActivityReferenceInterface<OrderingMatchingActivityContentInterface, OrderingMatchingActivityConfigInterface>;

@Component({
    selector: 'app-order-matching',
    templateUrl: './order-matching.component.html',
    styleUrls: ['./order-matching.component.scss']
})

export class OrderMatchingComponent extends BaseActivityComponent<any, any> {
    public availableAnswers: ItemAnswerInterface[] = []; // réponses disponible à sélectionner.
    private answersOrderToGuess: ItemAnswerInterface[] = []; // réponses dans le bon ordre à deviner.
    private referenceActivityGranule: OrderingMatchingActivityReferenceInterface; // réference de l'activité (contient l'instruction, la config, contenu, etc.. de l'activité
    private cacheForDisplaySolution: ItemAnswerInterface[] = []; // sert à afficher les réponse de l'utilisateur si il regarde la solution de l'activité.
    public disableAllAnswers: boolean;
    public isOrderSentence = false; // in some case the order is a sentence in this case design must differ of just a standart order
    /**
     * renseigne les variables de l'activité, les réponses, la consigne la sauvegarde etc..
     * @param activityAttributes attributs d'une dataEntity
     * @private
     */
    protected setContentData(activityAttributes): void {
        if (activityAttributes.reference.config) {
            this.isTwoColumns = (activityAttributes.reference.config.direction === 'vertical');
            this.isOrderSentence = (activityAttributes.reference.config?.type === 'orderSentence');
        }
        this.referenceActivityGranule = activityAttributes.reference;
        this.instruction = this.referenceActivityGranule.instruction;
        this.instructionAudio = this.referenceActivityGranule.instructionAudio;
        this.wording = this.referenceActivityGranule.wording;
        this.wordingAudio = this.referenceActivityGranule.wordingAudio;
        this.answersOrderToGuess = this.referenceActivityGranule.activity_content.answers;
        this.setDefaultAnswersSelected();
        this.makeItShuffled();
        this.loadUserSave();
    }

    /**
     * calcule et retourne la note obtenue en fonction des bonnes et mauvaises reponses de l'activité
     * @private
     */
    protected getGrade(): { oldGrade: number, newGrade: number } {
        let countFromUserSave = 0;
        let count = 0;
        this.answersOrderToGuess.forEach((answerToGuess: ItemAnswerInterface, index: number) => {
            const answerSaved = this.userSave && this.userSave.get('userActivity').entitySave.answers[index];
            if (answerSaved && answerSaved.id === answerToGuess.id) {
                countFromUserSave++;
            }
            if (answerToGuess.id === this.answersSelected[index].id) {
                count++;
            }
        });

        return {
            oldGrade: countFromUserSave / this.answersOrderToGuess.length,
            newGrade: count / this.answersOrderToGuess.length
        };
    }

    /**
     * définis les réponses présentes dans la sauvegarde pour qu'elle soit affiché aux emplacements des réponses
     * @private
     */
    protected setAnswer(): void {
        if (this.userSave && this.userSave.get('userActivity').entitySave.answers.length > 0) {
            this.answersSelected = this.answersOrderToGuess.map((answerToGuess: ItemAnswerInterface, index: number) => {
                const answerSaved = this.userSave.get('userActivity').entitySave.answers[index];
                return {
                    state: ItemAnswerStateEnum.pristine,
                    id: answerSaved ? answerSaved.id : answerToGuess.id,
                    answer: answerSaved ? answerSaved.answer : null,
                    select: answerSaved ? answerSaved.select : answerToGuess.select,
                    image: answerSaved ? answerSaved.image : null
                };
            });
        }
        this.checkAnswer();
        this.activitiesService.userAnswer.next(this.userSave.get('userActivity').entitySave.answers);
    }

    /**
     * corrige l'activité en attribuant un état bonne, mauvaise ou manquante pour les réponses
     * @private
     */
    protected checkAnswer(): void {
        if (this.testAnswer) {
            this.answersSelected.forEach((answerSelected: ItemAnswerInterface, index: number) => {
                const answerToGuess = this.answersOrderToGuess[index];
                const answerAvailable = this.availableAnswers.find((answer: ItemAnswerInterface) => answer.id === answerSelected.id);
                if (answerSelected.id === answerToGuess.id) {
                    answerSelected.state = ItemAnswerStateEnum.currentlyCorrect;
                    answerAvailable.state = ItemAnswerStateEnum.currentlyCorrect;
                } else {
                    answerSelected.state = ItemAnswerStateEnum.incorrect;
                    answerAvailable.state = ItemAnswerStateEnum.incorrect;
                }
            });
        }

        if (this.answersSelected.length === this.answersOrderToGuess.length) {
            if (this.answersOrderToGuess
                .every((answerToGuess: ItemAnswerInterface, index: number) => answerToGuess.id === this.answersSelected[index].id)) {
                this.answerStatus = answerStatusEnum.correct;
            } else {
                this.answerStatus = answerStatusEnum.wrong;
            }
        } else {
            this.answerStatus = answerStatusEnum.missing;
        }
    }

    /**
     * permet d'initialisé le tableau des réponses selectionnés
     * @param isForSolution permet de savoir si l'on veut que le tableau des réponses sélectionnés soit remplie avec les bonne réponses pour la solution.
     * @private
     */
    private setDefaultAnswersSelected(isForSolution = false): void {
        this.answersSelected = this.answersOrderToGuess.map((answer) => {
            return {
                state: isForSolution ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.pristine,
                id: answer.id,
                answer: isForSolution ? answer.answer : null,
                select: answer.select,
                image: isForSolution ? answer.image : null,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
    }

    /**
     * mélange le tableau des réponses disponible (cliquable)
     * @private
     */
    private makeItShuffled(): void {
        this.availableAnswers = this.answersOrderToGuess.map((answer) => {
            return {
                state: ItemAnswerStateEnum.pristine,
                id: answer.id,
                answer: answer.answer,
                select: answer.select,
                image: answer.image,
                feedback: answer.feedback ? answer.feedback : ''
            };
        });
        this.availableAnswers = shuffle(this.availableAnswers, true);
    }

    /**
     * retourne l'index de la premiere réponse non répondu
     */
    public get currentAnswerToSelect(): number {
        return this.answersSelected.findIndex((answer: ItemAnswerInterface) => this.isTwoColumns ? !answer.answer : !answer.image);
    }

    /**
     * si l'autocorrection active, au clique sur une réponse, on affiche une correction
     * dans le cas contraire on renseigne dans le tableau des réponses sélectionné la réponse cliqué.
     * @param answer
     */
    public clickAnswer(answer: ItemAnswerInterface): void {
        // if answer is already correct it's avoid to click again on it
        if (answer.state === ItemAnswerStateEnum.wasCorrect) {
            return;
        }
        if (!this.disableAllAnswers) {
            this.disableAllAnswers = true;
            if (this.autoCorrection) {
                const answerResult: AnswerResultInterface = {
                    id: +this.activityId.id,
                    isAnswerCorrect: undefined,
                    isLast: undefined
                };
                if (this.answersOrderToGuess[this.currentAnswerToSelect].id === answer.id) {
                    const fieldToEdit = this.isTwoColumns ? 'answer' : 'image';
                    this.answersSelected[this.currentAnswerToSelect].state = ItemAnswerStateEnum.currentlyCorrect;
                    this.answersSelected[this.currentAnswerToSelect][fieldToEdit] = answer[fieldToEdit];
                    answerResult.isAnswerCorrect = true;
                    answer.state = ItemAnswerStateEnum.currentlyCorrect;
                } else {
                    answer.state = ItemAnswerStateEnum.incorrect;
                    this.answersSelected.find(a => a.id === answer.id).state = ItemAnswerStateEnum.incorrect;
                    answerResult.isAnswerCorrect = false;
                }
                answerResult.isLast = this.allAnswerCorrect();
                super.ifFeedbackOpenModal(answer.id);
                this.answerResult.next(answerResult);
                this.animateAndSaveAnswer();
            } else {
                this.answersSelected[this.currentAnswerToSelect] = {
                    state: ItemAnswerStateEnum.pristine,
                    id: answer.id,
                    answer: answer.answer,
                    select: answer.select,
                    image: answer.image,
                    feedback: answer.feedback
                };
                this.disableAllAnswers = false;
            }
        }
    }

    private animateAndSaveAnswer(): void {
        // TODO faire une meilleure animation angular
        setTimeout(() => {
            this.answersSelected.filter(a => a.state === ItemAnswerStateEnum.incorrect).map(a => a.state = ItemAnswerStateEnum.pristine);
            this.disableAllAnswers = false;
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.incorrect).forEach(a => a.state = ItemAnswerStateEnum.pristine);
            this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.currentlyCorrect).forEach(a => a.state = ItemAnswerStateEnum.wasCorrect);
            if (this.allAnswerCorrect()) {
                this.doAction('next', ['save']);
            }
        }, 250);

    }

    private allAnswerCorrect(): boolean {
        return this.currentAnswerToSelect === -1;
    }

    /**
     * récupere dans la config de l'activité la mise en page.
     */
    public getColumnClass(): string {
        return this.answersOrderToGuess.length > 3 ? 'columns-2' : 'columns-1';
    }

    /**
     * réinitialise l'activité.
     * @param resetAllSubscribe
     * @param type
     */
    protected reset(resetAllSubscribe: boolean = false, type = null): Observable<boolean> {
        this.cacheForDisplaySolution = [];
        this.setDefaultAnswersSelected();
        this.makeItShuffled();
        return super.reset(resetAllSubscribe, type);
    }

    /**
     * permet d'afficher la solution de l'exercice
     */
    protected seeAnswerSolution(): void {
        this.cacheForDisplaySolution = this.answersSelected.map((answer, index) => {
            this.availableAnswers[index].state = ItemAnswerStateEnum.currentlyCorrect;
            return {
                state: ItemAnswerStateEnum.pristine,
                id: answer.id,
                answer: answer.answer,
                select: answer.select,
                image: answer.image,
            };
        });
        this.setDefaultAnswersSelected(true);
    }

    /**
     * permet de revoir la réponse de l'utilisateur si il regarde la solution
     */
    reviewAnswer(): void {
        this.answersSelected = this.cacheForDisplaySolution.map((answerInCache: ItemAnswerInterface, index) => {
            const availableAnswer = this.availableAnswers.find((answer: ItemAnswerInterface) => answer.id === answerInCache.id);
            availableAnswer.state = answerInCache.id === this.answersOrderToGuess[index].id && !!answerInCache.answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.pristine;

            return {
                state: answerInCache.id === this.answersOrderToGuess[index].id && !!answerInCache.answer ? ItemAnswerStateEnum.currentlyCorrect : ItemAnswerStateEnum.pristine,
                id: answerInCache.id,
                answer: answerInCache.answer,
                select: answerInCache.select,
                image: answerInCache.image,
            };
        });
    }

    /**
     * create answer entered by the user.
     * no need to create answer because answer already exist.
     * method needed for save in baseActivityComponent
     * @protected
     */
    protected saveAnswer(): Observable<number[]> {
        return of(null);
    }

    /**
     * add class in regard of answer state current reponse and previous response
     * @param index index in the ngfor loop to find the good place where push the class
     * @param answerState current answer in the loop ngfor
     */
    returnClassAnswerState(index: number, answerState: ItemAnswerStateEnum): string {
        // by default this class is add it s used for good answers
        let classToAdd = answerState.toString();
        // if a bad answer is made we add another class to make an grey background effect
        if (this.availableAnswers.find(a => a.state === ItemAnswerStateEnum.incorrect)) {
            const nextPlaceForGoodAnswerImage = this.availableAnswers.filter(a => a.state === ItemAnswerStateEnum.wasCorrect).length;
            if (nextPlaceForGoodAnswerImage === index) {
                classToAdd = classToAdd + ' ' + ItemAnswerStateEnum.incorrect;
            }
        }
        const isCurrentClassAdd = this.currentAnswerToSelect === index ? ' current' : '';
        classToAdd = classToAdd + isCurrentClassAdd;
        return this.currentAnswerToSelect !== index &&  answerState.toString() === ItemAnswerStateEnum.incorrect ? isCurrentClassAdd : classToAdd;
    }
}
