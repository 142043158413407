import {Injectable} from '@angular/core';
import {Angulartics2} from 'angulartics2';
import {DataEntity} from 'octopus-connect';
import {CommunicationCenterService} from '@modules/communication-center';

@Injectable()
export class EventService {

    private currentUser: DataEntity;

    constructor(
        private angulartics2: Angulartics2,
        private communicationCenter: CommunicationCenterService,
    ) {
        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.currentUser = data;
            });
    }

    public trackEvent(action: string, label: string, category: string, value?: string): void{
        this.angulartics2.eventTrack.next({
            action: action,
            properties: {
                label: label,
                category: category,
                value: value ? value : this.currentUser.id,
            },
        });
    }

}
